import styled from 'styled-components';
// this file may contain all the main reusable styled-components from the app

export const H1 = styled.h1`
    font-weight: bold;
    font-size: ${({ theme }) => theme.font.size.big};
    color: ${({ theme }) => theme.color.black.main};
`;

export const H2 = styled(H1)`
    font-size: ${({ theme }) => theme.font.size.regular};
`;

export const DefaultButton = styled.button`
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

export const SimpleBlock = styled.div`
    background-color: ${({ theme }) => theme.color.white.main};
    border-radius: 5px;
`;

export const CapLine = styled.span`
    font-size: ${({ theme }) => theme.font.size.medium};
    color: ${({ theme }) => theme.color.black.main};
    display: block;
`;

export const Text = styled.span`
    font-size: ${({ size, theme }) => size || theme.font.size.small};
    color: ${({ color, theme }) => color || theme.color.black.main};
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    margin: ${({ margin }) => margin || 0};
    display: block;
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
`;

export const Input = styled.input`
    border: none;
    border-radius: ${({ theme }) => theme.radius.s};
    background-color: ${({ theme }) => theme.color.white.main};
    height: 33px;
    width: ${({ width }) => width || '300px'};
    margin: 20px 0;
    padding: 5px 10px;
`;

export const Label = styled.label`
    font-size: ${({ theme }) => theme.font.size.small};
    color: ${({ theme }) => theme.color.grey.main};
    text-align: left;
`;

export const BasePrimaryButton = styled(DefaultButton)`
    border: none;
    border-radius: ${({ theme }) => theme.radius.s};
    background-color: ${({ theme }) => theme.color.blue.primary};
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.color.white.main};
    cursor: pointer;
    margin: ${({ margin }) => margin || 0};
    font-weight: 700;
    padding: 0 16px;

    &:hover {
        background-color: ${({ theme }) => theme.color.blue.medium};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.color.blue.medium};
    }
`;

// FIXME later progressively take BasePrimaryButton instead of PrimaryButton
// (to avoid dealing with !important rules)
export const PrimaryButton = styled(BasePrimaryButton)`
    background-color: ${({ theme }) => theme.color.blue.primary} !important;
    height: 40px !important;
    width: 217px !important;
    &:hover {
        background-color: ${({ theme }) => theme.color.blue.primary2} !important;
    }

    svg {
        margin-left: 20px;
    }
`;

export const SubmitButton = styled(PrimaryButton)`
    background-color: ${({ theme }) => theme.color.black.main} !important;
    margin: ${({ margin }) => margin || '10px 0 0 0'};
`;

export const Flex = styled.div`
    align-items: ${({ align }) => align || 'center'};
    align-content: ${({ alignContent }) => alignContent || 'center'};
    display: flex;
    flex-direction: ${({ column }) => (column ? 'column' : 'row')};
    height: ${({ height }) => height || 'auto'};
    justify-content: ${({ justify }) => justify || 'center'};
    margin: ${({ margin }) => margin || 0};
    padding: ${({ padding }) => padding || 0};
    width: ${({ width }) => width || '100%'};
    max-width: ${({ maxWidth }) => maxWidth || '100%'};
    white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : 'normal')};
    text-align: ${({ textAlign }) => textAlign || 'left'};
    flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'initial')};
`;

export const Ellipsis = styled.span`
    display: inline-block;
    width: ${({ width }) => width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Error = styled.p`
    color: ${({ theme }) => theme.color.red.primary};
    font-size: ${({ theme }) => theme.font.size.small};
    margin: ${({ margin }) => margin || 0};
`;

export const Warning = styled(Error)`
    color: ${({ theme }) => theme.color.orange.main};
`;

export const Success = styled(Error)`
    color: ${({ theme }) => theme.color.green.main};
`;

export const Infos = styled(Error)`
    color: ${({ theme }) => theme.color.blue.primary};
`;

// wrapper for svg icon buttons
export const ButtonIcon = styled.button`
    background-color: transparent;
    display: block;
    border: 0;
    outline: none;
    cursor: pointer;
    color: ${({ color }) => color || 'inherit'};
    margin-top: ${({ marginTop }) => marginTop || 0};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};
    margin-left: ${({ marginLeft }) => (marginLeft !== undefined ? marginLeft : 'auto')};
    margin-right: ${({ marginRight }) => (marginRight !== undefined ? marginRight : 'auto')};
    flex: ${({ flex }) => flex || '0 0 auto'};
    text-align: ${({ textAlign }) => textAlign || 'left'};

    svg {
        width: ${({ size }) => size || 'auto'};
        height: ${({ size }) => size || 'auto'};
        margin: 0 auto;
        display: block;
    }

    &:after {
        content: ${({ label }) => {
            if (label) return `'${label}'`;

            return '';
        }};
        font-size: 9px;
        display: block;
        text-align: center;
        text-transform: capitalize;
        margin-top: 5px;
    }

    &:hover {
        background-color: transparent !important;

        svg {
            color: ${({ color }) => color || 'grey'};
            opacity: 0.5;
        }
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        svg {
            cursor: inherit;
        }
    }
`;

export const BlueContainer = styled.div`
    background-color: ${({ theme }) => theme.color.blue.light};
    height: 100vh;
    width: 100%;
    padding: 70px;
    position: fixed;
    top: 0;
    left: 140px;
`;

export const NavControlContainer = styled(Flex).attrs({
    justify: 'space-between',
    width: 'auto',
    padding: '0 0 0 90px',
})``;
