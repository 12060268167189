import type { SessionFilter, SessionSort } from '../core/domain/Session';
import type { ExternalSessionConfigurationPort } from '../core/sessions/ExternalSessionConfigurationPort';

const SESSION_PREFIX = 'sessions';

export class ExternalConfigurationProvider implements ExternalSessionConfigurationPort {
    private readonly sortKey = `${SESSION_PREFIX}-sort`;
    private readonly filterKey = `${SESSION_PREFIX}-filter`;

    saveSessionsSort(sort: SessionSort): void {
        localStorage.setItem(this.sortKey, JSON.stringify(sort));
    }

    loadSessionsSort(): SessionSort {
        const storedSort = localStorage.getItem(this.sortKey);

        return storedSort ? JSON.parse(storedSort) : [];
    }

    saveSessionsFilter(filter: SessionFilter): void {
        localStorage.setItem(this.filterKey, JSON.stringify(filter));
    }

    loadSessionsFilter(): SessionFilter {
        const storedFilter = localStorage.getItem(this.filterKey);

        return storedFilter ? JSON.parse(storedFilter) : { items: [] };
    }
}
