import * as Sentry from '@sentry/browser';
import { SubmissionError } from 'redux-form';
import apiEndpoint from '../../../core/legacy/utils/api';
import { DELETE, GET, PATCH, POST } from '../../../core/legacy/utils/http';

export const getNewCalibrationProcessRequest = async (imageId) => {
    try {
        const calibrationProcess = await POST(`${apiEndpoint}/calibration-process`, {
            imageId,
        });

        return calibrationProcess;
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileCreatingCalibrationPoint' });
    }
};

export const updateCalibrationPointRequest = async (calibrationProcessId, calibrationPoint, pointData) => {
    try {
        return await PATCH(
            `${apiEndpoint}/calibration-process/${calibrationProcessId}/calibration-point/${calibrationPoint.id}`,
            {
                pointData,
            },
        );
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileUpdatingCalibrationPoint' });
    }
};

export const updateCalibrationPixelsRequest = async (calibrationProcessId, calibrationPoint, pointData) => {
    try {
        return await PATCH(
            `${apiEndpoint}/calibration-process/${calibrationProcessId}/calibration-point/${calibrationPoint.id}`,
            {
                pointData,
            },
        );
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileUpdatingCalibrationPoint' });
    }
};

export const deleteCalibrationPointRequest = async (calibrationProcessId, calibrationPointId) => {
    try {
        await DELETE(
            `${apiEndpoint}/calibration-process/${calibrationProcessId}/calibration-point/${calibrationPointId}`,
        );
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileDeletingCalibrationPoint' });
    }
};

export const addCalibrationPointRequest = async (calibrationProcessId, pixels) => {
    try {
        const calibrationProcess = await POST(
            `${apiEndpoint}/calibration-process/${calibrationProcessId}/calibration-point`,
            {
                pixels,
            },
        );

        return calibrationProcess;
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileCreatingCalibrationPoint' });
    }
};

export const generateCalibrationProcessResultRequest = async (calibrationProcessId) => {
    try {
        const result = await GET(
            `${apiEndpoint}/calibration-process/${calibrationProcessId}/calibration-process-result`,
        );

        if (!result.success) {
            throw result;
        }

        return result;
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw e;
    }
};
