import { FC, useCallback, useState } from 'react';
import { GreySquareButton as OptionsButton } from '../../../../../design_system/inputs/buttons/GreySquareButton';
import { StyledMenu } from '../../../ui/Menu/style';
import { CalibrateFromImageMenuItem } from './menu-items/CalibrateFromImageMenuItem';
import { DownloadImageMenuItem } from './menu-items/DownloadImageMenuItem';
import { MenuList } from '@mui/material';
import { OpenGoogleMapsMenuItem } from './menu-items/OpenGoogleMapsMenuItem';
import { OpenNetworkMapMenuItem } from './menu-items/OpenNetworkMapMenuItem';
import { OptionsIcon } from '../../../../../design_system/icons/OptionsIcon';

interface OptionsMenuProps {
    isCalibrationScreen?: boolean;
}

export const OptionsMenu: FC<OptionsMenuProps> = ({ isCalibrationScreen = false }) => {
    const [anchorEl, setAnchorEl] = useState<unknown>(null);
    const open = Boolean(anchorEl);

    const handleClickMenu = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event?.stopPropagation();
        setAnchorEl(null);
    };
    const handleActionExecuted = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    return (
        <>
            <OptionsButton onClick={handleClickMenu}>
                <OptionsIcon themeStyle="dark" />
            </OptionsButton>
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuList>
                    <DownloadImageMenuItem onActionExecuted={handleActionExecuted} />
                    {!isCalibrationScreen && <CalibrateFromImageMenuItem onActionExecuted={handleActionExecuted} />}
                    {!isCalibrationScreen && <OpenNetworkMapMenuItem onActionExecuted={handleActionExecuted} />}
                    <OpenGoogleMapsMenuItem onActionExecuted={handleActionExecuted} />
                </MenuList>
            </StyledMenu>
        </>
    );
};
