import {
    GridSlotProps,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { FC } from 'react';
import { theme } from '../../legacy/style/theme';

const slotProps = { button: { sx: { color: theme.color.black.main } } };

const CustomToolbar: FC<GridSlotProps['toolbar']> = ({ setPanelElement: setFilterElement, ...props }) => (
    <GridToolbarContainer
        {...props}
        sx={{
            ...props.sx,
            position: 'absolute',
            top: -20,
            left: -24,
            zIndex: 10,
            transform: 'translateY(-100%)',
        }}
        ref={setFilterElement}
    >
        <GridToolbarColumnsButton slotProps={slotProps} />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} slotProps={slotProps} />
        <GridToolbarDensitySelector slotProps={slotProps} />
        <GridToolbarFilterButton slotProps={slotProps} />
    </GridToolbarContainer>
);

export default CustomToolbar;
