import type { Rubric } from '../../domain/Roaddata';
import { createCommand } from '../../utils';
import { moduleName, rubricToDisplayIdsUpdated } from '../state';
import { AddReferencesToDisplayIdCommand } from './AddReferencesToDisplayIdCommand';
import { RemoveReferencesToDisplayIdCommand } from './RemoveReferencesToDisplayIdCommand';
import { UpdateFiltersCommand } from './UpdateFiltersCommand';

export const ToggleRubricToDisplayIdCommand = createCommand(
    `${moduleName}/TOGGLE_RUBRIC_TO_DISPLAY_ID`,
    async (rubric: Rubric, { dispatch, extra }) => {
        const previouslyStoredRubricIds: Array<string> = [];
        const rubricId = rubric.id;
        const rubricIdsFromProvider = extra.storageProvider.getRubricIds();
        if (rubricIdsFromProvider) {
            previouslyStoredRubricIds.push(...rubricIdsFromProvider);
        }

        const newStoredRubricIds: Array<string> = [];
        if (previouslyStoredRubricIds.includes(rubricId)) {
            newStoredRubricIds.push(...previouslyStoredRubricIds.filter((id) => id !== rubricId));
            dispatch(RemoveReferencesToDisplayIdCommand(rubric.references.map((r) => r.id)));
        } else {
            newStoredRubricIds.push(rubricId, ...previouslyStoredRubricIds);
            dispatch(AddReferencesToDisplayIdCommand(rubric.references.map((r) => r.id)));
        }

        extra.storageProvider.setRubricIds(newStoredRubricIds);
        dispatch(rubricToDisplayIdsUpdated({ rubricIds: newStoredRubricIds }));

        dispatch(UpdateFiltersCommand());
    },
);
