import { Button, SxProps, Tooltip, Typography } from '@mui/material';
import { useMemo, FC, ReactNode, BaseSyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../legacy/style/theme';
import { useAppDispatch } from '../../../types';
import { StartAiAnalysisWorkflowOnSessionsCommand } from '../../../core/automatic_analysis/StartAiAnalysisWorkflowOnSessionsCommand';
import { OpenModalWithContentCommand } from '../../../core/sessions/commands/OpenModalWithContentCommand';
import { SessionsModalTypes } from '../../../core/legacy/reducers/modals';

export type TagVariant = 'calibrationNotDone' | 'calibration' | 'aiOngoing' | 'aiDone';

interface StatusTagProps {
    variant: TagVariant;
    usedAiModelsNames: (string | undefined)[];
    remainingTime?: number;
    sessionId: string;
}

const variantColors: Record<TagVariant, SxProps> = {
    aiDone: { backgroundColor: theme.color.blue.selected, color: theme.color.blue.primary },
    aiOngoing: { backgroundColor: theme.color.orange.light, color: theme.color.orange.dark },
    calibration: { backgroundColor: theme.color.green.light, color: theme.color.green.dark },
    calibrationNotDone: { backgroundColor: theme.color.yellow.medium, color: theme.color.yellow.dark },
};

const variantTitles: Record<TagVariant, string> = {
    aiDone: 'labels.ai_analyzed',
    aiOngoing: 'labels.ai-analysis-ongoing',
    calibration: 'session.calibrationDone',
    calibrationNotDone: 'session.calibrationNotDone',
};

const buttonStyle: SxProps = {
    padding: '6px 12px',
    borderRadius: '4px',
    fontWeight: 400,
    fontSize: '13px',
    textTransform: 'none',
    maxWidth: '100%',
};

export const StatusTag: FC<StatusTagProps> = ({ sessionId, variant, usedAiModelsNames, remainingTime }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const getTooltipTitle = useMemo((): NonNullable<ReactNode> => {
        if (remainingTime) {
            return `${t('labels.ai_analysis_remaining_time')} ${remainingTime} min`;
        }
        if (usedAiModelsNames.length === 0) {
            return '';
        }

        return (
            <>
                {usedAiModelsNames.map((name, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Typography key={`${name}_${i}`} sx={{ fontSize: 12, lineHeight: 1 }}>
                        {name}
                    </Typography>
                ))}
            </>
        );
    }, [remainingTime, usedAiModelsNames]);

    const handleStatusClick = useCallback(
        (e: BaseSyntheticEvent) => {
            e.stopPropagation();

            if (variant === 'calibration' || variant === 'aiDone')
                dispatch(StartAiAnalysisWorkflowOnSessionsCommand([sessionId]));
            if (variant === 'calibrationNotDone')
                dispatch(
                    OpenModalWithContentCommand({
                        modalToDisplay: SessionsModalTypes.SESSIONS_CALIBRATE_SESSION,
                        sessionIds: [sessionId],
                    }),
                );
        },
        [sessionId, variant],
    );

    return (
        <Tooltip title={getTooltipTitle} arrow>
            <Button onClick={handleStatusClick} sx={{ ...buttonStyle, ...variantColors[variant] }}>
                {t(variantTitles[variant])}
            </Button>
        </Tooltip>
    );
};
