import { Grid, Stack, SxProps, Typography } from '@mui/material';
import type { FC } from 'react';
import { LogoutIconButton } from '../../authentication/LogoutIconButton';
import { RoadcareLogoBlue } from '../logo/RoadcareLogoBlue';
import { useAppSelector } from '../../../types';
import { theme } from '../../legacy/style/theme';
import { organizationQuery } from '../../../core/organization/queries';

const logoutSx: SxProps = {
    display: 'flex',
    gap: 3,
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: theme.color.black.main,
};
export const Header: FC = () => {
    const organization = useAppSelector(organizationQuery);

    return (
        <Stack direction="row" sx={{ justifyContent: 'space-between', my: 3 }}>
            {/* empty div with same width as logoutButton, to correctly center title */}
            <Grid container>
                <Grid item xs={3} />
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <RoadcareLogoBlue height="44px" />
                </Grid>
                <Grid item xs={3} sx={logoutSx}>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}> {organization?.name}</Typography>
                    <LogoutIconButton />
                </Grid>
            </Grid>
        </Stack>
    );
};
