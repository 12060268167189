import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';
import CatalogEnum from '../../../../../core/legacy/utils/CatalogEnum';
import { ReactComponent as DegradationLogo } from '../../../../../assets/icons/degradations.svg';
import { ReactComponent as MarkingsLogo } from '../../../../../assets/icons/markings.svg';
import { ReactComponent as PinLogo } from '../../../../../assets/icons/pin-outline.svg';
import { ReactComponent as MeasureLogo } from '../../../../../assets/icons/measure.svg';
import { ReactComponent as RoadsignsLogo } from '../../../../../assets/icons/roadsigns.svg';
import { ReactComponent as SelectPointLogo } from '../../../../../assets/icons/select-point.svg';
import { ReactComponent as MachineLearningLogo } from '../../../../../assets/icons/machine-learning.svg';
import { ReactComponent as EditLogo } from '../../../../../assets/icons/edit.svg';
import { getCatalogBySelectedRubricSelector } from '../../../../../core/legacy/selectors/streetView';
import CatalogTree from '../../annotationLibrary/CatalogTree';
import useCatalogMenuEvents from './use-catalog-menu-events';
import { Container, ButtonContainer, Button, StyledMenu } from './styles';
import { useAppSelector } from '../../../../../types';
import { catalogsQuery } from '../../../../../core/catalogs/queries';
import type { Catalog } from '../../../../../core/domain/Roaddata';
import { sessionQuery } from '../../../../../core/streetViewer/queries';

const buildCatalogButton = (
    catalog: Catalog,
    label,
    buttonHovered,
    selectedCatalog,
    isMenuOpen,
    handleOpenCatalog,
    anchorEl,
    handleCloseCatalog,
) => {
    let Logo;
    switch (catalog.name) {
        case CatalogEnum.MEASURES:
            Logo = MeasureLogo;
            break;
        case CatalogEnum.ROADSIGNS:
            Logo = RoadsignsLogo;
            break;
        case CatalogEnum.DEGRADATIONS:
            Logo = DegradationLogo;
            break;
        case CatalogEnum.MARKINGS:
            Logo = MarkingsLogo;
            break;
        case CatalogEnum.REPERAGE:
        case CatalogEnum.REFERENTIAL:
            Logo = PinLogo;
            break;
        case CatalogEnum.AI:
        case CatalogEnum.IA:
            Logo = MachineLearningLogo;
            break;
        case CatalogEnum.RELEVES:
            Logo = EditLogo;
            break;
        default:
            Logo = MeasureLogo;
            break;
    }

    return (
        <ButtonContainer
            key={catalog.id}
            hovered={buttonHovered.catalogId === catalog.id}
            selected={selectedCatalog?.id === catalog.id}
        >
            <Button
                aria-controls={isMenuOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? 'true' : undefined}
                onMouseOver={(event) => handleOpenCatalog(event, catalog)}
                label={label}
                type="button"
                size="24px"
            >
                <Logo />
            </Button>
            <StyledMenu
                open={isMenuOpen}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                onClose={handleCloseCatalog}
                MenuListProps={{ onMouseLeave: handleCloseCatalog }}
                TransitionComponent={Fade}
                hideBackdrop
            >
                <CatalogTree />
            </StyledMenu>
        </ButtonContainer>
    );
};

const CatalogMenu = () => {
    const { t } = useTranslation();
    const catalogs = useAppSelector(catalogsQuery);
    const selectedCatalog = useAppSelector(getCatalogBySelectedRubricSelector);
    const session = useAppSelector(sessionQuery);

    const {
        handleOpenCatalog,
        handleCloseCatalog,
        handleClickOnMovePointButton,
        movePointSelected,
        buttonHovered,
        isMenuOpen,
        anchorEl,
    } = useCatalogMenuEvents();

    if (!session?.calibrationDone || !catalogs) return null;

    return (
        <Container>
            <ButtonContainer onClick={handleClickOnMovePointButton} selected={movePointSelected}>
                <Button size="24px" label={t(`catalog.selectPoint`)} type="button">
                    <SelectPointLogo />
                </Button>
            </ButtonContainer>
            {catalogs.map((catalog) =>
                buildCatalogButton(
                    catalog,
                    t(`catalog.${catalog.name.toLowerCase()}`, { defaultValue: catalog.name }),
                    buttonHovered,
                    selectedCatalog,
                    isMenuOpen,
                    handleOpenCatalog,
                    anchorEl,
                    handleCloseCatalog,
                ),
            )}
        </Container>
    );
};

export default CatalogMenu;
