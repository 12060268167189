import { allRubricsIdsQuery, allRubricsQuery } from '../../catalogs/queries';
import { createCommand } from '../../utils';
import { applyFilterToImageSet, moduleName, referenceToDisplayIdsUpdated, rubricToDisplayIdsUpdated } from '../state';

export const InitFiltersCommand = createCommand(
    `${moduleName}/INIT_FILTERS_COMMAND`,
    async (_, { dispatch, extra, getState }) => {
        const previouslyStoredApplyFilterToImage = extra.storageProvider.getIsFilterApplied();
        const applyFilterToImage = previouslyStoredApplyFilterToImage ?? false;

        extra.storageProvider.setIsFilterApplied(applyFilterToImage);
        await dispatch(applyFilterToImageSet({ applyFilterToImage }));

        const previouslyStoredFilteredRubricIds = extra.storageProvider.getRubricIds();
        const rubricIds: Array<string> = [];
        if (previouslyStoredFilteredRubricIds !== null) {
            rubricIds.push(...previouslyStoredFilteredRubricIds);
        } else {
            const state = getState();
            const initialRubricIds = allRubricsIdsQuery(state);
            rubricIds.push(...initialRubricIds);
        }

        await dispatch(rubricToDisplayIdsUpdated({ rubricIds }));

        const previouslyStoredFilteredReferenceIds = extra.storageProvider.getReferenceIds();
        const referenceIds: Array<string> = [];
        if (previouslyStoredFilteredReferenceIds !== null) {
            referenceIds.push(...previouslyStoredFilteredReferenceIds);
        } else {
            const state = getState();
            const initialReferenceIds = allRubricsQuery(state)
                .map((r) => r.references.map((ref) => ref.id))
                .flat();
            referenceIds.push(...initialReferenceIds);
        }

        await dispatch(referenceToDisplayIdsUpdated({ referenceIds }));
    },
);
