import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { ChangeEvent, FC, FormEvent, MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginCommand } from '../../core/authentication/LoginCommand';
import { logFailedErrorQuery, logOngoingQuery } from '../../core/authentication/queries';
import useQueryParams from '../../core/legacy/utils/useQueryParams';
import { emailFormat, required } from '../../core/legacy/utils/validation';
import { useAppDispatch, useAppSelector } from '../../types';
import { theme } from '../legacy/style/theme';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

type FormValues = {
    email: string;
    emailError?: string;
    password: string;
    passwordError?: string;
    showPassword: boolean;
};

export const LoginForm: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [values, setValues] = useState<FormValues>({
        email: '',
        password: '',
        showPassword: false,
    });

    // Validation
    const { expired } = useQueryParams();
    const ongoing = useAppSelector(logOngoingQuery);
    const error = useAppSelector(logFailedErrorQuery);
    const applyValidation = (formValues: FormValues): void => {
        const validateField = (validators: Array<(value: string) => string | null>, value: string) =>
            validators.map((validator) => validator(value) ?? undefined).filter((err) => err !== undefined)[0];
        setValues({
            ...formValues,
            emailError: validateField([emailFormat, required], formValues.email),
            passwordError: validateField([required], formValues.password),
        });
    };
    const isValid = !values.emailError && !values.passwordError;

    // Handlers
    const handleSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            applyValidation(values);
            if (isValid) {
                dispatch(
                    LoginCommand({
                        email: values.email,
                        password: values.password,
                    }),
                );
            }
        },
        [values, dispatch],
    );
    const handleEmailChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            applyValidation({
                ...values,
                email: event.target.value,
            });
        },
        [values, setValues],
    );
    const handlePasswordChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            applyValidation({
                ...values,
                password: event.target.value,
            });
        },
        [values, setValues],
    );
    const handleClickShowPassword = useCallback(() => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    }, [values, setValues]);
    const handleMouseDownPassword = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }, []);

    return (
        <Paper component="form" elevation={3} onSubmit={handleSubmit}>
            <Stack paddingX={4} paddingTop={6} paddingBottom={1} gap={4} alignItems="center">
                {expired && (
                    <Typography color={theme.color.red.primary} fontSize={theme.font.size.small}>
                        {t('login.expiredMessage')}
                    </Typography>
                )}
                <TextField
                    id="email"
                    name="email"
                    label={t('login.email')}
                    type="text"
                    value={values.email}
                    onChange={handleEmailChange}
                    fullWidth
                    error={values.emailError !== undefined}
                    helperText={t(values.emailError ?? '')}
                />
                <FormControl variant="outlined" fullWidth error={values.passwordError !== undefined}>
                    <InputLabel htmlFor="password">{t('login.password')}</InputLabel>
                    <OutlinedInput
                        id="password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handlePasswordChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label={t('login.password')}
                    />
                    <FormHelperText id="password">{t(values.passwordError ?? '')}</FormHelperText>
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                    fullWidth
                    size="large"
                    disabled={ongoing}
                >
                    {ongoing && <CircularProgress sx={{ color: 'inherit', mr: 1 }} size={16} />}
                    {t('login.connect')}
                </Button>
                <Typography
                    height="25px"
                    color={theme.color.red.primary}
                    fontSize={theme.font.size.small}
                    component="div"
                >
                    {error ? t('login.wrong_email_or_password') : ''}
                </Typography>
            </Stack>
        </Paper>
    );
};
