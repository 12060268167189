import type { FC } from 'react';
import type {
    NetworkRoadDataGeojsonProperties,
    NetworkRoadDataProperties,
} from '../../../../../../core/networkMap/ExternalNetworkMapPort';
import Content from '../Content';
import ButtonGoToSession from '../../Shared/ButtonGoToSession';
import ButtonHideSession from '../../Shared/ButtonHideSession';
import useNetworkProperties from '../useNetworkProperties';

type Props = {
    networkProperties: NetworkRoadDataGeojsonProperties | null;
};

const RoadDataPopupOnClick: FC<Props> = ({ networkProperties }) => {
    if (!networkProperties) {
        return null;
    }

    const properties: NetworkRoadDataProperties = useNetworkProperties(networkProperties);

    return (
        <>
            <Content properties={properties} />
            <ButtonGoToSession
                sessionId={properties.sessionId}
                cumulStartSession={Number(properties.imageCumulStart)}
            />
            <ButtonHideSession sessionId={properties.sessionId} />
        </>
    );
};

export default RoadDataPopupOnClick;
