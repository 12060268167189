import { FC, useCallback } from 'react';
import { annotationInEditionSelector, selectedRoadDataSelector } from '../../../../../core/legacy/selectors/streetView';
import { AttributeValueComp } from './AttributeValue';
import { translateAttribute, translateRubric } from '../../../../../core/legacy/utils/translations';
import {
    roadDataIdToDelete,
    selectAnnotationInEditionAndJump,
    saveEditedAnnotation,
} from '../../../../../core/legacy/actions';
import { Text } from '../../../style';
import { sortedReferencesByRubricIdQuery } from '../../../../../core/catalogs/queries';
import SelectedElementDetails from '../../ui/panels/right-panel/SelectedElementDetails';
import { Attributes, ElementLayout as LayoutClassName, Value } from '../../ui/panels/right-panel/styles';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { useSortAndFilterAttributeValues } from './use-sort-and-filter-attribute-values';
import type { Reference, RoadData, RoadDataTag } from '../../../../../core/domain/Roaddata';
import { useTranslation } from 'react-i18next';
import { SubClassComp } from './SubClass';
import { allTagsQuery } from '../../../../../core/tags/queries';
import { updateRoaddataTags } from '../../../../../core/actions/streetView';

const findRoadDataReference = (roadData?: RoadData): Reference | undefined => roadData?.reference ?? undefined;

const SelectedRoadDataDetails: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const selectedRoadData: RoadData | undefined = useAppSelector(selectedRoadDataSelector);
    const tags: RoadDataTag[] = useAppSelector(allTagsQuery);
    const annotationInEdition = useAppSelector(annotationInEditionSelector);
    const classAttributeName = translateAttribute('class');
    const rubricName = translateRubric(selectedRoadData?.name);
    const references = useAppSelector(sortedReferencesByRubricIdQuery(selectedRoadData?.rubricId));
    const hasTags = Boolean(selectedRoadData?.tags?.length);

    const handleEdit = useCallback(
        async (roadDataId: string) => {
            if (!annotationInEdition) {
                dispatch(selectAnnotationInEditionAndJump(roadDataId));
            } else {
                dispatch(saveEditedAnnotation());
            }
        },
        [annotationInEdition, dispatch],
    );
    const handleDelete = useCallback((roadDataId) => dispatch(roadDataIdToDelete(roadDataId)), [dispatch]);
    const handleTag = useCallback(async () => {
        if (!selectedRoadData) return;
        await dispatch(
            updateRoaddataTags(selectedRoadData.id, hasTags ? [] : tags.filter((tag) => tag.code === 'WARNING')),
        );
    }, [dispatch, hasTags, selectedRoadData, tags]);

    const roadDataReference = findRoadDataReference(selectedRoadData) ?? null;
    const shouldShowSubclass = references ? references?.length > 0 : false;

    const { filterAttributeValues, sortAttributeValues } = useSortAndFilterAttributeValues(selectedRoadData);
    const filteredAttributeValues = filterAttributeValues(selectedRoadData?.attributeValues);
    const sortedAttributeValues = sortAttributeValues(filteredAttributeValues);

    if (!selectedRoadData) {
        return null;
    }

    return (
        <SelectedElementDetails
            title={t('streetView.informations')}
            selected={!!annotationInEdition}
            onEdit={() => handleEdit(selectedRoadData.id)}
            onDelete={() => handleDelete(selectedRoadData.id)}
            onTag={handleTag}
            tagged={hasTags}
        >
            <Attributes>
                <LayoutClassName>
                    <Text>{classAttributeName}</Text>
                    <Value>{rubricName}</Value>
                </LayoutClassName>
                {shouldShowSubclass && (
                    <SubClassComp
                        attributeName={translateAttribute('sub_class')}
                        roadData={selectedRoadData}
                        reference={roadDataReference}
                    />
                )}
                {sortedAttributeValues.map((attributeValue) => {
                    return (
                        <AttributeValueComp
                            key={attributeValue.id}
                            roadData={selectedRoadData}
                            attributeValue={attributeValue}
                        />
                    );
                })}
            </Attributes>
        </SelectedElementDetails>
    );
};

export default SelectedRoadDataDetails;
