import React, { useCallback, useState } from 'react';
import { Text } from '../../../style';
import { EditableValue, ElementLayout as Layout, Value as ScValue } from '../../ui/panels/right-panel/styles';
import { useDispatch } from 'react-redux';
import AttributeInput from '../../ui/panels/right-panel/values/AttributeInput';
import { useTranslation } from 'react-i18next';
import { CalibrationPoint, CalibrationPointAttribute } from '../../propTypes';
import { updateCoordinateOfCalibrationPoint } from '../../../../../core/legacy/actions';

const CalibrationAttribute = ({ calibrationPoint, attribute }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [editionMode, setEditionMode] = useState(false);
    const enableEditionMode = useCallback(() => {
        setEditionMode(true);
    });
    const disableEditionMode = useCallback(() => setEditionMode(false));

    const save = useCallback(async (newAttributeValue) => {
        const valueAsNumber = +newAttributeValue;
        if (!Number.isNaN(valueAsNumber)) {
            dispatch(updateCoordinateOfCalibrationPoint(calibrationPoint, attribute.type, valueAsNumber));
        }
        disableEditionMode();
    });

    const { name: rawName, value, isEditable, unit } = attribute;

    const name = t(`calibration.${rawName}`);

    if (!editionMode && !isEditable) {
        return (
            <Layout>
                <Text>{name}</Text>
                <ScValue>
                    {value} {unit}
                </ScValue>
            </Layout>
        );
    }

    if (!editionMode && isEditable) {
        return (
            <Layout editable onClick={enableEditionMode}>
                <Text>{name}</Text>
                <EditableValue>
                    {value} {unit}
                </EditableValue>
            </Layout>
        );
    }

    return (
        <Layout>
            <Text>{name}</Text>
            <AttributeInput type="number" value={value ?? ''} save={save} cancel={disableEditionMode} />
        </Layout>
    );
};

CalibrationAttribute.propTypes = {
    calibrationPoint: CalibrationPoint.isRequired,
    attribute: CalibrationPointAttribute.isRequired,
};

export default CalibrationAttribute;
