import type { SessionsModalTypes } from '../../../../../../core/legacy/reducers/modals';
import ResultIcon from '../ResultIcon';
import GenericModal, { GenericModalProps } from '../GenericModal';
import { useTranslation } from 'react-i18next';
import { ContentContainer, ModalCenterSubTitle, ModalCenterTitle } from '../styles';
import type { FC } from 'react';
import type { GenericModalOptions } from '../../../../../components/propTypes';

interface FailureModalProps extends Pick<GenericModalProps, 'children' | 'onCancel' | 'onValidate'> {
    modalType: keyof typeof SessionsModalTypes;
    options?: GenericModalOptions;
    subtitle: string;
    title: string;
}

const FailureModal: FC<FailureModalProps> = ({ children, subtitle, title, options = {}, ...props }) => {
    const { t } = useTranslation();
    const opts = { ...options, hideCancelButton: true, centerButtons: true };

    return (
        <GenericModal options={opts} validateButtonText={t('labels.backToSessions')} {...props}>
            <ContentContainer>
                <ResultIcon failure />
                <ModalCenterTitle>{title}</ModalCenterTitle>
                <ModalCenterSubTitle>{subtitle}</ModalCenterSubTitle>
                {children}
            </ContentContainer>
        </GenericModal>
    );
};

export default FailureModal;
