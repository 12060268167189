import styled from 'styled-components';

export const MainContainer = styled.div`
    margin: 0 0 20px 0;
`;

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    width: ${({ width }) => width || 'auto'};
    align-content: center;
    input {
        outline: none;
    }
    button {
        position: absolute;
        right: 5px;
        top: 15px;
        margin: 20px 0 0 0;
    }
`;
