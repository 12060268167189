import { createCommand } from '../../utils';
import { currentMapBoundsStored, moduleName } from '../state';
import type { LngLatBounds } from 'mapbox-gl';

export const StoreCurrentMapBoundsCommand = createCommand(
    `${moduleName}/STORE_CURRENT_MAP_BOUNDS`,
    async (mapBounds: LngLatBounds, { dispatch }) => {
        dispatch(currentMapBoundsStored(mapBounds));
    },
);
