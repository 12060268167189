import { translateAttribute } from '../../../../../core/legacy/utils/translations';
import type { FC } from 'react';
import type { Landmark } from '../../../../../core/domain/Roaddata';

interface LandmarkValueProps {
    landmark?: Landmark;
    unit?: string;
}

const LandmarkValue: FC<LandmarkValueProps> = ({ landmark, unit = '' }) =>
    landmark ? (
        <>
            {translateAttribute(landmark.label)} {landmark.sign} {landmark.abd} {unit ?? ''}
        </>
    ) : null;

export default LandmarkValue;
