import { FC, useCallback } from 'react';
import type { AttributeValue, Value } from '../../../../../../core/domain/Roaddata';
import AttributeInput from '../../../ui/panels/right-panel/values/AttributeInput';

type AttributeValueNumberInputProps = {
    attributeValue: AttributeValue;
    save: (value: Partial<Value>) => void;
    cancel: () => void;
};

const AttributeValueNumberInput: FC<AttributeValueNumberInputProps> = ({ attributeValue, save, cancel }) => {
    const saveNumeric = useCallback(
        (value) =>
            save({
                numeric: value.length > 0 ? value : 0,
            }),
        [],
    );

    return (
        <AttributeInput type="number" value={attributeValue.value.numeric ?? ''} save={saveNumeric} cancel={cancel} />
    );
};

export default AttributeValueNumberInput;
