const useQueryParams = () => {
    const params = window.location.search.slice(1).split('&');

    const result = {};

    params.forEach((param) => {
        const [key, value] = param.split('=');
        result[key] = decodeURIComponent(value || '');
    });

    return JSON.parse(JSON.stringify(result));
};

export default useQueryParams;
