import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import { ReactComponent as Edit } from '../../../../../../assets/icons/edit.svg';
import { ReactComponent as Trash } from '../../../../../../assets/icons/trash.svg';
import { H2 } from '../../../../style';
import { Actions, GridLayout } from './styles';
import type { FC, PropsWithChildren } from 'react';
import { theme } from '../../../../style/theme';
import { RightPanelButton } from './common';

interface SelectedElementDetailsProps extends PropsWithChildren {
    onDelete: () => void;
    onEdit: () => void;
    onTag?: () => void;
    selected: boolean;
    tagged?: boolean;
    title: string;
}

const SelectedElementDetails: FC<SelectedElementDetailsProps> = ({
    children,
    onDelete,
    onEdit,
    onTag,
    selected,
    tagged,
    title,
}) => {
    const handleKeyDown = (event) => {
        event.target.blur();
    };

    return (
        <GridLayout>
            <H2>{title}</H2>
            <Actions>
                {onTag && (
                    <RightPanelButton
                        onClick={onTag}
                        sx={{ color: theme.color[`${tagged ? 'orange' : 'black'}`].main }}
                        title="tag-icon"
                    >
                        <ReportOutlinedIcon />
                    </RightPanelButton>
                )}
                <RightPanelButton onClick={onEdit} onKeyDown={handleKeyDown} selected={selected} title="edit-icon">
                    <Edit />
                </RightPanelButton>
                <RightPanelButton onClick={onDelete} title="trash-icon">
                    <Trash />
                </RightPanelButton>
            </Actions>
            {children}
        </GridLayout>
    );
};

export default SelectedElementDetails;
