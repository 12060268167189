import { Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { aiAnalysesOngoingOnSessionQuery } from '../../../../../../core/automatic_analysis/queries';
import type { SessionId } from '../../../../../../core/domain/Session';
import { useAppSelector } from '../../../../../../types';
import { ETA } from '../../../../../design_system/data_display/ETA';
import { AnalysisIcon } from '../../../../../design_system/icons/AnalysisIcon';
import { theme } from '../../../../style/theme';

type Props = {
    sessionId: SessionId;
};

export const ExecutionOngoingBaner: FC<Props> = ({ sessionId }) => {
    const { t } = useTranslation();
    const executionsOngoing = useAppSelector(aiAnalysesOngoingOnSessionQuery(sessionId));

    return (
        <Stack
            direction="row"
            alignItems="center"
            gap={2}
            sx={{
                paddingY: 4,
                paddingX: 3,
                backgroundColor: theme.color.blue.light3,
            }}
        >
            <AnalysisIcon />
            <Stack gap={0.5}>
                <Typography fontSize={theme.font.size.regular} fontWeight="700">
                    {t('ai_analysis.analysis_ongoing_on_session')}
                </Typography>
                <Typography fontSize={theme.font.size.small} fontWeight="500" color={theme.color.grey.main}>
                    {t('labels.ai_analysis_remaining_time')} <ETA seconds={executionsOngoing[0]?.remainingInSeconds} />
                </Typography>
            </Stack>
        </Stack>
    );
};
