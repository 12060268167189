import { createTheme, Theme } from '@mui/material';
import { theme } from '../legacy/style/theme';

const muiTheme: Readonly<Theme> = createTheme({
    typography: {
        fontFamily: "'Hind Siliguri', sans-serif",
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '12px',
                    fontWeight: 400,
                    backgroundColor: theme.color.grey.lighter,
                    color: theme.color.grey.dark,
                    padding: '12px 24px',
                },
            },
        },
    },
});

export default muiTheme;
