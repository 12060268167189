import styled from 'styled-components';
import { Flex } from '../../../style';

export const FolderContainer = styled.div`
    * {
        box-sizing: border-box;
        user-select: none;
    }
`;
export const FolderHeader = styled(Flex).attrs({
    justify: 'space-between',
})`
    background: ${({ theme }) => theme.color.blue.light};
    border-radius: 5px;
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: 700;
    margin-bottom: 5px;
    padding: 10px 12px 10px 8px;

    &:hover {
        cursor: pointer;
    }
`;
export const FolderContent = styled.div`
    padding-left: 15px;
`;
