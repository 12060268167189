import type { FC, ReactNode } from 'react';
import { Popup } from 'react-map-gl';

type Props = {
    popupCoordinates: [number, number];
    popupIsShown: boolean;
    hidePopup: () => void;
    children: ReactNode;
    closeButton: boolean;
};

const MapPopup: FC<Props> = ({ closeButton, popupIsShown, popupCoordinates, hidePopup, children }) => {
    if (!popupIsShown || !popupCoordinates) {
        return null;
    }

    return (
        <Popup
            closeButton={closeButton}
            latitude={popupCoordinates[0]}
            longitude={popupCoordinates[1]}
            onClose={hidePopup}
            style={{
                width: '330px',
                maxWidth: '330px',
            }}
        >
            {children}
        </Popup>
    );
};

export default MapPopup;
