import type { FC } from 'react';
import type { NetworkImageProperties } from '../../../../../../core/networkMap/ExternalNetworkMapPort';
import Content from '../Content';

type Props = {
    properties: NetworkImageProperties | null;
};

const ImagePopupOnHover: FC<Props> = ({ properties }) => {
    if (!properties) {
        return null;
    }

    return <Content properties={properties} />;
};

export default ImagePopupOnHover;
