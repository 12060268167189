import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FieldArray, reduxForm, Field } from 'redux-form';
import { filesRequired, required } from '../../../../../core/legacy/utils/validation';
import { StyledForm } from '../../../../legacy/components/ui/forms/styles';
import { uploadCalibrationFile } from '../../../../../core/legacy/actions';
import { uploadHasFileSelectedSelector } from '../../../../../core/legacy/selectors/calibration';
import { ButtonsContainer, ValidateButton } from '../../../../legacy/components/ui/modals/styles';
import FileUpload from '../../../../legacy/components/ui/forms/FileUpload';
import { CumulCheckboxLabel } from '../../../../legacy/components/ui/forms/FileUpload/styles';
import type { FC } from 'react';
import { sessionByIdQuery, targettedSessionIdsForInteractionQuery } from '../../../../../core/sessions/queries';

const FIELD_ID = 'replace-calibration';

interface CalibrateSessionFormProps {
    handleSubmit?: () => void;
    invalid?: boolean;
}

const CalibrateSessionForm: FC<CalibrateSessionFormProps> = ({ handleSubmit, invalid }) => {
    const { t } = useTranslation();
    const selectedSessionId = useSelector(targettedSessionIdsForInteractionQuery);
    const isCalibrationDone = selectedSessionId.reduce((done, sessionId) => {
        const session = useSelector(sessionByIdQuery(sessionId));

        return done || !!session?.calibrationDone;
    }, false);
    const hasFileSelected = useSelector(uploadHasFileSelectedSelector);

    return (
        <StyledForm onSubmit={handleSubmit}>
            <FieldArray
                component={FileUpload}
                name="calibration"
                props={{
                    accept: { 'application/json': ['.json'] },
                    multiple: false,
                    extraInfo: t('calibrateSession.extraInfo'),
                }}
                validate={[required, filesRequired]}
                description="uploadFile.descriptionJSON"
            />

            {isCalibrationDone && hasFileSelected && (
                <CumulCheckboxLabel htmlFor={FIELD_ID}>
                    <Field id={FIELD_ID} name={FIELD_ID} component="input" type="checkbox" />
                    <div>{t('uploadFile.calculId')}</div>
                </CumulCheckboxLabel>
            )}
            <ButtonsContainer>
                <ValidateButton disabled={invalid} type="submit">
                    {t('uploadFile.submit')}
                </ValidateButton>
            </ButtonsContainer>
        </StyledForm>
    );
};

export default reduxForm({
    form: 'upload-calibration',
    initialValues: { [FIELD_ID]: true },
    onSubmit: async ({ calibration, [FIELD_ID]: replace }, dispatch) => {
        await dispatch(uploadCalibrationFile(calibration, replace));
    },
})(CalibrateSessionForm);
