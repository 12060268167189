import { captureException } from '@sentry/browser';
import axios from 'axios';
import type { ExternalAuthenticationPort } from '../core/authentication/ExternalAuthenticationPort';
import type { User } from '../core/domain/User';

export class ExternalAuthenticationProvider implements ExternalAuthenticationPort {
    constructor(private readonly baseEndpoint: string) {}

    async confirmPassword(password: string): Promise<User> {
        try {
            const response = await axios.request({
                method: 'PUT',
                url: `${this.baseEndpoint}/user/update`,
                data: { password },
                withCredentials: true,
            });

            return response.data;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async loadCurrentUser(): Promise<User | undefined> {
        try {
            const response = await axios.request({
                method: 'GET',
                url: `${this.baseEndpoint}/user/me`,
                withCredentials: true,
            });

            return response.data;
        } catch (err) {
            if (axios.isAxiosError(err) && err.response?.data.type === 'Unauthorized') {
                return undefined;
            }
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async login(email: string, password: string): Promise<User> {
        try {
            const response = await axios.request({
                method: 'POST',
                url: `${this.baseEndpoint}/auth/login`,
                data: {
                    email,
                    password,
                },
                withCredentials: true,
            });

            return response.data;
        } catch (err) {
            console.error(err);
            if (axios.isAxiosError(err) && err.response?.data.type !== 'Unauthorized') {
                captureException(err);
                throw new Error('Unauthorized');
            }
            throw new Error('Try later');
        }
    }

    async logout(): Promise<void> {
        try {
            await axios.request({
                method: 'GET',
                url: `${this.baseEndpoint}/auth/logout`,
                withCredentials: true,
            });
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }
}
