import { useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GenericModal from '../../../../legacy/components/ui/modals/GenericModal';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';
import { ContentContainer, ModalTitle } from '../../../../legacy/components/ui/modals/styles';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { selectedSessionsQuery, targettedSessionIdsForInteractionQuery } from '../../../../../core/sessions/queries';
import editSessions from '../../../../../core/sessions/commands/EditSessions';
import { TargetSessionsForInteractionCommand } from '../../../../../core/sessions/commands/TargetSessionsForInteractionCommand';
import { FormControl, MenuItem, Select } from '@mui/material';
import { SessionState } from '../../../../../core/sessions/consts';

const EditSessionStateModal: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const checkedSessions = useAppSelector(selectedSessionsQuery);
    const checkedSessionIds = checkedSessions.map((s) => s.id);
    const targetSession = useAppSelector(targettedSessionIdsForInteractionQuery);

    // if one session is focused only modify this one, not checked ones
    const sessionIds = targetSession?.length ? targetSession : checkedSessionIds;

    const [state, setState] = useState<string | null>(null);

    useEffect(() => {
        const states = checkedSessions.reduce((uniqueStates, { state: sessionState }) => {
            if (sessionState !== undefined) {
                uniqueStates.add(sessionState);
            }

            return uniqueStates;
        }, new Set<string | null>());

        if (states.size === 1) {
            const { value } = states.values().next();
            setState(value ?? SessionState.NONE);
        } else {
            setState(SessionState.NONE);
        }
    }, [checkedSessions]);

    const onValidate = () => {
        dispatch(editSessions({ sessionIds, edit: { state } }));
        setState('');
        // reset focused session on close
        dispatch(TargetSessionsForInteractionCommand([]));
    };

    return (
        <GenericModal
            onValidate={onValidate}
            modalType={SessionsModalTypes.SESSIONS_EDIT_STATE}
            options={{ notDismissableByClickingOnBackground: true }}
        >
            <ContentContainer>
                <ModalTitle>{t('sessions.modals.editState')}</ModalTitle>
                <FormControl fullWidth>
                    <Select size="small" value={state} onChange={({ target }) => setState(target.value)}>
                        {Object.values(SessionState).map((value) => (
                            <MenuItem key={value} value={value}>
                                {t(`sessionState.${value}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </ContentContainer>
        </GenericModal>
    );
};

export default EditSessionStateModal;
