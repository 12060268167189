import PropTypes from 'prop-types';
import { ButtonIcon } from './styles';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import { theme } from '../../style/theme';
import { FileItem, FileLabel, FileSize, RightContainer } from '../ui/files/styles';

const File = ({ file, onClose, maxSize }) => {
    const humanFileSize = (size) => {
        if (Number.isNaN(Number(size))) {
            return 'This is not a valid file size';
        }
        const BASE = 1024;
        const i = Math.floor(Math.log(size) / Math.log(BASE));

        return `${(size / BASE ** i).toFixed(2) * 1} ${['o', 'ko', 'Mo', 'Go', 'To'][i]}`;
    };
    const isOversized = () => file?.size > maxSize;

    return (
        <FileItem>
            <FileLabel warning={isOversized()}>{file.name}</FileLabel>
            <RightContainer>
                <FileSize warning={isOversized()}>{humanFileSize(file?.size)}</FileSize>
                <ButtonIcon onClick={() => onClose(file)}>
                    <TrashIcon color={isOversized() ? theme.color.orange.main : theme.color.black.main} />
                </ButtonIcon>
            </RightContainer>
        </FileItem>
    );
};

File.propTypes = {
    file: PropTypes.shape({ name: PropTypes.string, size: PropTypes.number }).isRequired,
    onClose: PropTypes.func,
    maxSize: PropTypes.number,
};

export default File;
