export default {
    // Root
    noRowsLabel: 'Aucune session à afficher',
    noResultsOverlayLabel: 'Aucun résultat',

    // Density selector toolbar button text
    toolbarDensity: 'Densité',
    toolbarDensityLabel: 'Densité',
    toolbarDensityCompact: 'Compact',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Confortable',

    // Columns selector toolbar button text
    toolbarColumns: 'Colonnes',
    toolbarColumnsLabel: 'Colonnes à afficher',

    // Filters toolbar button text
    toolbarFilters: 'Filtres',
    toolbarFiltersLabel: 'Afficher les filtres',
    toolbarFiltersTooltipHide: 'Masquer les filtres',
    toolbarFiltersTooltipShow: 'Afficher les filtres',
    toolbarFiltersTooltipActive: (count: number) => (count !== 1 ? `${count} filtres actifs` : `${count} filtre actif`),

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Search…',
    toolbarQuickFilterLabel: 'Search',
    toolbarQuickFilterDeleteIconLabel: 'Clear',

    // Export selector toolbar button text
    toolbarExport: 'Exporter',
    toolbarExportLabel: 'Exporter',
    toolbarExportCSV: 'Télécharger en CSV',
    toolbarExportPrint: 'Imprimer',
    toolbarExportExcel: 'Télécharger en Excel',

    // Columns management text
    columnsManagementSearchTitle: 'Rechercher',
    columnsManagementNoColumns: 'Pas de colonnes',
    columnsManagementShowHideAllText: 'Afficher/Masquer tout',

    // Filter panel text
    filterPanelAddFilter: 'Ajouter un filtre',
    filterPanelRemoveAll: 'Tout supprimer',
    filterPanelDeleteIconLabel: 'Supprimer',
    filterPanelLogicOperator: 'Opérateur logique',
    filterPanelOperator: 'Opérateur',
    filterPanelOperatorAnd: 'Et',
    filterPanelOperatorOr: 'Ou',
    filterPanelColumns: 'Colonne',
    filterPanelInputLabel: 'Valeur',
    filterPanelInputPlaceholder: '',
    filterPanelOperators: 'Opérateurs',

    // Filter operators text
    filterOperatorContains: 'Contient',
    filterOperatorEquals: 'Est égal à',
    filterOperatorStartsWith: 'Commence par',
    filterOperatorEndsWith: 'Finit par',
    filterOperatorIs: 'Est',
    filterOperatorNot: "N'est pas",
    filterOperatorAfter: 'Après',
    filterOperatorOnOrAfter: 'À partir du',
    filterOperatorBefore: 'Avant le',
    filterOperatorOnOrBefore: 'Le ou avant',
    filterOperatorIsEmpty: 'Est vide',
    filterOperatorIsNotEmpty: "N'est pas vide",
    filterOperatorIsAnyOf: 'Parmi',
    'filterOperator=': '=',
    'filterOperator!=': '!=',
    'filterOperator>': '>',
    'filterOperator>=': '>=',
    'filterOperator<': '<',
    'filterOperator<=': '<=',

    // Header filter operators text
    headerFilterOperatorContains: 'Contient',
    headerFilterOperatorEquals: 'Est égal à',
    headerFilterOperatorStartsWith: 'Commence par',
    headerFilterOperatorEndsWith: 'Finit par',
    headerFilterOperatorIs: 'Est',
    headerFilterOperatorNot: "N'est pas",
    headerFilterOperatorAfter: 'Après',
    headerFilterOperatorOnOrAfter: 'À partir du',
    headerFilterOperatorBefore: 'Avant le',
    headerFilterOperatorOnOrBefore: 'Le ou avant',
    headerFilterOperatorIsEmpty: 'Est vide',
    headerFilterOperatorIsNotEmpty: "N'est pas vide",
    headerFilterOperatorIsAnyOf: 'Parmi',
    'headerFilterOperator=': 'Égal à',
    'headerFilterOperator!=': 'Différent de',
    'headerFilterOperator>': 'Supérieur',
    'headerFilterOperator>=': 'Supérieur ou égal',
    'headerFilterOperator<': 'Inférieur',
    'headerFilterOperator<=': 'Inférieur ou égal',

    // Filter values text
    filterValueAny: 'Tout',
    filterValueTrue: 'vrai',
    filterValueFalse: 'faux',

    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Afficher les colonnes',
    columnMenuManageColumns: 'Gérer les colonnes',
    columnMenuFilter: 'Filtrer',
    columnMenuHideColumn: 'Cacher',
    columnMenuUnsort: 'Effacer le tri',
    columnMenuSortAsc: 'Tri croissant',
    columnMenuSortDesc: 'Tri décroissant',

    // Column header text
    columnHeaderFiltersTooltipActive: (count: number) =>
        count !== 1 ? `${count} filtres actifs` : `${count} filtre actif`,
    columnHeaderFiltersLabel: 'Voir les filtres',
    columnHeaderSortIconLabel: 'Trier',

    // Rows selected footer text
    footerRowSelected: (count: number) =>
        count !== 1 ? `${count.toLocaleString()} lignes sélectionnées` : `${count.toLocaleString()} ligne sélectionnée`,

    // Total row amount footer text
    footerTotalRows: 'Lignes totales:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount: number, totalCount: number) =>
        `${visibleCount.toLocaleString()} sur ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox selection',
    checkboxSelectionSelectAllRows: 'Select all rows',
    checkboxSelectionUnselectAllRows: 'Unselect all rows',
    checkboxSelectionSelectRow: 'Select row',
    checkboxSelectionUnselectRow: 'Unselect row',

    // Boolean cell text
    booleanCellTrueLabel: 'oui',
    booleanCellFalseLabel: 'non',

    // Actions cell more text
    actionsCellMore: 'plus',

    // Column pinning text
    pinToLeft: 'Épingler à gauche',
    pinToRight: 'Épingler à droite',
    unpin: 'Désépingler',

    // Tree Data
    treeDataGroupingHeaderName: 'Group',
    treeDataExpand: 'see children',
    treeDataCollapse: 'hide children',

    // Grouping columns
    groupingColumnHeaderName: 'Group',
    groupColumn: (name) => `Group by ${name}`,
    unGroupColumn: (name) => `Stop grouping by ${name}`,

    // Master/detail
    detailPanelToggle: 'Detail panel toggle',
    expandDetailPanel: 'Expand',
    collapseDetailPanel: 'Collapse',

    // Used core components translation keys
    MuiTablePagination: {
        labelRowsPerPage: 'Sessions par page',
        labelDisplayedRows: ({ from, to, count }) =>
            `${from}-${to}, total: ${count} ${count !== 1 ? 'sessions' : 'session'}`,
    },

    // Row reordering text
    rowReorderingHeaderName: 'Row reordering',

    // Aggregation
    aggregationMenuItemHeader: 'Aggregation',
    aggregationFunctionLabelSum: 'sum',
    aggregationFunctionLabelAvg: 'avg',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'size',
};
