import { Dispatch, forwardRef, SetStateAction } from 'react';
import { noMoreSelectedRoadData } from '../../../../../../core/legacy/actions';
import { useTranslation } from 'react-i18next';
import {
    BulkSelectionButton,
    BulkSelectionButtonText,
    Close,
    FilterContentContainer,
    FilterContentHeaderContainer,
    Summary as FilterSummary,
    SwitchContainer,
    SwitchText,
} from './styles';
import { Switch } from '@mui/material';
import FilterRubric from './FilterRubric';
import { useAppDispatch, useAppSelector } from '../../../../../../types';
import { allRubricsQuery } from '../../../../../../core/catalogs/queries';
import {
    applyFilterToImageQuery,
    rubricToDisplayIdsQuery,
    taggedElementsOnlyQuery,
} from '../../../../../../core/filters/queries';
import { UpdateRubricToDisplayIdsCommand } from '../../../../../../core/filters/commands/UpdateRubricToDisplayIdsCommand';
import { ToggleApplyFilterToImageCommand } from '../../../../../../core/filters/commands/ToggleApplyFilterToImageCommand';
import { UpdateReferenceToDisplayIdsCommand } from '../../../../../../core/filters/commands/UpdateReferenceToDisplayIdsCommand';
import TagFilter from '../../../../../components/streetView/roadDatasPanel/filters/TagFilter';
import RoaddataFilter from '../../../../../components/streetView/roadDatasPanel/filters/RoadDataFilter';

type FilterContentProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
};

// forwardRef component is needed to work with the ClickAwayListener
const FiltersContent = forwardRef<any, FilterContentProps>(({ setOpen }, ref) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const rubrics = useAppSelector(allRubricsQuery);
    const references = rubrics.map((r) => r.references).flat();
    const rubricToDisplayIds = useAppSelector(rubricToDisplayIdsQuery);
    const onlyTaggeElements = useAppSelector(taggedElementsOnlyQuery);
    const displayNone = () => {
        dispatch(UpdateRubricToDisplayIdsCommand([]));
        dispatch(UpdateReferenceToDisplayIdsCommand([]));
        dispatch(noMoreSelectedRoadData());
    };

    const allRubricIds = rubrics.map(({ id }) => id);
    const displayAll = () => {
        dispatch(UpdateRubricToDisplayIdsCommand(rubrics));
        dispatch(UpdateReferenceToDisplayIdsCommand(references));
    };

    const applyFilterToImage = useAppSelector(applyFilterToImageQuery);

    const toggleApplyToImage = () => {
        dispatch(ToggleApplyFilterToImageCommand());
    };

    const isEverythingSelected = rubricToDisplayIds.length === allRubricIds.length;
    const bulkSelectionButtonText = isEverythingSelected
        ? t('filters.panel.eraseSelection')
        : t('filters.panel.selectAll');

    return (
        <FilterContentContainer ref={ref}>
            <FilterContentHeaderContainer>
                <BulkSelectionButton onClick={isEverythingSelected ? displayNone : displayAll}>
                    <BulkSelectionButtonText>{bulkSelectionButtonText}</BulkSelectionButtonText>
                </BulkSelectionButton>
                <Close onClick={() => setOpen(false)} />
            </FilterContentHeaderContainer>
            <FilterSummary>
                {rubrics.map((rubric) => (
                    <FilterRubric rubric={rubric} key={rubric.id} />
                ))}
            </FilterSummary>
            <TagFilter checked={onlyTaggeElements} />
            <RoaddataFilter />
            <SwitchContainer>
                <Switch color="primary" checked={applyFilterToImage} onClick={toggleApplyToImage} />
                <SwitchText>{t('filters.panel.applyFiltersToImage')}</SwitchText>
            </SwitchContainer>
        </FilterContentContainer>
    );
});

export default FiltersContent;
