import { styled, Typography } from '@mui/material';

export const BaseTypography = styled(Typography)(({ theme, color, fontWeight, fontStyle }) => ({
    fontFamily: theme.font.family,
    color: theme.color.black.main,
    fontStyle: 'normal',
    fontWeight: 'normal',
    ...(color && {
        color,
    }),
    ...(fontWeight && {
        fontWeight,
    }),
    ...(fontStyle && {
        fontStyle,
    }),
}));

export const SmallText = styled(BaseTypography)(({ theme }) => ({
    fontSize: theme.font.size.small,
}));
export const SmallTextItalic = styled(SmallText)(() => ({
    fontStyle: 'italic',
}));

export const RegularText = styled(BaseTypography)(({ theme }) => ({
    fontSize: theme.font.size.regular,
}));

export const RegularTextBold = styled(BaseTypography)(({ theme }) => ({
    fontSize: theme.font.size.regular,
    fontWeight: 'bold',
}));

export const MediumText = styled(BaseTypography)(({ theme }) => ({
    fontSize: theme.font.size.medium,
}));

export const MediumTextBold = styled(BaseTypography)(({ theme }) => ({
    fontSize: theme.font.size.medium2,
    fontWeight: 'bold',
}));
