import debounce from 'lodash.debounce';
import { MutableRefObject, useEffect, useMemo } from 'react';
import type { MapRef } from 'react-map-gl';
import type { SessionId } from '../../../../core/domain/Session';
import { UpdateRenderedSessionsIdsCommand } from '../../../../core/networkMap/commands/UpdateRenderedSessionsIdsCommand';
import { currentMapBoundsQuery } from '../../../../core/networkMap/queries';
import { sessionsIdsQuery } from '../../../../core/sessions/queries';
import { useAppDispatch, useAppSelector } from '../../../../types';
import { SOURCE_NETWORK_SESSION_ID } from '../network-map-session-layer-configuration';

export const useRenderedSessionsIds = (mapRef: MutableRefObject<MapRef | null>): (() => void) => {
    const dispatch = useAppDispatch();
    const allSessionsIds = useAppSelector(sessionsIdsQuery);
    const currentMapBounds = useAppSelector(currentMapBoundsQuery);

    const updateRenderedSessionsIds = useMemo(
        () =>
            debounce(() => {
                const renderedFeatures = mapRef.current?.getMap().querySourceFeatures(SOURCE_NETWORK_SESSION_ID);

                let renderedSessionsIds: SessionId[];

                if (!renderedFeatures) {
                    renderedSessionsIds = allSessionsIds;
                } else {
                    renderedSessionsIds = Array.from(
                        renderedFeatures.reduce((idsSet, feature) => {
                            if (feature.properties?.id) {
                                idsSet.add(feature.properties.id as SessionId);
                            }

                            return idsSet;
                        }, new Set<SessionId>()),
                    );
                }

                dispatch(UpdateRenderedSessionsIdsCommand({ renderedSessionsIds }));
            }, 500),
        [mapRef, dispatch, allSessionsIds],
    );

    useEffect(() => {
        updateRenderedSessionsIds();
    }, [currentMapBounds, updateRenderedSessionsIds]);

    return updateRenderedSessionsIds;
};
