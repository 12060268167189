import type { MutableRefObject } from 'react';
import type { MapboxGeoJSONFeature, MapboxMap, MapLayerMouseEvent, MapRef, PointLike } from 'react-map-gl';

export type FeatureManager = {
    getCurrentMap: () => MapboxMap | undefined;
    getClickedFeature: (event: MapLayerMouseEvent) => MapboxGeoJSONFeature | undefined;
    restoreFeatureStyle: (target: { source: string; sourceLayer?: string }) => void;
    setFeatureStyleToHover: (feature: MapboxGeoJSONFeature) => void;
};

// fixme(dim) atelier metier pour surcharger les termes techniques de carto
const featureManager = (mapRef: MutableRefObject<MapRef | null>, layers: string[]): FeatureManager => {
    const getCurrentMap = (): MapboxMap | undefined => {
        return mapRef.current?.getMap();
    };

    const getClickedFeature = (event: MapLayerMouseEvent): MapboxGeoJSONFeature | undefined => {
        const map = getCurrentMap();
        if (!map) {
            return undefined;
        }
        const bbox: [PointLike, PointLike] = [
            [event.point.x - 5, event.point.y - 5] as PointLike,
            [event.point.x + 5, event.point.y + 5] as PointLike,
        ];
        const features = map.queryRenderedFeatures(bbox, { layers });

        return features[0];
    };

    const restoreFeatureStyle = ({ source, sourceLayer }: { source: string; sourceLayer?: string }): void => {
        const map = getCurrentMap();
        if (!map) {
            return;
        }
        map.removeFeatureState({
            source,
            sourceLayer,
        });
    };

    const setFeatureStyleToHover = (feature: MapboxGeoJSONFeature) => {
        const map = getCurrentMap();
        if (!map) {
            return;
        }
        map.setFeatureState(feature, {
            hover: true,
        });
    };

    return {
        getCurrentMap,
        getClickedFeature,
        restoreFeatureStyle,
        setFeatureStyleToHover,
    };
};

export default featureManager;
