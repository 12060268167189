import { Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { Calibration } from '../../../core/domain/Session';
import { tooltipCell } from './styles';

interface SessionCalibrationProps {
    calibration?: Calibration;
}

const SessionCalibration: FC<SessionCalibrationProps> = ({ calibration }) => {
    if (!calibration) return null;

    return (
        <Tooltip title={calibration.fileName}>
            <Typography sx={tooltipCell}>{calibration.fileName}</Typography>
        </Tooltip>
    );
};

export default SessionCalibration;
