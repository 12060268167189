import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const moduleName = 'FILTERS';

type State = {
    rubricToDisplayIds: string[];
    referenceToDisplayIds: string[];
    applyFilterToImage: boolean;
    taggedElementsOnly: boolean;
    imageRoaddataOnly: boolean;
};

const initialState: State = {
    rubricToDisplayIds: [],
    referenceToDisplayIds: [],
    applyFilterToImage: false,
    taggedElementsOnly: false,
    imageRoaddataOnly: true,
};

const filtersSlice = createSlice({
    name: moduleName,
    initialState,
    reducers: {
        rubricToDisplayIdsUpdated(state: State, action: PayloadAction<{ rubricIds: Array<string> }>) {
            state.rubricToDisplayIds = [...action.payload.rubricIds];
        },
        referenceToDisplayIdsUpdated(state: State, action: PayloadAction<{ referenceIds: Array<string> }>) {
            state.referenceToDisplayIds = [...action.payload.referenceIds];
        },
        applyFilterToImageToggled(state: State) {
            state.applyFilterToImage = !state.applyFilterToImage;
        },
        applyFilterToImageSet(state: State, action: PayloadAction<{ applyFilterToImage: boolean }>) {
            state.applyFilterToImage = action.payload.applyFilterToImage;
        },
        taggedElementsOnly(state: State, action: PayloadAction<boolean>) {
            state.taggedElementsOnly = action.payload;
        },
        imageRoadataOnly(state: State, action: PayloadAction<boolean>) {
            state.imageRoaddataOnly = action.payload;
        },
    },
});

const {
    actions: {
        applyFilterToImageSet,
        applyFilterToImageToggled,
        referenceToDisplayIdsUpdated,
        rubricToDisplayIdsUpdated,
        taggedElementsOnly,
        imageRoadataOnly,
    },
    reducer: filterReducer,
} = filtersSlice;

export {
    applyFilterToImageSet,
    applyFilterToImageToggled,
    filterReducer,
    moduleName,
    referenceToDisplayIdsUpdated,
    rubricToDisplayIdsUpdated,
    taggedElementsOnly,
    imageRoadataOnly,
};
