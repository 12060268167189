import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { theme } from '../../legacy/style/theme';

export type IconProps = {
    size?: 'small' | 'tableIconSize' | 'medium';
    children?: ReactNode;
    themeStyle?: 'light' | 'dark';
    title?: string;
};

type Props = {
    children: ReactNode;
} & IconProps;

const StyledIcon = styled.div`
    display: flex;
    title: ${({ title }) => title};
    svg {
        width: ${({ size }) => {
            if (size === 'small') return '16px';
            if (size === 'tableIconSize') return '20px';

            return '24px';
        }};
        height: ${({ size }) => {
            if (size === 'small') return '16px';
            if (size === 'tableIconSize') return '20px';

            return '24px';
        }};
        height: ${({ size }) => (size === 'small' ? '16px' : '24px')};
        background-color: ${({ themeStyle }) => (themeStyle === 'light' ? theme.color.blue.tint2 : 'transparent')};
        color: ${({ themeStyle }) => (themeStyle === 'dark' ? theme.color.white.main : theme.color.black.main)};
        padding:  ${({ themeStyle }) => (themeStyle === 'light' ? '4px' : '0px')};
        border-radius:  ${({ themeStyle }) => (themeStyle === 'light' ? '20px' : '0px')};

`;

export const BaseIcon: FC<Props> = ({ size, children, themeStyle, title }) => {
    return (
        <StyledIcon themeStyle={themeStyle} size={size} title={title}>
            {children}
        </StyledIcon>
    );
};
