import type { Reference } from '../../domain/Roaddata';
import { createCommand } from '../../utils';
import { moduleName, referenceToDisplayIdsUpdated } from '../state';
import { UpdateFiltersCommand } from './UpdateFiltersCommand';

export const UpdateReferenceToDisplayIdsCommand = createCommand(
    `${moduleName}/UPDATE_REFERENCE_TO_DISPLAY_IDS`,
    async (references: Array<Reference>, { dispatch, extra }) => {
        const referenceIds = references.map((r) => r.id);
        extra.storageProvider.setReferenceIds(referenceIds);
        dispatch(referenceToDisplayIdsUpdated({ referenceIds }));

        dispatch(UpdateFiltersCommand());
    },
);
