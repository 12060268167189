import type { ReferenceKey, Reference } from '../../domain/Roaddata';

/**
 * Fix the gap between old deprecated Reference type (string) and new Reference (object came from backend)
 * Temporary
 */
const referenceResolver = (reference: Reference | ReferenceKey): ReferenceKey => {
    return typeof reference === 'object' ? (reference as Reference).key : reference;
};

export default referenceResolver;
