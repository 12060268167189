import { useEffect, MutableRefObject } from 'react';
import type { MapboxMap, MapRef } from 'react-map-gl';
import type { OrganizationNetwork } from '../../../../core/networkMap/ExternalNetworkMapPort';
import { zoomedSessionQuery } from '../../../../core/networkMap/queries';
import { useAppSelector } from '../../../../types';
import { getSessionBounds } from '../get-session-bounds';
import { FIT_BOUNDS_OPTIONS } from '../map-configuration';

const useZoomOnSession = (mapRef: MutableRefObject<MapRef | null>, networkData: OrganizationNetwork | null) => {
    const zoomedSession = useAppSelector(zoomedSessionQuery);

    const getCurrentMap = (): MapboxMap => {
        return mapRef?.current?.getMap() as MapboxMap;
    };

    useEffect(() => {
        if (networkData && zoomedSession) {
            const sessionBounds = getSessionBounds(networkData, zoomedSession);
            if (sessionBounds) {
                getCurrentMap()?.fitBounds(sessionBounds, FIT_BOUNDS_OPTIONS);
            }
        }
    }, [networkData, zoomedSession]);
};

export default useZoomOnSession;
