import { createCommand } from '../utils';
import { moduleName, userUpdated } from './state';

export const ConfirmPasswordCommand = createCommand(
    `${moduleName}/CONFIRM_PASSWORD`,
    async (confirmPassword: string, { dispatch, extra }) => {
        const updatedUser = await extra.externalAuthentication.confirmPassword(confirmPassword);
        await dispatch(userUpdated(updatedUser));
    },
);
