import type { Expression, SymbolLayer, SymbolPaint } from 'mapbox-gl';
import { theme } from '../../legacy/style/theme';
import { loadConfigFromRootDataset } from '../../../config';

const { mapboxPrTilesetId, mapboxPrLayer } = loadConfigFromRootDataset();

export const PR_SOURCE_ID = 'pr';
export const PR_SOURCE_URL = `mapbox://${mapboxPrTilesetId}`;
export const PR_SOURCE_LAYER = mapboxPrLayer;

export const PR_LAYER_ID = 'pr';

const untilZoomLevelReaches = (level: number): number => level;

const ICON_SIZE: Expression = [
    'step',
    ['zoom'],
    0.2,
    untilZoomLevelReaches(10),
    0.3,
    untilZoomLevelReaches(16),
    0.4,
    untilZoomLevelReaches(18),
    0.5,
    untilZoomLevelReaches(19),
    0.5,
];

const TEXT = ['concat', ['get', 'ROAD'], ' PR ', ['get', 'PR']];
const TEXT_STEP: Expression = ['step', ['zoom'], '', 14, TEXT];

export const PR_LAYER: SymbolLayer = {
    id: PR_LAYER_ID,
    'source-layer': PR_SOURCE_LAYER,
    type: 'symbol',
    layout: {
        'icon-anchor': 'bottom',
        'icon-image': 'pin',
        'icon-size': ICON_SIZE,
        'text-anchor': 'top',
        'text-field': TEXT_STEP,
    },
    minzoom: 10,
};

const PR_LAYER_PAINT_COMMON: SymbolPaint = {
    'icon-color': theme.color.blue.primary,
    'text-halo-width': 1,
};

export const PR_LAYER_PAINT_STREET: SymbolPaint = {
    ...PR_LAYER_PAINT_COMMON,
    'text-color': '#000000',
    'text-halo-color': '#FFFFFF',
};

export const PR_LAYER_PAINT_SATELLITE: SymbolPaint = {
    ...PR_LAYER_PAINT_COMMON,
    'text-color': '#FFFFFF',
    'text-halo-color': '#000000',
};
