import PropTypes from 'prop-types';
import { useState } from 'react';
import { FolderContainer, FolderHeader, FolderContent } from './styles';
import { ReactComponent as ArrowDown } from '../../../../../assets/icons/arrow_down.svg';
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/arrow_right.svg';
import { translateFolder } from '../../../../../core/legacy/utils/translations';

const Folder = ({ children, name }) => {
    const folderName = translateFolder(name);
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => setIsOpen(!isOpen);

    return (
        <FolderContainer>
            <FolderHeader onClick={handleClick}>
                {folderName}
                {!isOpen ? <ArrowRight /> : <ArrowDown />}
            </FolderHeader>
            {isOpen && <FolderContent>{children}</FolderContent>}
        </FolderContainer>
    );
};

Folder.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    name: PropTypes.string.isRequired,
};

export default Folder;
