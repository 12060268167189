import type { SessionId } from '../../../../../core/domain/Session';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { landmarksForSessionQuery, sessionTimelineLengthQuery } from '../../../../../core/sessions/queries';
import { LoadItineraryForOneSessionCommand } from '../../../../../core/sessions/commands/LoadItineraryForOneSessionCommand';
import type { RoadData } from '../../../../../core/domain/Roaddata';
import { useEffect, useState } from 'react';

const useItineraryData = (sessionId?: SessionId): [number, RoadData[]] => {
    const dispatch = useAppDispatch();
    const [landmarks, setLandmarks] = useState<RoadData[]>([]);
    const [length, setLength] = useState(0);

    // to be sure to have a string, will return undefined in underlying queries
    const sessionIdFallback = sessionId ?? '';
    const foundLandmarks = useAppSelector(landmarksForSessionQuery(sessionIdFallback));
    const foundLength = useAppSelector(sessionTimelineLengthQuery(sessionIdFallback));

    useEffect(() => {
        if (!sessionId) return;

        if (foundLandmarks === undefined || foundLength === undefined) {
            dispatch(LoadItineraryForOneSessionCommand({ sessionId }));
        } else {
            setLandmarks(foundLandmarks);
            setLength(foundLength);
        }
    }, [sessionId, foundLandmarks, foundLength]);

    return [length, landmarks];
};

export default useItineraryData;
