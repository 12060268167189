/* eslint-disable @typescript-eslint/no-unused-vars */
import { createCommand } from '../../utils';
import { displayPr, moduleName } from '../state';
import { userOrganizationQuery } from '../../authentication/queries';

type Args = {
    display: boolean;
};

export const UpdateDisplayPrCommand = createCommand(
    `${moduleName}/UPDATE_DISPLAY_PR`,
    async ({ display }: Args, { dispatch, extra, getState }) => {
        const userOrganization = userOrganizationQuery(getState());

        if (userOrganization) {
            await extra.mapViewSettings.updateDisplayPrForOrganization(userOrganization.id, display);
        }

        dispatch(displayPr(display));
    },
);
