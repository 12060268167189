import type { FC, ReactNode } from 'react';
import { GreySquareButton } from '../../design_system/inputs/buttons/GreySquareButton';

export type LabelGreyButtonProps = {
    label: string;
    children: ReactNode;
    selected?: boolean;
    borderRadius?: string;
    handleClick?: () => void;
};
export const LabelGreyButton: FC<LabelGreyButtonProps> = ({ label, selected, handleClick, children, borderRadius }) => {
    return (
        <GreySquareButton
            selected={selected ?? false}
            borderRadius={borderRadius}
            sx={{
                marginRight: '16px',
                display: 'flex',
                flexDirection: 'column',
                textTransform: 'unset',
                alignItems: 'center',
                '& svg': {
                    position: 'absolute',
                    top: '17px',
                },
                '&:after': {
                    content: `"${label}"`,
                    fontSize: '13px',
                    display: 'block',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    marginTop: '5px',
                    position: 'absolute',
                    bottom: 0,
                },
            }}
            onClick={handleClick ?? null}
        >
            {children}
        </GreySquareButton>
    );
};
