import styled from 'styled-components';
import { AnnotationEngine as RawAnnotationEngine } from '../annotation-engine-lib/annotation-engine';

export const AnnotationEngine = styled(RawAnnotationEngine)`
    overflow: hidden;
    display: flex;
    justify-content: center;

    cursor: ${({ cursorStyle }) => cursorStyle};
    width: ${({ canvasSize }) => (canvasSize ? `${canvasSize.width}px` : '100%')} !important;
    height: ${({ canvasSize }) => (canvasSize ? `${canvasSize.height}px` : '100%')} !important;

    canvas {
        position: relative;
        align-self: center;

        width: ${({ canvasSize }) => (canvasSize ? `${canvasSize.width}px` : '100%')} !important;
        height: ${({ canvasSize }) => (canvasSize ? `${canvasSize.height}px` : '100%')} !important;
    }
`;
