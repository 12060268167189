import { clickStyle, hoverStyle } from '../../ui/annotation-engine/drawing-styles';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectedRoadDataIdSelector, hoveredRoadDataIdSelector } from '../../../../../core/legacy/selectors/streetView';

const useSortRealAnnoWithAnnoInForeground = (realAnnotations, setRealAnnotations, styleOps) => {
    const hoveredRoadDataId = useSelector(hoveredRoadDataIdSelector);
    const selectedRoadDataId = useSelector(selectedRoadDataIdSelector);

    const getAnnoIndexInImageReferential = (annoIndex) =>
        realAnnotations.findIndex((realAnno) => realAnno?.id === annoIndex);

    const getNeitherSelectedNorHoveredAnnotations = () =>
        realAnnotations.filter((realAnno) => realAnno?.id !== hoveredRoadDataId && realAnno?.id !== selectedRoadDataId);

    const isAnnotationAlreadyInForeground = (annoId) => {
        const indexOfAnnotationInImageReferential = getAnnoIndexInImageReferential(annoId);

        return indexOfAnnotationInImageReferential === realAnnotations.length - 1;
    };

    const isRoadDataOnImage = (roadDataId) => realAnnotations.some((realAnno) => realAnno?.id === roadDataId);

    const getReorganizedAnnotations = (annotationToMoveToForegroundId) => {
        const annotationsToDisplay = [...getNeitherSelectedNorHoveredAnnotations()];

        if (
            selectedRoadDataId &&
            hoveredRoadDataId &&
            selectedRoadDataId !== hoveredRoadDataId &&
            isRoadDataOnImage(selectedRoadDataId)
        ) {
            annotationsToDisplay.push(
                realAnnotations.find((realAnno) => realAnno.id === selectedRoadDataId),
                realAnnotations.find((realAnno) => realAnno.id === hoveredRoadDataId),
            );
        } else {
            annotationsToDisplay.push(
                realAnnotations.find((realAnno) => realAnno.id === annotationToMoveToForegroundId),
            );
        }

        return annotationsToDisplay;
    };

    const sortAnnotationsWithAnnoInForeground = (annotationToMoveToForegroundId) => {
        if (
            annotationToMoveToForegroundId === undefined ||
            !isRoadDataOnImage(annotationToMoveToForegroundId) ||
            realAnnotations?.length === 0 ||
            isAnnotationAlreadyInForeground(annotationToMoveToForegroundId)
        ) {
            return realAnnotations;
        }

        return getReorganizedAnnotations(annotationToMoveToForegroundId);
    };

    useEffect(() => {
        if (!hoveredRoadDataId && !selectedRoadDataId) {
            return styleOps.removeStylesFromAnnotationsByStyleNames(hoverStyle.name, clickStyle.name);
        }

        if (hoveredRoadDataId && hoveredRoadDataId !== selectedRoadDataId) {
            const sortedRealAnnotations = sortAnnotationsWithAnnoInForeground(hoveredRoadDataId);
            setRealAnnotations(sortedRealAnnotations);

            return styleOps.setStyleExclusivelyToAnnotationId(hoverStyle, hoveredRoadDataId);
        }
        const sortedRealAnnotations = sortAnnotationsWithAnnoInForeground(selectedRoadDataId);
        setRealAnnotations(sortedRealAnnotations);

        return styleOps.setStyleExclusivelyToAnnotationIdAndRemoveAllStyles(clickStyle, selectedRoadDataId);
    }, [hoveredRoadDataId, selectedRoadDataId, realAnnotations]);
};

export default useSortRealAnnoWithAnnoInForeground;
