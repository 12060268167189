import RoadDatasPanel from './RoadDatasPanel';
import { FC, PropsWithChildren, useEffect } from 'react';
import { addWarnings, resetMessages } from '../../../../core/legacy/actions/flashbag';
import Player from './Player';
import NavigationBar from './NavigationBar';
import RoadViewer from './RoadViewer';
import { BlueContainer } from '../ui/road-view/styles';
import ImageActions from './RoadViewer/ImageActions';
import { InitAiAnalysisCommand } from '../../../../core/automatic_analysis/InitAiAnalysisCommand';
import { useAppDispatch, useAppSelector } from '../../../../types';
import { loaderStarted, loaderStopped } from '../../../../core/loader/state';
import CatalogMenu from './CatalogMenu';
import { Box, Stack } from '@mui/material';
import { RefreshItineraryButton } from './NavigationBar/RefreshItineraryButton';
import { LeftPanel as RoadViewPanel, StreetViewActionsContainer } from '../../../roadview';
import { BackButton } from './BackButton';
import { isMovingSessionQuery, sessionQuery } from '../../../../core/streetViewer/queries';
import { useNavigate } from 'react-router-dom';
import useGoToImageByCumul from './use-go-to-image-by-cumul';
import { InitTagsgsCommand } from '../../../../core/tags/commands/InitTagsCommand';
import CurrentPositionInSession from '../../../components/streetView/CurrentPositionInSession';

const CatalogMenuContainer: FC<PropsWithChildren> = ({ children }) => (
    <Box
        sx={{
            gridRow: 1,
            display: 'flex',
            marginLeft: '16px',
            gap: 2,
        }}
    >
        {children}
    </Box>
);

const StreetView = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const session = useAppSelector(sessionQuery);
    const isMovingSession = useAppSelector(isMovingSessionQuery);
    const goToCumul = useGoToImageByCumul(session);

    useEffect(() => {
        if (!session) {
            dispatch(loaderStarted());

            return;
        }

        dispatch(InitAiAnalysisCommand());
        dispatch(InitTagsgsCommand());
        goToCumul();
        dispatch(loaderStopped());

        if (session.calibrationDone !== undefined && !session.calibrationDone) {
            dispatch(addWarnings(['session.calibrationWarningMessage']));
        } else {
            dispatch(resetMessages());
        }
    }, [session]);

    return (
        <BlueContainer data-test-id="streetview">
            <RoadViewPanel>
                <RoadViewer>
                    <StreetViewActionsContainer hasPlayer={isMovingSession}>
                        <CatalogMenuContainer>
                            <BackButton handleClick={() => navigate('/dashboard/acquisition')} />
                            <CatalogMenu />
                        </CatalogMenuContainer>
                        {isMovingSession && <Player />}
                        <ImageActions />
                    </StreetViewActionsContainer>
                </RoadViewer>
                <Box sx={{ px: 2, py: 1 }}>
                    <CurrentPositionInSession session={session} />
                    <NavigationBar />
                    <Stack sx={{ flexDirection: 'row', justifyContent: 'flex-end', pr: 2 }}>
                        <RefreshItineraryButton />
                    </Stack>
                </Box>
            </RoadViewPanel>
            <RoadDatasPanel />
        </BlueContainer>
    );
};

export default StreetView;
