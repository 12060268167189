import type { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { LightBlueButton } from '../../../../design_system/inputs/buttons/LightBlueButton';
import { ReactComponent as AutorenewIcon } from '../../../../../assets/icons/autorenew.svg';
import { sessionIdQuery } from '../../../../../core/streetViewer/queries';
import { SmallTextItalic } from '../../../../design_system/texts';
import { selectedGradeReferenceQuery } from '../../../../../core/networkMap/queries';
import { translateReference } from '../../../../../core/legacy/utils/translations';
import { LoadItineraryForOneSessionCommand } from '../../../../../core/sessions/commands/LoadItineraryForOneSessionCommand';

export const RefreshItineraryButton = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedGradeReference = useAppSelector(selectedGradeReferenceQuery) ?? 'AGGREGATE';

    const translatedReference = translateReference(selectedGradeReference);
    const sessionId = useAppSelector(sessionIdQuery);
    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (sessionId) {
            dispatch(LoadItineraryForOneSessionCommand({ sessionId }));
        }
    };

    return (
        <LightBlueButton handleClick={handleClick} textContent={t('buttons.refresh')}>
            <AutorenewIcon />
            <SmallTextItalic color="inherit">{translatedReference}</SmallTextItalic>
        </LightBlueButton>
    );
};
