import { CircularProgress, Tooltip, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { Session } from '../../../../core/domain/Session';
import {
    StyledBlock,
    ElementData,
    ElementClassEllipsis,
} from '../../../legacy/components/ui/panels/right-panel/ElementSummary/styles';
import formatDateWithBrowserLocale from '../../../../core/legacy/utils/date';
import ZoomOption from './Options/ZoomOption';
import ToggleSessionOption from './Options/ToggleSessionOption';
import useZoomOnSession from './use-zoom-on-session';
import { Container, LeftContainer, OptionsContainer, SessionNameContainer } from './components';
import { useAppSelector } from '../../../../types';
import {
    isSessionShownQuery,
    networkMapShouldRefreshQuery,
    networkExecutionStatusesQuery,
} from '../../../../core/networkMap/queries';

export type Props = {
    session: Session;
};
const SessionSummary: FC<Props> = ({ session }) => {
    const { t } = useTranslation();
    const handleZoomOnSession = useZoomOnSession(session);
    const sessionIsShownInStore = useAppSelector(isSessionShownQuery(session.id));
    const executionStatuses = useAppSelector(networkExecutionStatusesQuery);
    const shouldRefresh = useAppSelector(networkMapShouldRefreshQuery);
    const executionStatus = executionStatuses?.find((s) => s.sessionId === session.id);
    const ongoing = executionStatus?.status === 'ONGOING';
    const needRefresh = shouldRefresh.find((s) => s.sessionId === session.id)?.refresh;

    return (
        <StyledBlock
            onSelect={!sessionIsShownInStore ? null : handleZoomOnSession}
            disabled={!sessionIsShownInStore || ongoing || needRefresh}
        >
            <Container>
                <LeftContainer>
                    <SessionNameContainer>
                        <Tooltip title={session.name} placement="top-start" arrow>
                            <ElementClassEllipsis
                                sx={{
                                    width: '210px',
                                    transform: 'translateY(50%)',
                                }}
                            >
                                {session.name}
                            </ElementClassEllipsis>
                        </Tooltip>
                    </SessionNameContainer>
                    <Tooltip title={t(`session.acquisitionDate`)} placement="top-start" arrow>
                        <ElementData>
                            {formatDateWithBrowserLocale(new Date(session.metadata.acquisitionDate))}
                        </ElementData>
                    </Tooltip>
                </LeftContainer>
                <OptionsContainer>
                    {ongoing && <CircularProgress />}
                    {needRefresh && (
                        <Typography textAlign="center" fontWeight="bold">
                            {t('networkMap.sessionsPanel.refreshNeeded')}
                        </Typography>
                    )}
                    {!ongoing && !needRefresh && (
                        <>
                            <ZoomOption session={session} />
                            <ToggleSessionOption session={session} />
                        </>
                    )}
                </OptionsContainer>
            </Container>
        </StyledBlock>
    );
};

export default SessionSummary;
