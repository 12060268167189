import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    StyledDuplicateWarning as WarningContainer,
    BoldWarningText as WarningHeadLine,
    WarningText as WarningBody,
} from './styles';

const DuplicateWarning = () => {
    const { t } = useTranslation();

    return (
        <WarningContainer>
            <WarningHeadLine>{t('calibration.warnings.duplicatePoints.headline')}</WarningHeadLine>
            <WarningBody>{t('calibration.warnings.duplicatePoints.body')}</WarningBody>
        </WarningContainer>
    );
};

export default DuplicateWarning;
