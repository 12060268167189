/* eslint-disable @typescript-eslint/no-shadow */
// TODO reorganized content of this file ?
// todo check if this is used in more than 1 place ?
import styled from 'styled-components';
import { theme } from '../../../../style/theme';

export const Panel = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: ${({ theme }) => theme.color.blue.selected};
    width: 20vw;
    height: 100vh;
    box-shadow: 0 0 3px 1px ${({ theme }) => theme.color.blue.primary}1A; // alpha 10%
    overflow: auto;
    box-sizing: border-box;
`;

export const ElementLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    background-color: ${theme.color.blue.light};
    gap: 0.5rem;
    padding: 6px 10px;
    border-radius: 5px;
    cursor: ${({ editable }) => (editable ? 'pointer' : 'default')};
`;

export const Value = styled.span`
    font-size: ${theme.font.size.small};
    color: ${theme.color.black.main};
    font-weight: bold;
    text-align: end;
`;

export const GridLayout = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
        'title      .           actions'
        'attributes attributes  attributes';
    background-color: ${theme.color.blue.tint};
    padding: 20px;
    padding-top: 0;
    margin: 1rem;
    margin-top: 10px;
    box-shadow: ${theme.boxShadow.light};
`;

export const Actions = styled.div`
    grid-area: actions;
    display: flex;
`;

export const Button = styled.button`
    border: none;
    background: none;
    cursor: pointer;
    color: ${({ selected }) => (!selected ? 'inherit' : theme.color.blue.primary)};
`;

export const Attributes = styled.div`
    grid-area: attributes;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const Summary = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    flex-grow: 1;
`;

export const RightPanel = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: ${({ distanceToBottom }) => distanceToBottom || '0'};
    right: 0;
    z-index: 1;
    background-color: ${theme.color.blue.light};
    padding: 1rem;
    width: ${({ close }) => (close ? '2%' : '25%')};
    box-shadow: 0 0 3px 1px ${theme.color.blue.primary}1A; // alpha 10%
    overflow: auto;
`;

export const EditableValue = styled.span.attrs(() => ({
    role: 'button',
    'aria-label': 'edit',
}))`
    font-size: ${theme.font.size.small};
    color: ${theme.color.blue.medium};
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    text-align: end;

    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;

    ${({ empty }) =>
        empty &&
        `background-color: ${theme.color.white.main};
        width: 100%;
        height: 100%;
        border-radius: 5px;`}

    ${({ emptyLexicon }) =>
        emptyLexicon &&
        `background-color: ${theme.color.white.main};
        width: 100%;
        height: 100%;
        border-radius: 5px;
        position: relative;
        text-decoration: none;
        color: ${theme.color.black.main};
        font-size: 12px;
        &:before {
            content: "-";
            position: absolute;
            left: 50%;
        }
        &:after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border: 5px solid transparent;
            border-bottom: 0;
            border-top: 5px solid grey;
            right: 5px;
            top: 8px;
        }`}
`;
