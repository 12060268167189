import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { userQuery } from '../../../core/authentication/queries';
import { useAppSelector } from '../../../types';
import { UploadSessionsButton } from '../../design_system/inputs/buttons/UploadSessionsButton';
import { EmptySession, TextInformation, Title } from './styles';

/// Component used only  if sesssionList is empty but will be implemented later
export const EmptySessionList: FC = () => {
    const { t } = useTranslation();
    const user = useAppSelector(userQuery);

    return (
        <EmptySession>
            <TextInformation>
                {t('session.welcomeMessage')} {user?.lastName} {user?.firstName} !
            </TextInformation>
            <Title>{t('session.emptySession')}</Title>
            <UploadSessionsButton />
        </EmptySession>
    );
};
