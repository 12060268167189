import type { Reference, Rubric } from '../../domain/Roaddata';
import { createCommand } from '../../utils';
import { moduleName, referenceToDisplayIdsUpdated } from '../state';
import { AddRubricsToDisplayIdCommand } from './AddRubricToDisplayIdCommand';
import { RemoveRubricsToDisplayIdCommand } from './RemoveRubricToDisplayIdCommand';
import { UpdateFiltersCommand } from './UpdateFiltersCommand';

type Command = {
    reference: Reference;
    rubric: Rubric;
};

export const ToggleReferenceToDisplayIdCommand = createCommand(
    `${moduleName}/TOGGLE_REFERENCE_TO_DISPLAY_ID`,
    async ({ reference, rubric }: Command, { dispatch, extra }) => {
        const referenceId = reference.id;
        const previouslyStoredReferenceIds: Array<string> = [];
        const referenceIdsFromProvider = extra.storageProvider.getReferenceIds();
        if (referenceIdsFromProvider) {
            previouslyStoredReferenceIds.push(...referenceIdsFromProvider);
        }

        const newStoredReferenceIds: Array<string> = [];
        if (previouslyStoredReferenceIds.includes(referenceId)) {
            newStoredReferenceIds.push(...previouslyStoredReferenceIds.filter((id) => id !== referenceId));
            const rubricReferences = rubric.references.map((r) => r.id);
            if (!newStoredReferenceIds.find((e) => rubricReferences.includes(e))) {
                dispatch(RemoveRubricsToDisplayIdCommand(rubric.id));
            }
        } else {
            newStoredReferenceIds.push(referenceId, ...previouslyStoredReferenceIds);
            dispatch(AddRubricsToDisplayIdCommand(rubric.id));
        }

        extra.storageProvider.setReferenceIds(newStoredReferenceIds);
        dispatch(referenceToDisplayIdsUpdated({ referenceIds: newStoredReferenceIds }));

        dispatch(UpdateFiltersCommand());
    },
);
