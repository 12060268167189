import { FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { FC, KeyboardEvent, useCallback, useState } from 'react';
import type { AttributeValue, Lexicon } from '../../../../../../core/domain/Roaddata';
import { translateLexiconValue } from '../../../../../../core/legacy/utils/translations';
import { theme } from '../../../../style/theme';

type Props = {
    attributeValue: AttributeValue;
    save: (val: { lexicon: Lexicon }) => void;
    cancel: () => void;
};

export const AttributeValueLexiconInput: FC<Props> = ({ attributeValue, save, cancel }) => {
    const [open, setOpen] = useState(true);
    const lexicons: Lexicon[] = attributeValue.attribute.lexiconValues ?? [];

    const getSortedLexicons = useCallback(() => {
        return lexicons.sort((lexicon1, lexicon2) => {
            return translateLexiconValue(attributeValue.attribute.name, lexicon1.value ?? '').localeCompare(
                translateLexiconValue(attributeValue.attribute.name, lexicon2.value ?? ''),
            );
        });
    }, [lexicons, attributeValue.attribute.name]);

    const sortedLexicons = getSortedLexicons();

    const findLexicon = useCallback((id: string) => lexicons.find((option) => option.id === id), [lexicons]);
    const currentLexicon = findLexicon(attributeValue.value.lexicon?.id ?? '');
    const { isOptional: lexiconIsOptional } = attributeValue.attribute;

    const handleKeyUp = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Escape') {
                event.stopPropagation();
                cancel();
            }
        },
        [cancel],
    );
    const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Escape') {
            e.stopPropagation();
        }
    }, []);

    const handleInput = useCallback(
        (input: SelectChangeEvent) => {
            save({
                lexicon: findLexicon(input.target.value) ?? { id: null, value: null },
            });
        },
        [save],
    );
    const handleCancel = useCallback(() => {
        setOpen(false);
        cancel();
    }, [setOpen, cancel]);

    return (
        <FormControl variant="standard" size="small">
            <Select
                value={currentLexicon?.id ?? ''}
                onChange={handleInput}
                onKeyDownCapture={handleKeyDown}
                onKeyUpCapture={handleKeyUp}
                sx={{
                    height: '19px',
                }}
                autoFocus
                onClose={handleCancel}
                open={open}
            >
                {lexiconIsOptional && (
                    <MenuItem value="" key="-">
                        <Typography fontSize={theme.font.size.small}>-</Typography>
                    </MenuItem>
                )}
                {sortedLexicons.map((option) => (
                    <MenuItem value={option.id ?? ''} key={option.id ?? ''}>
                        <Typography fontSize={theme.font.size.small}>
                            {translateLexiconValue(attributeValue.attribute.name, option.value ?? '')}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
