import { useCallback } from 'react';
import { origins } from '../../../../../core';
import type { AttributeValue, RoadData } from '../../../../../core/domain/Roaddata';
import { translateAttribute } from '../../../../../core/legacy/utils/translations';

const buildListOfAttributeCodesToHide = (isAI?: boolean) => {
    const ATTRIBUTE_CODES_TO_ALWAYS_HIDE = ['DATASOURCE', 'REFERENCE'];
    const ATTRIBUTE_CODES_TO_HIDE_FOR_AI_ROAD_DATAS = [
        'LANDMARK_FOR_LANDMARKS_END_CALCULATION',
        'CUMUL_END',
        'POSITION',
    ];

    const hiddenAttributeCodes = [...ATTRIBUTE_CODES_TO_ALWAYS_HIDE];
    if (isAI) {
        hiddenAttributeCodes.push(...ATTRIBUTE_CODES_TO_HIDE_FOR_AI_ROAD_DATAS);
    }

    return hiddenAttributeCodes;
};

const removeByAttributeWithCode = (attributeCodesToHide: string[]) => (element: AttributeValue) =>
    !attributeCodesToHide.includes(element.attribute.code ?? '');

const sortAttributeValues = (attributeValues?: AttributeValue[]): AttributeValue[] => {
    if (!attributeValues) {
        return [];
    }
    const getOriginIndexForAttribute = (attributeValue: AttributeValue) =>
        origins.findIndex((origin) => attributeValue.attribute.origin === origin);

    return [...attributeValues].sort((a1, a2) => {
        if (a1.attribute.origin !== a2.attribute.origin) {
            return getOriginIndexForAttribute(a1) - getOriginIndexForAttribute(a2);
        }

        return translateAttribute(a1.attribute.name).localeCompare(translateAttribute(a2.attribute.name));
    });
};

export const useSortAndFilterAttributeValues = (selectedRoadData?: RoadData) => {
    const filterAttributeValues = useCallback(
        (attributeValues?: AttributeValue[]) => {
            if (!attributeValues) {
                return [];
            }

            const attributeCodesToHide = buildListOfAttributeCodesToHide(selectedRoadData?.isAI);

            return attributeValues.filter(removeByAttributeWithCode(attributeCodesToHide));
        },
        [selectedRoadData],
    );

    return {
        filterAttributeValues,
        sortAttributeValues,
    };
};
