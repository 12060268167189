import type { FitBoundsOptions } from 'mapbox-gl';
import mapboxToken from '../../../core/legacy/utils/mapbox';
import { COLORS, WIDTH_COLORS } from '../../legacy/components/StreetView/NavigationBar/Itinerary/use-itinerary';

export const FIT_BOUNDS_OPTIONS: FitBoundsOptions = {
    animate: false,
    padding: 100,
} as const;

export const MAPBOX_TOKEN = mapboxToken as string;

export const MAP_GRADE_COLORS = [
    COLORS.TARGET,
    0,
    COLORS.SATURATED,
    0.011,
    COLORS.BAD,
    0.3,
    COLORS.PRETTYBAD,
    0.5,
    COLORS.PRETTYGOOD,
    0.7,
    COLORS.GOOD,
    0.99,
    COLORS.PERFECT,
];

export const MAP_WIDTH_COLORS = [
    WIDTH_COLORS.TARGET,
    0,
    WIDTH_COLORS.BELOW_FOUR,
    4,
    WIDTH_COLORS.FOUR_TO_EIGHT,
    8,
    WIDTH_COLORS.EIGHT_TO_TWELVE,
    12,
    WIDTH_COLORS.TWELVE_TO_SIXTEEN,
    16,
    WIDTH_COLORS.ABOVE_SIXTEEN,
];
