import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../legacy/style/theme';

const ZonesLoading: FC = () => {
    const { t } = useTranslation();

    return (
        <Stack sx={{ alignItems: 'center', color: theme.color.black.main, gap: 3, maxWidth: 400, textAlign: 'center' }}>
            <CircularProgress />
            <Box>
                <Typography sx={{ fontSize: 18, mb: 1 }}>{t('homogeneousZones.loading.title')}</Typography>
                <Typography sx={{ fontSize: 14 }}>{t('homogeneousZones.loading.subtitle')}</Typography>
            </Box>
            <Typography sx={{ fontSize: 12, color: theme.color.grey.main }}>
                {t('homogeneousZones.loading.info')}
            </Typography>
        </Stack>
    );
};

export default ZonesLoading;
