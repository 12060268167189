import type { Reference, RoadData } from '../core/domain/Roaddata';
import { translateReference, translateRubric } from '../core/legacy/utils/translations';

export const resolveRoadDataName = (roadData?: RoadData): string => {
    const getRawReference = (): Reference | null => {
        const reference = roadData?.reference;

        return reference ?? null;
    };

    const rawReference = getRawReference();
    const rubricName = translateRubric(roadData?.name);

    if (!roadData) {
        return '';
    }

    if (rawReference) {
        return translateReference(rawReference) as string;
    }

    return rubricName;
};
