import { Box } from '@mui/material';
import type { SessionMetadata } from '../../../core/domain/Session';
import { useMemo, FC } from 'react';
import formatDateWithBrowserLocale from '../../../core/legacy/utils/date';

type HomogeneousZonesProps = {
    sessionMetadata: SessionMetadata;
};

export const HomogeneousZones: FC<HomogeneousZonesProps> = ({ sessionMetadata }) => {
    const {
        homogeneousZoneAlpha,
        homogeneousZoneDate,
        homogeneousZoneMinLength,
        homogeneousZoneType,
    } = sessionMetadata;

    const canBeDisplayed = useMemo(() => homogeneousZoneAlpha && homogeneousZoneMinLength && homogeneousZoneType, [
        sessionMetadata,
    ]);

    return (
        <Box>
            <Box component="span">
                {homogeneousZoneDate ? formatDateWithBrowserLocale(new Date(homogeneousZoneDate)) : ''}
            </Box>
            <Box component="span" sx={{ display: 'block' }}>
                {canBeDisplayed
                    ? `${
                          homogeneousZoneType === 'NOTE_GLOBALE' ? 'Note' : 'Largeur'
                      } : ${homogeneousZoneAlpha} : ${homogeneousZoneMinLength}m`
                    : ''}
            </Box>
        </Box>
    );
};
