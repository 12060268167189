import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ItinerariesBySessionIds } from '../domain/Itinerary';
import type { Session, SessionFilter, SessionId, SessionPagination, SessionSort } from '../domain/Session';
import { mergeDeep } from '../utils';

export const moduleName = 'SESSIONS';

export type HomogeneousZoneParams = {
    alpha?: number;
    minLength?: number;
    zoneType?: 'NOTE_GLOBALE' | 'LARGEUR';
};

type State = {
    resetSessionsOngoing: boolean;
    sessions: Array<Session>;
    selectedSessionIds: Array<SessionId>;
    sessionIdsTargettedForInteraction: Array<SessionId>;
    sort: SessionSort;
    filter: SessionFilter;
    lastOpenedSessionId: SessionId | undefined;
    itinerariesBySessionIds: ItinerariesBySessionIds;
    homogeneousZonesErrors: string[];
    defaultHomogeneousZoneParams: HomogeneousZoneParams;
    pagination: SessionPagination;
};

const initialState: State = {
    resetSessionsOngoing: false,
    sessions: [],
    selectedSessionIds: [],
    sessionIdsTargettedForInteraction: [],
    sort: [],
    filter: { items: [] },
    lastOpenedSessionId: undefined,
    itinerariesBySessionIds: {},
    homogeneousZonesErrors: [],
    defaultHomogeneousZoneParams: {
        alpha: 0.5,
        minLength: 10,
        zoneType: 'NOTE_GLOBALE',
    },
    pagination: { page: 0, pageSize: 100 },
};

const sessionsSlice = createSlice({
    name: moduleName,
    initialState,
    reducers: {
        resetStarted(state: State) {
            state.sessions = [];
            state.resetSessionsOngoing = true;
            state.selectedSessionIds = [];
            state.sessionIdsTargettedForInteraction = [];
        },
        resetWith(
            state: State,
            action: PayloadAction<{ sessions: Array<Session>; sort: SessionSort; filter: SessionFilter }>,
        ) {
            state.sort = action.payload.sort;
            state.filter = action.payload.filter;
            state.sessions = action.payload.sessions;
            state.resetSessionsOngoing = false;
            state.selectedSessionIds = [];
            state.sessionIdsTargettedForInteraction = [];
        },
        updatedSession(state: State, action: PayloadAction<Session>) {
            const idx = state.sessions.findIndex((aSession) => aSession.id === action.payload.id);
            if (idx < 0) {
                throw new Error(`Session ${action.payload.id} not found in store`);
            }
            state.sessions[idx] = action.payload;
        },
        setSelectedSessions(state: State, action: PayloadAction<SessionId[]>) {
            state.selectedSessionIds = action.payload;
        },
        sortUpdated(state: State, action: PayloadAction<SessionSort>) {
            state.sort = action.payload;
        },
        filterUpdated(state: State, action: PayloadAction<SessionFilter>) {
            state.filter = action.payload;
        },
        paginationUpdated(state: State, action: PayloadAction<Partial<SessionPagination>>) {
            state.pagination = { ...state.pagination, ...action.payload };
        },
        sessionIdsTargettedForInteraction(state: State, action: PayloadAction<Array<SessionId>>) {
            state.sessionIdsTargettedForInteraction = action.payload;
        },
        lastOpenedSessionIdIsSet(state: State, action: PayloadAction<SessionId>) {
            state.lastOpenedSessionId = action.payload;
        },
        itinerariesBySessionIdsUpdated(state: State, action: PayloadAction<ItinerariesBySessionIds>) {
            const nextStateItinerariesBySessionIds = mergeDeep(state.itinerariesBySessionIds, action.payload);
            state.itinerariesBySessionIds = nextStateItinerariesBySessionIds;
        },
        homogeneousZonesFailedComputing(state: State, action: PayloadAction<string[]>) {
            state.homogeneousZonesErrors = action.payload;
        },
        homogeneousZonesChangeDefaultParams(state: State, action: PayloadAction<HomogeneousZoneParams>) {
            state.defaultHomogeneousZoneParams = action.payload;
        },
    },
});

export const {
    resetStarted,
    resetWith,
    updatedSession,
    sortUpdated,
    sessionIdsTargettedForInteraction,
    lastOpenedSessionIdIsSet,
    itinerariesBySessionIdsUpdated,
    homogeneousZonesFailedComputing,
    homogeneousZonesChangeDefaultParams,
    setSelectedSessions,
    filterUpdated,
    paginationUpdated,
} = sessionsSlice.actions;
export const sessionsReducer = sessionsSlice.reducer;
