import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation, Trans } from 'react-i18next';
import {
    Description,
    DragAndDropContainer,
    DescriptionContainer,
    FilesContainer,
    Error,
    ColoredText,
    MediumText,
} from './styles';
import { ReactComponent as UploadLogo } from '../../../../../../assets/icons/upload.svg';
import File from '../../../file';
import { Text } from '../../../../style';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { calibrationSelectFiles } from '../../../../../../core/legacy/actions';
import { Typography } from '@mui/material';
import { theme as appTheme } from '../../../../style/theme';

const LeftText = styled(Text)`
    align-self: start;
    color: ${({ theme }) => theme.color.black.main};
    font-size: ${({ theme }) => theme.font.size.regular};
`;
const ExtraInfo = ({ children }) => <LeftText>{children}</LeftText>;

ExtraInfo.propTypes = {
    children: PropTypes.string.isRequired,
};

const sortFiles = (file1, file2) => file1.name.localeCompare(file2.name);

const FileUpload = ({ accept, fields, multiple, name, maxSize, extraInfo, description }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const maxFiles = multiple ? 0 : 1;
    const files = fields?.getAll();
    const isAFileTooBig = files?.some((file) => file.size > maxSize);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => fields.push(file));
    }, []);

    const unicityValidator = useCallback(
        (file) => {
            const fileAreadyExists = files?.some(
                ({ name: fileName, size }) => fileName === file.name && size === file.size,
            );

            if (fileAreadyExists) return { code: 'already-existing', message: 'File already exists' };

            return null;
        },
        [files],
    );

    const { fileRejections, getRootProps, getInputProps } = useDropzone({
        accept,
        maxFiles,
        onDrop,
        useFsAccessApi: false,
        validator: unicityValidator,
    });

    useEffect(() => {
        dispatch(calibrationSelectFiles((files ?? []).map((file) => file.name)));
        if (!multiple && files?.length > 1) {
            fields.remove(0);
        }
    }, [files, fields, multiple]);

    const shouldDisplayDropZone = useMemo(() => {
        if (multiple || files === undefined) {
            return true;
        }

        return !files.length;
    }, [multiple, files]);

    const shouldDisplayExtraInfo = () => extraInfo && shouldDisplayDropZone;

    return (
        <>
            {shouldDisplayDropZone && (
                <DragAndDropContainer {...getRootProps()}>
                    <input {...getInputProps()} multiple={multiple} name={name} type="file" />
                    <DescriptionContainer>
                        <UploadLogo height="40px" width="40px" />
                        <Description>
                            <Trans
                                components={{ color: <ColoredText />, medium: <MediumText /> }}
                                i18nKey={description}
                            />
                        </Description>
                    </DescriptionContainer>
                </DragAndDropContainer>
            )}
            {shouldDisplayExtraInfo() && <ExtraInfo>{extraInfo}</ExtraInfo>}
            {files?.length === 0 && <Error>{t('error.filesEmpty')}</Error>}
            <FilesContainer>
                {/* sort by name here and in UploadInProgressModal to keep consistency - to be challenged */}
                {files?.sort(sortFiles).map((file, index) => (
                    <File
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${file.name}_${index}`}
                        file={file}
                        maxSize={maxSize}
                        onClose={() => fields.remove(index)}
                    />
                ))}
            </FilesContainer>
            {fileRejections?.length > 0 && (
                <Typography
                    sx={{ color: appTheme.color.orange.dark, fontSize: 14, fontWeight: 600, lineHeight: 'normal' }}
                >
                    {t('uploadFile.unicityError', { count: fileRejections.length })}
                </Typography>
            )}
            {maxSize && isAFileTooBig && <Error>{t('error.fileSize')}</Error>}
        </>
    );
};

FileUpload.defaultProps = {
    extraInfo: '',
};

FileUpload.propTypes = {
    accept: PropTypes.any,
    fields: PropTypes.shape({
        getAll: PropTypes.func,
        map: PropTypes.func,
        push: PropTypes.func,
        remove: PropTypes.func,
    }),
    maxSize: PropTypes.number,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    onFileChange: PropTypes.func,
    extraInfo: PropTypes.string,
    description: PropTypes.string,
};

export default FileUpload;
