import {
    noMoreCalibrationPointInEdition,
    selectHoveredCalibrationPoint,
    noMoreHoveredCalibrationPoint,
    selectSelectedCalibrationPoint,
    noMoreSelectedCalibrationPoint,
} from '../../../../../../core/legacy/actions';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectedCalibrationPointIdSelector,
    hoveredCalibrationPointIdSelector,
} from '../../../../../../core/legacy/selectors/calibration';
import { CalibrationPoint } from '../../../propTypes';
import {
    ElementClass as CalibrationPointClass,
    ElementData as CalibrationPointData,
    StyledBlock,
} from '../../../ui/panels/right-panel/ElementSummary/styles';
import { useTranslation } from 'react-i18next';
import {
    getCoordinateXValue,
    getCoordinateYValue,
    getCoordinateZValue,
} from '../../../../../../core/legacy/utils/calibrationPointsUtils';

const CalibrationPointSummary = ({ calibrationPoint }) => {
    const dispatch = useDispatch();
    const selectedCalibrationPointId = useSelector(selectedCalibrationPointIdSelector);
    const handleSelect = useCallback(() => {
        dispatch(selectSelectedCalibrationPoint(calibrationPoint.id));
        dispatch(noMoreCalibrationPointInEdition());
        dispatch(noMoreHoveredCalibrationPoint());
    });
    const handleUnselect = useCallback(() => {
        dispatch(noMoreCalibrationPointInEdition());
        dispatch(noMoreSelectedCalibrationPoint());
        dispatch(selectHoveredCalibrationPoint(calibrationPoint.id));
    });

    const handleMouseOver = () => {
        dispatch(selectHoveredCalibrationPoint(calibrationPoint.id));
    };
    const handleMouseLeave = () => {
        dispatch(noMoreHoveredCalibrationPoint());
    };

    const hoveredCalibrationPointId = useSelector(hoveredCalibrationPointIdSelector);
    const selected = selectedCalibrationPointId === calibrationPoint.id;
    const hovered = hoveredCalibrationPointId === calibrationPoint.id && !selected;

    const { t } = useTranslation();

    const x = getCoordinateXValue(calibrationPoint);
    const y = getCoordinateYValue(calibrationPoint);
    const z = getCoordinateZValue(calibrationPoint);

    return (
        <StyledBlock
            onHover={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            selected={selected}
            hovered={hovered}
            onSelect={handleSelect}
            onUnselect={handleUnselect}
        >
            <CalibrationPointClass>{t('calibration.calibrationPoint')}</CalibrationPointClass>
            <CalibrationPointData>{`[${x}, ${y}, ${z}]`}</CalibrationPointData>
        </StyledBlock>
    );
};

CalibrationPointSummary.propTypes = {
    calibrationPoint: CalibrationPoint.isRequired,
};

export default CalibrationPointSummary;
