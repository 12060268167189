import { SessionsListModals } from './Modals';
import { SessionHeader } from './SessionHeader';
import { useAppDispatch, useAppSelector } from '../../../types';
import { userIdQuery } from '../../../core/authentication/queries';
import { Stack } from '@mui/material';
import { InitSessionsCommand } from '../../../core/sessions/commands/InitSessionsCommand';
import {
    lastOpenedSessionIdQuery,
    loadingSessionsQuery,
    sessionFilterQuery,
    sessionPaginationQuery,
    sessionSortQuery,
    sessionsQuery,
} from '../../../core/sessions/queries';
import { InitAiAnalysisCommand } from '../../../core/automatic_analysis/InitAiAnalysisCommand';
import {
    DataGridPro,
    GridCellEditStopReasons,
    GridPinnedColumnFields,
    gridClasses,
    useGridApiRef,
    GRID_CHECKBOX_SELECTION_FIELD,
    GridColDef,
} from '@mui/x-data-grid-pro';
import { theme } from '../../legacy/style/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDataGridColumns } from './getDataGridColumns';
import { BaseSyntheticEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import localeTexts from '../../../providers/translations/dataGridFilterTranslations';
import { allExecutionsQuery } from '../../../core/automatic_analysis/queries';
import { paginationUpdated, setSelectedSessions } from '../../../core/sessions/state';
import { SortSessionsCommand } from '../../../core/sessions/commands/SortSessionsCommand';
import FilterSessionCommand from '../../../core/sessions/commands/FilterSessionCommand';
import buildFiltersFromSession from '../../../utils/buildFiltersFromSessions';
import { SetLastOpenedSessionIdCommand } from '../../../core/sessions/commands/SetLastOpenedSessionIdCommand';
import { EmptySessionList } from './EmptySessionList';
import CustomPagination from './CustomPagination';
import { SessionWithExecutions } from '../../../core/domain/Session';
import editSession from '../../../core/sessions/commands/EditSession';
import { setModalToDisplay } from '../../../core/legacy/actions/modals';
import { SessionsModalTypes } from '../../../core/legacy/reducers/modals';
import CustomToolbar from './CustomToolbar';
import { LAST_SELECTED_CLASS, gridStyle } from '../../style/datagrid';

const getTogglableColumns = (columns: GridColDef[]): string[] =>
    columns.filter(({ hideable }) => hideable).map(({ field }) => field);

export const SessionsListPage: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const apiRef = useGridApiRef();

    const { t } = useTranslation();
    const sessions = useAppSelector(sessionsQuery);
    const executions = useAppSelector(allExecutionsQuery);
    const sort = useAppSelector(sessionSortQuery);
    const filter = useAppSelector(sessionFilterQuery);
    const pagination = useAppSelector(sessionPaginationQuery);
    const lastSelectedSession = useAppSelector(lastOpenedSessionIdQuery);
    const userId = useAppSelector(userIdQuery);
    const loadingState = useAppSelector(loadingSessionsQuery);

    const [locallySelectedIds, setLocallySelectedIds] = useState<string[]>([]);
    const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumnFields>({
        left: [GRID_CHECKBOX_SELECTION_FIELD],
        right: ['actions'],
    });
    const [panelElement, setPanelElement] = useState<HTMLElement | null>(null);
    const [lastSelectedSessionIndex, setlastSelectedSessionIndex] = useState<number>(0);

    const [sessionsWithExecutions, campaigns, zhs, calibrations, authors] = useMemo(
        () => buildFiltersFromSession(t, sessions, executions),
        [sessions, executions],
    );
    const isEmpty = useMemo(() => !loadingState && sessionsWithExecutions.length === 0, [
        loadingState,
        sessionsWithExecutions,
    ]);
    const columns = useMemo(() => getDataGridColumns(t, campaigns, zhs, calibrations, authors, !isEmpty), [
        campaigns,
        zhs,
        isEmpty,
        authors,
    ]);
    const isLoading = useMemo(
        () => loadingState || sessions.length !== sessionsWithExecutions.length || columns.length === 0,
        [loadingState, sessions, sessionsWithExecutions, columns],
    );

    useEffect(() => {
        if (userId) {
            dispatch(InitSessionsCommand());
            dispatch(InitAiAnalysisCommand());
        }
    }, [userId]);

    const handleRowEdit = useCallback(async (updatedRow: SessionWithExecutions, originalRow: SessionWithExecutions) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { executions: updatedRowExecutions, ...sessionData } = updatedRow;

        try {
            await dispatch(editSession({ sessionData, sessionId: sessionData.id }));

            return updatedRow;
        } catch (err) {
            dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_EDIT_FAILURE));

            return originalRow;
        }
    }, []);

    const handlePinedColumnsChange = useCallback(({ left, right }: GridPinnedColumnFields) => {
        const lastColumnField = 'actions';

        setPinnedColumns({
            // only sort to let actions column as last
            right: right?.sort((a, b) => {
                if (a === lastColumnField) return 1;
                if (b === lastColumnField) return -1;

                return 0;
            }),
            left,
        });
    }, []);

    const onRowClick = useCallback(
        ({ row }, event: BaseSyntheticEvent) => {
            event.stopPropagation();
            dispatch(SetLastOpenedSessionIdCommand(row.id));
            navigate(`${location.pathname}/street-view/${row.id}`);
        },
        [location],
    );

    useEffect(() => {
        if (lastSelectedSessionIndex && apiRef.current) {
            const timeout = setTimeout(
                () => apiRef.current.scrollToIndexes({ rowIndex: lastSelectedSessionIndex }),
                400,
            );

            return () => clearTimeout(timeout);
        }

        return undefined;
    }, [lastSelectedSessionIndex]);

    return (
        <Stack sx={{ width: '95%' }}>
            <SessionHeader />
            <Stack
                sx={{
                    height: 'calc(100vh - 264px)', // problem with auto height computing, we remove header size from screen size
                    padding: '10px 20px 0',
                    borderRadius: '6px',
                    boxShadow: theme.boxShadow.grey.thin,
                    backgroundColor: theme.color.white.main,
                    mb: 2.5,
                }}
            >
                <DataGridPro
                    loading={isLoading}
                    apiRef={apiRef}
                    pinnedColumns={pinnedColumns}
                    onPinnedColumnsChange={handlePinedColumnsChange}
                    onStateChange={({
                        dimensions: { contentSize },
                        filter: { filteredRowsLookup },
                        rowSelection,
                        sorting: { sortedRows },
                    }) => {
                        const selectedSessionsMatchingFilters =
                            Object.keys(filteredRowsLookup).length > 0
                                ? rowSelection.filter((id: string) => filteredRowsLookup[id])
                                : rowSelection;

                        // filtering the whole selected array to keep only the one matching filters
                        dispatch(setSelectedSessions(selectedSessionsMatchingFilters));

                        if (lastSelectedSession && lastSelectedSessionIndex < 1 && contentSize.height > 0) {
                            setlastSelectedSessionIndex(sortedRows.indexOf(lastSelectedSession));
                        }
                    }}
                    slots={{
                        noRowsOverlay: EmptySessionList,
                        pagination: CustomPagination,
                        toolbar: CustomToolbar,
                    }}
                    getRowClassName={(params) => (params.row.id === lastSelectedSession ? LAST_SELECTED_CLASS : '')}
                    slotProps={{
                        filterPanel: {
                            filterFormProps: {
                                columnInputProps: {
                                    size: 'small',
                                    variant: 'outlined',
                                },
                                operatorInputProps: {
                                    size: 'small',
                                    variant: 'outlined',
                                    width: 'auto',
                                },
                                valueInputProps: {
                                    InputComponentProps: {
                                        variant: 'outlined',
                                        size: 'small',
                                    },
                                },
                                deleteIconProps: {
                                    sx: { mx: 0 },
                                },
                            },
                            sx: { [`.${gridClasses.filterForm}`]: { gap: 1 } },
                        },
                        panel: { anchorEl: panelElement },
                        toolbar: { setPanelElement },
                        columnsManagement: {
                            getTogglableColumns,
                        },
                    }}
                    paginationModel={pagination}
                    onPaginationModelChange={(newPagination) => {
                        if (!isLoading) dispatch(paginationUpdated(newPagination));
                    }}
                    pageSizeOptions={[25, 50, 100, 500, 1000]}
                    sx={gridStyle}
                    sortModel={sort}
                    onSortModelChange={(m) => dispatch(SortSessionsCommand(m))}
                    filterModel={filter}
                    onFilterModelChange={(m) => dispatch(FilterSessionCommand(m))}
                    rows={sessionsWithExecutions}
                    onRowClick={onRowClick}
                    columns={columns}
                    checkboxSelection
                    disableRowSelectionOnClick
                    hideFooterSelectedRowCount
                    rowSelectionModel={locallySelectedIds}
                    localeText={localeTexts}
                    onRowSelectionModelChange={(m) => setLocallySelectedIds(m as string[])}
                    disableColumnMenu={isEmpty}
                    disableColumnFilter={isEmpty}
                    processRowUpdate={handleRowEdit}
                    onCellEditStop={({ field, id, reason }, event) => {
                        const validReasonsToLeave = [
                            GridCellEditStopReasons.enterKeyDown,
                            GridCellEditStopReasons.escapeKeyDown,
                        ];

                        if (!validReasonsToLeave.includes(reason as GridCellEditStopReasons)) {
                            event.defaultMuiPrevented = true;
                            apiRef.current.stopCellEditMode({ id, field, ignoreModifications: true });
                        }
                    }}
                    pagination
                />
            </Stack>
            <SessionsListModals />
        </Stack>
    );
};
