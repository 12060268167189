import type { FC } from 'react';
import { Container, MapPanel } from './components';
import useLoadNetworkData from './hooks/use-load-network-data';
import NetworkMap from './Map';
import { NoDataForNetworkMapModal } from './NoDataForNetworkMapModal';
import SessionsPanel from './SessionsPanel';

const MapView: FC = () => {
    const dataLoaded = useLoadNetworkData();

    return (
        <Container>
            {!dataLoaded ? null : (
                <>
                    <MapPanel>
                        <NetworkMap />
                    </MapPanel>
                    <SessionsPanel />
                </>
            )}
            <NoDataForNetworkMapModal />
        </Container>
    );
};

export default MapView;
