import { Typography, Box } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { NetworkRoadDataProperties } from '../../../../../../core/networkMap/ExternalNetworkMapPort';
import { AttributeLine } from './styles';
import ExpandableImage from '../../Shared/ExpandableImage';
import { theme } from '../../../../../legacy/style/theme';
import Measure from '../../Shared/Measure';
import LandmarkValue from '../../../../../components/streetView/roadDatasPanel/values/LandmarkValue';

type Props = {
    properties: NetworkRoadDataProperties;
};

const WidthAttributes: FC<Props> = ({ properties }) => {
    const { t } = useTranslation();

    return <AttributeLine label={t('map.popup.measured_width')} value={`${properties?.roadData.measured_width}m`} />;
};

const WidthAndSurfaceAttributes: FC<Props> = ({ properties }) => {
    const { t } = useTranslation();

    return (
        <>
            <AttributeLine label={t('map.popup.measured_width')} value={`${properties?.roadData.measured_width}m`} />
            <AttributeLine label={t('map.popup.section_surface')} value={`${properties?.roadData.section_surface}m²`} />
            <AttributeLine label={t('map.popup.total_surface')} value={`${properties?.roadData.total_surface}m²`} />
        </>
    );
};

const getAttributesByRubricCode = (rubricCode: string): FC<Props> | null => {
    switch (rubricCode) {
        case 'LARGEUR':
            return WidthAttributes;
        case 'LARGEUR_ET_SURFACE':
        case 'LARGEUR_ET_SURFACE_II':
        case 'LARGEUR_ET_SURFACE_III':
            return WidthAndSurfaceAttributes;
        default:
            return null;
    }
};

const Content: FC<Props> = ({ properties }) => {
    const { t } = useTranslation();

    const AttributesComponent = getAttributesByRubricCode(properties.rubricCode);

    return (
        <Box marginBottom=".5rem">
            <Typography
                noWrap
                sx={{
                    wordBreak: 'break-all',
                    fontWeight: 600,
                    color: theme.color.black.main,
                }}
            >
                {properties?.sessionName}
            </Typography>
            <Measure
                label={t('map.popup.cumul-start')}
                value={<LandmarkValue landmark={properties.roadData.landmark_start} unit="m" />}
            />
            <ExpandableImage sessionId={properties.sessionId} imageIndex={properties.imageIndex} />
            <Box
                boxSizing="border-box"
                marginTop="-6px"
                padding="10px"
                color="white"
                width="100%"
                borderRadius="0 0 5px 5px"
                bgcolor={properties.color}
            >
                {AttributesComponent && <AttributesComponent properties={properties} />}
            </Box>
        </Box>
    );
};

export default Content;
