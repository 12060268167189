import type { ExternalAiAnalysisPort } from '../core/automatic_analysis/ExternalAiAnalysisPort';
import { captureException } from '@sentry/browser';
import axios from 'axios';
import type { Execution, Model } from '../core/domain/AIAnalysis';

export class ExternalAiAnalysisProvider implements ExternalAiAnalysisPort {
    constructor(private readonly baseEndpoint: string) {}

    async triggerAnalysis(modelCode: string, sessionId: string): Promise<Execution> {
        try {
            const response = await axios.post(
                `${this.baseEndpoint}/ai-analyses/executions`,
                {
                    modelCode,
                    sessionId,
                },
                {
                    withCredentials: true,
                },
            );

            return response.data as Execution;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async loadModels(): Promise<Model[]> {
        try {
            const response = await axios.get(`${this.baseEndpoint}/ai-analyses/models`, {
                withCredentials: true,
            });

            return response.data as Array<Model>;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async loadExecutions(): Promise<Execution[]> {
        try {
            const response = await axios.get(`${this.baseEndpoint}/ai-analyses/executions`, {
                withCredentials: true,
            });

            return response.data as Array<Execution>;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }
}
