import { SxProps } from '@mui/material';
import { theme } from '../legacy/style/theme';
import { gridClasses } from '@mui/x-data-grid-pro';

const LAST_SELECTED_CLASS = 'lastSelected';

const gridStyle: SxProps = {
    '@keyframes fadeBg': {
        '0%': {
            bgcolor: theme.color.grey.medium,
        },
        '50%': {
            bgcolor: theme.color.grey.medium,
        },
        '100%': {
            bgcolor: 'transparent',
        },
    },
    [`&, [class^=MuiDataGrid]:not(.${gridClasses.footerContainer}):not(.${gridClasses.columnHeaders})`]: {
        border: 'none',
    },
    [`.${gridClasses.row}`]: { cursor: 'pointer' },
    [`.${gridClasses.row}:not(.Mui-selected) .${gridClasses.checkboxInput}`]: { opacity: 0 },
    [`.${gridClasses.row}:hover .${gridClasses.checkboxInput}`]: { opacity: 1 },
    [`.${gridClasses.row}.${LAST_SELECTED_CLASS}`]: { animation: `fadeBg 2s ease-in-out` },
    [`.${gridClasses.columnHeaders}`]: {
        background: theme.color.white.main,
        borderBottom: `1px solid ${theme.color.grey.medium}`,
    },
    [`.${gridClasses.columnHeaderTitleContainer}`]: { color: theme.color.grey.main },
    [`.${gridClasses.columnHeaderTitleContainer} .${gridClasses.iconButtonContainer} button svg`]: {
        color: theme.color.blue.primary,
    },
    [`.${gridClasses.columnHeader}:focus-within, .${gridClasses.cell}:focus-within`]: { outline: 0 },
    '& .light': {
        color: theme.color.grey.main,
        borderColor: 'transparent',
    },
    '& .dark': {
        color: theme.color.black.dark,
        borderColor: 'transparent',
    },
    [`.${gridClasses['columnHeader--pinnedLeft']}, .${gridClasses['columnHeader--pinnedRight']}, .${gridClasses['cell--pinnedLeft']}, .${gridClasses['cell--pinnedRight']}`]: {
        background: theme.color.white.main,
    },
};

export { gridStyle, LAST_SELECTED_CLASS };
