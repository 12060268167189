import AttributeTypeEnum from './AttributeTypeEnum';

export const findAttributeValuesByType = (roadData, type) => {
    return roadData.attributeValues.find((att) => {
        return att.attribute.type === type;
    });
};

const getNumericValueFromAttributeValue = (attributeValue) => attributeValue.value.numeric;
const getCumulStartValueFromRoadData = (roadData) => {
    const cumulStartAttributeValue = findAttributeValuesByType(roadData, AttributeTypeEnum.CUMUL_START);
    const cumulStartValue = getNumericValueFromAttributeValue(cumulStartAttributeValue);

    return cumulStartValue;
};

const sortByCumulStartAsc = (obj1, obj2) => obj1.cumulStart - obj2.cumulStart;
const roadDatasIdsAndCumulStartMapper = (roadDatas) =>
    roadDatas
        .map((roadData) => ({
            id: roadData.id,
            cumulStart: +getCumulStartValueFromRoadData(roadData),
        }))
        .sort(sortByCumulStartAsc);

const findNextRoadDataAfter = (currentCumulStartSession) => (element) => element.cumulStart > currentCumulStartSession;
export const findRoadDataIdToScrollTo = (currentCumulStartSession, roadDatas) => {
    const roadDatasIdsAndCumulStart = roadDatasIdsAndCumulStartMapper(roadDatas);
    let closestRoadData = roadDatasIdsAndCumulStart.find(findNextRoadDataAfter(currentCumulStartSession));

    if (!closestRoadData) {
        closestRoadData = roadDatasIdsAndCumulStart[roadDatasIdsAndCumulStart.length - 1];
    }

    return closestRoadData?.id;
};

export const getAdjustedCumulStart = (currentImageCumulStart) => {
    const CUMUL_START_OFFSET_FOR_SCROLL = 3;
    const adjustedCumulStart = currentImageCumulStart + CUMUL_START_OFFSET_FOR_SCROLL;

    return adjustedCumulStart;
};
