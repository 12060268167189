import FailureModal from '../../legacy/components/ui/modals/FailureModal';
import { useTranslation } from 'react-i18next';
import { SessionsModalTypes } from '../../../core/legacy/reducers/modals';

export const UploadInitFailureModal = () => {
    const { t } = useTranslation();

    return (
        <FailureModal
            title={t('uploadSession.uploadInitFailed.title')}
            subtitle={t('uploadSession.uploadInitFailed.subtitle')}
            modalType={SessionsModalTypes.SESSIONS_UPLOAD_INIT_FAILURE}
        />
    );
};
