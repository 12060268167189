import type { Expression, LineLayer } from 'mapbox-gl';
import { MIN_ZOOM_FOR_OBJECT_DISPLAY } from '../../../core/networkMap/commands/LoadNetworkMapRoadDataCommand';

export const SOURCE_NETWORK_ROAD_DATA_ID = 'network_road_data' as const;

const COLOR: Expression = ['case', ['boolean', ['feature-state', 'hover'], false], ['get', 'color'], ['get', 'color']];
const OPACITY: Expression = ['case', ['boolean', ['feature-state', 'hover'], false], 0.5, 1];
const WIDTH: Expression = ['case', ['boolean', ['feature-state', 'hover'], false], 6, 3];

// layer config for display lines road-data
export const LAYER_LINE_NETWORK_ROAD_DATA_ID = 'network_road_data_line' as const;
export const ROAD_DATA_LINESTRING_LAYER: LineLayer = {
    id: LAYER_LINE_NETWORK_ROAD_DATA_ID,
    source: SOURCE_NETWORK_ROAD_DATA_ID,
    type: 'line',
    minzoom: MIN_ZOOM_FOR_OBJECT_DISPLAY,
    paint: {
        'line-color': COLOR,
        'line-opacity': OPACITY,
        'line-width': WIDTH,
    },
} as const;
export const LINESTRING_FILTER: Expression = ['all', ['==', '$type', 'LineString']];
