export type SessionUploadBlock = {
    id: string;
    objectUrl?: string;
    blockSize: number;
};

export enum UploadStatuses {
    INITIATED = 'INITIATED',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export type SessionUpload = {
    url: string;
    fileName: string;
    archiveId: string;
    key: string;
    totalSize: number;
    uploadedSize: number;
    status: UploadStatuses;
    blocks: Array<SessionUploadBlock>;
    failedBlocks: Array<SessionUploadBlock>;
    blockList: Array<string>;
};
