import { Box, Stack } from '@mui/material';
import type { ReactNode, FC } from 'react';

type ControlProps = {
    children: ReactNode;
};
export const Control: FC<ControlProps> = ({ children }) => (
    <Stack
        direction="column"
        justifyContent="right"
        alignItems="stretch"
        width="auto"
        sx={{
            top: 0,
            right: 0,
            position: 'absolute',
            margin: '24px',
        }}
    >
        {children}
    </Stack>
);

type MarginBottomBlockProps = {
    children: ReactNode;
};
export const MarginBottomBlock: FC<MarginBottomBlockProps> = ({ children }) => (
    <Stack
        sx={{
            marginBottom: '10px',
        }}
    >
        {children}
    </Stack>
);

type ButtonLabelProps = {
    children: ReactNode;
};
export const ButtonLabel: FC<ButtonLabelProps> = ({ children }) => (
    <Box
        sx={{
            position: 'absolute',
            bottom: '0px',
            fontSize: '13px',
            textTransform: 'none',
            width: '100%',
            paddingTop: '.5rem',
            background: 'linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 54%, rgba(0,0,0,0) 100%)',
        }}
    >
        {children}
    </Box>
);
