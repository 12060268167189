import type { Rubric } from '../../domain/Roaddata';
import { createCommand } from '../../utils';
import { moduleName, rubricToDisplayIdsUpdated } from '../state';
import { UpdateFiltersCommand } from './UpdateFiltersCommand';

export const UpdateRubricToDisplayIdsCommand = createCommand(
    `${moduleName}/UPDATE_RUBRIC_TO_DISPLAY_IDS`,
    async (rubrics: Array<Rubric>, { dispatch, extra }) => {
        const rubricIds = rubrics.map((r) => r.id);
        extra.storageProvider.setRubricIds(rubricIds);
        dispatch(rubricToDisplayIdsUpdated({ rubricIds }));

        dispatch(UpdateFiltersCommand());
    },
);
