import GenericModal from '../../legacy/components/ui/modals/GenericModal';
import { useTranslation } from 'react-i18next';
import { ContentContainer, ModalTitle } from '../../legacy/components/ui/modals/styles';
import { SessionsModalTypes } from '../../../core/legacy/reducers/modals';
import UploadSessionForm from './UploadSessionForm';
import { theme } from '../../legacy/style/theme';
import type { FC } from 'react';

export const UploadSessionModal: FC<{}> = () => {
    const { t } = useTranslation();

    // FIXME manu: on a pas besoin d'un formulaire ici, juste des composants » supprimer la dépendance à redux form
    return (
        <GenericModal
            options={{ hideDefaultButtons: true, backgroundColor: theme.color.blue.grey }}
            modalType={SessionsModalTypes.SESSIONS_UPLOAD_SESSIONS}
        >
            <ContentContainer>
                <ModalTitle>{t('labels.import')}</ModalTitle>
                <UploadSessionForm />
            </ContentContainer>
        </GenericModal>
    );
};
