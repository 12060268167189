import { DeepPartial } from '../../../types';
import type { Session, SessionId } from '../../domain/Session';
import { setModalToDisplay, unsetModalToDisplay } from '../../legacy/actions/modals';
import { SessionsModalTypes } from '../../legacy/reducers/modals';
import { createCommand } from '../../utils';
import { moduleName, updatedSession } from '../state';

interface EditSessionParams {
    sessionIds: SessionId[];
    edit: DeepPartial<Session>;
}

const editSessions = createCommand(
    `${moduleName}/EDIT_CAMPAIGN`,
    async ({ edit, sessionIds }: EditSessionParams, { dispatch, extra }) => {
        try {
            const sessions = await extra.externalSessions.editSessions(sessionIds, edit);
            sessions.forEach((session) => dispatch(updatedSession(session)));
            dispatch(unsetModalToDisplay());
        } catch (err) {
            dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_EDIT_FAILURE));
        }
    },
);

export default editSessions;
