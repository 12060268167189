import { FC, useCallback } from 'react';
import AttributeInput from '../../../ui/panels/right-panel/values/AttributeInput';
import type { AttributeValue, Value } from '../../../../../../core/domain/Roaddata';

type AttributeValueTextInputProps = {
    attributeValue: AttributeValue;
    save: (value: Partial<Value>) => void;
    cancel: () => void;
};

const AttributeValueTextInput: FC<AttributeValueTextInputProps> = ({ attributeValue, save, cancel }) => {
    const saveText = useCallback(
        (value) =>
            save({
                text: value,
            }),
        [],
    );

    return <AttributeInput type="text" value={attributeValue.value.text ?? ''} save={saveText} cancel={cancel} />;
};

export default AttributeValueTextInput;
