import { useCallback, ChangeEvent, FC } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import toggleImageRoaddataOnlyCommand from '../../../../../core/filters/commands/ToggleImageRoaddataOnlyCommand';
import { imageRoaddataOnlyQuery } from '../../../../../core/filters/queries';

const RoaddataFilter: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const checked = useAppSelector(imageRoaddataOnlyQuery);

    const handleChange = useCallback(
        ({ target }: ChangeEvent<HTMLInputElement>) => dispatch(toggleImageRoaddataOnlyCommand(target.checked)),
        [dispatch],
    );

    return (
        <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} size="small" disableRipple />}
            label={<Typography sx={{ fontSize: 14 }}>{t('filters.panel.imageRoaddataOnly')}</Typography>}
        />
    );
};

export default RoaddataFilter;
