import axios from 'axios';
import { captureException } from '@sentry/browser';
import type { ExternalOrganizationPort } from './ExternalOrganizationProviderPort';

export class ExternalOrganizationProvider implements ExternalOrganizationPort {
    constructor(private readonly baseEndpoint: string) {}

    async loadOrganization(organizationId: string): Promise<any> {
        try {
            const response = await axios.request({
                method: 'GET',
                url: `${this.baseEndpoint}/organization/${organizationId}`,
                withCredentials: true,
            });

            return response.data;
        } catch (e) {
            console.error(e);
            captureException(e);
            throw e;
        }
    }
}
