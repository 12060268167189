import { Stack } from '@mui/material';
import type { FC, ReactNode } from 'react';

type ContainerProps = {
    children: ReactNode;
};
export const Container: FC<ContainerProps> = ({ children }) => {
    return (
        <Stack direction="row" justifyContent="space-between">
            {children}
        </Stack>
    );
};

type LeftContainerProps = {
    children: ReactNode;
};
export const LeftContainer: FC<LeftContainerProps> = ({ children }) => {
    return (
        <Stack direction="column" justifyContent="center">
            {children}
        </Stack>
    );
};

type SessionNameContainerProps = {
    children: ReactNode;
};
export const SessionNameContainer: FC<SessionNameContainerProps> = ({ children }) => {
    return (
        <Stack direction="row" justifyContent="center">
            {children}
        </Stack>
    );
};

type OptionsContainerProps = {
    children: ReactNode;
};
export const OptionsContainer: FC<OptionsContainerProps> = ({ children }) => {
    return (
        <Stack direction="row" gridRow={1} justifyContent="flex-end" alignItems="center" gap="16px">
            {children}
        </Stack>
    );
};
