import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../../../../style/theme';

const Input = styled.input`
    border: none;
    border-radius: ${theme.radius.xs};
    background-color: ${theme.color.blue.tint};
    text-align: left;
    &:focus {
        outline: none;
    }
`;

export default function AttributeInput({ type, value, save, cancel }) {
    const [newValue, setValue] = useState(value);
    const handleInput = useCallback((event) => setValue(event.target.value));
    const handleKeyboard = useCallback((event) => {
        switch (event.key) {
            case 'Enter':
                save(newValue);
                break;
            case 'Escape':
                cancel();
                break;
            default:
                break;
        }
    });

    return (
        <Input
            type={type}
            value={newValue}
            // autoFocus after a explicit click on the element, so it's ok
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            onChange={handleInput}
            onBlur={cancel}
            onKeyDown={handleKeyboard}
        />
    );
}

AttributeInput.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    save: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
};
