import { useTranslation } from 'react-i18next';
import BaseButton from './BaseButton';
import { useAppDispatch } from '../../../../types';
import { setModalToDisplay } from '../../../../core/legacy/actions/modals';
import { SessionsModalTypes } from '../../../../core/legacy/reducers/modals';
import type { FC } from 'react';
import { EditIcon } from '../../icons/EditIcon';

const EditStateButton: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <BaseButton
            onClick={() => dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_EDIT_STATE))}
            variant="secondary"
        >
            <EditIcon />
            {t('session.edit_state')}
        </BaseButton>
    );
};

export default EditStateButton;
