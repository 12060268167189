enum CatalogEnum {
    MEASURES = 'MEASURES',
    ROADSIGNS = 'ROADSIGNS',
    MARKINGS = 'MARKINGS',
    DEGRADATIONS = 'DEGRADATIONS',
    REFERENTIAL = 'REFERENTIAL',
    REPERAGE = 'REPERAGE',
    IA = 'IA',
    AI = 'AI',
    RELEVES = 'RELEVES',
}

export default CatalogEnum;
