import { FC } from 'react';
import { MediumText } from '../../design_system/texts';
import { Session } from '../../../core/domain/Session';
import { useAppSelector } from '../../../types';
import { currentImageSelector } from '../../../core/legacy/selectors/streetView';

interface CurrentPositionInSessionProps {
    session: Session | null;
}

const CurrentPositionInSession: FC<CurrentPositionInSessionProps> = ({ session }) => {
    const currentImage = useAppSelector(currentImageSelector);
    const currentPosition = currentImage?.cumulStartSession ?? 0;
    const totalLength = session?.metadata?.cumulativeEnd ?? 0;

    return (
        <MediumText sx={{ marginBottom: '8px' }}>
            <MediumText component="span" fontWeight="bold">{`${currentPosition} m`}</MediumText>
            {` / ${totalLength} m`}
        </MediumText>
    );
};

export default CurrentPositionInSession;
