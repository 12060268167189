import {
    deleteCalibrationPointRequest,
    updateCalibrationPointRequest,
    getNewCalibrationProcessRequest,
    addCalibrationPointRequest,
    updateCalibrationPixelsRequest,
    generateCalibrationProcessResultRequest,
} from '../../../providers/legacy/datasources/calibrationDataSource';
import { getCalibrationImageSelector } from '../selectors/calibration';
import CalibrationPointTypeEnum from '../utils/CalibrationPointTypeEnum';
import { SessionsModalTypes } from '../reducers/modals';
import { setModalToDisplay } from './modals';
import { getImageBackgroundFromAnnotationEngine } from '../utils/roadViewerUtils';
import { loaderStarted, loaderStopped } from '../../loader/state';

export const initCalibrationProcess = (imageId) => async (dispatch) => {
    dispatch(loaderStarted());

    const calibrationProcess = await getNewCalibrationProcessRequest(imageId);
    dispatch({
        type: 'CALIBRATION/INIT_CALIBRATION_PROCESS',
        calibrationProcess,
    });

    dispatch(loaderStopped());
};

export const calibrationSelectFiles = (fileNames) => ({
    type: 'CALIBRATION/UPLOAD-FILES-SELECTED',
    payload: fileNames,
});

export const resetCalibrationProcess = () => ({
    type: 'CALIBRATION/RESET_CALIBRATION_PROCESS',
});

export const closeWelcomeCalibrationPanel = () => ({
    type: 'CALIBRATION/CLOSE_WELCOME_CALIBRATION_PANEL',
});

export const noMoreCalibrationPointInEdition = () => ({
    type: 'CALIBRATION/NO_MORE_CALIBRATION_POINT_IN_EDITION',
});

export const selectHoveredCalibrationPoint = (calibrationPointId) => ({
    type: 'CALIBRATION/SELECT_HOVERED_CALIBRATION_POINT',
    calibrationPointId,
});

export const noMoreHoveredCalibrationPoint = () => ({
    type: 'CALIBRATION/NO_MORE_HOVERED_CALIBRATION_POINT',
});

export const selectSelectedCalibrationPoint = (calibrationPointId) => ({
    type: 'CALIBRATION/SELECT_SELECTED_CALIBRATION_POINT',
    calibrationPointId,
});

export const noMoreSelectedCalibrationPoint = () => ({
    type: 'CALIBRATION/NO_MORE_SELECTED_CALIBRATION_POINT',
});

export const setCalibrationProcessResultWasExported = (wasExported) => ({
    type: 'CALIBRATION/SET_CALIBRATION_PROCESS_RESULT_WAS_EXPORTED',
    wasExported,
});

export const deleteCalibrationPoint = (calibrationPointId) => async (dispatch, getState) => {
    dispatch(loaderStarted());

    const { id: calibrationProcessId } = getState().calibration.calibrationProcess;
    await deleteCalibrationPointRequest(calibrationProcessId, calibrationPointId);
    dispatch({
        type: 'CALIBRATION/DELETE_CALIBRATION_POINT',
        calibrationPointId,
    });
    dispatch(setCalibrationProcessResultWasExported(false));

    dispatch(loaderStopped());
};

export const selectCalibrationPointInEdition = (calibrationPointId) => ({
    type: 'CALIBRATION/SELECT_CALIBRATION_POINT_IN_EDITION',
    calibrationPointId,
});

const updateCalibrationProcess = (newCalibrationProcess) => ({
    type: 'CALIBRATION/UPDATE_CALIBRATION_PROCESS',
    newCalibrationProcess,
});

const updateCalibrationProcessResult = (result) => ({
    type: 'CALIBRATION/UPDATE_CALIBRATION_PROCESS_RESULT',
    result,
});
export const activateDrawingTool = () => ({
    type: 'CALIBRATION/ACTIVATE_DRAWING_TOOL',
});
export const desactivateDrawingTool = () => ({
    type: 'CALIBRATION/DESACTIVATE_DRAWING_TOOL',
});

const getPointDataFromCoordinate = (attributeType, newValue) => {
    const coordinateMapper = Object.freeze({
        [CalibrationPointTypeEnum.COORDINATE_X]: 'x',
        [CalibrationPointTypeEnum.COORDINATE_Y]: 'y',
        [CalibrationPointTypeEnum.COORDINATE_Z]: 'z',
    });

    return {
        coordinates: {
            [coordinateMapper[attributeType]]: newValue,
        },
    };
};

export const updateCoordinateOfCalibrationPoint = (calibrationPoint, attributeType, newAttributeValue) => async (
    dispatch,
    getState,
) => {
    dispatch(loaderStarted());

    const { calibrationProcess } = getState().calibration;
    const { id: calibrationProcessId } = calibrationProcess;

    const pointData = getPointDataFromCoordinate(attributeType, newAttributeValue);
    const newCalibrationProcess = await updateCalibrationPointRequest(
        calibrationProcessId,
        calibrationPoint,
        pointData,
    );
    dispatch(updateCalibrationProcess(newCalibrationProcess));
    dispatch(setCalibrationProcessResultWasExported(false));

    dispatch(loaderStopped());
};

export const addCalibrationPoint = (pixelCoordinates) => async (dispatch, getState) => {
    dispatch(loaderStarted());
    const { id: calibrationProcessId } = getState().calibration.calibrationProcess;
    const updatedCalibrationProcess = await addCalibrationPointRequest(calibrationProcessId, pixelCoordinates);
    dispatch(updateCalibrationProcess(updatedCalibrationProcess));
    dispatch(setCalibrationProcessResultWasExported(false));

    const { id: lastCalibrationPointId } = updatedCalibrationProcess.calibrationPoints[
        updatedCalibrationProcess.calibrationPoints.length - 1
    ];
    dispatch(selectSelectedCalibrationPoint(lastCalibrationPointId));

    dispatch(loaderStopped());
};

export const updateCalibrationPixels = (deformedAnnotationToEdit, pixels) => async (dispatch, getState) => {
    const { calibrationProcess } = getState().calibration;
    const { id: calibrationProcessId } = calibrationProcess;
    dispatch(loaderStarted());
    const newPixels = {
        pixels,
    };
    const updatedCalibrationProcess = await updateCalibrationPixelsRequest(
        calibrationProcessId,
        deformedAnnotationToEdit,
        newPixels,
    );
    dispatch(updateCalibrationProcess(updatedCalibrationProcess));
    dispatch(setCalibrationProcessResultWasExported(false));
    dispatch(noMoreCalibrationPointInEdition());
    dispatch(loaderStopped());
};

export const generateCalibrationProcessResult = () => async (dispatch, getState) => {
    const { calibrationProcess } = getState().calibration;
    const { id: calibrationProcessId } = calibrationProcess;
    let result;
    dispatch(loaderStarted());
    try {
        result = await generateCalibrationProcessResultRequest(calibrationProcessId);
    } catch (e) {
        dispatch(setModalToDisplay(SessionsModalTypes.MANUAL_CALIBRATION_FAILURE));
        dispatch(loaderStopped());

        return;
    }
    const calibrationImage = getImageBackgroundFromAnnotationEngine();
    result.parameters.imWidth = calibrationImage.naturalWidth;
    result.parameters.imHeight = calibrationImage.naturalHeight;
    dispatch(updateCalibrationProcessResult(result));
    dispatch(setCalibrationProcessResultWasExported(false));
    dispatch(loaderStopped());
};

export const getCalibrationFile = () => async (dispatch, getState) => {
    const { session, cumul } = getCalibrationImageSelector(getState());
    const sessionName = session?.name
        .replace(/[\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '_')
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '');
    const jsonParameters = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(getState().calibration.result.parameters),
    )}`;
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', jsonParameters);
    downloadAnchorNode.setAttribute('download', `calibration-${sessionName}_${cumul}m.json`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    dispatch(setCalibrationProcessResultWasExported(true));
};
