import { useState, FC, useCallback, BaseSyntheticEvent, useMemo } from 'react';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { theme } from '../../../../legacy/style/theme';
import BaseButton from '../../../../design_system/inputs/buttons/BaseButton';
import type { HomogeneousZoneParams } from '../../../../../core/sessions/state';
import { sessionDefaultHomogeneousZoneParamsQuery } from '../../../../../core/sessions/queries';

const FORM_ID = 'homogeneousZoneForm';

const PARAMETERS = [
    {
        label: 'Note',
        value: 'NOTE_GLOBALE',
        alpha: {
            min: 0,
            max: 1,
            step: 0.01,
            default: 0.5,
        },
    },
    {
        label: 'Largeur',
        value: 'LARGEUR',
        alpha: {
            min: 0,
            step: 0.1,
            default: 2,
        },
    },
];

interface ZonesFormProps {
    onClose: () => void;
    onSubmit: (values: HomogeneousZoneParams) => void;
}

const ZonesForm: FC<ZonesFormProps> = ({ onClose, onSubmit }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const defaultParams = useAppSelector(sessionDefaultHomogeneousZoneParamsQuery());
    const [formValue, setFormValue] = useState<HomogeneousZoneParams>(defaultParams);

    const handleChange = useCallback(
        ({ target: { value, name } }: BaseSyntheticEvent | SelectChangeEvent) => {
            setFormValue((oldValue) => ({
                ...oldValue,
                [name]: value,
                // reset alpha to default if user changes type
                ...(name === 'zoneType' ? { alpha: PARAMETERS.find((p) => p.value === value)?.alpha.default } : {}),
            }));
        },
        [setFormValue],
    );

    const handleValidation = useCallback(async () => {
        onSubmit({
            alpha: Number(formValue.alpha) ?? PARAMETERS.find((e) => e.value === formValue.zoneType)?.alpha.default,
            minLength: Number(formValue.minLength) ?? 100,
            // default is set in back
            zoneType: formValue.zoneType,
        });
    }, [dispatch, formValue]);

    const [min, max, step] = useMemo(() => {
        const selected = PARAMETERS.find((e) => e.value === formValue.zoneType);

        return selected ? [selected.alpha.min, selected.alpha.max, selected.alpha.step] : [0, undefined, 'any'];
    }, [PARAMETERS, formValue]);

    return (
        <Stack gap={3} color={theme.color.black.main}>
            <Box sx={{ maxWidth: '80%' }}>
                <Typography component="h2" sx={{ fontSize: 18 }}>
                    {t('homogeneousZones.title')}
                </Typography>
                <Typography sx={{ color: theme.color.grey.main, fontSize: 12 }}>
                    {t('homogeneousZones.subtitle')}
                </Typography>
            </Box>
            <Box
                component="form"
                id={FORM_ID}
                onChange={handleChange}
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleValidation();
                }}
                sx={{ display: 'grid', gap: 3 }}
            >
                <FormControl required fullWidth>
                    <Typography>{t('homogeneousZones.labels.type')}</Typography>
                    <Select
                        size="small"
                        onChange={handleChange}
                        inputProps={{ name: 'zoneType' }}
                        value={formValue.zoneType}
                    >
                        {PARAMETERS.map(({ label, value }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl required fullWidth>
                    <Typography>{t('homogeneousZones.labels.alpha')}</Typography>
                    <TextField
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min, max, step }}
                        name="alpha"
                        placeholder="1"
                        size="small"
                        type="number"
                        value={formValue.alpha}
                        required
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <Typography>{t('homogeneousZones.labels.minLength')}</Typography>
                    <TextField
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                        name="minLength"
                        placeholder="100"
                        size="small"
                        type="number"
                        value={formValue.minLength}
                        required
                    />
                </FormControl>
            </Box>
            <Stack direction="row" gap={1} justifyContent="flex-end">
                <BaseButton variant="secondaryGrey" onClick={onClose}>
                    {t('forms.cancel')}
                </BaseButton>
                <BaseButton type="submit" form={FORM_ID}>
                    {t('homogeneousZones.submit')}
                </BaseButton>
            </Stack>
        </Stack>
    );
};

export default ZonesForm;
