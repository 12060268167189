import { FC, useEffect, useState } from 'react';
import { ReactComponent as FilterIcon } from '../../../../../../assets/icons/filter.svg';
import FiltersContent from './FiltersContent';
import { StyledFilters } from './styles';
import { ClickAwayListener } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../../types';
import { InitFiltersCommand } from '../../../../../../core/filters/commands/InitFiltersCommand';
import { LightBlueButton } from '../../../../../design_system/inputs/buttons/LightBlueButton';

type ButtonProps = {
    isOpen: boolean;
    handleClick: () => void;
};

const ToggleFilterPanelButton: FC<ButtonProps> = ({ isOpen, handleClick }) => {
    const { t } = useTranslation();

    return (
        <LightBlueButton selected={isOpen} textContent={t('filters.accessButton')} handleClick={handleClick}>
            <FilterIcon />
        </LightBlueButton>
    );
};

const Filters = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(InitFiltersCommand());
    }, []);

    const [open, setOpen] = useState<boolean>(false);

    const toggleFilter = () => {
        setOpen(!open);
    };

    const closeFilter = () => {
        setOpen(false);
    };

    return (
        <StyledFilters>
            <ToggleFilterPanelButton handleClick={toggleFilter} isOpen={open} />
            {open && (
                <ClickAwayListener onClickAway={closeFilter}>
                    <FiltersContent setOpen={setOpen} />
                </ClickAwayListener>
            )}
        </StyledFilters>
    );
};

export default Filters;
