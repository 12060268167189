import { createSlice } from '@reduxjs/toolkit';

export const moduleName = 'LOADER';

type State = {
    isLoading: boolean;
};

const initialState: State = {
    isLoading: false,
};

const loaderSlice = createSlice({
    name: moduleName,
    initialState,
    reducers: {
        loaderStarted(state: State) {
            state.isLoading = true;
        },
        loaderStopped(state: State) {
            state.isLoading = false;
        },
    },
});

export const { loaderStarted, loaderStopped } = loaderSlice.actions;
export const loaderReducer = loaderSlice.reducer;
