import { useState, useEffect, KeyboardEvent, FC } from 'react';
import { useAppDispatch } from '../../../../types';
import { EditableValue, ElementLayout as Layout } from '../../../legacy/components/ui/panels/right-panel/styles';
import { Text } from '../../../legacy/style';
import { FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { goToImageByRoadData } from '../../../../core/legacy/actions';
import { theme } from '../../../legacy/style/theme';
import type { EditableSelectTitle } from '../../../../core/types/roadDataPanel';

type Option = { value: string; label: string | null };

interface EditableSelectPropsBase {
    attributeName?: string;
    options: Option[];
    roadDataId: string;
    title: EditableSelectTitle;
}

type EditableSelectProps =
    | (EditableSelectPropsBase & {
          initialValue: string[];
          multiple: true;
          onSave: (newValues: string[]) => void;
      })
    | (EditableSelectPropsBase & {
          initialValue: string;
          multiple?: false;
          onSave: (newValues: string) => void;
      });

const EditableSelect: FC<EditableSelectProps> = ({
    attributeName,
    initialValue,
    multiple,
    onSave,
    options,
    roadDataId,
    title,
}) => {
    const dispatch = useAppDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | string[]>(initialValue);

    // caveat: if we click on an editable field into a road-data, it reloads the image where we already are
    useEffect(() => {
        if (isEditing) {
            dispatch(goToImageByRoadData(roadDataId));
        }
    }, [isEditing]);

    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Escape') {
            event.stopPropagation();

            setValue(initialValue);
            setIsEditing(false);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Escape') {
            event.stopPropagation();
        }
    };

    const handleChange = ({ target }: SelectChangeEvent<string | string[]>) => {
        const newValue = target.value;
        if (multiple) {
            setValue(newValue);
        } else {
            setValue(newValue);
            handleClose(true, newValue);
        }
    };

    const handleClose = (triggerSave = false, newValue?: string | string[]) => {
        setOpen(false);
        setIsEditing(false);
        setValue('');

        if (triggerSave) {
            onSave(newValue ?? (value as any));
        }
    };

    const displayedValue = title.render ?? title.value;

    return (
        <Layout
            editable={!isEditing}
            onClick={() => {
                if (!isEditing) {
                    setIsEditing(true);
                    setOpen(true);
                }
            }}
            onKeyUp={(e: KeyboardEvent<HTMLDivElement>) => e.stopPropagation()}
        >
            <Text>{attributeName}</Text>
            {isEditing ? (
                <FormControl size="small" variant="standard">
                    <Select
                        multiple={multiple}
                        onClose={() => handleClose(multiple)}
                        onKeyDownCapture={handleKeyDown}
                        onKeyUpCapture={handleKeyUp}
                        onChange={handleChange}
                        open={open}
                        // never display value
                        renderValue={() => null}
                        sx={{ height: 19 }}
                        value={value}
                    >
                        {options.map(({ label, value: optionValue }) => (
                            <MenuItem key={optionValue} value={optionValue}>
                                <Typography fontSize={theme.font.size.small}>{label}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <EditableValue emptyLexicon={!displayedValue} title={title.value}>
                    {displayedValue}
                </EditableValue>
            )}
        </Layout>
    );
};

export default EditableSelect;
