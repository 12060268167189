import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { Block } from './styles';

const SelectableBlock = forwardRef(
    ({ children, onSelect, onUnselect, onHover, onMouseLeave, hovered, selected = false, ...props }, ref) => {
        const clickHandler = () => {
            if (!selected) {
                if (onSelect) {
                    onSelect();
                }
            } else if (onUnselect) {
                onUnselect();
            }
        };

        const mouseOverHandler = () => {
            if (onHover) {
                onHover();
            }
        };

        const mouseLeaveHandler = () => {
            if (onMouseLeave) {
                onMouseLeave();
            }
        };

        return (
            <Block
                onMouseLeave={mouseLeaveHandler}
                onMouseOver={mouseOverHandler}
                onClick={clickHandler}
                externalHover={hovered}
                selected={selected}
                ref={ref}
                {...props}
            >
                {children}
            </Block>
        );
    },
);

SelectableBlock.propTypes = {
    children: PropTypes.node,
    onHover: PropTypes.func,
    onMouseLeave: PropTypes.func,
    hovered: PropTypes.bool,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    onUnselect: PropTypes.func,
};

export default SelectableBlock;
