import { captureException } from '@sentry/browser';
import { SubmissionError } from 'redux-form';
import axios, { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/browser';
import type { ExternalUploadPort } from '../core/uploads/ExternalUploadPort';
import { XMLBuilder, XMLParser } from 'fast-xml-parser';
import type { Accessor } from '../core/uploads/commands/utils';
import type { SerializableFileProviderPort } from './SerializableFileProviderPort';
import { SessionUpload, SessionUploadBlock, UploadStatuses } from '../core/domain/SessionUpload';
import type { SessionUploadData, UploadableSession } from '../core/uploads/state';

export type PostAccessOutput = {
    url: string;
    archiveId: string;
    key: string;
};

export class ExternalUploadProvider implements ExternalUploadPort {
    constructor(private readonly apiEndpoint: string) {}

    async init(
        session: UploadableSession,
        serializableFileProvider: SerializableFileProviderPort,
    ): Promise<SessionUploadData> {
        const { url, archiveId, key } = await this.postAccess(session);
        const blocks = serializableFileProvider.splitFileIntoBlocks(session);
        const { size: totalSize } = session;

        return {
            url,
            archiveId,
            key,
            blocks,
            fileName: session.path,
            totalSize,
        };
    }

    async execute(accessor: Accessor): Promise<any> {
        try {
            await this.uploadBlocks(accessor);
            await this.updateInternalBlockList(accessor);
            await this.checkBlocks(accessor);
            await this.buildBlocksIntoBlob(accessor);
            await this.finalizeUpload(accessor);
        } catch (error) {
            console.error(error);
            accessor.updateUploadStatus(UploadStatuses.FAILURE);
        }
    }

    private async postAccess({ size }: UploadableSession): Promise<PostAccessOutput> {
        try {
            const { data } = await axios.request({
                method: 'POST',
                url: `${this.apiEndpoint}/sessions/upload-access`,
                data: { size },
                withCredentials: true,
            });

            return data as PostAccessOutput;
        } catch (e) {
            console.error(e);
            captureException(e);
            throw new Error('serverErrors.whileRequestingUploadAuthorization');
        }
    }

    async uploadBlocks(accessor: Accessor): Promise<any> {
        const { blocks, failedBlocks, status, url } = accessor.reloadSessionUpload();
        accessor.updateUploadStatus(UploadStatuses.IN_PROGRESS);

        const blocksToUpload = status === UploadStatuses.INITIATED ? blocks : failedBlocks;
        // split into chunks to avoid opening thousands of requests simultaneously
        const blockChunks = this.groupBlocksAsChunks(blocksToUpload);

        for (const blockChunk of blockChunks) {
            const uploads: Array<Promise<AxiosResponse<any, any>>> = [];
            for (const block of blockChunk) {
                const { id, objectUrl } = block;
                // eslint-disable-next-line no-await-in-loop
                const data = await this.retrieveBlobFromObjectUrl(objectUrl);
                uploads.push(this.putBlockRequest(url, id, data, accessor));
            }
            // eslint-disable-next-line no-await-in-loop
            await Promise.allSettled(uploads);
        }
    }

    private groupBlocksAsChunks(blocks: Array<SessionUploadBlock>): Array<Array<SessionUploadBlock>> {
        const itemsPerChunk = 10;
        const chunks = blocks.reduce((resultArray: Array<Array<SessionUploadBlock>>, item, index) => {
            const chunkIndex = Math.floor(index / itemsPerChunk);

            if (!resultArray[chunkIndex]) {
                // eslint-disable-next-line no-param-reassign
                resultArray[chunkIndex] = []; // start a new chunk
            }

            resultArray[chunkIndex]!.push(item);

            return resultArray;
        }, []);

        return chunks;
    }

    private async retrieveBlobFromObjectUrl(objectURL?: string): Promise<Blob> {
        try {
            if (!objectURL) {
                throw new Error('Error when retrieving blob from url');
            }
            const { data } = await axios.request({
                method: 'GET',
                url: objectURL,
                responseType: 'blob',
            });

            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw error;
        }
    }

    private async putBlockRequest(
        presignedSASUrl: string,
        id: string,
        data: Blob,
        accessor: Accessor,
    ): Promise<AxiosResponse<any, any>> {
        try {
            const url = `${presignedSASUrl}&comp=block&blockid=${id}`;
            const request = axios.request({
                method: 'PUT',
                url,
                data,
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.loaded === progressEvent.total) {
                        accessor.incrementUploadedSize(progressEvent.loaded);
                    }
                },
            });

            return request;
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
            throw new SubmissionError({ _error: 'serverErrors.whileUploadingSessionBlock' });
        }
    }

    private async updateInternalBlockList(accessor: Accessor): Promise<any> {
        const { url } = accessor.reloadSessionUpload();
        const blockList = await this.getBlockListRequest(url);
        accessor.updateBlockList(blockList);
    }

    private async getBlockListRequest(presignedSASUrl: string): Promise<Array<string>> {
        try {
            const queryParams = '&comp=blocklist&blocklisttype=uncommitted';
            const url = `${presignedSASUrl}${queryParams}`;
            const { data: xml } = await axios.request({
                method: 'GET',
                url,
            });
            const blockIds = this.extractBlockIdsFromXML(xml);

            return blockIds;
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
            throw new SubmissionError({ _error: 'serverErrors.whileValidatingUploadedSessionBlocks' });
        }
    }

    private extractBlockIdsFromXML(xmlData: string): Array<string> {
        const parser = new XMLParser();
        const jsonData = parser.parse(xmlData);
        let blocks = jsonData?.BlockList?.UncommittedBlocks?.Block;
        if (!Array.isArray(blocks)) {
            blocks = [blocks];
        }
        const blockIds: Array<string> = [];

        blocks.forEach((block) => {
            if (block?.Name) {
                blockIds.push(block.Name);
            }
        });

        return blockIds ?? [];
    }

    private async checkBlocks(accessor: Accessor): Promise<void> {
        const { blockList, blocks } = accessor.reloadSessionUpload();
        const failedBlocks = this.getFailedBlocks(blockList, blocks);
        if (failedBlocks.length) {
            accessor.updateFailedBlocks(failedBlocks);
            accessor.updateUploadedSize();
            const error = new Error('Some blocks failed');

            throw error;
        }
    }

    private getFailedBlocks(blockList: Array<string>, blocks: Array<SessionUploadBlock>): Array<SessionUploadBlock> {
        return blocks.filter((block) => !blockList.includes(block.id));
    }

    private async buildBlocksIntoBlob(accessor: Accessor): Promise<any> {
        const sessionUpload = accessor.reloadSessionUpload();
        await this.putBlockListRequest(sessionUpload);
    }

    private async putBlockListRequest({
        url: presignedSASUrl,
        blockList: uploadedBlockIds,
    }: SessionUpload): Promise<void> {
        try {
            const url = `${presignedSASUrl}&comp=blocklist`;
            const data = this.preparePutBlockListRequestBody(uploadedBlockIds);
            await axios.request({
                method: 'PUT',
                url,
                data,
            });
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
            throw new SubmissionError({ _error: 'serverErrors.whileMergingSessionBlocksIntoBlob' });
        }
    }

    private preparePutBlockListRequestBody(blockIds: Array<string>) {
        const sortedBlocksIds = [...blockIds].sort(this.sortBlockIdsAsc);
        const putBlockListRequestBody = this.formatPutBlockListRequestBody(sortedBlocksIds);

        return putBlockListRequestBody;
    }

    private sortBlockIdsAsc(blockId1: string, blockId2: string): number {
        const id1 = blockId1.replace(/A*/, '');
        const id2 = blockId2.replace(/A*/, '');

        return +id1 - +id2;
    }
    private formatPutBlockListRequestBody(blockIds: Array<string>): any {
        const builder = new XMLBuilder({
            ignoreAttributes: false,
        });
        const objectData = {
            '?xml': {
                '@_version': '1.0',
                '@_encoding': 'utf-8',
            },
            BlockList: {
                Uncommitted: [...blockIds],
            },
        };

        const xmlData = builder.build(objectData);

        return xmlData;
    }

    private async finalizeUpload(accessor: Accessor): Promise<any> {
        const sessionUpload = accessor.reloadSessionUpload();
        await this.postUploadDoneRequest(sessionUpload);
        accessor.clearSessionUploads([sessionUpload]);
        accessor.updateUploadStatus(UploadStatuses.SUCCESS);
    }

    private async postUploadDoneRequest({ archiveId, key }: SessionUpload): Promise<void> {
        try {
            await axios.request({
                method: 'POST',
                url: `${this.apiEndpoint}/sessions/upload-access-done`,
                data: {
                    archiveId,
                    key,
                },
                withCredentials: true,
            });
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
            throw new SubmissionError({ _error: 'serverErrors.whileConfirmingSessionUploadToApi' });
        }
    }
}
