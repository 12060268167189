import { StatusTag, TagVariant } from './StatusTag';
import { ContextMenu } from './Menu';
import { RoadDirection } from './RoadDirection';
import { HomogeneousZones } from './HomogeneousZones';
import type { Session } from '../../../core/domain/Session';
import {
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridColDef,
    ValueOptions,
    getGridDateOperators,
    getGridNumericOperators,
    getGridSingleSelectOperators,
    getGridStringOperators,
} from '@mui/x-data-grid-pro';
import type { TFunction } from 'react-i18next';
import type { Execution } from '../../../core/domain/AIAnalysis';
import SessionStringDisplay from './SessionStringDisplay';
import {
    isAnalysisDoneOnSession,
    isAnalysisNotStartedOnSession,
    isAnalysisOngoingOnSession,
} from '../../../core/automatic_analysis/utils';
import { EMPTY_VALUE, getZhValue } from '../../../utils/buildFiltersFromSessions';
import SessionCalibration from './SessionCalibration';
import { SessionState as SessionStateEnum } from '../../../core/sessions/consts';
import SessionState from './SessionState';
import { formatDistance } from '../../../utils/numbers';
import CustomTextEdit from './CustomTextEdit';

export type ValueTypeToFilter = 'string' | 'direction' | 'date' | 'number';
interface SessionWithExecutions extends Session {
    executions: Execution[];
}

const getStatusTagVariant = (session: SessionWithExecutions): TagVariant => {
    const { calibrationDone, executions, id: sessionId } = session;
    const sessionAiNotStarted = isAnalysisNotStartedOnSession(executions, sessionId);
    const sessionAiOngoing = isAnalysisOngoingOnSession(executions, sessionId);
    const sessionAiDone = isAnalysisDoneOnSession(executions, sessionId);

    if (calibrationDone && sessionAiNotStarted) return 'calibration';
    if (sessionAiOngoing) return 'aiOngoing';
    if (sessionAiDone) return 'aiDone';

    return 'calibrationNotDone';
};

const getUsedModelsNames = (session: SessionWithExecutions) => session.executions.map((model) => model.modelName);

const getRemainingTime = (session: SessionWithExecutions) => {
    const aiRemaningTimeSeconds = session.executions.reduce(
        (remainingTime, { remainingInSeconds }) => Math.max(remainingTime, remainingInSeconds ?? 0),
        0,
    );

    return Math.round(aiRemaningTimeSeconds / 60);
};

const getFilter = (type: ValueTypeToFilter) => {
    switch (type) {
        case 'string':
            return getGridStringOperators().filter(({ value }) => value !== 'isAnyOf');
        case 'direction':
            return getGridSingleSelectOperators().filter(({ value }) => value === 'is');
        case 'number':
            return getGridNumericOperators().filter(({ value }) => ['>', '<'].includes(value));
        default:
            return getGridDateOperators().filter(({ value }) => ['onOrAfter', 'before', 'is', 'not'].includes(value));
    }
};

export const getDataGridColumns = (
    t: TFunction,
    campaigns: ValueOptions[],
    zhs: ValueOptions[],
    calibrations: ValueOptions[],
    authors: ValueOptions[],
    sortable: boolean,
): GridColDef[] => {
    const baseColDef = (align: GridColDef['align'] = 'center'): Partial<GridColDef> => ({
        align,
        headerAlign: align,
        cellClassName: 'light',
        sortable,
    });

    return [
        { ...GRID_CHECKBOX_SELECTION_COL_DEF, disableReorder: true, hideable: false },
        {
            ...baseColDef('left'),
            cellClassName: 'dark',
            display: 'flex',
            field: 'name',
            filterOperators: getFilter('string'),
            flex: 3,
            headerName: t('session.name'),
            minWidth: 200,
            renderCell: ({ row }) => SessionStringDisplay({ data: row.name, stopPropagation: false }),
        },
        {
            ...baseColDef('left'),
            display: 'flex',
            field: 'author',
            headerName: t('session.author'),
            minWidth: 100,
            renderCell: ({ row }) =>
                SessionStringDisplay({
                    data: row.metadata?.author
                        ? `${row.metadata?.author?.firstName} ${row.metadata?.author?.lastName}`
                        : '',
                    stopPropagation: false,
                }),
            type: 'singleSelect',
            valueGetter: (_, row) => {
                if (row.metadata?.author) {
                    return `${row.metadata?.author?.firstName} ${row.metadata?.author?.lastName}`;
                }

                return EMPTY_VALUE;
            },
            valueOptions: authors,
        },
        {
            ...baseColDef(),
            field: 'length',
            filterOperators: getFilter('number'),
            headerName: t('session.length'),
            minWidth: 100,
            renderCell: ({ row }) => `${formatDistance(row.metadata.cumulativeEnd)} ${t('sessions.km')}`,
            valueGetter: (_, row) => row.metadata.cumulativeEnd / 1000,
        },
        {
            ...baseColDef('left'),
            display: 'flex',
            editable: true,
            field: 'campaignName',
            flex: 2,
            headerName: t('session.campaign'),
            minWidth: 180,
            renderCell: ({ row }) =>
                SessionStringDisplay({ data: row.metadata.campaignName, fullCell: !row.metadata.campaignName }),
            renderEditCell: (params) => CustomTextEdit(params),
            type: 'singleSelect',
            valueGetter: (_, row) => row.metadata.campaignName || EMPTY_VALUE,
            valueOptions: campaigns,
            valueSetter: (value, row) => ({ ...row, metadata: { ...row.metadata, campaignName: value } }),
        },
        {
            ...baseColDef(),
            display: 'flex',
            editable: true,
            field: 'state',
            headerName: t('session.state'),
            minWidth: 120,
            renderCell: ({ row }) => SessionState({ state: row.state, t }),
            type: 'singleSelect',
            valueGetter: (_, row) => row.state ?? SessionStateEnum.NONE,
            valueOptions: Object.values(SessionStateEnum).map((value) => ({
                label: t(`sessionState.${value}`),
                value,
            })),
        },
        {
            ...baseColDef(),
            field: 'status',
            headerName: t('session.status'),
            minWidth: 150,
            renderCell: ({ row }) => {
                const variant: TagVariant = getStatusTagVariant(row);
                const usedAiModelsNames = getUsedModelsNames(row);
                const remainingTime = getRemainingTime(row);

                return StatusTag({ variant, usedAiModelsNames, remainingTime, sessionId: row.id });
            },
            type: 'singleSelect',
            valueOptions: [
                { value: 'calibrationNotDone', label: t('session.calibrationNotDone') },
                { value: 'calibration', label: t('session.calibrationDone') },
                { value: 'aiOngoing', label: t('labels.ai-analysis-ongoing') },
                { value: 'aiDone', label: t('labels.ai_analyzed') },
            ],
            valueGetter: (_, row) => getStatusTagVariant(row),
        },
        {
            ...baseColDef(),
            display: 'flex',
            field: 'calibration',
            headerName: t('session.calibration'),
            minWidth: 200,
            renderCell: ({ row }) => SessionCalibration({ calibration: row.calibration }),
            type: 'singleSelect',
            valueGetter: (_, row) => row.calibration?.fileName ?? EMPTY_VALUE,
            valueOptions: calibrations,
        },
        {
            ...baseColDef(),
            display: 'flex',
            field: 'zh',
            headerName: t('session.ZH'),
            minWidth: 150,
            renderCell: (params) => HomogeneousZones({ sessionMetadata: params.row.metadata }),
            type: 'singleSelect',
            valueGetter: (_, row) => getZhValue(row.metadata),
            valueOptions: zhs,
        },
        {
            ...baseColDef(),
            editable: true,
            field: 'roadDirection',
            filterOperators: getFilter('direction'),
            headerName: t('session.measureDirection'),
            minWidth: 100,
            renderCell: (params) => RoadDirection({ session: params.row }),
            type: 'singleSelect',
            valueGetter: (_, row) => row.metadata.roadDirection,
            valueOptions: [
                { value: t('filters.roadDirectionGoing'), label: t('session.going') },
                { value: t('filters.roadDirectionReturn'), label: t('session.coming') },
            ],
            valueSetter: (value, row) => ({ ...row, metadata: { ...row.metadata, roadDirection: value } }),
        },
        {
            ...baseColDef(),
            field: 'createdAt',
            filterOperators: getFilter('date'),
            headerName: t('session.creationDate'),
            minWidth: 150,
            type: 'date',
            valueGetter: (_, row) => new Date(row.createdAt),
        },
        {
            ...baseColDef(),
            field: 'acquisitionDate',
            filterOperators: getFilter('date'),
            headerName: t('session.acquisitionDate'),
            minWidth: 150,
            type: 'date',
            valueGetter: (_, row) => new Date(row.metadata.acquisitionDate),
        },
        {
            disableColumnMenu: true,
            disableReorder: true,
            field: 'actions',
            hideable: false,
            renderCell: (params) => ContextMenu({ sessionId: params.row.id }),
            resizable: false,
            type: 'actions',
            width: 130,
        },
    ];
};
