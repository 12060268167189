import styled from 'styled-components';

export const CategoriesRow = styled.div`
    align-self: normal;
    background-color: ${({ theme }) => theme.color.grey.dark};
    opacity: 0.5;
    box-shadow: 0px 2px 9px -3px black;
    width: 220px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    vertical-align: top;

    &:before {
        content: '\\00a0';
        width: 10px;
        height: 10px;
        background-color: ${({ theme }) => theme.color.grey.dark};
        display: block;
        border-radius: 0;
        transform: rotate(45deg);
        top: -5px;
        position: relative;
        left: 50%;
    }
`;
