import type { FC } from 'react';
import { loggedInQuery } from '../../core/authentication/queries';
import { LoginForm } from './LoginForm';
import Background from '../../assets/img/login-background.jpg';
import { Stack } from '@mui/material';
import { RoadcareLogoWhite } from '../design_system/logo/RoadcareLogoWhite';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../types';
import { LoadCurrentUserCommand } from '../../core/authentication/LoadCurrentUserCommand';

export const LoginPage: FC = () => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector(loggedInQuery);

    if (!isAuthenticated) {
        dispatch(LoadCurrentUserCommand());
    } else {
        return <Navigate to="/dashboard/acquisition" />;
    }

    return (
        <Stack
            sx={{
                justifyContent: 'center',
                width: '100vw',
                height: '100vh',
                backgroundImage: `url('${Background}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom left',
            }}
        >
            <Stack
                sx={{
                    gap: 10,
                    marginTop: '-150px',
                    width: '400px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <RoadcareLogoWhite width="100%" />
                <LoginForm />
            </Stack>
        </Stack>
    );
};
