import { useMemo } from 'react';
import type {
    NetworkRoadDataGeojsonProperties,
    NetworkRoadDataProperties,
} from '../../../../../core/networkMap/ExternalNetworkMapPort';

const useNetworkProperties = (networkProperties: NetworkRoadDataGeojsonProperties): NetworkRoadDataProperties => {
    return useMemo(
        () => ({
            ...networkProperties,
            roadData: JSON.parse(networkProperties.roadData),
        }),
        [networkProperties],
    );
};

export default useNetworkProperties;
