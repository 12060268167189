import { captureException } from '@sentry/browser';
import axios from 'axios';
import type {
    ExecutionStatus,
    ExternalNetworkMapPort,
    NetworkMapRoadData,
    OrganizationNetwork,
} from '../core/networkMap/ExternalNetworkMapPort';

export class ExternalNetworkMapProvider implements ExternalNetworkMapPort {
    constructor(private readonly baseEndpoint: string) {}

    async loadNetworkMapData(): Promise<OrganizationNetwork> {
        try {
            const response = await axios.request<OrganizationNetwork>({
                method: 'POST',
                url: `${this.baseEndpoint}/network-map`,
                withCredentials: true,
            });

            return response.data;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async loadNetworkMapRoadData(bbox: [number, number, number, number]): Promise<NetworkMapRoadData> {
        try {
            const [xmin, ymin, xmax, ymax] = bbox;
            const response = await axios.request<NetworkMapRoadData>({
                method: 'GET',
                url: `${this.baseEndpoint}/network-map/road-data/features`,
                withCredentials: true,
                params: { xmin, ymin, xmax, ymax },
            });

            return response.data;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async refreshExecutionStatuses(): Promise<Array<ExecutionStatus>> {
        try {
            const response = await axios.request<Array<ExecutionStatus>>({
                method: 'GET',
                url: `${this.baseEndpoint}/network-map/executions`,
                withCredentials: true,
            });

            return response.data;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }
}
