import { Box, Tooltip } from '@mui/material';
import type { MouseEventHandler, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../legacy/style/theme';
import styled from 'styled-components';
import SelectableBlock from '../../../legacy/components/ui/blocks/SelectableBlock';

export const StyledBlock = styled(SelectableBlock)(() => ({
    padding: '10px',
    margin: '0 0 0 10px',
    display: 'grid',
    gridTemplateColumns: '2fr auto',
    opacity: 1,
}));

type Props = {
    isVisible: boolean;
    handleClick: MouseEventHandler;
};
export const ToggleSessionsVisibility: FC<Props> = ({ isVisible, handleClick }) => {
    const { t } = useTranslation();

    return (
        <Tooltip
            title={t(
                isVisible ? 'networkMap.sessionsPanel.toggleSessionsOn' : 'networkMap.sessionsPanel.toggleSessionsOff',
            )}
            placement="top"
            arrow
        >
            <StyledBlock
                onClick={handleClick}
                sx={{
                    padding: 5,
                    fontWeight: 'bold',
                    backgroundColor: theme.color.blue.tint2,
                    color: theme.color.blue.primary,
                }}
            >
                <Box
                    sx={{
                        fontWeight: 'bold',
                        color: theme.color.blue.primary,
                    }}
                >
                    {t(isVisible ? 'networkMap.sessionsPanel.hide' : 'networkMap.sessionsPanel.show')}
                </Box>
            </StyledBlock>
        </Tooltip>
    );
};
