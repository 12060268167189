import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Execution, Model } from '../domain/AIAnalysis';
import type { SessionId } from '../domain/Session';

export const moduleName = 'AUTOMATIC_ANALYSIS';

const aiAnalysisSlice = createSlice({
    name: moduleName,
    initialState: {
        executions: [] as Array<Execution>,
        sessionIdsForAnalyses: [] as Array<SessionId>,
        models: [] as Array<Model>,
    },
    reducers: {
        initialized(state, action: PayloadAction<{ models: Array<Model>; executions: Array<Execution> }>) {
            state.executions = action.payload.executions;
            state.models = action.payload.models;
        },
        updatedExecutions(state, action: PayloadAction<Array<Execution>>) {
            state.executions = action.payload;
        },
        analysisStarted(state, action: PayloadAction<Execution>) {
            state.executions.push(action.payload);
        },
        sessionIdsForAnalysesChosen(state, action: PayloadAction<Array<SessionId>>) {
            state.sessionIdsForAnalyses = action.payload;
        },
    },
});

export const { initialized, analysisStarted, sessionIdsForAnalysesChosen, updatedExecutions } = aiAnalysisSlice.actions;
export const aiAnalysisReducer = aiAnalysisSlice.reducer;
