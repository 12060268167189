import type { MutableRefObject } from 'react';
import type { MapRef } from 'react-map-gl';
import type { SessionId } from '../../../../core/domain/Session';
import type { OrganizationNetwork } from '../../../../core/networkMap/ExternalNetworkMapPort';
import { currentMapBoundsQuery } from '../../../../core/networkMap/queries';
import { useAppSelector } from '../../../../types';
import { getSessionBoundsById } from '../get-session-bounds';
import { FIT_BOUNDS_OPTIONS } from '../map-configuration';

const useInitializeMapBounds = (
    mapRef: MutableRefObject<MapRef | null>,
    networkData: OrganizationNetwork | null,
    routeQueryParams: URLSearchParams,
) => {
    const storedMapBounds = useAppSelector(currentMapBoundsQuery);

    const zoomToTargetedSession = (targetedSessionId: SessionId) => {
        if (networkData) {
            const sessionBounds = getSessionBoundsById(networkData, targetedSessionId);
            const map = mapRef.current?.getMap();
            if (map && sessionBounds) {
                map.fitBounds(sessionBounds, FIT_BOUNDS_OPTIONS);
            }
        }
    };

    const initializeMapBounds = () => {
        const targetedSessionId = routeQueryParams.get('sessionId');
        if (targetedSessionId) {
            zoomToTargetedSession(targetedSessionId);

            return;
        }

        if (storedMapBounds !== null) {
            mapRef.current?.fitBounds(storedMapBounds, FIT_BOUNDS_OPTIONS);

            return;
        }

        if (networkData?.bbox) {
            mapRef.current?.fitBounds(networkData?.bbox, FIT_BOUNDS_OPTIONS);
        }
    };

    return initializeMapBounds;
};

export default useInitializeMapBounds;
