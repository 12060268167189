import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form, reduxForm } from 'redux-form';
import { Error, FieldFlex, FirstFieldFlex } from './styles';
import InputPassword from '../../ui/forms/input-password';
import { Flex, Label, PrimaryButton } from '../../../style';
import { passwordFormat, passwordsMatch, required } from '../../../../../core/legacy/utils/validation';
import { ConfirmPasswordCommand } from '../../../../../core/authentication/ConfirmPasswordCommand';
import { NavigateToSessionsCommand } from '../../../../../core/sessions/commands/NavigateToSessionsCommand';

const InitPasswordForm = ({ handleSubmit, error, invalid }) => {
    const { t } = useTranslation();

    return (
        <Form onSubmit={handleSubmit}>
            <Flex>
                <FirstFieldFlex>
                    <Label>{t('init-password.new-password')}</Label>
                    <Field
                        component={InputPassword}
                        name="newPassword"
                        type="password"
                        validate={[required, passwordFormat]}
                        width="400px"
                    />
                </FirstFieldFlex>
                <FieldFlex>
                    <Label>{t('init-password.confirm-password')}</Label>
                    <Field
                        component={InputPassword}
                        name="confirmPassword"
                        type="password"
                        validate={[required, passwordFormat, passwordsMatch]}
                        width="400px"
                    />
                </FieldFlex>
            </Flex>
            <FieldFlex>
                <PrimaryButton disabled={invalid} type="submit">
                    {t('forms.submit')}
                </PrimaryButton>
            </FieldFlex>
            {error && <Error>{t(error)}</Error>}
        </Form>
    );
};

InitPasswordForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
};

export default reduxForm({
    form: 'init-password',
    onSubmit: async (values, dispatch) => {
        await dispatch(ConfirmPasswordCommand(values.confirmPassword));
    },
    // eslint-disable-next-line no-unused-vars
    onSubmitSuccess: async (result, dispatch) => {
        await dispatch(NavigateToSessionsCommand());
    },
})(InitPasswordForm);
