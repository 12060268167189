import type GeoJSON from 'geojson';
import { LngLatBounds } from 'mapbox-gl';
import type { Session, SessionId } from '../../../core/domain/Session';
import type { OrganizationNetwork, OrganizationNetworkData } from '../../../core/networkMap/ExternalNetworkMapPort';

const initializeSessionBounds = (firstCoordinates: GeoJSON.Position): LngLatBounds => {
    return new LngLatBounds([
        [firstCoordinates[0] as number, firstCoordinates[1] as number],
        [firstCoordinates[0] as number, firstCoordinates[1] as number],
    ]);
};

const extendSessionBoundsToGeojson = (
    sessionBounds: LngLatBounds,
    geojson: OrganizationNetworkData | undefined,
): void => {
    for (const feature of geojson?.features ?? []) {
        const { coordinates } = feature.geometry as GeoJSON.Point;
        sessionBounds.extend([coordinates[0] as number, coordinates[1] as number]);
    }
};

export const getSessionBoundsById = (mapData: OrganizationNetwork, sessionId: SessionId): LngLatBounds | null => {
    if (mapData.data.features) {
        const geojson = {
            ...mapData.data,
            features: mapData.data.features.filter((feature) => feature.properties?.sessionId === sessionId),
        };
        const firstCoordinates = (geojson.features[0]?.geometry as GeoJSON.Point)?.coordinates;
        const sessionBounds = initializeSessionBounds(firstCoordinates);
        extendSessionBoundsToGeojson(sessionBounds, geojson);

        return new LngLatBounds([sessionBounds.getSouthWest(), sessionBounds.getNorthEast()]);
    }

    return null;
};

export const getSessionBounds = (mapData: OrganizationNetwork, session: Session): LngLatBounds | null => {
    return getSessionBoundsById(mapData, session.id);
};
