import { Position } from '../../../propTypes';
import React from 'react';

const PositionValue = ({ position }) => {
    let positionToDisplay = position?.start?.join(', ');
    if (position.end.length > 0) {
        positionToDisplay += ` - ${position.end.join(', ')}`;
    }

    return <> {positionToDisplay} </>;
};

PositionValue.propTypes = {
    position: Position.isRequired,
};

export default PositionValue;
