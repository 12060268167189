import { push } from 'redux-first-history';
import { createCommand } from '../../utils';
import { moduleName } from '../state';

export const NavigateToSessionsCommand = createCommand(
    `${moduleName}/NAVIGATE_TO_SESSIONS`,
    async (_, { dispatch }) => {
        await dispatch(push('/dashboard/acquisition'));
    },
);
