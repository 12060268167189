import { ReactComponent as BaseCheckMarkIcon } from '../../../../../../assets/icons/checkmark.svg';
import { numberOfRoadDataByRubricIdSelector } from '../../../../../../core/legacy/selectors/streetView';
import { theme } from '../../../../style/theme';
import {
    FilterRubricMainText,
    FilterRubricExtraText,
    FilterRubricTextContainer,
    StyledFilterRubric,
    FilterRubricOpen,
} from './styles';
import { useState, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../types';
import { rubricToDisplayIdsQuery } from '../../../../../../core/filters/queries';
import { ToggleRubricToDisplayIdCommand } from '../../../../../../core/filters/commands/ToggleRubricToDisplayIdCommand';
import type { Rubric } from '../../../../../../core/domain/Roaddata';
import FilterReference from './FilterReference';
import { Stack } from '@mui/material';

const CheckMarkIcon = () => <BaseCheckMarkIcon color={theme.color.white.main} />;

type FilterRubricProps = {
    rubric: Rubric;
};

const FilterRubric: FC<FilterRubricProps> = ({ rubric }) => {
    const dispatch = useAppDispatch();
    const rubricToDisplayIds = useAppSelector(rubricToDisplayIdsQuery);
    const numberOfRoadDataForThisRubric = useAppSelector(numberOfRoadDataByRubricIdSelector(rubric.id));
    const selected = rubricToDisplayIds.includes(rubric.id);

    const [open, setOpen] = useState(false);
    const references = [...rubric.references].sort((a, b) => a.name.localeCompare(b.name));

    const handleClick = () => {
        dispatch(ToggleRubricToDisplayIdCommand(rubric));
    };

    const rubricNameColor = selected ? theme.color.white.main : theme.color.black.dark;
    const rubricCountColor = selected ? theme.color.white.main : theme.color.grey.main;

    return (
        <>
            <Stack direction="row" alignItems="center" sx={{ position: 'relative' }}>
                {references.length > 0 && (
                    <FilterRubricOpen onClick={() => setOpen(!open)}>{open ? '-' : '+'}</FilterRubricOpen>
                )}
                <StyledFilterRubric selected={selected} onClick={handleClick}>
                    <FilterRubricTextContainer>
                        <FilterRubricMainText color={rubricNameColor}>{rubric.name}</FilterRubricMainText>
                        <FilterRubricExtraText color={rubricCountColor}>
                            {numberOfRoadDataForThisRubric}
                        </FilterRubricExtraText>
                    </FilterRubricTextContainer>
                    {selected && <CheckMarkIcon />}
                </StyledFilterRubric>
            </Stack>
            {open && references.map((r) => <FilterReference reference={r} rubric={rubric} />)}
        </>
    );
};

export default FilterRubric;
