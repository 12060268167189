import { allReferencesQuery } from '../../catalogs/queries';
import type { ItinerariesBySessionIds } from '../../domain/Itinerary';
import type { SessionId } from '../../domain/Session';
import { setModalToDisplay, unsetModalToDisplay } from '../../legacy/actions/modals';
import { SessionsModalTypes } from '../../legacy/reducers/modals';
import referenceResolver from '../../legacy/utils/referenceResolver';
import { selectedGradeReferenceQuery } from '../../networkMap/queries';
import { createCommand } from '../../utils';
import { itinerariesBySessionIdsUpdated, moduleName } from '../state';

export type LoadItineraryForOneSessionCommandArgs = {
    sessionId: SessionId;
};

export const LoadItineraryForOneSessionCommand = createCommand(
    `${moduleName}/LOAD_ITINERARY_FOR_ONE_SESSION`,
    async ({ sessionId }: LoadItineraryForOneSessionCommandArgs, { dispatch, extra, getState }) => {
        dispatch(setModalToDisplay(SessionsModalTypes.REFRESH_ALL_SESSIONS_GRADE_LOADING));

        const rubricReferences = allReferencesQuery(getState());
        const selectedGradeReference = selectedGradeReferenceQuery(getState());

        let itineraryBySessionId: ItinerariesBySessionIds;

        if (!selectedGradeReference || selectedGradeReference === 'AGGREGATE') {
            itineraryBySessionId = await extra.externalSessions.loadAggregateItineraryForOneSession(
                sessionId,
                rubricReferences.map((r) => r.key),
            );
        } else {
            itineraryBySessionId = await extra.externalSessions.loadReferenceItineraryForOneSession(
                sessionId,
                referenceResolver(selectedGradeReference),
            );
        }

        dispatch(itinerariesBySessionIdsUpdated(itineraryBySessionId));
        dispatch(unsetModalToDisplay());
    },
);
