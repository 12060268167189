import { RefObject, useCallback, useEffect, useState } from 'react';
import { getImageBackgroundFromAnnotationEngine } from '../../../../../core/legacy/utils/roadViewerUtils';
import useWindowResizeListener from '../../utils/use-window-resizer';
import useForceUpdate from '../../utils/use-force-component-rerender';
import type { Session } from '../../../../../core/domain/Session';

type Size = {
    width: number;
    height: number;
};

const useAnnotationEngineSize = (
    refAE: RefObject<HTMLDivElement>,
    currentImage,
    isZoomed: () => boolean,
    pxRatio: number,
    session: Session | null,
) => {
    const [canvasSize, setCanvasSize] = useState<Size | undefined>(undefined);
    const imageNode = getImageBackgroundFromAnnotationEngine();
    const windowSize: Size = useWindowResizeListener();
    const forceUpdate = useForceUpdate();

    const isImageAvailable = () => imageNode && !!imageNode.naturalWidth;

    const updateAnnotationEngineSize = useCallback((image: HTMLImageElement) => {
        isZoomed();
        const container = document.getElementById('annotation-engine')?.parentNode?.parentNode as HTMLDivElement;
        const containerWidth = container?.clientWidth ?? 0;
        const containerHeight = container?.clientHeight ?? 0;
        const computeContainerRatio = (containerElement?: HTMLDivElement) => {
            if (!containerElement) {
                return 1;
            }

            const { clientWidth, clientHeight } = containerElement;

            return clientWidth / clientHeight;
        };
        const computeImageRatio = (imageElement?: HTMLImageElement) => {
            if (!imageElement) {
                return 1;
            }

            const { naturalWidth, naturalHeight } = imageElement;

            return naturalWidth / naturalHeight;
        };

        const containerRatio = computeContainerRatio(container);
        const imageRatio = computeImageRatio(image);

        const imageRatioIsBiggerThanContainerRatio = imageRatio > containerRatio;

        let canvasWidth = 0;
        let canvasHeight = 0;
        if (imageRatioIsBiggerThanContainerRatio) {
            canvasWidth = containerWidth;
            canvasHeight = canvasWidth / imageRatio;
        } else {
            canvasHeight = containerHeight;
            canvasWidth = canvasHeight * imageRatio;
        }

        setCanvasSize({ width: canvasWidth, height: canvasHeight });
    }, []);

    useEffect(() => {
        if (!isImageAvailable()) {
            forceUpdate();
        }
        if (refAE?.current && isImageAvailable() && !canvasSize) {
            updateAnnotationEngineSize(imageNode);
        }
    }, [refAE?.current, session, currentImage, pxRatio]);

    useEffect(() => {
        if (refAE?.current && isImageAvailable() && canvasSize) {
            updateAnnotationEngineSize(imageNode);
        }
    }, [windowSize, pxRatio]);

    return { canvasSize };
};

export default useAnnotationEngineSize;
