import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    // noMoreAnnotationInEdition,
    selectHoveredCalibrationPoint,
    noMoreHoveredCalibrationPoint,
    selectSelectedCalibrationPoint,
    noMoreSelectedCalibrationPoint,
    addCalibrationPoint,
    updateCalibrationPixels,
} from '../../../../../core/legacy/actions';

import {
    isDrawingToolActivatedSelector,
    hoveredCalibrationPointIdSelector,
    selectedCalibrationPointIdSelector,
} from '../../../../../core/legacy/selectors/calibration';

import {
    convertAnnotationPointsToRealSize,
    convertAnnotationPointToRealSize,
} from '../../../../../core/legacy/utils/roadViewerUtils';

const useEngineStateMachine = (calibrationPointToEdit) => {
    const dispatch = useDispatch();
    const hoveredCalibrationPointId = useSelector(hoveredCalibrationPointIdSelector);
    const selectedCalibrationPointId = useSelector(selectedCalibrationPointIdSelector);

    const saveAnnotation = (geometryPoints) => {
        if (calibrationPointToEdit) {
            const recalculatedAnnotationPoints = convertAnnotationPointToRealSize(geometryPoints);
            dispatch(updateCalibrationPixels(calibrationPointToEdit, recalculatedAnnotationPoints));
        } else {
            const recalculatedAnnotationPoints = convertAnnotationPointsToRealSize(geometryPoints);
            dispatch(addCalibrationPoint(recalculatedAnnotationPoints[0]));
        }
    };

    const drawingToolActivated = useSelector(isDrawingToolActivatedSelector);
    const isModeEdition = () => calibrationPointToEdit !== undefined;
    const isModeCreation = () => !isModeEdition() && drawingToolActivated;
    const isModeInactive = () => !isModeCreation() && !isModeEdition();

    const initState = () => ({
        dragPoint: undefined,
    });
    const state = useRef(initState());

    const shapeFinished = (currentGeometry) => {
        state.current = initState();
        const geometryToSave = currentGeometry;
        saveAnnotation(geometryToSave);
    };

    const isLeftClick = (event) => event.event.button === 0;

    const mouseUpEvent = (event) => {
        return shapeFinished(event.currentGeometry);
    };

    return (event, operations) => {
        if (isModeInactive()) {
            switch (event.type) {
                case 'mouse_down_on_annotation_event': {
                    const clickedAnnotationId = event.annotationsId[0];
                    if (isLeftClick(event)) {
                        if (clickedAnnotationId !== selectedCalibrationPointId) {
                            dispatch(selectSelectedCalibrationPoint(clickedAnnotationId));
                        } else {
                            dispatch(noMoreSelectedCalibrationPoint());
                        }
                    }
                    break;
                }
                case 'mouse_move_on_annotation_event': {
                    const { annotationsIdsWithStyle } = event;
                    const hoveredAnnotationsId = annotationsIdsWithStyle.map((annotation) => annotation.id);
                    const newHoveredAnnotationId = hoveredAnnotationsId[0];

                    if (newHoveredAnnotationId) {
                        if (hoveredCalibrationPointId !== newHoveredAnnotationId) {
                            dispatch(selectHoveredCalibrationPoint(newHoveredAnnotationId));
                        }
                    }
                    break;
                }
                case 'mouse_move_event':
                    if (hoveredCalibrationPointId) {
                        dispatch(noMoreHoveredCalibrationPoint());
                    }
                    break;

                default:
                    break;
            }
        }
        if (isModeCreation()) {
            switch (event.type) {
                case 'mouse_move_on_existing_point_event':
                case 'mouse_move_on_annotation_event':
                case 'mouse_move_event':
                    operations.movePoint(event.currentGeometry.length - 1, event.to ?? event.at);

                    break;
                case 'mouse_down_on_annotation_event':
                case 'mouse_down_on_existing_point_event':
                case 'mouse_down_event':
                    if (isLeftClick(event)) {
                        operations.addPoint(event.at);
                    }
                    break;

                case 'mouse_up_event':
                    if (isLeftClick(event)) {
                        mouseUpEvent(event, operations);
                    }
                    break;

                default:
                // nothing to do
            }
        }
        if (isModeEdition()) {
            switch (event.type) {
                case 'mouse_down_event':
                    if (isLeftClick(event)) {
                        saveAnnotation(event.at);
                    }
                    break;
                case 'mouse_down_on_existing_point_event':
                    if (isLeftClick(event)) {
                        [state.current.dragPoint] = event.pointIds;
                    }
                    break;
                case 'mouse_move_event':
                case 'mouse_move_on_existing_point_event':
                case 'mouse_move_on_annotation_event':
                    if (state.current.dragPoint !== undefined) {
                        operations.movePoint(state.current.dragPoint, event.to ?? event.at);
                    }
                    break;
                case 'mouse_up_event':
                case 'mouse_up_on_existing_point_event':
                    if (isLeftClick(event)) {
                        if (state.current.dragPoint !== undefined) {
                            saveAnnotation(event.at);
                        }
                        state.current.dragPoint = undefined;
                    }
                    break;
                default:
                // nothing to do
            }
        }
    };
};

export default useEngineStateMachine;
