import { useTranslation } from 'react-i18next';
import type { Model } from '../../core/domain/AIAnalysis';
import { useState, FC, useCallback } from 'react';
import { theme } from '../legacy/style/theme';
import { Box, Card, Stack, SxProps, Typography } from '@mui/material';
import { BorderSelectedIcon } from '../design_system/icons/BorderSelected';
import { ButtonLink } from '../design_system/inputs/buttonLinks/ButtonLink';

const detailsSx = (isSelected: boolean): SxProps => ({
    position: 'relative',
    padding: 2,
    width: '500px',
    cursor: 'pointer',
    overflow: 'visible',
    margin: isSelected ? '0' : '1px',
    borderColor: isSelected ? theme.color.blue.medium : theme.color.grey.medium,
    borderWidth: isSelected ? 2 : 1,
    ':hover': { borderColor: theme.color.blue.medium },
});

type ModelCardProps = {
    model: Model;
    isSelected: boolean;
    onSelection: (modelCode: string) => void;
};

export const ModelCard: FC<ModelCardProps> = ({ model, isSelected, onSelection }) => {
    const { t } = useTranslation();
    const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

    const toggleDetails = useCallback(() => {
        setDetailsOpen(!detailsOpen);
    }, [detailsOpen]);

    return (
        <Card variant="outlined" onClick={() => onSelection(model.code)} sx={detailsSx(isSelected)}>
            <Stack gap={0.5}>
                {isSelected && (
                    // icon has an empty space on the right side
                    <Box sx={{ position: 'absolute', top: 0, right: -2 }}>
                        <BorderSelectedIcon />
                    </Box>
                )}
                <Typography component="h2" sx={{ fontSize: 16, fontWeight: 600 }}>
                    {t(model.name)}
                </Typography>
                <Typography sx={{ fontSize: 14 }}>{t(model.shortDescription)}</Typography>
                <Typography sx={{ fontSize: 12, color: theme.color.grey.main }}>
                    {t('ai_analysis.eta', { seconds_per_km: model.estimatedSecondPerKm })}
                </Typography>
                {detailsOpen ? (
                    <Stack>
                        <Typography sx={{ fontSize: '14px', my: 2 }}>{model.longDescription}</Typography>
                        <ButtonLink onClick={toggleDetails} variant="primary">
                            {t('ai_analysis.reduce_details')}
                        </ButtonLink>
                    </Stack>
                ) : (
                    <ButtonLink onClick={toggleDetails} variant="primary">
                        {t('ai_analysis.show_more_details')}
                    </ButtonLink>
                )}
            </Stack>
        </Card>
    );
};
