import { useTranslation } from 'react-i18next';
import { FC, useCallback, useMemo, useState } from 'react';
import GenericModal from '../legacy/components/ui/modals/GenericModal';
import { theme } from '../legacy/style/theme';
import { SessionsModalTypes } from '../../core/legacy/reducers/modals';
import { ContentContainer, ModalTitle } from '../legacy/components/ui/modals/styles';
import { Stack, SxProps, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../types';
import {
    availableModelsQuery,
    modelCodesPerSessionId,
    sessionIdsForAnalysesQuery,
} from '../../core/automatic_analysis/queries';
import { TriggerAnalysisOnSessionsCommand } from '../../core/automatic_analysis/TriggerAnalysisOnSessionsCommand';
import type { Model } from '../../core/domain/AIAnalysis';
import { ModelCard } from './ModelCard';

const modelListSx: SxProps = { gap: 1, maxHeight: '60vh', mb: 1, overflow: 'auto' };

const subtitleSx: SxProps = {
    color: theme.color.grey.light,
    fontSize: theme.font.size.small,
    textAlign: 'left',
    width: '100%',
    mt: -1,
    mb: 2,
};

export const TriggerAiAnalysisModal: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedModels, setSelectedModels] = useState<string[]>([]);
    const availableModels = useAppSelector(availableModelsQuery);
    const sessionIds = useAppSelector(sessionIdsForAnalysesQuery);
    const modelsPerSessions = useAppSelector(modelCodesPerSessionId);

    const filteredModels = useMemo(() => {
        // we filter the models only if one session is selected, otherwise we cannot know
        if (sessionIds.length > 1) return availableModels;

        const sessionId = sessionIds[0] as string;

        return availableModels.reduce((codes, model) => {
            const { code } = model;
            const modelAlreadyExecuted = modelsPerSessions[sessionId]?.includes(code);

            if (!modelAlreadyExecuted) codes.push(model);

            return codes;
        }, [] as Model[]);
    }, [availableModels, sessionIds, modelsPerSessions]);

    const handleModelSelection = useCallback(
        (modelCode: string) => {
            const alreadySelected = selectedModels.includes(modelCode);
            if (alreadySelected) {
                setSelectedModels(selectedModels.filter((code) => code !== modelCode));
            } else {
                setSelectedModels(selectedModels.concat(modelCode));
            }
        },
        [selectedModels],
    );
    const handleRunAnalyses = useCallback(() => {
        dispatch(TriggerAnalysisOnSessionsCommand(selectedModels));
        setSelectedModels([]);
    }, [dispatch, selectedModels]);

    return (
        <GenericModal
            options={{
                hideDefaultButtons: false,
                backgroundColor: theme.color.white.main,
            }}
            modalType={SessionsModalTypes.AI_ANALYSIS}
            validateButtonText={t('labels.run_the_ai_analysis')}
            onCancel={() => setSelectedModels([])}
            onValidate={handleRunAnalyses}
            isValidateDisable={selectedModels.length === 0}
        >
            <ContentContainer>
                <ModalTitle>{t('ai_analysis.select_the_model')}</ModalTitle>
                {sessionIds.length > 1 && <Typography sx={subtitleSx}>{t('ai_analysis.modal_subtitle')}</Typography>}
                <Stack sx={modelListSx}>
                    {filteredModels.length === 0 ? (
                        <Typography
                            sx={{ color: theme.color.grey.light, fontSize: theme.font.size.small, margin: '20px' }}
                        >
                            {t('ai_analysis.no_model_available')}
                        </Typography>
                    ) : (
                        filteredModels.map((model) => (
                            <ModelCard
                                key={model.code}
                                model={model}
                                isSelected={selectedModels.includes(model.code)}
                                onSelection={handleModelSelection}
                            />
                        ))
                    )}
                </Stack>
            </ContentContainer>
        </GenericModal>
    );
};
