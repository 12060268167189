import styled from 'styled-components';

export const H1 = styled.h1`
    span {
        color: ${({ theme }) => theme.color.red.primary};
        text-transform: capitalize;
    }
`;

export const Container = styled.div`
    margin-top: 20px;
`;
