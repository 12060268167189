import Modal from 'styled-react-modal';
import styled from 'styled-components';
import { Text, Flex as FlexBase, BasePrimaryButton } from '../../../style';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';

export const StyledModal = Modal.styled`
		min-width: 500px;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.color.white.main};
		border-radius: 15px;
		padding: 30px 60px;
		box-sizing: border-box;
		position: relative;
`;

export const CloseContainer = styled(FlexBase).attrs({ justify: 'right' })``;

export const ButtonsContainer = styled(FlexBase).attrs((props) => ({
    justify: props.centerButtons ? 'center' : 'right',
}))`
    margin: 20px 0 0;
`;

export const CancelButton = styled(BasePrimaryButton)`
    background-color: ${({ theme }) => theme.color.blue.grey};
    color: ${({ theme }) => theme.color.black.main};
    &:hover {
        background-color: ${({ theme }) => theme.color.grey.medium};
    }
`;

export const ValidateButton = styled(BasePrimaryButton)`
    margin: 0 0 0 8px;
    background-color: ${({ theme, isDangerous }) => isDangerous && theme.color.red.primary};

    &:hover {
        background-color: ${({ theme, isDangerous }) => isDangerous && theme.color.red.darker};
    }
`;

export const Close = styled(CloseIcon)`
    cursor: pointer;
    height: 20px;
    position: absolute;
    top: 30px;
    right: 30px;
`;

export const IconContainer = styled.div`
    background-color: ${({ theme, failure }) => (failure ? theme.color.red.darker : theme.color.blue.primary)};
    width: 50px;
    height: 50px;
    border-radius: 25px;
    position: relative;
    font-size: 0;

    & svg {
        position: absolute;
        height: ${({ failure }) => (failure ? '30px' : '20px')};
        width: ${({ failure }) => (failure ? '30px' : '20px')};
        top: ${({ failure }) => (failure ? '10px' : '15px')};
        left: ${({ failure }) => (failure ? '10px' : '15px')};
        color: ${({ theme }) => theme.color.white.main};
    }
`;

export const ModalTitle = styled(Text)`
    margin-top: 10px;
    margin-bottom: 5px;
    align-self: start;
    font-size: ${({ theme }) => theme.font.size.large};
`;

export const ModalCenterTitle = styled(ModalTitle)`
    align-self: center;
    text-align: center;
`;

export const ModalCenterSubTitle = styled(ModalCenterTitle)`
    font-size: ${({ theme }) => theme.font.size.medium};
    margin-bottom: 30px;
`;

export const ContentContainer = styled(FlexBase).attrs({ justify: 'left', column: true })``;

export const ModalCenterDescription = styled(ModalCenterSubTitle)`
    color: ${({ theme }) => theme.color.grey.main};
    text-align: center;
    margin-bottom: 0;
`;
