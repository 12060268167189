import { Tooltip } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EyeOff } from '../../../../../../assets/icons/eye-off.svg';
import { ReactComponent as EyeOn } from '../../../../../../assets/icons/eye-on.svg';
import type { Session } from '../../../../../../core/domain/Session';
import { ToggleSessionOnMapCommand } from '../../../../../../core/networkMap/commands/ToggleSessionOnMapCommand';
import { isSessionShownQuery } from '../../../../../../core/networkMap/queries';
import { useAppDispatch, useAppSelector } from '../../../../../../types';
import { ElementData } from '../../../../../legacy/components/ui/panels/right-panel/ElementSummary/styles';
import { theme } from '../../../../../legacy/style/theme';

export type Props = {
    session: Session;
};
const ToggleSessionOption: FC<Props> = ({ session }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const sessionIsShownInStore = useAppSelector(isSessionShownQuery(session.id));

    const handleToggleSession = (e: MouseEvent) => {
        e.stopPropagation();
        dispatch(
            ToggleSessionOnMapCommand({
                sessionId: session.id,
                shown: !sessionIsShownInStore,
            }),
        );
    };

    return (
        <ElementData onClick={handleToggleSession}>
            {sessionIsShownInStore ? (
                <Tooltip title={t('networkMap.sessionsPanel.toggleSessionOn')} placement="top" arrow>
                    <EyeOn color={theme.color.blue.primary} />
                </Tooltip>
            ) : (
                <Tooltip title={t('networkMap.sessionsPanel.toggleSessionOff')} placement="top" arrow>
                    <EyeOff color={theme.color.blue.primary} />
                </Tooltip>
            )}
        </ElementData>
    );
};

export default ToggleSessionOption;
