import { Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { theme } from '../../../../legacy/style/theme';

type Props = {
    label: string | ReactNode;
    value: string | ReactNode;
};

const Measure: FC<Props> = ({ label, value }) => {
    return (
        <Typography
            sx={{
                wordBreak: 'break-all',
                color: theme.color.grey.main,
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Typography component="span">{label} :</Typography>
            <Typography component="span" fontWeight={600}>
                {value}
            </Typography>
        </Typography>
    );
};

export default Measure;
