import { sessionsQuery } from '../../sessions/queries';
import { setModalToDisplay } from '../../legacy/actions/modals';
import { SessionsModalTypes } from '../../legacy/reducers/modals';
import { createCommand } from '../../utils';
import { initSessionsToggledOnMap, moduleName, networkMapLoaded } from '../state';
import { catalogsInitialized } from '../../catalogs/state';

export const LoadNetworkMapDataCommand = createCommand(
    `${moduleName}/LOAD_NETWORK_MAP_DATA`,
    async (_, { dispatch, extra, getState }) => {
        dispatch(setModalToDisplay(SessionsModalTypes.NETWORK_MAP_LOADER));

        const sessions = sessionsQuery(getState());

        dispatch(initSessionsToggledOnMap(sessions));

        const { catalogs } = await extra.externalCatalogs.loadCatalogs();
        dispatch(catalogsInitialized({ catalogs }));

        const networkData = await extra.externalNetworkMap.loadNetworkMapData();

        if (!networkData.data.features) {
            dispatch(setModalToDisplay(SessionsModalTypes.NO_DATA_FOR_NETWORK_MAP));

            return;
        }

        dispatch(networkMapLoaded(networkData));
    },
);
