import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

const slice = (state: RootState) => state.filters;

export const rubricToDisplayIdsQuery = createSelector(slice, (state) => state.rubricToDisplayIds);
export const referenceToDisplayIds = createSelector(slice, (state) => state.referenceToDisplayIds);
export const applyFilterToImageQuery = createSelector(slice, (state) => state.applyFilterToImage);
export const taggedElementsOnlyQuery = createSelector(slice, (state) => state.taggedElementsOnly);
export const imageRoaddataOnlyQuery = createSelector(slice, (state) => state.imageRoaddataOnly);
