import styled from 'styled-components';
import { theme } from '../../../style/theme';
import { H2 as BaseH2, Text as BaseText } from '../../../style';
import { useTranslation } from 'react-i18next';
import { minimumNumberOfPointsForCalibration as minimumNumberOfPoints } from '../../../../../core';
import Tips from '../../ui/Tips';
import { Panel } from '../../ui/panels/right-panel/styles';

const H2 = styled(BaseH2)`
    font-size: ${theme.font.size.medium2};
    padding: 8px 16px;
`;

const Text = styled(BaseText)`
    font-size: ${theme.font.size.medium};
    margin-bottom: 16px;
    padding: 0 16px;
`;

const WelcomePanel = ({ children }) => {
    const { t } = useTranslation();

    return (
        <Panel>
            {children}
            <H2>{t('calibration.welcome.title')}</H2>
            <Text>{t('calibration.welcome.text1')}</Text>
            <Text>{t('calibration.welcome.text2', { minimumNumberOfPoints })}</Text>
            <Text>{t('calibration.welcome.text3')}</Text>
            <Tips
                headline={t('calibration.welcome.tips.headline')}
                body={t('calibration.welcome.tips.body', {
                    minimumNumberOfPoints,
                })}
            />
        </Panel>
    );
};

export default WelcomePanel;
