/* eslint-disable no-param-reassign */
import { mergeDeep } from '../../../../../../core/utils';
import { smallPointsStyleOptions } from '../../annotation-engine/drawing-styles';
import type {
    Coordinates,
    Annotation,
    AnnotationStyle,
    PartialAnnotationStyle,
    AnnotationPathData,
    StyleDataById,
    ShadowStyle,
    Segment,
} from './models';
import defaultStyle from './style/defaultStyleOptions';

export const areCoordinatesInsideCircle = (
    pointCoordinates: Coordinates,
    circleCenterCoordinates: Coordinates,
    radius: number,
): boolean => {
    const distance =
        (pointCoordinates.x - circleCenterCoordinates.x) * (pointCoordinates.x - circleCenterCoordinates.x) +
        (pointCoordinates.y - circleCenterCoordinates.y) * (pointCoordinates.y - circleCenterCoordinates.y);
    const squareRadius = radius * radius;

    return distance < squareRadius;
};

export const drawRoundRect = (
    renderingContext: CanvasRenderingContext2D,
    coordinates: Coordinates,
    width: number,
    height: number,
    radius: number,
): Path2D => {
    const path = new Path2D();
    path.moveTo(coordinates.x + radius, coordinates.y);
    path.lineTo(coordinates.x + width - radius, coordinates.y);
    path.quadraticCurveTo(coordinates.x + width, coordinates.y, coordinates.x + width, coordinates.y + radius);
    path.lineTo(coordinates.x + width, coordinates.y + height - radius);
    path.quadraticCurveTo(
        coordinates.x + width,
        coordinates.y + height,
        coordinates.x + width - radius,
        coordinates.y + height,
    );
    path.lineTo(coordinates.x + radius, coordinates.y + height);
    path.quadraticCurveTo(coordinates.x, coordinates.y + height, coordinates.x, coordinates.y + height - radius);
    path.lineTo(coordinates.x, coordinates.y + radius);
    path.quadraticCurveTo(coordinates.x, coordinates.y, coordinates.x + radius, coordinates.y);
    renderingContext.fill(path);

    return path;
};

export const overloadStyle = (style: AnnotationStyle, customStyle?: PartialAnnotationStyle): AnnotationStyle => {
    if (!customStyle) {
        return style;
    }

    return mergeDeep(style, customStyle) as AnnotationStyle;
};

const applyShadow = (renderingContext: CanvasRenderingContext2D, { blur, color, offsetX, offsetY }: ShadowStyle) => {
    renderingContext.shadowOffsetX = offsetX;
    renderingContext.shadowOffsetY = offsetY;
    renderingContext.shadowColor = color;
    renderingContext.shadowBlur = blur;
};

const drawLabel = (
    renderingContext: CanvasRenderingContext2D,
    label: string,
    from: Coordinates,
    style: AnnotationStyle,
): Path2D => {
    const fontSize = 10;
    renderingContext.font = `Bold ${fontSize}px sans-serif`;
    const textSize = renderingContext.measureText(label);
    const textCoordinate = {
        x: from.x - textSize.width / 2,
        y: from.y - fontSize,
    };
    renderingContext.save();
    const { textAlign, textColor, fillColor, shadow } = style.label;
    renderingContext.textAlign = textAlign;
    applyShadow(renderingContext, shadow);
    renderingContext.fillStyle = fillColor;
    const path = drawRoundRect(
        renderingContext,
        { x: textCoordinate.x - 5, y: textCoordinate.y - 15 },
        textSize.width + 10,
        20,
        5,
    );
    renderingContext.restore();
    renderingContext.save();
    applyShadow(renderingContext, shadow);
    renderingContext.fillStyle = textColor;
    renderingContext.fillText(label, textCoordinate.x, textCoordinate.y);
    renderingContext.restore();

    return path;
};

const getStyle = (annotation: Annotation, styledAnnotations?: StyleDataById): AnnotationStyle => {
    let annotationStyle = defaultStyle;

    if (annotation.color) {
        annotationStyle = overloadStyle(annotationStyle, {
            point: { strokeColor: annotation.color },
            line: { strokeColor: annotation.color },
            polygon: { strokeColor: annotation.color, fillColor: annotation.color },
            label: { textColor: annotation.color, fillColor: 'transparent' },
        });
    }

    const customStyle = styledAnnotations?.get(annotation.id)?.style;
    if (styledAnnotations && customStyle) {
        annotationStyle = overloadStyle(annotationStyle, customStyle);
    }

    return annotationStyle;
};

export const drawPoint = (
    renderingContext: CanvasRenderingContext2D,
    coordinates: Coordinates,
    style: AnnotationStyle = defaultStyle,
): Path2D => {
    const pointPath = new Path2D();
    const { strokeColor, width, outerRadius, innerRadius, fillColor } = style.point;
    const { shadow } = style.label;
    // stroke and line
    renderingContext.strokeStyle = strokeColor;
    renderingContext.lineWidth = width;

    // arc
    renderingContext.save();
    applyShadow(renderingContext, shadow);
    pointPath.arc(coordinates.x, coordinates.y, outerRadius, 0, Math.PI * 2, true);
    renderingContext.stroke(pointPath);
    renderingContext.restore();

    // fill
    renderingContext.fillStyle = fillColor;

    // arc
    pointPath.arc(coordinates.x, coordinates.y, innerRadius, 0, Math.PI * 2, true);

    renderingContext.fill(pointPath);

    return pointPath;
};

export const drawLine = (
    renderingContext: CanvasRenderingContext2D,
    startCoordinates: Coordinates,
    endCoordinates: Coordinates,
    style: AnnotationStyle = defaultStyle,
    shapePath: Path2D = new Path2D(),
): Path2D => {
    const { strokeColor, cap, width } = style.line;
    const { shadow } = style.label;
    renderingContext.strokeStyle = strokeColor;
    renderingContext.lineCap = cap;
    renderingContext.save();
    applyShadow(renderingContext, shadow);

    shapePath.moveTo(startCoordinates.x, startCoordinates.y);
    shapePath.lineTo(endCoordinates.x, endCoordinates.y);
    renderingContext.lineWidth = width;
    renderingContext.stroke(shapePath);
    renderingContext.restore();

    return shapePath;
};

export const drawPolygon = (
    renderingContext: CanvasRenderingContext2D,
    points: Coordinates[],
    style: AnnotationStyle = defaultStyle,
    shapePath: Path2D = new Path2D(),
): Path2D => {
    const firstPoint = points[0];
    if (points.length < 2 || !firstPoint) {
        throw Error('points should have at least 3 elements');
    }

    const { strokeColor, cap, width, fillColor, opacity } = style.polygon;
    const { shadow } = style.label;
    renderingContext.strokeStyle = strokeColor;
    renderingContext.fillStyle = fillColor;
    renderingContext.lineCap = cap;
    renderingContext.save();
    applyShadow(renderingContext, shadow);
    renderingContext.globalAlpha = opacity;
    shapePath.moveTo(firstPoint.x, firstPoint.y);

    points.forEach((p, i) => {
        if (i === 0) return;
        shapePath.lineTo(p.x, p.y);
    });

    shapePath.closePath();
    renderingContext.lineWidth = width;
    renderingContext.fill(shapePath);
    renderingContext.globalAlpha = 1;
    renderingContext.stroke(shapePath);
    renderingContext.restore();

    return shapePath;
};

export const drawAnnotations = (
    renderingContext: CanvasRenderingContext2D,
    annotations: Annotation[],
    styledAnnotations: StyleDataById,
    annotationsPaths: Map<string, AnnotationPathData>,
): void => {
    annotations.forEach((annotation) => {
        const getHighestPoint = (coordinates: Coordinates[]): Coordinates =>
            coordinates.reduce((coord1, coord2) => {
                if (coord1.y < coord2.y) {
                    return coord1;
                }

                return coord2;
            });

        const highestPoint = getHighestPoint(annotation.coordinates);
        const style = getStyle(annotation, styledAnnotations);

        let pointPath: Path2D;

        if (annotation.coordinates.length === 1) {
            const radius = style.point.outerRadius;
            pointPath = drawPoint(renderingContext, highestPoint, style);
            const labelPoint = { x: highestPoint.x, y: highestPoint.y - radius / 2 - 2 };
            const labelPath = drawLabel(renderingContext, annotation.name, labelPoint, style);

            return annotationsPaths.set(annotation.id, {
                label: labelPath,
                point: pointPath,
            });
        }

        const linesPath: Path2D[] = [];
        let previousCoordinates: Coordinates | undefined =
            annotation.coordinates.length > 2 ? annotation.coordinates[annotation.coordinates.length - 1] : undefined;

        if (annotation.isClosed) {
            linesPath.push(drawPolygon(renderingContext, annotation.coordinates, style));
        } else {
            annotation.coordinates.forEach((coordinates: Coordinates, index: number) => {
                if (previousCoordinates) {
                    if (index > 0) {
                        linesPath.push(drawLine(renderingContext, previousCoordinates, coordinates, style));
                    }
                }
                previousCoordinates = coordinates;
            });
        }

        const labelPath = drawLabel(renderingContext, annotation.name, highestPoint, style);

        return annotationsPaths.set(annotation.id, {
            label: labelPath,
            lines: linesPath,
        });
    });
};

export const getSegmentsToDraw = (coordinates: Coordinates[], isClosed?: boolean): Segment[] => {
    const segments = coordinates.reduce((acc: Coordinates[][], coord: Coordinates) => {
        if (acc[acc.length - 1]?.length === 1) {
            acc[acc.length - 1]!.push(coord);
        }
        acc.push([coord]);

        return acc;
    }, []);

    if (segments[segments.length - 1]?.length === 1) {
        segments.pop();
    }

    if (isClosed) {
        segments.push([coordinates[coordinates.length - 1]!, coordinates[0]!]);
    }

    return segments as Segment[];
};

export const getMiddlePoint = (segment: Segment): Coordinates => {
    const start = segment[0];
    const end = segment[1];

    return {
        x: (start.x + end.x) / 2,
        y: (start.y + end.y) / 2,
    };
};

export const drawCurrentAnnotation = (
    renderingContext: CanvasRenderingContext2D,
    annotationPoints: Coordinates[],
    editStyle: AnnotationStyle,
    styleForPointsToEdit?: StyleDataById,
    annotationToEdit?: Annotation,
): void => {
    annotationPoints.forEach((annotationPoint: Coordinates, index: number) => {
        const style = overloadStyle(editStyle, styleForPointsToEdit?.get(`${index}`)?.style);
        drawPoint(renderingContext, annotationPoint, style);
    });

    const drawMiddlePoint = (segment: Segment, index: number): void => {
        const middlePoint = getMiddlePoint(segment);
        const styleOverload = styleForPointsToEdit?.get(`${index}`)?.style
            ? styleForPointsToEdit?.get(`${index}`)?.style
            : smallPointsStyleOptions;
        const style = overloadStyle(editStyle, styleOverload);
        drawPoint(renderingContext, middlePoint, style);
    };

    let segments: Segment[];

    if (annotationToEdit) {
        segments = getSegmentsToDraw(annotationToEdit.coordinates, annotationToEdit.isClosed);
    } else {
        segments = getSegmentsToDraw(annotationPoints);
    }
    segments.forEach((segment, index) => {
        drawLine(renderingContext, segment[0], segment[1], editStyle);

        if (annotationToEdit && annotationToEdit.hasMiddlePoints) {
            drawMiddlePoint(segment, annotationPoints.length + index);
        }
    });
};
