import { Box } from '@mui/material';
import type { FC } from 'react';
import type { Session } from '../../../core/domain/Session';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';

type RoadDirectionProps = {
    session: Session;
};
export const RoadDirection: FC<RoadDirectionProps> = ({ session }) => (
    <Box onClick={(e) => e.stopPropagation()}>
        {session.metadata.roadDirection === 'D - Sens aller' ? <ArrowUpIcon /> : <ArrowDownIcon />}
    </Box>
);
