/* eslint-disable @typescript-eslint/no-unused-vars */
import { createCommand } from '../../utils';
import { allSessionsToggledOnMap, moduleName } from '../state';

export const ToggleAllSessionsOnMapCommand = createCommand(
    `${moduleName}/TOGGLE_ALL_SESSIONS_ON_MAP`,
    async (toggle: boolean, { dispatch }) => {
        dispatch(allSessionsToggledOnMap(toggle));
    },
);
