import { setModalToDisplay, unsetModalToDisplay } from '../../legacy/actions/modals';
import { SessionsModalTypes } from '../../legacy/reducers/modals';
import { createCommand } from '../../utils';
import {
    HomogeneousZoneParams,
    homogeneousZonesChangeDefaultParams,
    homogeneousZonesFailedComputing,
    moduleName,
    updatedSession,
} from '../state';

interface ComputeSessionsZonesProps {
    sessionIds: string[];
    zoneParams: HomogeneousZoneParams;
}

const ComputeSessionsZones = createCommand(
    `${moduleName}/COMPUTE_HOMOGENEOUS_ZONES`,
    async ({ sessionIds, zoneParams }: ComputeSessionsZonesProps, { dispatch, extra }) => {
        const startTime = new Date().getTime();
        const computingResult = await extra.externalSessions.computeHomogeneousZones(sessionIds, zoneParams);
        const computingErrors: string[] = [];
        computingResult.forEach((c) => {
            if (c.status === 'error') {
                computingErrors.push(c.session.id);
            } else {
                dispatch(updatedSession(c.session));
            }
        });
        dispatch(homogeneousZonesFailedComputing(computingErrors));
        dispatch(homogeneousZonesChangeDefaultParams(zoneParams));

        // wait minimum 1.5s before rendering new state
        // giving the user the impression it computes a lot
        // eslint-disable-next-line no-empty
        while (new Date().getTime() < startTime + 1500) {}

        dispatch(
            computingErrors.length > 0
                ? setModalToDisplay(SessionsModalTypes.COMPUTE_ZONES_FAILURE)
                : unsetModalToDisplay(),
        );
    },
);

export default ComputeSessionsZones;
