import { useCallback, useRef } from 'react';

const useScreenIsZoomed = () => {
    const pxRatio = useRef(window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth);

    const isZoomed = useCallback(() => {
        const newPxRatio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
        if (newPxRatio !== pxRatio.current) {
            pxRatio.current = newPxRatio;

            return true;
        }

        return false;
    }, []);

    return { pxRatio: pxRatio.current, isZoomed };
};

export default useScreenIsZoomed;
