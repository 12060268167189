import type { AnnotationStyle } from '../models';

const defaultStyle: AnnotationStyle = {
    label: {
        textAlign: 'left',
        textColor: '#D9D4FC',
        fillColor: 'transparent',
        shadow: {
            offsetX: 0,
            offsetY: 0,
            color: 'black',
            blur: 0,
        },
    },
    line: {
        width: 2,
        cap: 'round',
        strokeColor: 'lightblue',
    },
    polygon: {
        width: 1,
        cap: 'round',
        strokeColor: 'lightblue',
        fillColor: 'lightblue',
        opacity: 0.15,
    },
    point: {
        width: 2,
        outerRadius: 7,
        innerRadius: 5,
        strokeColor: 'lightblue',
        fillColor: '#0053CC30',
    },
};

export default defaultStyle;
