import { useRef, useEffect } from 'react';
import {
    selectHoveredRoadData,
    noMoreHoveredRoadData,
    selectSelectedRoadData,
    noMoreSelectedRoadData,
    updateTemporaryDeformedAnnotationToEdit,
    saveEditedAnnotation,
    cancelAnnotationCreationAndEdition,
    roadDataIdToDelete,
    noMoreAnnotationInEdition,
    playerGoPrevious,
    playerGoNext,
    selectAnnotationInEditionAndJump,
} from '../../../../../core/legacy/actions';
import useScrollNavigation from './use-scroll-navigation';
import useShapeTypeSettings from './use-shape-type-settings';
import useGuideLinesDrawing from './use-guide-lines-drawing';
import useSaveAnnotation from './use-save-annotation';
import { hiddenStyle, highlightStyle } from '../../ui/annotation-engine/drawing-styles';
import { hoveredRoadDataIdSelector, selectedRoadDataIdSelector } from '../../../../../core/legacy/selectors/streetView';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import type {
    contextMenuEvent,
    KeyboardEvents,
    MouseDoubleClickEvent,
    MouseDoubleClickOnAnnotationEvent,
    MouseDownOnExistingPointEvent,
    MouseEvents,
    MouseMoveEvents,
    MouseMoveOnExistingPointEvent,
    MouseUpEvent,
    MouseUpOnExistingPointEvent,
    MouseWheelEvent,
    Operations,
    PointId,
    PointIds,
} from '../../ui/annotation-engine-lib/annotation-engine/use-annotation-engine';
import type { Coordinates, Geometry } from '../../ui/annotation-engine-lib/annotation-engine/models';
import { toggleRoadDataWarningTag } from '../../../../../core/actions/streetView';

const noPointWasSet = (lastPointIndex: number): boolean => lastPointIndex === -1;

const useEngineStateMachine = (
    refAE,
    previousSelectedRubric,
    selectedRubric,
    deformedAnnotationToEdit,
    styleOps,
    handleOpenContextMenu,
) => {
    const dispatch = useAppDispatch();
    const { drawGuideLines } = useGuideLinesDrawing();
    const hoveredRoadDataId: string | undefined = useAppSelector(hoveredRoadDataIdSelector);
    const selectedRoadDataId: string | undefined = useAppSelector(selectedRoadDataIdSelector);

    const { shapeType } = useShapeTypeSettings();
    const moveInSession = useScrollNavigation();
    const saveAnnotation = useSaveAnnotation(deformedAnnotationToEdit, selectedRubric);
    const initState = () => ({
        dragPoint: undefined,
    });
    const state = useRef<{ dragPoint: undefined | number }>(initState());

    // cancel current creation and edition
    useEffect(() => {
        (async () => {
            if (previousSelectedRubric.current !== selectedRubric) {
                state.current = initState();
                await dispatch(saveEditedAnnotation());
                refAE.current?.cancelCreation();
                // eslint-disable-next-line no-param-reassign
                previousSelectedRubric.current = selectedRubric;
            }
        })();
        if (selectedRubric || deformedAnnotationToEdit) {
            setCrosshairCursorStyle();
        } else {
            setDefaultCursorStyle();
        }
    }, [selectedRubric, deformedAnnotationToEdit]);
    const shapeFinishedOnKeyCodes = ['Space', 'Enter'];
    const cancelOnKeyCodes = ['Escape'];
    const deleteOnKeyCodes = ['Delete'];
    const deleteLastPointOnKeyCodes = ['Backspace'];
    const goNextImageKeys = ['ArrowRight'];
    const goPreviousImageKeys = ['ArrowLeft'];
    const tagElementKeys = ['t'];

    const isModeEdition = (): boolean => deformedAnnotationToEdit !== undefined;
    const isModeCreation = (): boolean => !isModeEdition() && shapeType !== undefined;
    const isModeInactive = (): boolean => !isModeCreation() && !isModeEdition();

    const setDefaultCursorStyle = (): void => styleOps.setCursorStyle('default');
    const setCrosshairCursorStyle = (): void => styleOps.setCursorStyle('crosshair');
    const setCrosshairCursorStyleIfNotGrabbing = (): void => {
        if (styleOps.cursorStyle !== 'grabbing') {
            styleOps.setCursorStyle('crosshair');
        }
    };
    const setPointerCursorStyle = (): void => {
        styleOps.setCursorStyle('pointer');
    };
    const setPointerCursorStyleIfNotGrabbing = (): void => {
        if (styleOps.cursorStyle !== 'grabbing') {
            setPointerCursorStyle();
        }
    };
    const setGrabbingCursorStyle = (): void => styleOps.setCursorStyle('grabbing');

    const isGeometryComplete = (length: number): boolean => {
        switch (shapeType) {
            case 'POINT':
                return length === 1;
            case 'LINE':
                return length === 2;
            case 'POLYGON':
            case 'POLYLINE':
            default:
                return false;
        }
    };
    const cancelCreationAndEdition = () => {
        state.current = initState();
        dispatch(cancelAnnotationCreationAndEdition());
        refAE.current?.cancelCreation();
    };

    /**
     * Dedicated to line, polygon and polylines shapes, return true if shape can be manually complete
     */
    const isGeometryReadyToBeManuallyCompleted = (length: number): boolean => {
        const lengthToCheck = isModeEdition() ? length : length - 1;
        switch (shapeType) {
            case 'POINT':
            case 'LINE':
                return isModeEdition();
            case 'POLYGON':
                return lengthToCheck >= 3;
            case 'POLYLINE':
                return lengthToCheck >= 2;
            default:
                return false;
        }
    };

    const isPolygonOrPolyline = (): boolean => {
        switch (shapeType) {
            case 'POLYGON':
            case 'POLYLINE':
                return true;
            default:
                return false;
        }
    };

    /**
     * Dedicated to polygon shapes, return true if:
     * - shape is ready to be manually finished
     * - mouse is moving on the first point of the shape
     */
    const isPolygonReadyToBeManuallyCompletedByClickOnFirstPoint = (
        currentGeometry: Geometry,
        pointIds: PointIds,
    ): boolean => {
        const { length } = currentGeometry;
        switch (shapeType) {
            case 'POLYGON':
                return isGeometryReadyToBeManuallyCompleted(length) && pointIds.some((id) => id === 0);
            default:
                return false;
        }
    };

    const createNewPoint = (at: Coordinates, operations: Operations) => operations.addPoint(at);

    const shapeFinished = (currentGeometry: Geometry) => {
        state.current = initState();
        let geometryToSave = currentGeometry;
        // remove unnecessary temporary point (the one under the cursor)
        if (isModeCreation() && shapeType && !['POINT', 'LINE'].includes(shapeType)) {
            geometryToSave = currentGeometry.slice(0, currentGeometry.length - 1);
        }
        saveAnnotation(geometryToSave);
    };

    const isLeftClick = (event: MouseEvents): boolean => event.event.button === 0;
    const isRightClick = (event: MouseEvents): boolean => event.event.button === 2;

    const keyDownEvent = (event: KeyboardEvents): void => {
        if (event.event.code === 'Space' && event.event.target === document.body) {
            // avoid page scrolldown on space key up
            event.event.preventDefault();
        }
    };

    const deleteLastPointKey = (event: KeyboardEvents, operations: Operations): void => {
        if (deleteLastPointOnKeyCodes.includes(event.event.code)) {
            const lastPoint = operations.deleteLastPoint();
            styleOps.setStyleExclusivelyToPointId(hiddenStyle, lastPoint.toString());
        }
    };

    const shapeFinishedKey = (event: KeyboardEvents): void => {
        if (
            shapeFinishedOnKeyCodes.includes(event.event.code) &&
            isGeometryReadyToBeManuallyCompleted(event.currentGeometry.length)
        ) {
            shapeFinished(event.currentGeometry);
        }
    };
    const cancelCreationAndEditionKey = (event: KeyboardEvents): void => {
        if (cancelOnKeyCodes.includes(event.event.code)) {
            cancelCreationAndEdition();
        }
    };
    const goPreviousImageKey = (event: KeyboardEvents): void => {
        if (goPreviousImageKeys.includes(event.event.code)) {
            dispatch(playerGoPrevious());
        }
    };

    const goNextImageKey = (event: KeyboardEvents): void => {
        if (goNextImageKeys.includes(event.event.code)) {
            dispatch(playerGoNext());
        }
    };

    const deleteAnnotationKey = (event: KeyboardEvents): void => {
        if (deleteOnKeyCodes.includes(event.event.code)) {
            if (selectedRoadDataId) {
                dispatch(noMoreAnnotationInEdition());
                dispatch(roadDataIdToDelete(selectedRoadDataId));
            }
        }
    };

    const tagAnnotationKey = ({ event: { key } }: KeyboardEvents): void => {
        if (selectedRoadDataId && tagElementKeys.includes(key)) {
            dispatch(toggleRoadDataWarningTag(selectedRoadDataId));
        }
    };

    const keepDrawingGuideLine = (
        event: Exclude<
            MouseEvents,
            MouseWheelEvent | contextMenuEvent | MouseDoubleClickEvent | MouseDoubleClickOnAnnotationEvent
        >,
        operations: Operations,
    ): void => {
        if (shapeType === 'LINE' && event.currentGeometry.length === 2) {
            drawGuideLines(event.currentGeometry, operations);
        }
    };
    // different action organise by event.type
    const keyUpEventInactive = (event: KeyboardEvents): void => {
        deleteAnnotationKey(event);
        cancelCreationAndEditionKey(event);
        goPreviousImageKey(event);
        goNextImageKey(event);
        tagAnnotationKey(event);
    };

    const keyUpEventCreation = (event: KeyboardEvents, operations: Operations): void => {
        shapeFinishedKey(event);
        deleteLastPointKey(event, operations);
        cancelCreationAndEditionKey(event);
        deleteAnnotationKey(event);
    };
    const keyUpEventEdition = (event: KeyboardEvents): void => {
        shapeFinishedKey(event);
        cancelCreationAndEditionKey(event);
        deleteAnnotationKey(event);
    };
    const saveOrUpdateAnnotation = (event: MouseUpEvent | MouseUpOnExistingPointEvent) => {
        if (state.current.dragPoint !== undefined && shapeType === 'POINT') {
            saveAnnotation(event.currentGeometry);
        } else {
            dispatch(updateTemporaryDeformedAnnotationToEdit(deformedAnnotationToEdit));
        }
        state.current.dragPoint = undefined;
    };
    const mouseMoveEvent = (event: MouseMoveEvents, operations: Operations) => {
        const destination = 'to' in event ? event.to : event.at;
        if (state.current.dragPoint !== undefined) {
            if (shapeType === 'LINE') {
                drawGuideLines(event.currentGeometry, operations);
            }
            operations.movePoint(state.current.dragPoint, destination);
        }
    };
    const pointCanBeDeletedFromPolygonOrPolyline = (pointId: PointId | undefined, length: number): boolean => {
        return !!pointId && isPolygonOrPolyline() && isGeometryReadyToBeManuallyCompleted(length);
    };
    const isMatchingMiddlePoint = (
        event: MouseMoveOnExistingPointEvent | MouseDownOnExistingPointEvent | MouseUpOnExistingPointEvent,
    ): boolean => {
        return event.pointIds[0] ? event.pointIds[0] > event.currentGeometry.length - 1 : false;
    };

    return (event, operations: Operations) => {
        if (isModeInactive()) {
            switch (event.type) {
                case 'mouse_down_on_annotation_event': {
                    const clickedRoadDataId = event.annotationsId[0];
                    if (isLeftClick(event)) {
                        if (clickedRoadDataId !== selectedRoadDataId) {
                            dispatch(selectSelectedRoadData(clickedRoadDataId));
                        } else {
                            dispatch(noMoreSelectedRoadData());
                        }
                    }
                    break;
                }
                case 'mouse_move_on_annotation_event': {
                    const { annotationsIdsWithStyle } = event;
                    const hoveredAnnotationsId = annotationsIdsWithStyle.map((annotation) => annotation.id);
                    const newHoveredAnnotationId = hoveredAnnotationsId[0];

                    if (newHoveredAnnotationId) {
                        if (hoveredRoadDataId !== newHoveredAnnotationId) {
                            dispatch(selectHoveredRoadData(newHoveredAnnotationId));
                        }
                    }
                    break;
                }
                case 'mouse_move_event':
                    if (hoveredRoadDataId) {
                        dispatch(noMoreHoveredRoadData());
                    }
                    break;
                case 'mouse_wheel_event':
                    moveInSession(event.deltaY);
                    break;
                case 'mouse_double_click_on_annotation_event': {
                    const clickedRoadDataId = event.annotationsId[0];
                    dispatch(selectAnnotationInEditionAndJump(clickedRoadDataId));
                    break;
                }
                case 'key_up_event':
                    keyUpEventInactive(event);
                    break;
                default:
                    break;
            }
        }
        if (isModeCreation()) {
            switch (event.type) {
                case 'mouse_double_click_on_annotation_event':
                case 'mouse_double_click_event':
                    if (isGeometryReadyToBeManuallyCompleted(event.currentGeometry.length)) {
                        shapeFinished(event.currentGeometry);
                        break;
                    }
                    break;
                case 'key_down_event':
                    keyDownEvent(event);
                    keepDrawingGuideLine(event, operations);
                    break;
                case 'key_up_event':
                    keyUpEventCreation(event, operations);
                    keepDrawingGuideLine(event, operations);
                    break;
                case 'mouse_move_on_existing_point_event':
                case 'mouse_move_on_annotation_event':
                case 'mouse_move_event':
                    operations.movePoint(event.currentGeometry.length - 1, event.to ?? event.at);
                    if (
                        isPolygonReadyToBeManuallyCompletedByClickOnFirstPoint(
                            event.currentGeometry,
                            event.pointIds ?? [],
                        )
                    ) {
                        styleOps.setStyleExclusivelyToPointId(highlightStyle, '0');
                    } else {
                        styleOps.removeStyleFromPointsByStyleNames(highlightStyle.name);
                    }
                    keepDrawingGuideLine(event, operations);
                    break;
                case 'context_menu_event':
                    event.event.preventDefault();
                    break;
                case 'mouse_down_on_annotation_event':
                case 'mouse_down_on_existing_point_event':
                case 'mouse_down_event':
                    if (isLeftClick(event) && event.currentGeometry.length === 0) {
                        const pointIndex = `${operations.addPoint(event.at)}`;
                        styleOps.setStyleExclusivelyToPointId(hiddenStyle, pointIndex + 1);
                    }
                    styleOps.removeStyleFromPointsByStyleNames(hiddenStyle.name);
                    if (isRightClick(event)) {
                        const lastPoint = operations.deleteLastPoint();
                        styleOps.setStyleExclusivelyToPointId(hiddenStyle, lastPoint.toString());
                        if (noPointWasSet(lastPoint)) {
                            handleOpenContextMenu(event.event);
                        }
                    }
                    break;
                case 'mouse_up_on_existing_point_event':
                    if (
                        isLeftClick(event) &&
                        isPolygonReadyToBeManuallyCompletedByClickOnFirstPoint(event.currentGeometry, event.pointIds)
                    ) {
                        shapeFinished(event.currentGeometry);
                        break;
                    }
                    break;
                case 'mouse_up_event':
                    if (isLeftClick(event)) {
                        if (isGeometryComplete(event.currentGeometry.length)) {
                            shapeFinished(event.currentGeometry);
                            break;
                        }
                        const newPointIndex = createNewPoint(event.at, operations);
                        styleOps.setStyleExclusivelyToPointId(hiddenStyle, `${newPointIndex}`);
                    }
                    break;
                case 'mouse_wheel_event':
                    moveInSession(event.deltaY);
                    break;
                default:
                    break;
                // nothing to do
            }
        }
        if (isModeEdition()) {
            switch (event.type) {
                case 'context_menu_event':
                    event.event.preventDefault();
                    break;
                case 'key_down_event':
                    keyDownEvent(event);
                    break;
                case 'key_up_event':
                    keyUpEventEdition(event);
                    break;
                case 'mouse_down_event':
                    if (isLeftClick(event)) {
                        saveAnnotation(event.currentGeometry);
                    }
                    break;
                case 'mouse_down_on_existing_point_event':
                    if (isLeftClick(event)) {
                        setGrabbingCursorStyle();
                        if (isMatchingMiddlePoint(event)) {
                            const pointId = event.pointIds[0];
                            const insertionIndex = pointId - event.currentGeometry.length + 1;

                            operations.insertPoint(event.at, insertionIndex);
                            state.current.dragPoint = insertionIndex;
                            styleOps.setStyleExclusivelyToPointId(highlightStyle, insertionIndex);
                            break;
                        }
                        const pointId = event.pointIds[0];
                        state.current.dragPoint = pointId;
                    }
                    break;
                case 'mouse_move_on_existing_point_event': {
                    setPointerCursorStyleIfNotGrabbing();
                    const pointId = event.pointIds[0]?.toString();
                    if (isMatchingMiddlePoint(event)) {
                        styleOps.setStyleExclusivelyToPointId(highlightStyle, pointId);
                        break;
                    }
                    mouseMoveEvent(event, operations);
                    styleOps.setStyleExclusivelyToPointId(highlightStyle, pointId);
                    break;
                }
                case 'mouse_move_event':
                    setCrosshairCursorStyleIfNotGrabbing();
                    mouseMoveEvent(event, operations);
                    styleOps.removeStyleFromPointsByStyleNames(highlightStyle.name);
                    break;
                case 'mouse_move_on_annotation_event':
                    mouseMoveEvent(event, operations);
                    styleOps.removeStyleFromPointsByStyleNames(highlightStyle.name);
                    break;
                case 'mouse_up_event':
                    if (isLeftClick(event)) {
                        saveOrUpdateAnnotation(event);
                    }
                    break;
                case 'mouse_up_on_existing_point_event': {
                    if (isLeftClick(event)) {
                        saveOrUpdateAnnotation(event);
                        setPointerCursorStyle();
                        break;
                    }
                    const pointId = event.pointIds[0]?.toString();
                    if (
                        isRightClick(event) &&
                        pointCanBeDeletedFromPolygonOrPolyline(pointId, event.currentGeometry.length - 1)
                    ) {
                        operations.deletePoint(pointId);
                        styleOps.removeStyleFromPointsByStyleNames(highlightStyle.name);
                    }
                    break;
                }
                default:
                // nothing to do
            }
        }
    };
};

export default useEngineStateMachine;
