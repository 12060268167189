import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleSessionOnMapCommand } from '../../../../../core/networkMap/commands/ToggleSessionOnMapCommand';
import { useAppDispatch } from '../../../../../types';
import { PopupButton } from '../styles';
import type { SessionId } from '../../../../../core/domain/Session';

const ButtonHideSession: FC<{ sessionId: SessionId }> = ({ sessionId }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const handleHideSession = () => {
        dispatch(
            ToggleSessionOnMapCommand({
                sessionId,
                shown: false,
            }),
        );
    };

    return <PopupButton onClick={handleHideSession}>{t('networkMap.imagePopup.hideSession')}</PopupButton>;
};

export default ButtonHideSession;
