import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unselectRubric } from '../../../../../core/legacy/actions';
import { selectedRubricSelector } from '../../../../../core/legacy/selectors/streetView';
import useCatalogHandler from './use-catalog-handler';

const useCatalogMenuEvents = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mouseMoveEvent, addMouseMoveEvent] = useState(false);
    const [mouseMoveEventHasBegun, setMouseMoveEventHasBegun] = useState(false);
    const [currentButtonBox, setCurrentButtonBox] = useState(null);
    const [buttonHovered, setButtonHovered] = useState({ catalogId: null });
    const selectedRubric = useSelector(selectedRubricSelector);
    const [movePointSelected, setMovePointSelected] = useState(false);

    const rubricHasBeenUnselected = () => selectedRubric === undefined;

    const getCatalogHandler = useCatalogHandler();

    const mouseIsInsideCatalogButton = (event) => {
        const { top: buttonTop, right: buttonRight, bottom: buttonBottom, left: buttonLeft } = currentButtonBox;
        const { clientX, clientY } = event;

        return (
            (clientX < buttonLeft && clientY < buttonBottom) ||
            (clientX > buttonRight && clientY < buttonBottom) ||
            clientY < buttonTop
        );
    };

    const catalogShouldBeClosed = (event) => {
        return mouseIsInsideCatalogButton(event);
    };

    const mouseIsOutsideCatalogButton = (event) => {
        const { top: buttonTop, right: buttonRight, bottom: buttonBottom, left: buttonLeft } = currentButtonBox;
        const { clientX, clientY } = event;

        return clientX < buttonLeft || clientX > buttonRight || clientY < buttonTop || clientY > buttonBottom;
    };

    const handleOpenCatalog = (event, catalog) => {
        getCatalogHandler(catalog);
        setAnchorEl(event.currentTarget);
        addMouseMoveEvent(true);
        setMouseMoveEventHasBegun(false);
        setCurrentButtonBox(event.target.getBoundingClientRect());
        setButtonHovered({ catalogId: catalog.id });
    };

    const handleCloseCatalog = () => {
        addMouseMoveEvent(false);
        setAnchorEl(null);
    };

    const handleClickOnMovePointButton = () => {
        if (selectedRubric && !movePointSelected) {
            dispatch(unselectRubric());
            setMovePointSelected(true);
        } else if (!selectedRubric) {
            setMovePointSelected(true);
        } else {
            setMovePointSelected(false);
        }
        handleCloseCatalog();
    };

    const isMenuOpen = !!anchorEl;

    useEffect(() => {
        setMovePointSelected(true);
    }, []);

    useEffect(() => {
        if (rubricHasBeenUnselected()) {
            setMovePointSelected(true);
        }
    }, [selectedRubric]);

    useEffect(() => {
        function handle(event) {
            if (catalogShouldBeClosed(event)) {
                handleCloseCatalog();
            }
            if (mouseIsOutsideCatalogButton(event)) {
                setButtonHovered({ catalogId: null });
            }
        }

        if (mouseMoveEvent && !mouseMoveEventHasBegun) {
            setMouseMoveEventHasBegun(true);
            document.body.addEventListener('mousemove', handle);
        }
        if (!mouseMoveEvent) {
            document.body.removeEventListener('mousemove', handle);
        }

        return () => {
            document.body.removeEventListener('mousemove', handle);
        };
    }, [mouseMoveEvent]);

    useEffect(() => {
        handleCloseCatalog();
        if (selectedRubric) {
            setMovePointSelected(false);
        }
    }, [selectedRubric]);

    return {
        handleOpenCatalog,
        handleCloseCatalog,
        handleClickOnMovePointButton,
        movePointSelected,
        buttonHovered,
        isMenuOpen,
        anchorEl,
    };
};

export default useCatalogMenuEvents;
