import { createCommand } from '../../utils';
import { moduleName, taggedElementsOnly } from '../state';
import { UpdateFiltersCommand } from './UpdateFiltersCommand';

const toggleCheckedFilterCommand = createCommand(
    `${moduleName}/TOGGLE_CHECKED_FILTER`,
    async (checked: boolean, { dispatch }) => {
        dispatch(taggedElementsOnly(checked));
        dispatch(UpdateFiltersCommand());
    },
);

export default toggleCheckedFilterCommand;
