import { useCallback, useState, useEffect, FC } from 'react';
import { Text } from '../../../style';
import AttributeValueNumberInput from './values/AttributeValueNumberInput';
import AttributeValueTextInput from './values/AttributeValueTextInput';
import PositionValue from './values/PositionValue';
import { goToImageByRoadData, updateAttributeValueOfRoadData } from '../../../../../core/legacy/actions';
import { AttributeValueLexiconInput } from './values/AttributeValueLexiconInput';
import AttributeTypeEnum from '../../../../../core/legacy/utils/AttributeTypeEnum';
import { translateLexiconValue, translateAttribute } from '../../../../../core/legacy/utils/translations';
import { EditableValue, ElementLayout as Layout, Value as ScValue } from '../../ui/panels/right-panel/styles';
import type { AttributeValue, RoadData } from '../../../../../core/domain/Roaddata';
import { useAppDispatch } from '../../../../../types';
import LandmarkValue from '../../../../components/streetView/roadDatasPanel/values/LandmarkValue';

type Props = {
    attributeValue: AttributeValue;
    roadData: RoadData;
    nameOverride?: string;
};

export const AttributeValueComp: FC<Props> = ({ roadData, attributeValue, nameOverride }) => {
    const dispatch = useAppDispatch();
    const { unit, displayType } = attributeValue.attribute;
    const attributeName = nameOverride ?? translateAttribute(attributeValue.attribute.name);
    const editable = attributeValue.attribute.editable && !attributeValue.hasShape;
    const [editionMode, setEditionMode] = useState(false);
    const enableEditionMode = useCallback(() => {
        if (editable) {
            setEditionMode(true);
        }
    }, [editable, setEditionMode]);
    const disableEditionMode = useCallback(() => setEditionMode(false), [setEditionMode]);
    const save = useCallback(
        async (newValue) => {
            await dispatch(updateAttributeValueOfRoadData(roadData, attributeValue, newValue));
            disableEditionMode();
        },
        [roadData, attributeValue, disableEditionMode, dispatch],
    );
    const lexiconValue = attributeValue.value.lexicon?.value;

    // some attributes utils
    const isAttributeNumeric = () => displayType === AttributeTypeEnum.NUMERIC;
    const isAttributeText = () => displayType === AttributeTypeEnum.TEXT;
    const isAttributeLexicon = () => displayType === AttributeTypeEnum.LEXICON;
    const isAttributeLandmarkStart = () => displayType === AttributeTypeEnum.LANDMARK_START;
    const isAttributeLandmarkEnd = () => displayType === AttributeTypeEnum.LANDMARK_END;
    const isAttributePosition = () => displayType === AttributeTypeEnum.POSITION;

    // catch every key input enter to avoid double action with annotation engine
    const catchInput = (event) => {
        event.stopPropagation();
    };
    // caveat: if we click on an editable field into a road-data, it reloads the image where we already are
    useEffect(() => {
        if (editionMode) {
            dispatch(goToImageByRoadData(roadData.id));
        }
    }, [editionMode]);

    if (!editionMode && !editable) {
        return (
            <Layout>
                <Text>{attributeName}</Text>
                {isAttributeNumeric() && (
                    <ScValue>
                        {attributeValue.value.numeric}
                        {attributeValue.value.numeric ? unit : null}
                    </ScValue>
                )}
                {isAttributeText() && (
                    <ScValue>
                        {attributeValue.value.text}
                        {attributeValue.value.text ? unit : null}
                    </ScValue>
                )}
                {isAttributeLandmarkStart() && (
                    <ScValue>
                        <LandmarkValue landmark={attributeValue.value.landmarkStart} />
                    </ScValue>
                )}
                {isAttributeLandmarkEnd() && (
                    <ScValue>
                        <LandmarkValue landmark={attributeValue.value.landmarkEnd} />
                    </ScValue>
                )}
                {isAttributeLexicon() && (
                    <ScValue title={lexiconValue}>
                        {lexiconValue}
                        {lexiconValue ? unit : null}
                    </ScValue>
                )}
                {isAttributePosition() && (
                    <ScValue>
                        <PositionValue position={roadData.position} />
                    </ScValue>
                )}
                {!isAttributePosition() && attributeValue.value.point && (
                    <ScValue>{attributeValue.value.point}</ScValue>
                )}
                {!isAttributePosition() && attributeValue.value.line && <ScValue>{attributeValue.value.line}</ScValue>}
                {!isAttributePosition() && attributeValue.value.polygon && (
                    <ScValue>{attributeValue.value.polygon}</ScValue>
                )}
                {!isAttributePosition() && attributeValue.value.polyline && (
                    <ScValue>{attributeValue.value.polyline}</ScValue>
                )}
            </Layout>
        );
    }

    if (!editionMode && editable) {
        return (
            <Layout editable onClick={enableEditionMode}>
                <Text>{attributeName}</Text>
                {isAttributeNumeric() && (
                    <EditableValue empty={!attributeValue.value.numeric}>
                        {attributeValue.value.numeric}
                        {attributeValue.value.numeric ? unit : null}
                    </EditableValue>
                )}
                {isAttributeText() && (
                    <EditableValue empty={attributeValue.value.text === '' || !attributeValue.value.text}>
                        {attributeValue.value.text}
                        {attributeValue.value.text ? unit : null}
                    </EditableValue>
                )}
                {isAttributeLexicon() && (
                    <EditableValue title={lexiconValue} emptyLexicon={attributeValue.value.lexicon === null}>
                        {translateLexiconValue(attributeValue.attribute.name, lexiconValue ?? '')}
                        {lexiconValue ? unit : null}
                    </EditableValue>
                )}
            </Layout>
        );
    }

    // Edition ongoing
    return (
        <Layout onKeyUp={catchInput}>
            <Text>{attributeName}</Text>

            {isAttributeNumeric() && (
                <AttributeValueNumberInput attributeValue={attributeValue} save={save} cancel={disableEditionMode} />
            )}
            {isAttributeText() && (
                <AttributeValueTextInput attributeValue={attributeValue} save={save} cancel={disableEditionMode} />
            )}
            {isAttributeLexicon() && (
                <AttributeValueLexiconInput attributeValue={attributeValue} save={save} cancel={disableEditionMode} />
            )}
            {/* Can't edit shape directly here */}
        </Layout>
    );
};
