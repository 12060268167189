import { Stack } from '@mui/material';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../../../legacy/components/StreetView/BackButton';

export const MapNavigation: FC = () => {
    const navigate = useNavigate();

    const handleClickOnBackButton = useCallback(() => {
        return navigate(-1);
    }, []);

    return (
        <Stack
            direction="row"
            justifyContent="left"
            alignItems="stretch"
            width="auto"
            sx={{
                top: 0,
                left: 0,
                position: 'absolute',
                margin: '24px',
            }}
        >
            <BackButton handleClick={handleClickOnBackButton} />
        </Stack>
    );
};
