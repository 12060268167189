import { useEffect, useState } from 'react';
import type { ShapeType } from '../../../../../core/domain/Roaddata';
import {
    annotationInEditionShapeTypeSelector,
    selectedRubricShapeTypeSelector,
} from '../../../../../core/legacy/selectors/streetView';
import { useAppSelector } from '../../../../../types';

const useShapeTypeSettings = () => {
    const annotationToEditShapeType = useAppSelector(annotationInEditionShapeTypeSelector);
    const selectedRubricShapeType = useAppSelector(selectedRubricShapeTypeSelector);
    const [shapeType, setShapeType] = useState<ShapeType>();

    useEffect(() => {
        setShapeType(annotationToEditShapeType || selectedRubricShapeType);
    }, [selectedRubricShapeType, annotationToEditShapeType]);

    return { shapeType };
};

export default useShapeTypeSettings;
