import { MapSourceDataEvent } from 'mapbox-gl';
import { useCallback } from 'react';
import { SOURCE_NETWORK_SESSION_ID } from '../network-map-session-layer-configuration';

type Args = {
    updateRenderedSessionsIds: () => void;
};

export const useRenderedSessionsIdsAfterInitialLoad = ({ updateRenderedSessionsIds }: Args) => {
    const updateRenderedSessionsIdsAfterInitialSourceDataLoad = useCallback(
        (event: MapSourceDataEvent) => {
            if (event.sourceId === SOURCE_NETWORK_SESSION_ID && event.isSourceLoaded) {
                updateRenderedSessionsIds();
            }
        },
        [updateRenderedSessionsIds],
    );

    return updateRenderedSessionsIdsAfterInitialSourceDataLoad;
};
