import { useEffect, useState } from 'react';
import {
    annotationInEditionSelector,
    annotationToDisplayOnImageSelector,
    temporaryDeformedAnnotationToEditSelector,
} from '../../../../../core/legacy/selectors/streetView';
import { convertAnnotationToEngineSize } from '../../../../../core/legacy/utils/roadViewerUtils';
import useWindowResizeListener from '../../utils/use-window-resizer';
import { hiddenStyle } from '../../ui/annotation-engine/drawing-styles';
import { useAppSelector } from '../../../../../types';

/**
 * Need to have the image already rendered in the dom in order to process a ratio
 */
// FIXME: la taille orginale de l'image devrait être récupérée dans l'objet image
//        la taille dans la page devrait être récupérér du parent par des props (la taille est fixé dans le css)
const useConvertAnnotationsPixels = (styleOps, currentImage, pxRatio: number) => {
    const annotations = useAppSelector(annotationToDisplayOnImageSelector);
    const annotationToEdit = useAppSelector(annotationInEditionSelector);
    const temporaryDeformedAnnotationToEdit = useAppSelector(temporaryDeformedAnnotationToEditSelector);
    const [deformedAnnotationToEdit, setDeformedAnnotationToEdit] = useState(temporaryDeformedAnnotationToEdit);
    const [realAnnotations, setRealAnnotations] = useState([]);
    const windowSize = useWindowResizeListener();

    const calculatePixels = () => {
        if (temporaryDeformedAnnotationToEdit) {
            setDeformedAnnotationToEdit(temporaryDeformedAnnotationToEdit);
        } else {
            setDeformedAnnotationToEdit(annotationToEdit ? convertAnnotationToEngineSize(annotationToEdit) : undefined);
        }
        setRealAnnotations(annotations.map(convertAnnotationToEngineSize));
        styleOps.removeStyleFromPointsByStyleNames(hiddenStyle.name);
    };

    useEffect(() => {
        // condition for issue: when component needs to update its road-data list but current image is not fully loaded.
        if (currentImage) {
            calculatePixels();
        }
    }, [annotationToEdit, annotations, temporaryDeformedAnnotationToEdit, currentImage, windowSize, pxRatio]);

    return {
        deformedAnnotationToEdit,
        realAnnotations,
        setRealAnnotations,
        windowSize,
    };
};

export default useConvertAnnotationsPixels;
