import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import GenericModal from '../../../../legacy/components/ui/modals/GenericModal';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';
import {
    ContentContainer,
    ModalCenterDescription,
    ModalCenterSubTitle,
    ModalCenterTitle,
} from '../../../../legacy/components/ui/modals/styles';
import type { FC } from 'react';

export const ExportInProgressModal: FC = () => {
    const { t } = useTranslation();
    const options = {
        hideDefaultButtons: true,
        notDismissable: true,
    };

    return (
        <GenericModal options={options} modalType={SessionsModalTypes.SESSIONS_EXPORT_IN_PROGRESS}>
            <ContentContainer>
                <CircularProgress />
                <ModalCenterTitle>{t('exportSession.inProgress.title')}</ModalCenterTitle>
                <ModalCenterSubTitle>{t('exportSession.inProgress.subtitle')}</ModalCenterSubTitle>
                <ModalCenterDescription>{t('exportSession.inProgress.description')}</ModalCenterDescription>
            </ContentContainer>
        </GenericModal>
    );
};
