import type { Session } from '../../../../core/domain/Session';
import { goToImageByCumul } from '../../../../core/legacy/actions';
import { currentImageCumulQuery } from '../../../../core/streetViewer/queries';
import { useAppDispatch, useAppSelector } from '../../../../types';
import useQueryParams from '../../../../utils/use-query-params';

const useGoToImageByCumul = (session: Session | null): (() => void) => {
    const dispatch = useAppDispatch();
    const routeQueryParams: URLSearchParams = useQueryParams();
    const storedCurrentImageCumul = useAppSelector(currentImageCumulQuery);

    const goToCumul = (): void => {
        const cumulImageQueryParam = routeQueryParams.get('cumul');
        if (cumulImageQueryParam) {
            dispatch(goToImageByCumul(cumulImageQueryParam));

            return;
        }

        if (session && storedCurrentImageCumul && session.id === storedCurrentImageCumul.sessionId) {
            dispatch(goToImageByCumul(storedCurrentImageCumul.cumul));
        }
    };

    return goToCumul;
};

export default useGoToImageByCumul;
