import NoDataToExportError from '../../domain/errors/NoDataToExportError';
import { setModalToDisplay, unsetModalToDisplay } from '../../legacy/actions/modals';
import { SessionsModalTypes } from '../../legacy/reducers/modals';
import { createCommand } from '../../utils';
import { moduleName } from '../state';

export const ExportGeojsonSectionsReportCommand = createCommand(
    `${moduleName}/EXPORT_SECTIONS_REPORT`,
    async (sessionIds: string[], { dispatch, extra }) => {
        try {
            dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_EXPORT_IN_PROGRESS));

            const { data, filename } = await extra.externalSessions.exportGeojsonSectionsReport(sessionIds);
            extra.download.execute(data, filename);

            dispatch(unsetModalToDisplay());
        } catch (e: any) {
            switch (e.constructor) {
                case NoDataToExportError:
                    dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_EXPORT_NO_ANNOTATION));
                    break;
                default:
                    dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_EXPORT_FAILURE));
                    break;
            }
        }
    },
);
