import { FC, useRef, useState } from 'react';
import MapboxGlMap, { Layer, MapboxEvent, MapLayerMouseEvent, MapRef, Source } from 'react-map-gl';
import MapPopup from './MapPopup';
import { useAppSelector } from '../../../types';
import {
    currentMapBoundsQuery,
    displayPrQuery,
    networkMapQuery,
    networkMapRoadDataQuery,
    selectedGradeReferenceQuery,
    selectedmapStyleQuery,
} from '../../../core/networkMap/queries';
import EnlargedImageModal from './EnlargedImageModal';
import ImagePopupOnClick from './MapPopup/ImagePopup/PopupOnClick';
import ImagePopupOnHover from './MapPopup/ImagePopup/PopupOnHover';
import useCreatePopupHover from './hooks/use-create-popup-hover';
import useCreatePopupClicked from './hooks/use-create-popup-clicked';
import { MAPBOX_TOKEN } from './map-configuration';
import { LAYER_NETWORK_DATA_ID, SOURCE_NETWORK_DATA_ID, getLayerConfig } from './network-map-layer-configuration';
import {
    PR_LAYER,
    PR_LAYER_PAINT_SATELLITE,
    PR_LAYER_PAINT_STREET,
    PR_SOURCE_ID,
    PR_SOURCE_URL,
} from './pr-layer-configuration';
import type {
    NetworkMapRoadData,
    NetworkImageProperties,
    ValidOrganizationNetworkData,
    NetworkRoadDataGeojsonProperties,
} from '../../../core/networkMap/ExternalNetworkMapPort';
import useZoomOnSession from './hooks/use-zoom-on-session';
import useZoomOnOrganization from './hooks/use-zoom-on-organization';
import useFilterOnSessions from './hooks/use-filter-on-sessions';
import { MapControls } from './MapControls';
import { MapNavigation } from './MapNavigation';
import { NetworkMapDataLoading } from './NetworkMapDataLoading';
import { RefreshAllSessionsGradeLoading } from './RefreshAllSessionsGradeLoading';
import useQueryParams from '../../../utils/use-query-params';
import useStoreMapBounds from './hooks/use-store-map-bounds';
import useInitializeMapBounds from './hooks/use-initialize-map-bounds';
import { useRenderedSessionsIds } from './hooks/use-rendered-sessions-ids';
import { useRenderedSessionsIdsAfterInitialLoad } from './hooks/use-rendered-sessions-ids-after-initial-load';
import {
    LAYER_LINE_NETWORK_ROAD_DATA_ID,
    ROAD_DATA_LINESTRING_LAYER,
    SOURCE_NETWORK_ROAD_DATA_ID,
} from './network-map-road-data-layer-configuration';
import useLoadRoadDatasMap from './use-load-road-datas-on-map';
import RoadDataPopupOnClick from './MapPopup/RoadDataPopup/PopupOnClick';
import RoadDataPopupOnHover from './MapPopup/RoadDataPopup/PopupOnHover';
import { getSessionLayerConfig, SOURCE_NETWORK_SESSION_ID } from './network-map-session-layer-configuration';
import pinIcon from '../../../assets/icons/pin.svg';
import featureManager from './feature-manager';
import { loadConfigFromRootDataset } from '../../../config';
import Image from './Image';

const ClickedPopup = MapPopup;
const HoverPopup = MapPopup;

const { displayPrLayer } = loadConfigFromRootDataset();

const NetworkMap: FC = () => {
    const routeQueryParams: URLSearchParams = useQueryParams();
    const mapRef = useRef<MapRef | null>(null);
    const networkRoadDatas = useAppSelector(networkMapRoadDataQuery);
    const networkData = useAppSelector(networkMapQuery);
    const [mapIsLoaded, setMapIsLoaded] = useState<boolean>(false);
    const mapStyle = useAppSelector(selectedmapStyleQuery);
    const displayPr = useAppSelector(displayPrQuery);
    const updateRenderedSessionsIds = useRenderedSessionsIds(mapRef);
    const selectedGradeReference = useAppSelector(selectedGradeReferenceQuery);
    const updateRenderedSessionsIdsAfterInitialSourceDataLoad = useRenderedSessionsIdsAfterInitialLoad({
        updateRenderedSessionsIds,
    });
    const initializeMapBounds = useInitializeMapBounds(mapRef, networkData, routeQueryParams);
    useZoomOnOrganization(mapRef, networkData);
    useZoomOnSession(mapRef, networkData);
    const layerFilters = useFilterOnSessions();
    const storeMapBounds = useStoreMapBounds();
    const storedMapBounds = useAppSelector(currentMapBoundsQuery);
    const loadRoadDatasOnMap = useLoadRoadDatasMap();
    const fm = featureManager(mapRef, [LAYER_NETWORK_DATA_ID, LAYER_LINE_NETWORK_ROAD_DATA_ID]);
    const hoverImagePopup = useCreatePopupHover<NetworkImageProperties>(
        fm,
        LAYER_NETWORK_DATA_ID,
        SOURCE_NETWORK_DATA_ID,
    );
    const clickImagePopup = useCreatePopupClicked<NetworkImageProperties>(
        fm,
        LAYER_NETWORK_DATA_ID,
        SOURCE_NETWORK_DATA_ID,
    );
    const hoverRoadDataPopup = useCreatePopupHover<NetworkRoadDataGeojsonProperties>(
        fm,
        LAYER_LINE_NETWORK_ROAD_DATA_ID,
        SOURCE_NETWORK_ROAD_DATA_ID,
    );
    const clickRoadDataPopup = useCreatePopupClicked<NetworkRoadDataGeojsonProperties>(
        fm,
        LAYER_LINE_NETWORK_ROAD_DATA_ID,
        SOURCE_NETWORK_ROAD_DATA_ID,
    );

    const initializeMap = () => {
        const map = mapRef?.current?.getMap();
        if (map) {
            initializeMapBounds();
            setMapIsLoaded(true);
        }
    };

    const handleOnMouseMoveOnMap = (event: MapLayerMouseEvent) => {
        hoverImagePopup.popupOnHover(event);
        hoverRoadDataPopup.popupOnHover(event);
    };

    const handleOnClickOnMap = (event: MapLayerMouseEvent) => {
        clickImagePopup.popupOnClick(event);
        clickRoadDataPopup.popupOnClick(event);
    };

    const handleMapLoad = async (event: MapboxEvent) => {
        if (event.type === 'load') {
            initializeMap();
        }
    };

    const handleMapIdle = () => {
        if (!mapIsLoaded) {
            // restore grades on map reuse
            initializeMap();
        }
    };

    const handleMoveEnd = () => {
        if (mapIsLoaded) {
            storeMapBounds(mapRef);
            loadRoadDatasOnMap(storedMapBounds, mapRef.current?.getZoom() as number);
        }
    };

    const handleZoomIn = () => {
        const map = mapRef.current;
        if (map) {
            map.zoomIn();
        }
    };

    const handleZoomOut = () => {
        const map = mapRef.current;
        if (map) {
            map.zoomOut();
        }
    };

    const getNetworkMapData = (): ValidOrganizationNetworkData | undefined => {
        if (!networkData?.data) {
            return undefined;
        }

        return networkData?.data as ValidOrganizationNetworkData;
    };

    const getSessionNetworkMapData = (): ValidOrganizationNetworkData | undefined => {
        if (!networkData?.sessionsData) {
            return {
                type: 'FeatureCollection',
                features: [],
            };
        }

        return networkData?.sessionsData as ValidOrganizationNetworkData;
    };

    const getNetworkMapRoadData = (): NetworkMapRoadData => {
        if (!networkRoadDatas) {
            return {
                type: 'FeatureCollection',
                features: [],
            };
        }

        return networkRoadDatas as NetworkMapRoadData;
    };

    if (!networkData) {
        return <NetworkMapDataLoading />;
    }

    return (
        <>
            <MapboxGlMap
                reuseMaps
                ref={mapRef}
                mapboxAccessToken={MAPBOX_TOKEN}
                onLoad={handleMapLoad}
                onIdle={handleMapIdle}
                onMoveEnd={handleMoveEnd}
                attributionControl={false}
                mapStyle={
                    mapStyle === 'STREETS'
                        ? 'mapbox://styles/mapbox/streets-v9'
                        : 'mapbox://styles/mapbox/satellite-streets-v12'
                }
                style={{ width: '100%', position: 'absolute', top: 0, bottom: 0 }}
                onClick={handleOnClickOnMap}
                onSourceData={updateRenderedSessionsIdsAfterInitialSourceDataLoad}
                onMouseMove={handleOnMouseMoveOnMap}
                initialViewState={{
                    fitBoundsOptions: {
                        animate: false,
                        padding: {
                            top: 100,
                            left: 100,
                            right: 100,
                            bottom: 100,
                        },
                    },
                }}
            >
                <Image name="pin" src={pinIcon} height={50} width={50} sdf />
                <Source id={SOURCE_NETWORK_SESSION_ID} type="geojson" data={getSessionNetworkMapData()} generateId>
                    <Layer {...getSessionLayerConfig(selectedGradeReference)} filter={layerFilters.sessions} />
                </Source>
                <Source id={SOURCE_NETWORK_DATA_ID} type="geojson" data={getNetworkMapData()} generateId>
                    <Layer {...getLayerConfig(selectedGradeReference)} filter={layerFilters.sessionImages} />
                </Source>
                <Source id={SOURCE_NETWORK_ROAD_DATA_ID} type="geojson" data={getNetworkMapRoadData()} generateId>
                    <Layer {...ROAD_DATA_LINESTRING_LAYER} filter={layerFilters.roadDatas} />
                    {/* todo other layers (for polygones, points, polylines) will be added there */}
                </Source>
                <Source id={PR_SOURCE_ID} type="vector" url={PR_SOURCE_URL}>
                    <Layer
                        {...PR_LAYER}
                        layout={{ ...PR_LAYER.layout, visibility: displayPr && displayPrLayer ? 'visible' : 'none' }}
                        paint={mapStyle === 'STREETS' ? PR_LAYER_PAINT_STREET : PR_LAYER_PAINT_SATELLITE}
                    />
                </Source>
                {/* popups for images */}
                <ClickedPopup
                    closeButton
                    popupIsShown={clickImagePopup.popupIsClicked}
                    popupCoordinates={clickImagePopup.popupCoordinates as [number, number]}
                    hidePopup={clickImagePopup.hideClickedPopup}
                >
                    <ImagePopupOnClick properties={clickImagePopup.networkProperties} />
                </ClickedPopup>
                <HoverPopup
                    closeButton={false}
                    popupIsShown={hoverImagePopup.popupIsHovered}
                    popupCoordinates={hoverImagePopup.popupCoordinates as [number, number]}
                    hidePopup={hoverImagePopup.hideHoverPopup}
                >
                    <ImagePopupOnHover properties={hoverImagePopup.networkProperties} />
                </HoverPopup>
                {/* popups for road-datas */}
                <ClickedPopup
                    closeButton
                    popupIsShown={clickRoadDataPopup.popupIsClicked}
                    popupCoordinates={clickRoadDataPopup.popupCoordinates as [number, number]}
                    hidePopup={clickRoadDataPopup.hideClickedPopup}
                >
                    <RoadDataPopupOnClick networkProperties={clickRoadDataPopup.networkProperties} />
                </ClickedPopup>
                <HoverPopup
                    closeButton={false}
                    popupIsShown={hoverRoadDataPopup.popupIsHovered}
                    popupCoordinates={hoverRoadDataPopup.popupCoordinates as [number, number]}
                    hidePopup={hoverRoadDataPopup.hideHoverPopup}
                >
                    <RoadDataPopupOnHover networkProperties={hoverRoadDataPopup.networkProperties} />
                </HoverPopup>
                <MapNavigation />
            </MapboxGlMap>
            <MapControls handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />
            <RefreshAllSessionsGradeLoading />
            {clickImagePopup.networkProperties && (
                <EnlargedImageModal networkProperties={clickImagePopup.networkProperties} />
            )}
            {clickRoadDataPopup.networkProperties && (
                <EnlargedImageModal networkProperties={clickRoadDataPopup.networkProperties} />
            )}
        </>
    );
};

export default NetworkMap;
