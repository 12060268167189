import validator from 'validator';

export const required = (value) => (!value ? 'error.required' : null);
export const emailFormat = (value) => (value && !validator.isEmail(value) ? 'error.invalidEmail' : null);
export const passwordFormat = (value) =>
    value &&
    !validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 0,
        minSymbols: 0,
    })
        ? 'error.invalidPassword'
        : null;
export const passwordsMatch = (value, allValues) =>
    value !== allValues.newPassword ? 'error.unmatchedPasswords' : undefined;
export const filesRequired = (value) => (!value.length ? 'error.filesEmpty' : null);
