import { useMemo } from 'react';
import type { LngLatBounds } from 'mapbox-gl';
import { useAppDispatch } from '../../../types';
import { LoadNetworkMapRoadDataCommand } from '../../../core/networkMap/commands/LoadNetworkMapRoadDataCommand';
import debounce from 'lodash.debounce';

const useLoadRoadDatasMap = () => {
    const dispatch = useAppDispatch();

    return useMemo(
        () =>
            debounce((bbox: LngLatBounds | null, zoom: number) => {
                if (bbox) {
                    const topRight = bbox.getNorthEast();
                    const bottomLeft = bbox.getSouthWest();
                    const xmin = bottomLeft.lng;
                    const ymin = bottomLeft.lat;
                    const xmax = topRight.lng;
                    const ymax = topRight.lat;
                    dispatch(
                        LoadNetworkMapRoadDataCommand({
                            bbox: [xmin, ymin, xmax, ymax],
                            zoom,
                        }),
                    );
                }
            }, 500),
        [dispatch],
    );
};

export default useLoadRoadDatasMap;
