import { createCommand } from '../../utils';
import { applyFilterToImageToggled, moduleName } from '../state';
import { UpdateFiltersCommand } from './UpdateFiltersCommand';

export const ToggleApplyFilterToImageCommand = createCommand(
    `${moduleName}/TOGGLE_APPLY_FILTER_TO_IMAGE`,
    async (_, { dispatch, extra }) => {
        const previouslyStoredApplyFilterToImage = extra.storageProvider.getIsFilterApplied();
        const newStoredApplyFilterToImage = !previouslyStoredApplyFilterToImage;
        extra.storageProvider.setIsFilterApplied(newStoredApplyFilterToImage);
        dispatch(applyFilterToImageToggled());

        dispatch(UpdateFiltersCommand());
    },
);
