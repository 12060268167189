import NoDataToExportError from '../../domain/errors/NoDataToExportError';
import type { ExportSessionsCommandArgs } from '../../domain/Session';
import { setModalToDisplay, unsetModalToDisplay } from '../../legacy/actions/modals';
import { SessionsModalTypes } from '../../legacy/reducers/modals';
import { createCommand } from '../../utils';
import { moduleName } from '../state';

export const ExportSectionsReportCommand = createCommand(
    `${moduleName}/EXPORT_SECTIONS_REPORT`,
    async ({ sessionIds }: ExportSessionsCommandArgs, { dispatch, extra }) => {
        try {
            dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_EXPORT_IN_PROGRESS));

            const file = await extra.externalSessions.exportSectionsReport(sessionIds);
            const time = extra.time.now();
            const filename = `export_${time.toISOString()}.csv`;
            extra.download.execute(file, filename);

            dispatch(unsetModalToDisplay());
        } catch (e: any) {
            switch (e.constructor) {
                case NoDataToExportError:
                    dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_EXPORT_NO_ANNOTATION));
                    break;
                default:
                    dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_EXPORT_FAILURE));
                    break;
            }
        }
    },
);
