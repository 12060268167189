import { Box } from '@mui/material';
import type { FC } from 'react';
import { SessionsModalTypes } from '../../../../core/legacy/reducers/modals';
import GenericModal from '../../../legacy/components/ui/modals/GenericModal';
import { theme } from '../../../legacy/style/theme';
import apiEndpoint from '../../../../core/legacy/utils/api';
import type {
    NetworkImageProperties,
    NetworkRoadDataGeojsonProperties,
} from '../../../../core/networkMap/ExternalNetworkMapPort';

type Props = {
    networkProperties: NetworkImageProperties | NetworkRoadDataGeojsonProperties | null;
};
const EnlargedImageModal: FC<Props> = ({ networkProperties }) => (
    <GenericModal
        options={{
            hideDefaultButtons: false,
            backgroundColor: theme.color.white.main,
            hideValidateButton: true,
            hideCancelButton: true,
        }}
        modalType={SessionsModalTypes.ENLARGED_IMAGE}
    >
        <Box
            component="img"
            alt={`${networkProperties?.imageIndex}`}
            src={`${apiEndpoint}/sessions/${networkProperties?.sessionId}/images/${networkProperties?.imageIndex}.jpeg`}
            width="80%"
        />
    </GenericModal>
);

export default EnlargedImageModal;
