import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { isCalibrationReadyToLeaveSelector } from '../../../../../core/legacy/selectors/calibration';
import { resetCalibrationProcess, resetStreetView } from '../../../../../core/legacy/actions';
import ToolButtonGroup from '../ToolButtonGroup';
import LeaveCalibrationModal from '../LeaveCalibrationModal';
import { Zoom } from '../../StreetView/RoadViewer/ImageActions/Zoom';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { BackButton } from '../../StreetView/BackButton';
import { StreetViewActionsContainer } from '../../../../roadview';
import { Stack } from '@mui/material';
import { OptionsMenu } from '../../StreetView/RoadViewer/ImageActions/OptionsMenu';

const ManualCalibrationNavigation = ({ cumul, sessionId }) => {
    const navigate = useNavigate();
    const leaveCalibrationModalRef = useRef<any>(null);
    const dispatch = useAppDispatch();
    const isCalibrationReadyToLeave = useAppSelector(isCalibrationReadyToLeaveSelector);

    const handleClick = () => {
        if (isCalibrationReadyToLeave) {
            goBackToSession();
        } else if (leaveCalibrationModalRef !== null) {
            leaveCalibrationModalRef.current?.openModal();
        }
    };

    const goBackToSession = () => {
        dispatch(resetCalibrationProcess());
        dispatch(resetStreetView());
        // we add one to cumul to get back on the right image, because cumul is rounded and we fallback to wrong image sometimes
        navigate(`../street-view/${sessionId}${cumul ? `?cumul=${+cumul + 1}` : ''}`);
    };

    return (
        <StreetViewActionsContainer>
            <Stack sx={{ gridRow: 1, flexDirection: 'row', justifyContent: 'space-between', px: 2 }}>
                <Stack direction="row" gap={2}>
                    <BackButton handleClick={handleClick} />
                    <ToolButtonGroup />
                </Stack>
                <Stack sx={{ flexDirection: 'row', gap: 2 }}>
                    <Zoom />
                    <OptionsMenu isCalibrationScreen />
                </Stack>
                <LeaveCalibrationModal ref={leaveCalibrationModalRef} onValidate={goBackToSession} />
            </Stack>
        </StreetViewActionsContainer>
    );
};

export default ManualCalibrationNavigation;
