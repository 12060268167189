import { useEffect, useState } from 'react';
import type { ItineraryFraction } from '../../../../../../core/domain/Itinerary';
import { LoadItineraryForOneSessionCommand } from '../../../../../../core/sessions/commands/LoadItineraryForOneSessionCommand';
import { sessionQuery } from '../../../../../../core/streetViewer/queries';
import { useAppDispatch, useAppSelector } from '../../../../../../types';
import { theme } from '../../../../style/theme';
import { useFractions } from './use-fractions';
import { selectedGradeReferenceQuery } from '../../../../../../core/networkMap/queries';
import referenceResolver from '../../../../../../core/legacy/utils/referenceResolver';
import { WIDTH_PARTIAL_CODE } from '../../../../../../core';

interface BarsDisplayData {
    color: string;
    id: string;
    left: number;
    width: number;
}

const COMMON_COLORS = {
    TARGET: 'rgba(0,83,204,10)',
    DEFAULT: theme.color.grey.main,
};

export const COLORS = {
    PERFECT: theme.color.grades.perfect,
    GOOD: theme.color.grades.good,
    PRETTYGOOD: theme.color.grades.prettyGood,
    PRETTYBAD: theme.color.grades.prettyBad,
    BAD: theme.color.grades.bad,
    SATURATED: theme.color.grades.saturated,
    ...COMMON_COLORS,
} as const;

export const WIDTH_COLORS = {
    BELOW_FOUR: theme.color.widths.belowFour,
    FOUR_TO_EIGHT: theme.color.widths.fourToEight,
    EIGHT_TO_TWELVE: theme.color.widths.eightToTwelve,
    TWELVE_TO_SIXTEEN: theme.color.widths.twelveToSixteen,
    ABOVE_SIXTEEN: theme.color.widths.aboveSixteen,
    ...COMMON_COLORS,
} as const;

export const mapGradeToColor = (reference: string, grade?: number): string => {
    const isWidthRef = reference.includes(WIDTH_PARTIAL_CODE);

    // go above what is needed for last one otherwise grades going over would have default color
    const BOUNDS = isWidthRef ? [0, 4, 8, 12, 16, 100] : [0, 0.011, 0.3, 0.5, 0.7, 0.99, 1.1];
    const gradeIndexToColor: Record<number, string> = isWidthRef
        ? {
              '-1': WIDTH_COLORS.DEFAULT, // not found
              0: WIDTH_COLORS.TARGET, // unset (below zero)
              1: WIDTH_COLORS.BELOW_FOUR,
              2: WIDTH_COLORS.FOUR_TO_EIGHT,
              3: WIDTH_COLORS.EIGHT_TO_TWELVE,
              4: WIDTH_COLORS.TWELVE_TO_SIXTEEN,
              5: WIDTH_COLORS.ABOVE_SIXTEEN,
          }
        : {
              '-1': COLORS.DEFAULT, // not found
              0: COLORS.TARGET, // unset (below zero)
              1: COLORS.SATURATED,
              2: COLORS.BAD,
              3: COLORS.PRETTYBAD,
              4: COLORS.PRETTYGOOD,
              5: COLORS.GOOD,
              6: COLORS.PERFECT,
          };
    const boundIndex = BOUNDS.findIndex((bound) => grade !== undefined && grade < bound);

    return gradeIndexToColor[boundIndex] as string;
};

const getDefaultBarData = (sessionLength: number): BarsDisplayData[] => [
    { color: theme.color.blue.medium, id: sessionLength.toString(), left: 0, width: sessionLength },
];

const getItineraryBarData = (fractions: ItineraryFraction[], reference: string): BarsDisplayData[] => {
    let left = 0;

    return fractions.reduce((list, { grade, imageId, index, length }) => {
        const previousZone = list.at(-1);
        const currentColor = mapGradeToColor(reference, grade);
        const id = imageId ?? index;

        if (previousZone && currentColor === previousZone.color) {
            const mergedZones: BarsDisplayData = {
                color: currentColor,
                id,
                left: previousZone.left,
                width: length + previousZone.width,
            };

            list.splice(-1, 1, mergedZones);
        } else {
            list.push({
                color: currentColor,
                id,
                left,
                width: length,
            });
        }

        left += length;

        return list;
    }, [] as BarsDisplayData[]);
};

export type ItineraryType = 'ELEMENTARY' | 'SECTION';

export const useItinerary = (itineraryType: ItineraryType, sessionLength: number): BarsDisplayData[] => {
    const dispatch = useAppDispatch();
    const session = useAppSelector(sessionQuery);
    const fractions = useFractions(session?.id);
    const selectedGradeReference = useAppSelector(selectedGradeReferenceQuery) ?? 'AGGREGATE';
    const selectedReference = referenceResolver(selectedGradeReference);
    const [barsDisplayData, setBarsDisplayData] = useState<BarsDisplayData[]>(getDefaultBarData(sessionLength));

    useEffect(() => {
        if (!session) {
            return;
        }

        if (fractions === undefined) {
            setBarsDisplayData(getDefaultBarData(sessionLength));
            dispatch(LoadItineraryForOneSessionCommand({ sessionId: session.id }));

            return;
        }

        const itineraryBarData = getItineraryBarData(
            fractions[itineraryType.toLowerCase()] ?? [],
            referenceResolver(selectedReference),
        );

        setBarsDisplayData(itineraryBarData);
    }, [session, fractions, sessionLength]);

    return barsDisplayData;
};
