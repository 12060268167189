/* eslint-disable @typescript-eslint/no-shadow */
import styled from 'styled-components';
import { theme } from '../../../../style/theme';
import { Flex, Text } from '../../../../style';
import { Summary as BaseSummary } from '../styles';
import SelectableBlock from '../../../ui/blocks/SelectableBlock';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';

// FIXME later
// start - refactor with component located in BaseModal
export const Close = styled(CloseIcon)`
    cursor: pointer;
    height: 13px;
`;

// end

export const BulkSelectionButton = styled(Flex)`
    width: fit-content;
    font-size: ${({ theme }) => theme.font.size.medium};
`;

export const Summary = styled(BaseSummary)`
    margin: 5px 0;
    width: calc(100% - 40px);
`;

export const StyledFilterRubric = styled(SelectableBlock)`
    background-color: ${({ selected, theme }) => selected && theme.color.blue.medium};
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1 1 0;
    justify-content: space-between;
    height: 20px;
    padding: 10px;
    margin: 1px 10px 1px 0;
    vertical-align: middle;
    line-height: 20px;
    box-shadow: none;
    border: none;
    align-items: center;
    &:hover {
        box-shadow: none;
        background-color: ${({ selected, theme }) => (selected ? theme.color.blue.medium : theme.color.blue.light)};
    }
`;

export const StyledFilterReference = styled(SelectableBlock)`
    background-color: ${({ selected, theme }) => selected && theme.color.blue.medium};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 20px;
    padding: 10px;
    margin: 1px 10px 1px 20px;
    vertical-align: middle;
    line-height: 20px;
    box-shadow: none;
    border: none;
    align-items: center;
    color: ${({ selected, theme }) => (selected ? theme.color.white.main : theme.color.black.dark)};
    font-size: ${({ theme }) => theme.font.size.small};
    &:hover {
        box-shadow: none;
        background-color: ${({ selected, theme }) => (selected ? theme.color.blue.medium : theme.color.blue.light)};
    }
`;

export const FilterRubricTextContainer = styled(Flex).attrs({ justify: 'start' })``;

export const FilterRubricMainText = styled(Text).attrs({ bold: true, pointer: true })``;
export const FilterRubricExtraText = styled(FilterRubricMainText).attrs({ bold: false, margin: '0 0 0 10px' })``;

export const StyledFilters = styled.div`
    position: relative;
`;

export const FilterRubricOpen = styled.div`
    cursor: pointer;
    position: absolute;
    left: -1.25rem;
    padding: 5px;
`;

export const FilterContentContainer = styled.div`
    position: absolute;
    width: 300px;
    height: 500px;
    padding: 0 20px 20px;
    top: 40px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.white.main};
    border-radius: 15px;
    // FIXME later adjust this box shadow
    box-shadow: ${({ theme }) => theme.boxShadow.medium};
    z-index: 1;
`;

export const FilterContentHeaderContainer = styled(Flex).attrs({ justify: 'space-between', margin: '15px 0 10px' })``;
export const BulkSelectionButtonText = styled(Text).attrs({
    pointer: true,
    bold: true,
    color: theme.color.blue.primary,
    size: theme.font.size.regular,
})``;
export const SwitchText = styled(Text).attrs({ bold: true, size: theme.font.size.regular })``;

export const SwitchContainer = styled(Flex).attrs({ justify: 'start', height: '48px' })`
    background-color: ${({ theme }) => theme.color.blue.light};
`;
