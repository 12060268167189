import { createCommand } from '../../utils';
import { gradeReferenceSelectedOnMap, moduleName } from '../state';
import { userOrganizationQuery } from '../../authentication/queries';

export const SelectGradeReferenceForOrganizationCommand = createCommand(
    `${moduleName}/SELECT_GRADE_REFERENCE_FOR_ORGANIZATION`,
    async (_, { dispatch, extra, getState }) => {
        const userOrganization = userOrganizationQuery(getState());

        if (userOrganization) {
            const organizationSettings = await extra.mapViewSettings.getSettingsByOrganizationId(userOrganization.id);
            if (organizationSettings?.settings?.selectedGradeReference) {
                dispatch(gradeReferenceSelectedOnMap(organizationSettings.settings.selectedGradeReference));
            }
        }
    },
);
