import type { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState } from './store';
import type { Providers } from './core/utils';
import type { AnyAction } from 'redux';

export type AppDispatch = ThunkDispatch<RootState, Providers, AnyAction>;
export type AppThunk = ThunkAction<void, RootState, Providers, AnyAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type MapStyle = 'STREETS' | 'SATELLITE';

export type DeepPartial<T> = T extends object
    ? {
          [K in keyof T]?: DeepPartial<T[K]>;
      }
    : T;
