import { createCommand } from '../../utils';
import { moduleName, referenceToDisplayIdsUpdated } from '../state';
import { UpdateFiltersCommand } from './UpdateFiltersCommand';
import { uniq } from 'ramda';

export const AddReferencesToDisplayIdCommand = createCommand(
    `${moduleName}/ADD_REFERENCES_TO_DISPLAY_ID`,
    async (referenceIds: string[], { dispatch, extra }) => {
        const previouslyStoredReferenceIds: Array<string> = [];
        const referenceIdsFromProvider = extra.storageProvider.getReferenceIds();
        if (referenceIdsFromProvider) {
            previouslyStoredReferenceIds.push(...referenceIdsFromProvider);
        }

        const newStoredReferenceIds: Array<string> = uniq([...previouslyStoredReferenceIds, ...referenceIds]);

        extra.storageProvider.setReferenceIds(newStoredReferenceIds);
        dispatch(referenceToDisplayIdsUpdated({ referenceIds: newStoredReferenceIds }));

        dispatch(UpdateFiltersCommand());
    },
);
