import { Divider, Stack } from '@mui/material';
import type { FC } from 'react';
import { areSelectedSessionCalibratedQuery, selectedSessionIdsQuery } from '../../../core/sessions/queries';
import { useAppSelector } from '../../../types';
import { AnalyzeAIOnSelectedSessionsButton } from '../../design_system/inputs/buttons/AnalyzeAIOnSelectedSessionsButton';
import { CalibrateSelectedSessionsButton } from '../../design_system/inputs/buttons/CalibrateSelectedSessionsButton';
import { GoToMapViewButton } from '../../design_system/inputs/buttons/GoToMapViewButton';
import { UploadSessionsButton } from '../../design_system/inputs/buttons/UploadSessionsButton';
import SessionExportsMenu from './Menu/ExportsMenu';
import ComputeHomogeneousZones from '../../design_system/inputs/buttons/ComputeHomogeneousZones';
import EditCampaignButton from '../../design_system/inputs/buttons/EditCampaignButton';
import EditStateButton from '../../design_system/inputs/buttons/EditState';

export const ActionsOnSelectedSessions: FC = () => {
    const areSessionCalibrated = useAppSelector(areSelectedSessionCalibratedQuery);
    const sessionsIds = useAppSelector(selectedSessionIdsQuery);

    return (
        <Stack sx={{ flexDirection: 'row', justifyContent: 'flex-end', mb: 1.5 }}>
            {sessionsIds && sessionsIds.length > 0 && (
                <>
                    <Stack direction="row" gap={1}>
                        <CalibrateSelectedSessionsButton calibrated={areSessionCalibrated} sessionIds={sessionsIds} />
                        <AnalyzeAIOnSelectedSessionsButton calibrated={areSessionCalibrated} sessionIds={sessionsIds} />
                        <EditCampaignButton />
                        <EditStateButton />
                        <ComputeHomogeneousZones calibrated={areSessionCalibrated} />
                        <SessionExportsMenu sessionIds={sessionsIds} />
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                </>
            )}
            <UploadSessionsButton />
            <GoToMapViewButton />
        </Stack>
    );
};
