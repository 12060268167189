import { useCallback, useEffect, useState } from 'react';
import { analyzedSessionsIdQuery } from '../../../core/automatic_analysis/queries';
import type { Session } from '../../../core/domain/Session';
import { currentMapBoundsQuery, renderedSessionsIdsQuery } from '../../../core/networkMap/queries';
import { sessionsQuery } from '../../../core/sessions/queries';
import { useAppSelector } from '../../../types';

const useGetSortedSessionsByIaAnalyzed = (): Session[] | null => {
    const sessions = useAppSelector(sessionsQuery);
    const renderedSessionsIds = useAppSelector(renderedSessionsIdsQuery);
    const currentMapBounds = useAppSelector(currentMapBoundsQuery);

    const analyzedSessionsIds = useAppSelector(analyzedSessionsIdQuery);
    const [sortedSessions, setSortedSessions] = useState<Session[] | null>(null);

    const renderedSessionsOnly = useCallback((session: Session): boolean => renderedSessionsIds.includes(session.id), [
        renderedSessionsIds,
    ]);

    useEffect(() => {
        if (sessions.length > 0) {
            setSortedSessions(
                sessions
                    .filter(renderedSessionsOnly)
                    .map((session) => {
                        const s = { ...session };
                        if (analyzedSessionsIds.includes(s.id)) {
                            s.processingIaStatus = 'DONE';
                        }

                        return s;
                    })
                    .sort((a, b) => Number(b.processingIaStatus === 'DONE') - Number(a.processingIaStatus === 'DONE')),
            );
        }
    }, [sessions, analyzedSessionsIds, currentMapBounds, renderedSessionsIds]);

    return sortedSessions;
};

export default useGetSortedSessionsByIaAnalyzed;
