import { fetchInterceptors } from './core/legacy/utils/http';
import { loadConfigFromRootDataset } from './config';
import { initSentry } from './providers/sentryProvider';
import { initGoogleAnalytics } from './providers/googleAnalyticsProvider';
import { buildUi } from './ui';
import { initI18n } from './providers/i18nProvider';
import { createStore } from './store';
import ReactGA from 'react-ga';
import apiEndpoint from './core/legacy/utils/api';
import { ExternalAiAnalysisProvider } from './providers/ExternalAiAnalysisProvider';
import { ExternalAuthenticationProvider } from './providers/ExternalAuthenticationProvider';
import { ExternalSessionsProvider } from './providers/ExternalSessionsProvider';
import { ExternalConfigurationProvider } from './providers/ExternalConfigurationProvider';
import { IntervalProvider } from './providers/IntervalProvider';
import { TimeProvider } from './providers/TimeProvider';
import { DownloadProvider } from './providers/DownloadProvider';
import { SerializableFileProvider } from './providers/SerializableFileProvider';
import { ExternalUploadProvider } from './providers/externalUploadProvider';
import { StorageProvider } from './providers/StorageProvider';
import { ExternalCatalogsProvider } from './providers/ExternalCatalogsProvider';
import { ExternalRoadDatasProvider } from './providers/ExternalRoadDatasProvider';
import { ExternalNetworkMapProvider } from './providers/ExternalNetworkMapProvider';
import MapViewSettingsProvider from './providers/MapViewSettingsProvider';
import { ExternalTagsProvider } from './providers/ExternalTagsProvider';
import { ExternalOrganizationProvider } from './providers/ExternalOrganizationProvider';

// Config
const config = loadConfigFromRootDataset();
const baseEndpoint = apiEndpoint!;

// Providers
const isProduction = initSentry(config.sentryDsn, config.sentryEnvironment);
initGoogleAnalytics(config.googleAnalyticsTag);
initI18n(isProduction);
fetchInterceptors.register();

// Store
const { store, history } = createStore({
    externalAuthentication: new ExternalAuthenticationProvider(baseEndpoint),
    externalAiAnalysis: new ExternalAiAnalysisProvider(baseEndpoint),
    externalSessions: new ExternalSessionsProvider(baseEndpoint),
    externalSessionConfiguration: new ExternalConfigurationProvider(),
    externalInterval: new IntervalProvider(),
    externalUpload: new ExternalUploadProvider(baseEndpoint),
    time: new TimeProvider(),
    download: new DownloadProvider(),
    serializableFileProvider: new SerializableFileProvider(),
    storageProvider: new StorageProvider(),
    externalCatalogs: new ExternalCatalogsProvider(baseEndpoint),
    externalRoadDatas: new ExternalRoadDatasProvider(baseEndpoint),
    externalNetworkMap: new ExternalNetworkMapProvider(baseEndpoint),
    mapViewSettings: new MapViewSettingsProvider(),
    externalTags: new ExternalTagsProvider(baseEndpoint),
    externalOrganization: new ExternalOrganizationProvider(baseEndpoint),
});
history.listen((update) => ReactGA.pageview(update.location.pathname));

// Ui
buildUi(isProduction, store, history);
