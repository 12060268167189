import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../types';
import { aiAnalysisAllowedQuery } from '../../../../core/authentication/queries';
import { FC, useCallback } from 'react';
import { AnalysisIcon } from '../../icons/AnalysisIcon';
import { StartAiAnalysisWorkflowOnSessionsCommand } from '../../../../core/automatic_analysis/StartAiAnalysisWorkflowOnSessionsCommand';
import BaseButton from './BaseButton';

interface AnalyzeAIOnSelectedSessionsButtonProps {
    calibrated: boolean;
    sessionIds: string[];
}

export const AnalyzeAIOnSelectedSessionsButton: FC<AnalyzeAIOnSelectedSessionsButtonProps> = ({
    calibrated,
    sessionIds,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const aiAnalysisAllowed = useAppSelector(aiAnalysisAllowedQuery);

    const handleClick = useCallback(() => {
        dispatch(StartAiAnalysisWorkflowOnSessionsCommand(sessionIds));
    }, [dispatch, sessionIds]);

    if (!aiAnalysisAllowed || !calibrated) {
        return null;
    }

    return (
        <BaseButton onClick={handleClick} variant="secondary">
            <AnalysisIcon />
            {t('labels.ai_analyze')}
        </BaseButton>
    );
};
