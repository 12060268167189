import { TablePaginationProps, Pagination as MuiPagination } from '@mui/material';
import { GridPagination, gridPageCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';

const Pagination = ({
    page,
    onPageChange,
    className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <MuiPagination
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event as any, newPage - 1);
            }}
        />
    );
};

const CustomPagination = (props: any) => <GridPagination ActionsComponent={Pagination} {...props} />;

export default CustomPagination;
