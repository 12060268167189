import { FC, useCallback } from 'react';
import { translateReference } from '../../../../../core/legacy/utils/translations';
import type { Reference, RoadData } from '../../../../../core/domain/Roaddata';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import EditableSelect from '../../../../components/streetView/roadDatasPanel/EditableSelect';
import { sortedReferencesByRubricIdQuery } from '../../../../../core/catalogs/queries';
import { updateRoadDataReference } from '../../../../../core/actions/streetView';

type Props = {
    attributeName?: string;
    reference: Reference | null;
    roadData: RoadData;
};

export const SubClassComp: FC<Props> = ({ attributeName, reference, roadData }) => {
    const dispatch = useAppDispatch();
    const references = useAppSelector(sortedReferencesByRubricIdQuery(roadData.rubricId));
    const findReference = useCallback((id?: string) => references.find((option) => option.id === id), [references]);
    const currentReference = findReference(roadData.reference?.id);

    const save = useCallback(
        async (refId: string) => {
            await dispatch(updateRoadDataReference(roadData, findReference(refId)));
        },
        [roadData, dispatch],
    );

    return (
        <EditableSelect
            attributeName={attributeName}
            initialValue={currentReference?.id || ''}
            onSave={save}
            options={references.map((ref) => ({ label: translateReference(ref), value: ref.id }))}
            roadDataId={roadData.id}
            title={{ value: translateReference(reference ?? undefined) }}
        />
    );
};
