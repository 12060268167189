import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import langFr from './translations/fr.json';

export const initI18n = (isProduction: boolean): void => {
    i18n
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            lng: 'fr',
            debug: false,
            resources: {
                fr: { translation: langFr },
            },
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            saveMissing: true,
            missingKeyHandler: (lng, ns, key, fallbackValue) => {
                if (isProduction) {
                    console.warn(lng, ns, key, fallbackValue);
                }
            },
        });
};
