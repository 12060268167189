/* eslint-disable @typescript-eslint/no-unused-vars */
import { createCommand } from '../../utils';
import { moduleName, sessionToggledOnMap, SessionShownOnMap } from '../state';

export type ToggleSessionProps = SessionShownOnMap;

export const ToggleSessionOnMapCommand = createCommand(
    `${moduleName}/TOGGLE_SESSION`,
    async (props: ToggleSessionProps, { dispatch }) => {
        dispatch(sessionToggledOnMap(props));
    },
);
