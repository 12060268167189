const initialState = {
    calibrationProcess: {
        id: undefined,
        calibrationPoints: [],
        image: { index: undefined, cumul: undefined, session: undefined },
    },
    result: {
        errorRates: undefined,
        parameters: undefined,
    },
    calibrationPointInEditionId: undefined,
    selectedCalibrationPointId: undefined,
    hoveredCalibrationPointId: undefined,
    calibrationProcessResultWasExported: false,
    drawingToolActivated: true,
    displayCalibrationWelcomePanel: true,
    uploadFilesSelected: [],
};

const getProcessWithSortedPoints = (calibrationProcess) => {
    const newCalibrationProcess = { ...calibrationProcess };
    const { calibrationPoints } = newCalibrationProcess;
    calibrationPoints.sort((point1, point2) => point1.createdAt - point2.createdAt);
    newCalibrationProcess.calibrationPoints = [...calibrationPoints];

    return newCalibrationProcess;
};

const calibrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CALIBRATION/UPLOAD-FILES-SELECTED':
            return {
                ...state,
                uploadFilesSelected: action.payload,
            };
        case 'CALIBRATION/INIT_CALIBRATION_PROCESS':
            return {
                ...initialState,
                calibrationProcess: getProcessWithSortedPoints(action.calibrationProcess),
            };
        case 'CALIBRATION/RESET_CALIBRATION_PROCESS':
            return {
                ...initialState,
            };
        case 'CALIBRATION/CLOSE_WELCOME_CALIBRATION_PANEL':
            return {
                ...state,
                displayCalibrationWelcomePanel: false,
            };
        case 'CALIBRATION/SELECT_CALIBRATION_POINT_IN_EDITION':
            return {
                ...state,
                calibrationPointInEditionId: action.calibrationPointId,
            };
        case 'CALIBRATION/NO_MORE_CALIBRATION_POINT_IN_EDITION':
            return {
                ...state,
                calibrationPointInEditionId: undefined,
            };
        case 'CALIBRATION/SELECT_HOVERED_CALIBRATION_POINT':
            return {
                ...state,
                hoveredCalibrationPointId: action.calibrationPointId,
            };
        case 'CALIBRATION/NO_MORE_HOVERED_CALIBRATION_POINT':
            return {
                ...state,
                hoveredCalibrationPointId: undefined,
            };
        case 'CALIBRATION/SELECT_SELECTED_CALIBRATION_POINT':
            return {
                ...state,
                selectedCalibrationPointId: action.calibrationPointId,
            };
        case 'CALIBRATION/NO_MORE_SELECTED_CALIBRATION_POINT':
            return {
                ...state,
                selectedCalibrationPointId: undefined,
            };
        case 'CALIBRATION/DELETE_CALIBRATION_POINT':
            return {
                ...state,
                calibrationProcess: {
                    ...state.calibrationProcess,
                    calibrationPoints: state.calibrationProcess.calibrationPoints.filter(
                        (cp) => cp.id !== action.calibrationPointId,
                    ),
                },
                selectedCalibrationPointId: undefined,
                hoveredCalibrationPointId: undefined,
            };
        case 'CALIBRATION/UPDATE_CALIBRATION_PROCESS': {
            return {
                ...state,
                calibrationProcess: getProcessWithSortedPoints(action.newCalibrationProcess),
            };
        }
        case 'CALIBRATION/UPDATE_CALIBRATION_PROCESS_RESULT': {
            return {
                ...state,
                result: action.result,
            };
        }
        case 'CALIBRATION/SET_CALIBRATION_PROCESS_RESULT_WAS_EXPORTED': {
            return {
                ...state,
                calibrationProcessResultWasExported: action.wasExported,
            };
        }
        case 'CALIBRATION/ACTIVATE_DRAWING_TOOL': {
            return {
                ...state,
                drawingToolActivated: true,
            };
        }
        case 'CALIBRATION/DESACTIVATE_DRAWING_TOOL': {
            return {
                ...state,
                drawingToolActivated: false,
            };
        }

        default:
            return state;
    }
};

export default calibrationReducer;
