import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';
import FailureModal from '../../../../legacy/components/ui/modals/FailureModal';

export const ExportNoAnnotationModal: FC = () => {
    const { t } = useTranslation();

    return (
        <FailureModal
            title={t('exportSession.noAnnotation.title')}
            subtitle={t('exportSession.noAnnotation.subtitle')}
            modalType={SessionsModalTypes.SESSIONS_EXPORT_NO_ANNOTATION}
        />
    );
};
