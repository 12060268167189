import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import type { Session, SessionId } from '../../../../../core/domain/Session';
import { useTranslation } from 'react-i18next';
import FailureModal from '../../../../legacy/components/ui/modals/FailureModal';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { homogeneousZonesFailedComputing } from '../../../../../core/sessions/state';
import { sessionsFailedZoneComputingPropertiesQuery } from '../../../../../core/sessions/queries';

export type ComputingZoneError = Partial<Session> & { id: SessionId };

const ComputeZonesModalFailure: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const failedComputings = useAppSelector(sessionsFailedZoneComputingPropertiesQuery());

    const onClose = () => {
        dispatch(homogeneousZonesFailedComputing([]));
    };

    return (
        <FailureModal
            modalType={SessionsModalTypes.COMPUTE_ZONES_FAILURE}
            subtitle={t('homogeneousZones.errors.subtitle')}
            title={t('homogeneousZones.errors.title')}
            onCancel={onClose}
            onValidate={onClose}
        >
            <Box component="ul">
                {failedComputings.map(({ id, name }) => (
                    <Typography component="li" key={id}>
                        {name}
                    </Typography>
                ))}
            </Box>
        </FailureModal>
    );
};

export default ComputeZonesModalFailure;
