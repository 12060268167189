import { Chip, ChipProps } from '@mui/material';
import type { FC } from 'react';

type SmallChipProps = Omit<ChipProps, 'size'>;

const SmallChip: FC<SmallChipProps> = ({ sx, ...chipProps }) => (
    <Chip {...chipProps} size="small" sx={{ cursor: 'inherit', fontSize: 12, height: 'auto', py: 0.5, ...sx }} />
);

export { SmallChip };
