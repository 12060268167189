const initialState = {
    catalogs: [],
    selectedCatalog: null,
    selectedAttribute: undefined,
};

const annotationReducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case 'SET_CATALOGS':
            return {
                ...state,
                catalogs: [...payload.catalogs],
            };
        case 'SET_SELECTED_CATALOG':
            return {
                ...state,
                selectedCatalog: payload.catalog,
            };
        case 'SELECT_ANNOTATION_ATTRIBUTE': {
            return {
                ...state,
                selectedAttribute: payload.attribute,
            };
        }
        case 'UNSELECT_ANNOTATION_ATTRIBUTE': {
            return {
                ...state,
                selectedAttribute: undefined,
            };
        }
        case 'CATALOGS/SELECTION_RESET': {
            return {
                ...state,
                selectedCatalog: null,
                selectedAttribute: undefined,
            };
        }
        default:
            return state;
    }
};

export default annotationReducer;
