import { ReactComponent as CalibrateIcon } from '../../../../assets/icons/calibrate.svg';
import { useTranslation } from 'react-i18next';
import BaseButton from './BaseButton';
import { useAppDispatch } from '../../../../types';
import { setModalToDisplay } from '../../../../core/legacy/actions/modals';
import { SessionsModalTypes } from '../../../../core/legacy/reducers/modals';
import type { FC } from 'react';

interface ComputeHomogeneousZonesProps {
    calibrated: boolean;
}

const ComputeHomogeneousZones: FC<ComputeHomogeneousZonesProps> = ({ calibrated }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    if (!calibrated) return null;

    return (
        <BaseButton onClick={() => dispatch(setModalToDisplay(SessionsModalTypes.COMPUTE_ZONES))} variant="secondary">
            <CalibrateIcon />
            {t('session.compute_zones')}
        </BaseButton>
    );
};

export default ComputeHomogeneousZones;
