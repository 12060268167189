import { useTranslation } from 'react-i18next';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';
import CalibrateSessionForm from './CalibrateSessionForm';
import GenericModal from '../../../../legacy/components/ui/modals/GenericModal';
import { theme } from '../../../../legacy/style/theme';
import { ContentContainer, ModalTitle } from '../../../../legacy/components/ui/modals/styles';
import type { FC } from 'react';
import { useAppSelector } from '../../../../../types';
import { targettedSessionIdsForInteractionQuery } from '../../../../../core/sessions/queries';

export const CalibrateSessionModal: FC = () => {
    const { t } = useTranslation();
    const targetedSessionIds = useAppSelector(targettedSessionIdsForInteractionQuery);

    return (
        <GenericModal
            options={{ hideDefaultButtons: true, backgroundColor: theme.color.blue.grey }}
            modalType={SessionsModalTypes.SESSIONS_CALIBRATE_SESSION}
        >
            <ContentContainer>
                <ModalTitle>{t('calibrateSession.title', { count: targetedSessionIds.length })}</ModalTitle>
                <CalibrateSessionForm />
            </ContentContainer>
        </GenericModal>
    );
};
