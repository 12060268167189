import PropTypes from 'prop-types';
import CalibrationPointTypeEnum from '../../../../core/legacy/utils/CalibrationPointTypeEnum';

export const Landmark = PropTypes.shape({
    abd: PropTypes.string,
    sign: PropTypes.string,
    label: PropTypes.string,
});

export const CalibrationPointAttribute = PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isEditable: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(Object.keys(CalibrationPointTypeEnum)),
    unit: PropTypes.string,
});

export const CalibrationPoint = PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.number,
    attributes: PropTypes.arrayOf(CalibrationPointAttribute),
});

export const Position = PropTypes.shape({
    start: PropTypes.arrayOf(PropTypes.string),
    end: PropTypes.arrayOf(PropTypes.string),
});
