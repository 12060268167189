import type { Reference } from '../../domain/Roaddata';
import type { RubricId } from '../../domain/Session';
import { createCommand } from '../../utils';
import { moduleName, selectedReferenceByRubricIdsUpdated } from '../state';

export type SelectedReferenceByRubricId = { rubricId: RubricId; reference: Reference };

export const SelectReferenceByRubricIdCommand = createCommand(
    `${moduleName}/SELECT_REFERENCE_BY_RUBRIC_ID`,
    async (referenceByRubricId: SelectedReferenceByRubricId, { dispatch }) => {
        dispatch(selectedReferenceByRubricIdsUpdated(referenceByRubricId));
    },
);
