import { Typography } from '@mui/material';
import { FC, MouseEvent, ReactNode, useCallback } from 'react';
import { theme } from '../../../legacy/style/theme';

type Props = {
    onClick?: () => void;
    children: ReactNode;
    variant: 'primary' | 'secondary';
};

export const ButtonLink: FC<Props> = ({ onClick, children, variant }) => {
    const handleClick = useCallback(
        (event: MouseEvent<HTMLSpanElement>) => {
            if (onClick) {
                event.stopPropagation();
                onClick();
            }
        },
        [onClick],
    );

    return (
        <Typography
            color={variant === 'primary' ? theme.color.blue.primary3 : theme.color.black.main}
            fontSize={theme.font.size.small}
            sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
            }}
            onClick={handleClick}
        >
            {children}
        </Typography>
    );
};
