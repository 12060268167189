import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import type { ReferenceKey } from '../domain/Roaddata';
import type { Session, SessionId } from '../domain/Session';
import type { ComputingZoneError } from '../../ui/session/list-sessions/Modals/ComputeZones/ZonesErrors';

const slice = (state: RootState) => state.sessions;

// Session list
export const loadingSessionsQuery = createSelector(slice, (state) => state.resetSessionsOngoing);
export const sessionsQuery = createSelector(slice, (state) => state.sessions);
export const sessionsIdsQuery = createSelector(slice, (state) => state.sessions.flatMap((session) => session.id));
export const sessionByIdQuery = (sessionId: SessionId) =>
    createSelector(slice, (state) => state.sessions.find((session) => session.id === sessionId));

// Selection
export const selectedSessionsQuery = createSelector(slice, (state) =>
    state.sessions.filter((session) => state.selectedSessionIds.includes(session.id)),
);
export const selectedSessionIdsQuery = (state) => selectedSessionsQuery(state).map((session) => session.id);
export const selectedSessionsCountQuery = (state) => selectedSessionsQuery(state).length;
export const areSelectedSessionCalibratedQuery = (state) =>
    selectedSessionsQuery(state).every((session) => session.calibrationDone);

// Sorting
export const sessionSortQuery = createSelector(slice, (state) => state.sort);

// Filtering
export const sessionFilterQuery = createSelector(slice, (state) => state.filter);

// Pagination
export const sessionPaginationQuery = createSelector(slice, (state) => state.pagination);

// Targetted sessions
export const targettedSessionIdsForInteractionQuery: (state: RootState) => Array<string> = createSelector(
    slice,
    (state) => state.sessionIdsTargettedForInteraction,
);
export const targettedSessionsForInteractionQuery = (state): Array<Session> =>
    targettedSessionIdsForInteractionQuery(state)
        .map((id) => sessionByIdQuery(id)(state))
        .filter((s) => Boolean(s))
        .map((s) => s!);
export const areTargettedSessionCalibratedQuery = (state) =>
    targettedSessionsForInteractionQuery(state).every((session) => session.calibrationDone);

// Last opened
export const lastOpenedSessionIdQuery = createSelector(slice, (state) => state.lastOpenedSessionId);

// itinerary
export const itinerariesBySessionsIdsQuery = createSelector(slice, (state) => state.itinerariesBySessionIds);
export const itineraryBySessionIdQuery = (sessionId?: SessionId) =>
    createSelector(slice, (state) => {
        if (!sessionId) {
            return null;
        }

        return state.itinerariesBySessionIds[sessionId];
    });

export const itineraryBySessionIdForReferenceQuery = (reference: ReferenceKey, sessionId: SessionId) =>
    createSelector(slice, (state) => {
        const itinerariesBySessionId = state.itinerariesBySessionIds[sessionId];
        if (!itinerariesBySessionId) {
            return undefined;
        }
        const itineraryByReference = itinerariesBySessionId.fractions[reference];

        return itineraryByReference;
    });

export const landmarksForSessionQuery = (sessionId: SessionId) =>
    createSelector(slice, (state) => {
        const itinerariesBySessionId = state.itinerariesBySessionIds[sessionId];
        if (!itinerariesBySessionId) {
            return undefined;
        }

        return itinerariesBySessionId.landmarks;
    });

export const sessionTimelineLengthQuery = (sessionId: SessionId) =>
    createSelector(slice, (state) => {
        const itinerariesBySessionId = state.itinerariesBySessionIds[sessionId];
        if (!itinerariesBySessionId) {
            return undefined;
        }

        return itinerariesBySessionId.length;
    });

export const sessionsFailedZoneComputingQuery = createSelector(slice, (state) => state.homogeneousZonesErrors);
export const sessionsFailedZoneComputingPropertiesQuery = (properties: (keyof Session)[] = ['name']) =>
    createSelector(slice, (state) =>
        state.homogeneousZonesErrors.map(
            (id): ComputingZoneError => {
                const session = state.sessions.find((s) => s.id === id) as Session;

                return properties.reduce((obj, property) => ({ ...obj, [property]: session[property] }), {
                    id: session.id,
                });
            },
        ),
    );

export const sessionDefaultHomogeneousZoneParamsQuery = () =>
    createSelector(slice, (state) => state.defaultHomogeneousZoneParams);
