import { Box } from '@mui/material';
import { ItineraryType, useItinerary } from './use-itinerary';

type ItinerarySchemaProps = {
    sessionLength: number;
    type: ItineraryType;
};

export const ItinerarySchema = ({ sessionLength, type }: ItinerarySchemaProps) => {
    const data = useItinerary(type, sessionLength);

    return (
        <Box sx={{ height: 10, overflow: 'hidden' }}>
            <svg width="100%" viewBox={`0 0 ${sessionLength} ${sessionLength}`}>
                {data.map(({ color, id, left, width }, i) => (
                    <rect z={i} fill={color} x={left} height="100%" width={width} key={`itinerary_${type}_${id}`} />
                ))}
            </svg>
        </Box>
    );
};
