import { createCommand } from '../../utils';
import { executionStatusesRefreshed, moduleName } from '../state';

export const RefreshNetworkMapExecutionCommand = createCommand(
    `${moduleName}/REFRESH_NETWORK_MAP_EXECUTIONS`,
    async (_, { dispatch, extra }) => {
        const statuses = await extra.externalNetworkMap.refreshExecutionStatuses();
        dispatch(executionStatusesRefreshed(statuses));
    },
);
