import { useCallback } from 'react';
import type { Session } from '../../../../core/domain/Session';
import { ZoomOnSessionCommand } from '../../../../core/networkMap/commands/ZoomOnSessionCommand';
import { useAppDispatch } from '../../../../types';

const useZoomOnSession = (session: Session): (() => void) => {
    const dispatch = useAppDispatch();

    return useCallback((): void => {
        dispatch(ZoomOnSessionCommand(session));
    }, [session]);
};

export default useZoomOnSession;
