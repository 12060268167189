import { push } from 'redux-first-history';
import type { SessionId } from '../../domain/Session';
import { sessionIdQuery } from '../../streetViewer/queries';
import { createCommand } from '../../utils';
import { moduleName, lastOpenedSessionIdIsSet } from '../state';

const getStreetviewUrl = (sessionId: string, cumul: number = 0): string => {
    let route = `/dashboard/acquisition/street-view/${sessionId}`;
    route += `?cumul=${cumul}`;

    return route;
};

export type Args = {
    sessionId: SessionId;
    cumul?: number;
};

export const NavigateToSessionStreetviewCommand = createCommand(
    `${moduleName}/NAVIGATE_TO_SESSION_STREET_VIEW_ON_SPECIFIC_CUMUL`,
    async ({ sessionId, cumul }: Args, { dispatch, getState }) => {
        const currentSessionIdInStreetview = sessionIdQuery(getState());
        if (!currentSessionIdInStreetview || currentSessionIdInStreetview !== sessionId) {
            await dispatch(lastOpenedSessionIdIsSet(sessionId));
        }
        const route = getStreetviewUrl(sessionId, cumul);
        await dispatch(push(route));
    },
);
