import { Trans, useTranslation } from 'react-i18next';
import { Container, H1 } from './styles';
import InitPasswordForm from '../../components/forms/InitPasswordForm';
import { BlueContainer, Text } from '../../style';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../types';
import { userQuery } from '../../../../core/authentication/queries';

const PasswordInit = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const user = useAppSelector(userQuery);
    const name = `${user?.firstName} ${user?.lastName}`;

    return (
        <BlueContainer>
            <H1>
                <Trans components={{ span: <span /> }} i18nKey="init-password.title" values={{ fullName: name }} />
            </H1>
            <span>{t('init-password.text')}</span>
            <Text>{t('init-password.rule')}</Text>
            <Container>
                <InitPasswordForm navigate={navigate} />
            </Container>
        </BlueContainer>
    );
};

export default PasswordInit;
