import apiEndpoint from '../utils/api';

export const PrecisionLevelEnum = Object.freeze({
    EXCELLENT: 'EXCELLENT',
    POOR: 'POOR',
});

export const uploadHasFileSelectedSelector = (state) => state.calibration.uploadFilesSelected.length > 0;

export const calibrationProcessIdSelector = (state) => state.calibration.calibrationProcess.id;

export const calibrationPointsSelector = (state) => state.calibration.calibrationProcess.calibrationPoints;

export const calibrationPointByIdSelector = (id) => (state) => {
    return calibrationPointsSelector(state).find((cp) => cp.id === id);
};

export const selectedCalibrationPointIdSelector = (state) => state.calibration.selectedCalibrationPointId;
export const selectedCalibrationPointSelector = (state) => {
    const selectedCpId = selectedCalibrationPointIdSelector(state);
    const calibrationPoints = calibrationPointsSelector(state);

    return calibrationPoints.find((cp) => cp.id === selectedCpId);
};

export const hoveredCalibrationPointIdSelector = (state) => state.calibration.hoveredCalibrationPointId;

export const calibrationPointInEditionIdSelector = (state) => state.calibration.calibrationPointInEditionId;
export const calibrationPointInEditionSelector = (state) => {
    const calibrationPointId = calibrationPointInEditionIdSelector(state);
    const calibrationPoints = calibrationPointsSelector(state);

    return calibrationPoints.find((cp) => cp.id === calibrationPointId);
};

export const calibrationSessionSelector = (state) => state.calibration.calibrationProcess.image?.session;

export const calibrationImageUrlSelector = (state) => {
    const { session, index } = state.calibration.calibrationProcess.image;

    return `${apiEndpoint}/sessions/${session.id}/images/${index}.jpeg`;
};

export const getCalibrationErrorRatesSelector = (state) => {
    return state.calibration.result.errorRates;
};

export const getCalibrationPrecisionLevelSelector = (state) => {
    const errorRates = getCalibrationErrorRatesSelector(state);
    if (errorRates === undefined) {
        return undefined;
    }
    if (errorRates.meters < 5) {
        return PrecisionLevelEnum.EXCELLENT;
    }

    return PrecisionLevelEnum.POOR;
};

export const getCalibrationImageSelector = (state) => state.calibration.calibrationProcess.image;

export const isCalibrationReadyToLeaveSelector = (state) => {
    const { calibrationPoints } = state.calibration.calibrationProcess;
    const { calibrationProcessResultWasExported } = state.calibration;

    return calibrationPoints.length === 0 || calibrationProcessResultWasExported;
};

export const isDrawingToolActivatedSelector = (state) => state.calibration.drawingToolActivated;
export const displayCalibrationWelcomePanelSelector = (state) => state.calibration.displayCalibrationWelcomePanel;
