import React, { forwardRef } from 'react';
import { CategoriesRow } from './styles';
import Folder from '../Folder';
import Rubric from '../Rubric';
import { useAppSelector } from '../../../../../types';
import { translateRubric } from '../../../../../core/legacy/utils/translations';

const CatalogTree = forwardRef(() => {
    const selectedCatalog = useAppSelector(({ annotation }) => annotation.selectedCatalog);

    const alphabeticalSort = (rubric1, rubric2) =>
        translateRubric(rubric1.name).localeCompare(translateRubric(rubric2.name));

    if (!selectedCatalog) {
        return null;
    }

    const { rubrics, folders } = selectedCatalog;

    return (
        <CategoriesRow>
            {[...rubrics].sort(alphabeticalSort).map((rubric) => (
                <Rubric key={rubric.id} rubric={rubric} />
            ))}
            {[...folders].map((folder) => (
                <Folder key={folder.id} name={folder.name}>
                    {folder?.rubrics?.sort(alphabeticalSort).map((rubric) => (
                        <Rubric key={rubric.id} rubric={rubric} />
                    ))}
                </Folder>
            ))}
        </CategoriesRow>
    );
});

export default CatalogTree;
