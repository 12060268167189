// TODO reimplement with Generic Modal system (cf SessionsList)

import styled from 'styled-components';
import { theme } from '../../../../style/theme';
import PropTypes from 'prop-types';
import ResultModal from '../../../ui/modals/ResultModal';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../style';

const Headline = styled(Text)`
    font-size: ${theme.font.size.medium2};
    margin-bottom: 10px;
`;
const BodyContent = styled.p`
    line-height: 1.3;
`;
const Body = styled(Text)`
    font-size: ${theme.font.size.regular};
    display: inline;
`;

const GenerateCalibrationSuccessModal = forwardRef(({ onValidate }, ref) => {
    const { t } = useTranslation();

    return (
        <ResultModal
            onValidate={onValidate}
            validateButtonText={t('calibration.modals.generate.success.validate')}
            ref={ref}
        >
            <Headline>{t('calibration.modals.generate.success.headline')}</Headline>
            <BodyContent>
                <Body>{t('calibration.modals.generate.success.body')}</Body>
            </BodyContent>
        </ResultModal>
    );
});

GenerateCalibrationSuccessModal.propTypes = {
    onValidate: PropTypes.func.isRequired,
};

export default GenerateCalibrationSuccessModal;
