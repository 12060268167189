import { createCommand } from '../utils';
import { logFailed, loggedIn, logOngoing, moduleName } from './state';
import { push } from 'redux-first-history';
import { NavigateToSessionsCommand } from '../sessions/commands/NavigateToSessionsCommand';
import { organizationInitialized } from '../organization/state';

export const LoginCommand = createCommand(
    `${moduleName}/LOG_IN`,
    async ({ email, password }: { email: string; password: string }, { dispatch, extra }) => {
        try {
            dispatch(logOngoing());
            const user = await extra.externalAuthentication.login(email, password);
            const organization = await extra.externalOrganization.loadOrganization(user.organization.id);
            dispatch(loggedIn(user));
            dispatch(organizationInitialized(organization));
            if (user.isActive) {
                await dispatch(NavigateToSessionsCommand());
            } else {
                dispatch(push('/init-password'));
            }
        } catch (err: any) {
            dispatch(logFailed(err?.message));
        }
    },
);
