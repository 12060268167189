import type { FC, ReactNode } from 'react';
import { RegularText } from '../../texts';

type Props = {
    children: ReactNode;
};

export const BaseMenuItemText: FC<Props> = ({ children }) => {
    return (
        <RegularText lineHeight="20px" color="inherit">
            {children}
        </RegularText>
    );
};
