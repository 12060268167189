import type { SessionItineraryFractions } from '../../../../../../core/domain/Itinerary';
import type { SessionId } from '../../../../../../core/domain/Session';
import { selectedGradeReferenceQuery } from '../../../../../../core/networkMap/queries';
import { itineraryBySessionIdForReferenceQuery } from '../../../../../../core/sessions/queries';
import { useAppSelector } from '../../../../../../types';
import referenceResolver from '../../../../../../core/legacy/utils/referenceResolver';

export const useFractions = (sessionId?: SessionId): SessionItineraryFractions | undefined => {
    if (!sessionId) {
        return { elementary: [] };
    }
    const selectedGradeReference = useAppSelector(selectedGradeReferenceQuery) ?? 'AGGREGATE';

    const resolvedReference = referenceResolver(selectedGradeReference);
    const fractions = useAppSelector(itineraryBySessionIdForReferenceQuery(resolvedReference, sessionId));

    return fractions;
};
