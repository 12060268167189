class TranslationService {
    /** Pre-computed high-precision Math.sqrt(3) */
    SQRT3 = 1.732050807568877293527446341505872366943;

    /** Compute the cube root of the input parameter */
    // eslint-disable-next-line class-methods-use-this
    computeCubeRoot(x) {
        if (x === 0) {
            return 0;
        }

        if (x > 0) {
            return x ** (1 / 3);
        }

        return -((-x) ** (1 / 3));
    }

    /** Collection of image- and session-specific calibration data. */
    calibrationParameters = {};

    /** Collection of unnamed, private, temporary values used in the translation formulae. */
    innerParameters = {
        r1: 0,
        r2: 0,
        r3: 0,
        r4: 0,
        r5: 0,
        r6: 0,
        r7: 0,
        r8: 0,
        r9: 0,
    };

    DEFAULT_CALIBRATION_CONSTANTS = {
        ncx: 1280.0,
        nfx: 1440.0,
        dx: 0.00443,
        dy: 0.00443,
        dpx: 0.0039377778,
        dpy: 0.00443,
        sx: 1.0,
        txVC: 0.0,
        tyVC: 0.0,
        rVC: 0.0,
        rx: 0,
        ry: 0,
        rz: 0,
        tx: 0,
        ty: 0,
        tz: 0,
    };

    /** Create a translation service with the specified parameters, and optional constants (which can also be set at a later time using `calibrate`). */
    constructor(parameters) {
        if (parameters) {
            this.calibrationParameters = { ...this.DEFAULT_CALIBRATION_CONSTANTS, ...parameters };
            this.computeInnerParameters();
        }
    }

    /** Re-calibrate the translation service with updated parameters. */
    calibrate(parameters) {
        this.calibrationParameters = { ...this.calibrationParameters, ...parameters };
        this.computeInnerParameters();
    }

    /** Perform a one-time calculation of the `r1`, `r2`, ..., `r9` values. */
    computeInnerParameters() {
        const sa = Math.sin(this.calibrationParameters.rx);
        const ca = Math.cos(this.calibrationParameters.rx);
        const sb = Math.sin(this.calibrationParameters.ry);
        const cb = Math.cos(this.calibrationParameters.ry);
        const sg = Math.sin(this.calibrationParameters.rz);
        const cg = Math.cos(this.calibrationParameters.rz);

        this.innerParameters.r1 = cb * cg;
        this.innerParameters.r2 = cg * sa * sb - ca * sg;
        this.innerParameters.r3 = sa * sg + ca * cg * sb;
        this.innerParameters.r4 = cb * sg;
        this.innerParameters.r5 = sa * sb * sg + ca * cg;
        this.innerParameters.r6 = ca * sb * sg - cg * sa;
        this.innerParameters.r7 = -sb;
        this.innerParameters.r8 = cb * sa;
        this.innerParameters.r9 = ca * cb;
    }

    /** Convert from distorted to undistorted sensor plane coordinates. */
    distortedToUndistortedSensorCoord({ x, y }) {
        const distortionFactor = 1 + this.calibrationParameters.distortion * (x ** 2 + y ** 2);
        const xu = x * distortionFactor;
        const yu = y * distortionFactor;

        return { x: xu, y: yu };
    }

    /** Convert from undistorted to distorted sensor plane coordinates. */
    undistortedToDistortedSensorCoord({ x, y }) {
        if ((x === 0 && y === 0) || this.calibrationParameters.distortion === 0) {
            return { x, y };
        }

        const Ru = Math.hypot(x, y);
        const c = 1 / this.calibrationParameters.distortion;
        const d = -c * Ru;
        const Q = c / 3;
        const R = -d / 2;
        let D = Q ** 3 + R ** 2;

        let Rd;

        if (D >= 0) {
            // Found one real root
            D **= 2;
            const S = this.computeCubeRoot(R + D);
            const T = this.computeCubeRoot(R - D);
            Rd = S + T;
            if (Rd < 0) {
                Rd = Math.sqrt(-1 / (3 * this.calibrationParameters.distortion));
            }
        } else {
            // Found three real roots
            D = Math.sqrt(-D);
            const S = this.computeCubeRoot(Math.hypot(R, D));
            const T = Math.atan2(D, R) / 3;
            const sinT = Math.sin(T);
            const cosT = Math.cos(T);

            // Use the smaller positive root
            Rd = -S * cosT + this.SQRT3 * S * sinT;
        }

        const mLambda = Rd / Ru;
        const xd = x * mLambda;
        const yd = y * mLambda;

        return { x: xd, y: yd };
    }

    /**
     * This routine takes the position of a point in world coordinates [mm]
     * and determines its position in camera coordinates [mm]
     * @private
     * This routine takes the position of a point in world coordinates [mm]
     * and determines its position in camera coordinates [mm]
     * @private
     */
    worldToCamera({ x = 0, y = 0, z = 0 }) {
        const xc =
            this.innerParameters.r1 * x +
            this.innerParameters.r2 * y +
            this.innerParameters.r3 * z +
            this.calibrationParameters.tx;
        const yc =
            this.innerParameters.r4 * x +
            this.innerParameters.r5 * y +
            this.innerParameters.r6 * z +
            this.calibrationParameters.ty;
        const zc =
            this.innerParameters.r7 * x +
            this.innerParameters.r8 * y +
            this.innerParameters.r9 * z +
            this.calibrationParameters.tz;

        return { x: xc, y: yc, z: zc };
    }

    /**
     * Takes the position of a point in world coordinates and determines its position in an image in pixels.
     *
     * @param {{x: number, y: number, z: number}} worldCoordinates - The point's coordinates in the world (latitude, longitude, elevation: can be 0).
     * Any non-provided value will default to `0`.
     * @returns {{x: *, y: *}} imagePosition - The corresponding point in the image.
     */
    worldToPixel({ x = 0, y = 0, z = 0 }) {
        // Convert from world coordinates to camera coordinates.
        const { x: xc, y: yc, z: zc } = this.worldToCamera({ x, y, z });

        // Convert from camera coordinates to undistorted sensor plane coordinates.
        const xu = (this.calibrationParameters.focalDistance * xc) / zc;
        const yu = (this.calibrationParameters.focalDistance * yc) / zc;

        // Convert from undistorted to distorted sensor plane coordinates.
        const { x: xd, y: yd } = this.undistortedToDistortedSensorCoord({ x: xu, y: yu });

        const xf =
            (xd * this.calibrationParameters.sx) / this.calibrationParameters.dpx + this.calibrationParameters.cx;
        const yf = yd / this.calibrationParameters.dpy + this.calibrationParameters.cy;

        return { x: xf, y: yf };
    }

    /**
     * Performs an inverse perspective projection to determine the position of a point in world coordinates that
     * corresponds to a given position in image coordinates.
     *
     * To constrain the inverse projection to a single point, the methode requires a `z` world coordinate for the
     * point in addition to the `x` and `y` image coordinates.
     *
     * @param {{x: number, y: number, z: number}} imagePosition - The (x, y) position of the pixel in the image, along an optional `zWorld` parameter.
     * Any non-provided value will default to `0`.
     * @returns {{x: number, y: number, z: number}} worldPosition - The corresponding coordinate location in the world (latitude, longitude). Note
     * that the elevation is provided as a parameter to the function.
     */
    pixelToWorld({ x = 0, y = 0, z: zWorld = 0 }) {
        // Convert from image to distorted sensor plane coordinates.
        const xd =
            (this.calibrationParameters.dpx * (x - this.calibrationParameters.cx)) / this.calibrationParameters.sx;
        const yd = this.calibrationParameters.dpy * (y - this.calibrationParameters.cy);

        // Convert from distorted to undistorted sensor plane coordinates.
        const { x: xu, y: yu } = this.distortedToUndistortedSensorCoord({ x: xd, y: yd });

        // Compute the corresponding `xw` and `yw` world coordinates.
        // These equations were derived by simply inverting the perspective projection equations using Macsyma.
        const commonDenominator =
            (this.innerParameters.r1 * this.innerParameters.r8 - this.innerParameters.r2 * this.innerParameters.r7) *
                yu +
            (this.innerParameters.r5 * this.innerParameters.r7 - this.innerParameters.r4 * this.innerParameters.r8) *
                xu -
            this.calibrationParameters.focalDistance * this.innerParameters.r1 * this.innerParameters.r5 +
            this.calibrationParameters.focalDistance * this.innerParameters.r2 * this.innerParameters.r4;

        const xw =
            (((this.innerParameters.r2 * this.innerParameters.r9 - this.innerParameters.r2 * this.innerParameters.r7) *
                yu +
                (this.innerParameters.r6 * this.innerParameters.r8 -
                    this.innerParameters.r5 * this.innerParameters.r9) *
                    xu -
                this.calibrationParameters.focalDistance * this.innerParameters.r2 * this.innerParameters.r6 +
                this.calibrationParameters.focalDistance * this.innerParameters.r3 * this.innerParameters.r5) *
                zWorld +
                (this.innerParameters.r2 * this.calibrationParameters.tz -
                    this.innerParameters.r8 * this.calibrationParameters.tx) *
                    yu +
                (this.innerParameters.r8 * this.calibrationParameters.ty -
                    this.innerParameters.r5 * this.calibrationParameters.tz) *
                    xu -
                this.calibrationParameters.focalDistance * this.innerParameters.r2 * this.calibrationParameters.ty +
                this.calibrationParameters.focalDistance * this.innerParameters.r5 * this.calibrationParameters.tx) /
            commonDenominator;

        const yw =
            -(
                ((this.innerParameters.r1 * this.innerParameters.r9 -
                    this.innerParameters.r3 * this.innerParameters.r7) *
                    yu +
                    (this.innerParameters.r6 * this.innerParameters.r7 -
                        this.innerParameters.r4 * this.innerParameters.r9) *
                        xu -
                    this.calibrationParameters.focalDistance * this.innerParameters.r1 * this.innerParameters.r6 +
                    this.calibrationParameters.focalDistance * this.innerParameters.r3 * this.innerParameters.r4) *
                    zWorld +
                (this.innerParameters.r1 * this.calibrationParameters.tz -
                    this.innerParameters.r7 * this.calibrationParameters.tx) *
                    yu +
                (this.innerParameters.r7 * this.calibrationParameters.ty -
                    this.innerParameters.r4 * this.calibrationParameters.tz) *
                    xu -
                this.calibrationParameters.focalDistance * this.innerParameters.r1 * this.calibrationParameters.ty +
                this.calibrationParameters.focalDistance * this.innerParameters.r4 * this.calibrationParameters.tx
            ) / commonDenominator;

        return { x: xw, y: yw, z: zWorld };
    }

    /**
     * This method takes the pixels coordinates of the current measure.
     * It returns an array with the coordinates of the 2 guide lines in order to draw them onto the canvas.
     */
    getGuideLinesPixelsCoordinates({ x: x1, y: y1, z: z1 = 0 }, { x: x2, y: y2, z: z2 = 0 }) {
        // we want to draw guide lines which measure 5 meters.
        const helpLineLength = 5;
        // measure line pixels coordinates to world coordinates
        // first point
        const { x: xw1, y: yw1 } = this.pixelToWorld({ x: x1, y: y1, z: z1 });
        // second point
        const { x: xw2, y: yw2 } = this.pixelToWorld({ x: x2, y: y2, z: z2 });

        // angle calculation
        const angle = Math.atan2(yw2 - yw1, xw2 - xw1);

        // First guide line
        // higher point
        let Xw = xw1 - helpLineLength * 0.5 * Math.sin(angle);
        let Yw = yw1 + helpLineLength * 0.5 * Math.cos(angle);
        const { x: Xim1a, y: Yim1a } = this.worldToPixel({ x: Xw, y: Yw, z: 0 });
        const p1a = { x: Xim1a, y: Yim1a };
        // lower point
        Xw = xw1 + helpLineLength * 0.5 * Math.sin(angle);
        Yw = yw1 - helpLineLength * 0.5 * Math.cos(angle);
        const { x: Xim1b, y: Yim1b } = this.worldToPixel({ x: Xw, y: Yw, z: 0 });
        const p1b = { x: Xim1b, y: Yim1b };

        // Second guide line
        // higher point
        Xw = xw2 - helpLineLength * 0.5 * Math.sin(angle);
        Yw = yw2 + helpLineLength * 0.5 * Math.cos(angle);
        const { x: Xim2a, y: Yim2a } = this.worldToPixel({ x: Xw, y: Yw, z: 0 });
        const p2a = { x: Xim2a, y: Yim2a };
        // lower point
        Xw = xw2 + helpLineLength * 0.5 * Math.sin(angle);
        Yw = yw2 - helpLineLength * 0.5 * Math.cos(angle);
        const { x: Xim2b, y: Yim2b } = this.worldToPixel({ x: Xw, y: Yw, z: 0 });
        const p2b = { x: Xim2b, y: Yim2b };

        return [
            [p1a, p1b],
            [p2a, p2b],
        ];
    }
}

export default TranslationService;
