import { createCommand } from '../../utils';
import { moduleName, referenceToDisplayIdsUpdated } from '../state';
import { UpdateFiltersCommand } from './UpdateFiltersCommand';

export const RemoveReferencesToDisplayIdCommand = createCommand(
    `${moduleName}/REMOVE_REFERENCES_TO_DISPLAY_ID`,
    async (referenceIds: string[], { dispatch, extra }) => {
        const previouslyStoredReferenceIds: Array<string> = [];
        const referenceIdsFromProvider = extra.storageProvider.getReferenceIds();
        if (referenceIdsFromProvider) {
            previouslyStoredReferenceIds.push(...referenceIdsFromProvider);
        }

        const newStoredReferenceIds: Array<string> = previouslyStoredReferenceIds.filter(
            (e) => !referenceIds.includes(e),
        );

        extra.storageProvider.setReferenceIds(newStoredReferenceIds);
        dispatch(referenceToDisplayIdsUpdated({ referenceIds: newStoredReferenceIds }));

        dispatch(UpdateFiltersCommand());
    },
);
