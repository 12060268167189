import { CircularProgress } from '@mui/material';
import ResultIcon from '../../legacy/components/ui/modals/ResultIcon';
import { ModalCenterTitle, ModalCenterSubTitle } from '../../legacy/components/ui/modals/styles';
import Tips from '../../legacy/components/ui/Tips';
import { useTranslation } from 'react-i18next';
import { SessionUpload, UploadStatuses } from '../../../core/domain/SessionUpload';

export const useUploadStatus = (sessionUploads: Array<SessionUpload>) => {
    const { t } = useTranslation();
    const hasInitiatedOrInProgressUploads = sessionUploads.some(
        (sessionUpload) =>
            sessionUpload.status === UploadStatuses.IN_PROGRESS || sessionUpload.status === UploadStatuses.INITIATED,
    );
    const hasFailedUploads = sessionUploads.some((sessionUpload) => sessionUpload.status === UploadStatuses.FAILURE);
    const hasOnlySuccessUploads =
        sessionUploads.length &&
        sessionUploads.every((sessionUpload) => sessionUpload.status === UploadStatuses.SUCCESS);

    const getModalInformations = () => {
        if (hasInitiatedOrInProgressUploads) {
            return (
                <>
                    <CircularProgress />
                    <ModalCenterTitle>{t('uploadSession.inProgress.title')}</ModalCenterTitle>
                    <ModalCenterSubTitle>{t('uploadSession.inProgress.subtitle')}</ModalCenterSubTitle>
                </>
            );
        }
        if (hasFailedUploads) {
            return (
                <>
                    <ResultIcon failure />
                    <ModalCenterTitle>{t('uploadSession.failure.title')}</ModalCenterTitle>
                    <Tips
                        headline={t('uploadSession.failure.tipTitle')}
                        body={t('uploadSession.failure.tipDescription')}
                        icon="bell"
                    />
                </>
            );
        }
        if (hasOnlySuccessUploads) {
            return (
                <>
                    <ResultIcon />
                    <ModalCenterTitle>{t('uploadSession.success.title')}</ModalCenterTitle>
                    <Tips
                        headline={t('uploadSession.success.tipTitle')}
                        body={t('uploadSession.success.tipDescription')}
                    />
                </>
            );
        }

        return null;
    };

    const getValidateButtonText = () => {
        return hasOnlySuccessUploads ? t('labels.backToSessions') : t('labels.abandonUploadsAndLeave');
    };

    return {
        getModalInformations,
        hasInitiatedOrInProgressUploads,
        hasOnlySuccessUploads,
        getValidateButtonText,
    };
};
