import { Stack, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { tooltipCell } from './styles';
import { SessionState as SessionStateEnum } from '../../../core/sessions/consts';
import { TFunction } from 'react-i18next';

interface SessionStateProps {
    state: string | null;
    t: TFunction;
}

const SessionState: FC<SessionStateProps> = ({ state, t }) => {
    const title = t(`sessionState.${state ?? SessionStateEnum.NONE}`);

    return (
        <Tooltip title={title}>
            <Stack onClick={(e) => e.stopPropagation()} sx={{ height: 1, justifyContent: 'center', width: 1 }}>
                <Typography sx={{ ...tooltipCell }}>{title}</Typography>
            </Stack>
        </Tooltip>
    );
};
export default SessionState;
