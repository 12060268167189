import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../types';
import { LogoutCommand } from '../../core/authentication/LogoutCommand';
import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { ButtonIcon } from '../legacy/style';

export const IconButtonOverride = styled(ButtonIcon).attrs({ size: '22px' })`
    display: inline;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: unset;
    margin-right: unset;
    color: ${({ theme }) => theme.color.black.main};
    background-color: ${({ theme }) => theme.color.white.main};
    padding: 0;
    &:hover {
        background-color: ${({ theme }) => theme.color.black.main} !important;
        opacity: 85%;

        svg {
            opacity: 1;
            color: ${({ theme }) => theme.color.white.main};
        }
    }
`;

export const LogoutIconButton: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const handleLogout = useCallback(() => dispatch(LogoutCommand()), []);

    return (
        <IconButtonOverride title={t('labels.logout')} onClick={handleLogout}>
            <LogoutIcon />
        </IconButtonOverride>
    );
};
