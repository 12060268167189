import { TextInformation, Title } from './styles';
import { useSelector } from 'react-redux';
import { ActionsOnSelectedSessions } from './ActionsOnSelectedSessions';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../types';
import { userQuery } from '../../../core/authentication/queries';
import { useMemo, FC } from 'react';
import { Header } from '../../design_system/page/Header';
import { selectedSessionsQuery, sessionsQuery } from '../../../core/sessions/queries';
import { Box, Typography } from '@mui/material';
import { theme } from '../../legacy/style/theme';
import { formatDistance } from '../../../utils/numbers';

export const SessionHeader: FC = () => {
    const { t } = useTranslation();
    const user = useAppSelector(userQuery);
    const sessionList = useSelector(sessionsQuery);
    const selectedSessions = useSelector(selectedSessionsQuery);

    const { count, totalDistance } = useMemo(() => {
        const meters = selectedSessions.reduce((sum, { metadata }) => sum + metadata.cumulativeEnd, 0);

        return { count: selectedSessions.length, totalDistance: formatDistance(meters) };
    }, [selectedSessions]);

    return (
        <>
            <Header />
            {sessionList.length > 0 && (
                <>
                    <Box>
                        <TextInformation>
                            {t('session.welcomeMessage')} {user?.lastName} {user?.firstName}
                        </TextInformation>
                        <Title>
                            {count === 0 ? t('session.yourSessions') : t('session.selectedSessions', { count })}
                        </Title>
                        {count > 0 && (
                            <Typography sx={{ color: theme.color.black.main, mt: -3 }}>
                                {t('session.totalDistance', { totalDistance })}
                            </Typography>
                        )}
                    </Box>
                    <ActionsOnSelectedSessions />
                </>
            )}
        </>
    );
};
