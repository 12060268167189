import { ValueOptions } from '@mui/x-data-grid-pro';
import type { Execution } from '../core/domain/AIAnalysis';
import type { Session, SessionMetadata, SessionWithExecutions } from '../core/domain/Session';
import { TFunction } from 'react-i18next';

const EMPTY_VALUE = 'EMPTY';

const getZhValue = ({ homogeneousZoneAlpha, homogeneousZoneType, homogeneousZoneMinLength }: SessionMetadata): string =>
    homogeneousZoneAlpha && homogeneousZoneMinLength && homogeneousZoneType
        ? `${homogeneousZoneType}:${homogeneousZoneAlpha}:${homogeneousZoneMinLength}`
        : EMPTY_VALUE;

const getZhDisplay = (
    { homogeneousZoneAlpha, homogeneousZoneType, homogeneousZoneMinLength }: SessionMetadata,
    defaultValue: string,
): string =>
    homogeneousZoneAlpha && homogeneousZoneMinLength && homogeneousZoneType
        ? `${
              homogeneousZoneType === 'NOTE_GLOBALE' ? 'Note' : 'Largeur'
          } : ${homogeneousZoneAlpha} : ${homogeneousZoneMinLength}`
        : defaultValue;

type SessionFiltersOptions = [SessionWithExecutions[], ValueOptions[], ValueOptions[], ValueOptions[], ValueOptions[]];

const mapToOptions = (map: Map<string, string>, defaultLabel: string): ValueOptions[] =>
    [...map.entries()]
        .map(([label, value]) => ({ label, value }))
        .sort(({ label }, { label: label2 }) => {
            // always set default at the top of options
            if (label === defaultLabel) return -1;
            if (label2 === defaultLabel) return 1;

            return label.toLowerCase().localeCompare(label2.toLowerCase());
        });

// todo: store only unique values of list
// needing campaign name, and zh (format to define)
const buildFiltersFromSession = (t: TFunction, sessions: Session[], executions: Execution[]): SessionFiltersOptions => {
    const defaultLabel: string = t('filters.emptyValue');
    const emptyOption: [[string, string]] = [[defaultLabel, EMPTY_VALUE]];

    const exec: SessionWithExecutions[] = [];
    const campaignMap = new Map<string, string>(emptyOption);
    const zhMap = new Map<string, string>(emptyOption);
    const calibrationMap = new Map<string, string>(emptyOption);
    const authorsMap = new Map<string, string>(emptyOption);

    sessions.forEach((session) => {
        exec.push({
            ...session,
            executions: executions.filter(({ sessionId }) => sessionId === session.id),
        });

        const { metadata, calibration } = session;
        const { author, campaignName } = metadata;
        const authorName = author ? `${author.firstName} ${author.lastName}` : null;

        campaignMap.set(campaignName || defaultLabel, campaignName || EMPTY_VALUE);
        zhMap.set(getZhDisplay(metadata, defaultLabel), getZhValue(metadata));
        calibrationMap.set(calibration?.fileName ?? defaultLabel, calibration?.fileName ?? EMPTY_VALUE);
        authorsMap.set(authorName ?? defaultLabel, authorName ?? EMPTY_VALUE);
    });

    return [
        exec,
        mapToOptions(campaignMap, defaultLabel),
        mapToOptions(zhMap, defaultLabel),
        mapToOptions(calibrationMap, defaultLabel),
        mapToOptions(authorsMap, defaultLabel),
    ];
};

export { EMPTY_VALUE, getZhValue };
export default buildFiltersFromSession;
