import { Input, Popover, Stack } from '@mui/material';
import { ChangeEventHandler, FC, MouseEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GreySquareButton } from '../../../../design_system/inputs/buttons/GreySquareButton';
import { TranslucentButton } from '../../../../design_system/inputs/buttons/TranslucentButton';
import { RegularText } from '../../../../design_system/texts';
import { theme } from '../../../style/theme';
import { MIN_STEP_VALUE, StepOptions } from './use-player-options';

type OptionLabelProps = {
    children: ReactNode;
};

const OptionLabel: FC<OptionLabelProps> = ({ children }) => {
    return (
        <RegularText fontWeight="bold" marginBottom="8px">
            {children}
        </RegularText>
    );
};
type DecrementButtonProps = {
    handleClick: () => void;
    children: ReactNode;
    disabled?: boolean;
};

const StepActionButton: FC<DecrementButtonProps> = ({ handleClick, children, disabled }) => {
    return (
        <GreySquareButton width="32px" height="32px" onClick={handleClick} disabled={disabled}>
            {children}
        </GreySquareButton>
    );
};

type StepInputProps = {
    step: number;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    handleBlur: () => void;
    isError: boolean;
};

const StepInput: FC<StepInputProps> = ({ step, handleChange, handleBlur, isError }) => {
    useEffect(() => {
        return handleBlur;
    }, []);

    return (
        <Input
            type="number"
            value={step}
            onChange={handleChange}
            onBlur={handleBlur}
            disableUnderline
            inputProps={{ min: 10 }}
            sx={{
                '& input': {
                    width: '64px',
                    paddingX: '8px',
                    textAlign: 'center',
                    color: theme.color.black.main,
                    fontFamily: theme.font.family,
                    borderRadius: '8px',
                    '&:hover, &:focus': {
                        backgroundColor: isError ? theme.color.red.darker : theme.color.blue.light,
                    },
                },
            }}
        />
    );
};

type OptionButtonProps = {
    handleClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

const OptionButton: FC<OptionButtonProps> = ({ handleClick }) => {
    return (
        <TranslucentButton
            sx={{
                position: 'absolute',
                right: -40,
            }}
            onClick={handleClick}
        >
            &#9881;
        </TranslucentButton>
    );
};

const Error: FC = () => {
    const { t } = useTranslation();

    return (
        <RegularText marginLeft="auto" color={theme.color.red.primary}>
            {t('streetView.minimumStep')}
        </RegularText>
    );
};

type PlayerOptionsProps = {
    stepOptions: StepOptions;
};

const PlayerOptions: FC<PlayerOptionsProps> = ({
    stepOptions: { step, incrementStep, decrementStep, updateStep, resetStepIfNeeded },
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const isError = step < MIN_STEP_VALUE;
    const isDecrementButtonDisabled = step <= MIN_STEP_VALUE;

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <OptionButton handleClick={handleClick} />
            <Popover anchorEl={anchorEl} onClose={handleClose} open={!!anchorEl}>
                <Stack direction="column" sx={{ padding: '16px' }}>
                    <OptionLabel>{t('streetView.customPlayerStep')} </OptionLabel>
                    <Stack direction="row" alignItems="center" gap="8px">
                        <StepActionButton disabled={isDecrementButtonDisabled} handleClick={decrementStep}>
                            -
                        </StepActionButton>
                        <StepInput
                            step={step}
                            handleChange={updateStep}
                            handleBlur={resetStepIfNeeded}
                            isError={isError}
                        />
                        <StepActionButton handleClick={incrementStep}>+</StepActionButton>
                        {isError && <Error />}
                    </Stack>
                </Stack>
            </Popover>
        </>
    );
};

export default PlayerOptions;
