import { Box, Stack, StackProps, styled } from '@mui/material';
import type { FC, ReactNode } from 'react';

const StyledLeftPanel = styled(Stack)<StackProps>(({ theme }) => ({
    justifyContent: 'space-between',
    flex: 1,
    padding: 0,
    overflow: 'hidden',
    alignSelf: 'stretch',
    minHeight: '100%',
    position: 'relative',
    backgroundColor: theme.color.white.main,
}));

export const LeftPanel: FC<{ children: ReactNode }> = ({ children }) => {
    return <StyledLeftPanel>{children}</StyledLeftPanel>;
};

const StyledStack = styled(Stack, {
    shouldForwardProp: (prop: string) => prop !== 'fullHeight',
})<StackProps & { fullHeight?: boolean }>(({ theme, fullHeight }) => ({
    alignSelf: 'center',
    height: '100%',
    position: 'relative',
    width: '100%',
    padding: 0,
    margin: 0,
    backgroundColor: theme.color.black.dark,
    maxHeight: '90vh',
    ...(fullHeight && {
        maxHeight: '100vh',
    }),
}));

export const RoadView = ({ children, fullHeight = false }) => {
    return (
        <StyledStack
            onContextMenu={(e) => {
                e.preventDefault();
            }}
            justifyContent="center"
            alignItems="center"
            fullHeight={fullHeight}
        >
            {children}
        </StyledStack>
    );
};

export const StreetViewActionsContainer: FC<{ children: ReactNode; hasPlayer?: boolean }> = ({
    children,
    hasPlayer,
}) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '24px',
                width: '100%',
                display: 'grid',
                gridAutoColumns: `1fr 1fr ${hasPlayer ? '1fr' : ''}`,
            }}
        >
            {' '}
            {children}
        </Box>
    );
};
