import styled from 'styled-components';
import { theme } from '../../../../style/theme';

export const StyledDuplicateWarning = styled.div`
    background-color: ${theme.color.yellow.warning};
    border-radius: ${theme.radius.s};
    padding: 10px;
    color: ${theme.color.black.dark};
    margin-bottom: 10px;
`;

export const WarningText = styled.span`
    display: block;
    font-size: ${theme.font.size.regular};
    color: ${theme.color.black.dark};
`;

export const BoldWarningText = styled(WarningText)`
    font-weight: bold;
`;
