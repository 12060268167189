import { styled as styledMUI, Typography } from '@mui/material';
import styled from 'styled-components';
import { Ellipsis } from '../../../../../style';
import SelectableBlock from '../../../blocks/SelectableBlock';

export const StyledBlock = styled(SelectableBlock)(({ disabled }) => ({
    padding: '10px',
    margin: '5px 0',
    display: 'grid',
    gridTemplateColumns: '2fr auto',
    opacity: disabled ? 0.5 : 1,
}));

export const ElementClass = styledMUI(Typography)(({ theme }) => ({
    minHeight: '40px',
    color: theme.color.black.main,
    fontSize: theme.font.size.small,
    fontWeight: 'bold',
    gridRow: 1,
    display: 'flex',
    alignItems: 'center',
}));

export const ElementData = styledMUI(ElementClass)(({ color }) => ({
    fontWeight: 'normal',
    color,
}));

export const ElementClassEllipsis = styledMUI(Typography)(({ theme, width }) => ({
    width: width ?? '100%',
    minHeight: '40px',
    gridRow: 1,
    color: theme.color.black.main,
    fontSize: theme.font.size.small,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

export const Name = styled(Ellipsis).attrs({
    width: '100%',
})`
    font-size: ${({ theme }) => theme.font.size.smaller};
    color: ${({ theme }) => theme.color.black.main};
`;
