// If any value is changed or added, do not forget to update translations
enum SessionState {
    NONE = 'none',
    TO_BE_TREATED = 'toBeTreated',
    TO_BE_VERIFIED = 'toBeVerified',
    IN_PROGRESS = 'inProgress',
    OK = 'ok',
    TO_DELETE = 'toDelete',
    TO_REDO = 'toRedo',
    CALIBRATION_PROBLEM = 'calibrationProblem',
    ANALYSIS_PROBLEM = 'analysisProblem',
}

export { SessionState };
