import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StyledModal } from './styles';
import { resetMessages } from '../../../../../core/legacy/actions/flashbag';
import { Error, Infos, Success, Warning } from '../../../style';

/**
 * Inject this FlashBag component where you need. Then, just dispatch the actions:
 * - addSuccess
 * - addErrors
 * - addWarnings
 * - addInfos
 * with an array of translate paths as argument.
 * It will display a modal with the given messages into.
 * eg:
 *      dispatch(addSuccess(['success.calibration']));
 * @returns {*|number|JSX.Element}
 * @constructor
 */
const FlashBag = () => {
    const TYPE_ERROR = 'error';
    const TYPE_INFO = 'info';
    const TYPE_SUCCESS = 'success';
    const TYPE_WARNING = 'warning';

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { errors, infos, success, warnings } = useSelector((state) => state.flashbag);
    const [isOpen, setIsOpen] = useState(false);

    const toggleModalHandler = (value) => {
        setIsOpen(value);
        dispatch(resetMessages());
    };

    const getMessage = (message, i, type) => {
        const key = `${type}_${i}`;
        let Component;
        switch (type) {
            case TYPE_ERROR:
                Component = Error;
                break;
            case TYPE_INFO:
                Component = Infos;
                break;
            case TYPE_WARNING:
                Component = Warning;
                break;
            case TYPE_SUCCESS:
            default:
                Component = Success;
        }

        return <Component key={key}>{t(message)}</Component>;
    };

    useEffect(() => {
        setIsOpen(true);
    }, [errors, infos, success, warnings]);

    return success.length || errors.length || warnings.length || infos.length ? (
        <StyledModal
            isOpen={isOpen}
            onBackgroundClick={() => toggleModalHandler(false)}
            onEscapeKeydown={() => toggleModalHandler(false)}
        >
            {success.map((message, i) => getMessage(message, i, TYPE_SUCCESS))}
            {infos.map((message, i) => getMessage(message, i, TYPE_INFO))}
            {warnings.map((message, i) => getMessage(message, i, TYPE_WARNING))}
            {errors.map((message, i) => getMessage(message, i, TYPE_ERROR))}
        </StyledModal>
    ) : null;
};

export default FlashBag;
