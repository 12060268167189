import { Link, ListItemText, MenuItem, Stack } from '@mui/material';
import { FC, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GoogleMapsLogo } from '../../../../../../../assets/icons/logo-google-maps.svg';
import { currentImageSelector } from '../../../../../../../core/legacy/selectors/streetView';
import { useAppSelector } from '../../../../../../../types';
import { BaseMenuItemText } from '../../../../../../design_system/inputs/menuI-items/BaseMenuItemText';

type Props = {
    onActionExecuted: () => void;
};

export const OpenGoogleMapsMenuItem: FC<Props> = ({ onActionExecuted }) => {
    const { t } = useTranslation();
    const currentImage = useAppSelector(currentImageSelector);

    if (!currentImage?.geom?.coordinates) {
        return null;
    }

    const { x, y } = currentImage.geom.coordinates;
    const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${y},${x}`;

    const handleClick = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            onActionExecuted();
        },
        [onActionExecuted],
    );

    return (
        <MenuItem
            color="inherit"
            underline="none"
            target="_blank"
            rel="noreferrer"
            onClick={handleClick}
            href={googleMapsLink}
            component={Link}
        >
            <Stack direction="row" gap="10px" alignItems="center">
                <GoogleMapsLogo height="24px" width="24px" />
                <ListItemText>
                    <BaseMenuItemText>{t('buttons.open-in-maps')}</BaseMenuItemText>
                </ListItemText>
            </Stack>
        </MenuItem>
    );
};
