import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { User } from '../domain/User';

export const moduleName = 'AUTHENTICATION';

type AuthenticationStatus = 'LOGGED_OUT' | 'LOGGED_IN' | 'LOG_FAILED' | 'LOG_ONGOING';

type State = {
    status: AuthenticationStatus;
    user?: User;
    errorMessage?: string;
};

const initialState: State = {
    status: 'LOGGED_OUT',
};

const authenticationSlice = createSlice({
    name: moduleName,
    initialState,
    reducers: {
        loggedIn(state, action: PayloadAction<User>) {
            state.status = 'LOGGED_IN';
            state.errorMessage = undefined;
            state.user = action.payload;
        },
        logOngoing(state) {
            state.status = 'LOG_ONGOING';
            state.errorMessage = undefined;
        },
        loggedOut(state) {
            state.status = 'LOGGED_OUT';
            state.errorMessage = undefined;
            state.user = undefined;
        },
        logFailed(state, action: PayloadAction<string>) {
            state.status = 'LOG_FAILED';
            state.errorMessage = action.payload;
        },
        userUpdated(state, action: PayloadAction<User>) {
            state.user = action.payload;
        },
    },
});

export const { loggedIn, logOngoing, loggedOut, logFailed, userUpdated } = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer;
