import { ReactComponent as Logo } from '../../../assets/img/blue-logo-and-brandname.svg';
import type { FC } from 'react';
import { Box } from '@mui/material';

type Props = {
    height?: string;
    width?: string;
};

export const RoadcareLogoBlue: FC<Props> = ({ width, height }) => {
    return (
        <Box
            sx={{
                '&>svg': {
                    display: 'block',
                    height: height ?? 'unset',
                    width: width ?? 'unset',
                },
            }}
        >
            <Logo />
        </Box>
    );
};
