import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';
import SuccessModalWithTip from '../../../../legacy/components/ui/modals/SuccessModalWithTip';
import { useAppDispatch } from '../../../../../types';
import { InitSessionsCommand } from '../../../../../core/sessions/commands/InitSessionsCommand';

export const CalibrateSuccessModal: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const refreshPage = useCallback(() => {
        dispatch(InitSessionsCommand());
    }, []);

    return (
        <SuccessModalWithTip
            title={t('calibrateSession.success.title')}
            headline={t('calibrateSession.success.tipTitle')}
            body={t('calibrateSession.success.tipDescription')}
            modalType={SessionsModalTypes.SESSIONS_CALIBRATE_SUCCESS}
            onValidate={refreshPage}
        />
    );
};

export default CalibrateSuccessModal;
