import { Box } from '@mui/material';
import { useCallback, FC } from 'react';
import apiEndpoint from '../../../../../core/legacy/utils/api';
import { useAppDispatch } from '../../../../../types';
import { useTranslation } from 'react-i18next';
import { setModalToDisplay } from '../../../../../core/legacy/actions/modals';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';
import type { SessionId } from '../../../../../core/domain/Session';

type Props = {
    sessionId: SessionId;
    imageIndex: number;
};

const ExpandableImage: FC<Props> = ({ imageIndex, sessionId }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const handleClickEnlargeImage = useCallback(async () => {
        await dispatch(setModalToDisplay(SessionsModalTypes.ENLARGED_IMAGE));
    }, [dispatch]);

    return (
        <Box
            onClick={handleClickEnlargeImage}
            component="img"
            alt={t('networkMap.imagePopup.enlargeImage')}
            title={t('networkMap.imagePopup.enlargeImage')}
            src={`${apiEndpoint}/sessions/${sessionId}/images/${imageIndex}.jpeg`}
            width="100%"
            sx={{
                cursor: 'zoom-in',
            }}
        />
    );
};

export default ExpandableImage;
