import { FC, MouseEvent, useCallback, useState } from 'react';
import { StyledButton, StyledMenu } from '../../../legacy/components/ui/Menu/style';
import { useAppDispatch, useAppSelector } from '../../../../types';
import { TargetSessionsForInteractionCommand } from '../../../../core/sessions/commands/TargetSessionsForInteractionCommand';
import type { SessionId } from '../../../../core/domain/Session';
import { areTargettedSessionCalibratedQuery } from '../../../../core/sessions/queries';
import { aiAnalysisAllowedQuery } from '../../../../core/authentication/queries';
import SessionMenuItems from '../../../design_system/inputs/menuI-items/SessionMenuItems';
import { OptionsIcon } from '../../../design_system/icons/OptionsIcon';

interface SessionMenuProps {
    sessionId: SessionId;
}

export const SessionMenu: FC<SessionMenuProps> = ({ sessionId }) => {
    const [anchorEl, setAnchorEl] = useState<unknown>(null);
    const dispatch = useAppDispatch();
    const calibrated = useAppSelector(areTargettedSessionCalibratedQuery);
    const aiAnalysisAllowed = useAppSelector(aiAnalysisAllowedQuery);

    const handleMenuClick = (event: MouseEvent) => {
        event.stopPropagation();
        dispatch(TargetSessionsForInteractionCommand([sessionId]));
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback(
        (event?: MouseEvent) => {
            event?.stopPropagation();
            setAnchorEl(null);
        },
        [setAnchorEl],
    );

    const handleItemClick = (action: any, event?: MouseEvent) => {
        if (action) dispatch(action);
        handleClose(event);
    };

    return (
        <>
            <StyledButton onClick={handleMenuClick} type="button">
                <OptionsIcon title="Options" themeStyle="light" size="tableIconSize" />
            </StyledButton>
            <StyledMenu
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SessionMenuItems
                    aiAnalysisAllowed={aiAnalysisAllowed}
                    calibrated={calibrated}
                    onItemClick={handleItemClick}
                    sessionId={sessionId}
                />
            </StyledMenu>
        </>
    );
};
