import { ReactComponent as AddIcon } from '../../../../../assets/icons/add.svg';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/arrow-cursor.svg';
import { useSelector, useDispatch } from 'react-redux';
import { isDrawingToolActivatedSelector } from '../../../../../core/legacy/selectors/calibration';
import { activateDrawingTool, desactivateDrawingTool } from '../../../../../core/legacy/actions';
import { useTranslation } from 'react-i18next';
import { ButtonContainer, Container } from '../../StreetView/CatalogMenu/styles';

const ToolButtonGroup = () => {
    const { t } = useTranslation();
    const drawingToolActivated = useSelector(isDrawingToolActivatedSelector);
    const dispatch = useDispatch();
    const handleArrowButtonClick = () => {
        dispatch(desactivateDrawingTool());
    };
    const addButtonClick = () => {
        dispatch(activateDrawingTool());
    };

    return (
        <Container>
            <ButtonContainer
                onClick={handleArrowButtonClick}
                selected={!drawingToolActivated}
                title={t('calibration.toolButtonsTips.arrow')}
            >
                <ArrowIcon />
            </ButtonContainer>
            <ButtonContainer
                onClick={addButtonClick}
                selected={drawingToolActivated}
                title={t('calibration.toolButtonsTips.addPoint')}
            >
                <AddIcon />
            </ButtonContainer>
        </Container>
    );
};

export default ToolButtonGroup;
