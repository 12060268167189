import * as Sentry from '@sentry/browser';

export const initSentry = (dsn: string | undefined, environment: string | undefined): boolean => {
    if (dsn === undefined) {
        return false;
    }
    Sentry.init({
        dsn,
        environment,
    });

    return true;
};
