import { getRoadDataIntervalForImageRequest } from '../../providers/datasources/imageDataSource';
import {
    updateRoadDataReferenceRequest,
    updateRoadDataTagsRequest,
} from '../../providers/legacy/datasources/sessionDataSource';
import type { RootState } from '../../store';
import type { AppDispatch } from '../../types';
import type { Reference, RoadData, RoadDataTag } from '../domain/Roaddata';
import {
    imageRoaddataOnlyQuery,
    referenceToDisplayIds,
    rubricToDisplayIdsQuery,
    taggedElementsOnlyQuery,
} from '../filters/queries';
import { currentImageSelector, roadDataByIdSelector } from '../legacy/selectors/streetView';
import { loaderStarted, loaderStopped } from '../loader/state';
import { sessionIdQuery } from '../streetViewer/queries';

// TAGS related actions
export const toggleRoadDataWarningTag = (roadDataId: string) => async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const {
        tags: { tags },
        ...rest
    }: RootState = getState();
    const currentRoadDataTags: RoadDataTag[] = roadDataByIdSelector(roadDataId)(rest).tags ?? [];
    let newTags: RoadDataTag[] = [];

    if (currentRoadDataTags.some((tag) => tag.code === 'WARNING')) {
        newTags = currentRoadDataTags.filter((tag) => tag.code !== 'WARNING');
    } else {
        newTags = [...currentRoadDataTags, ...tags.filter((tag) => tag.code === 'WARNING')];
    }

    await dispatch(updateRoaddataTags(roadDataId, newTags));
};

export const updateRoaddataTags = (roadDataId: string, tags: RoadDataTag[]) => async (dispatch, getState) => {
    dispatch(loaderStarted());

    const sessionId = sessionIdQuery(getState());
    const updatedRoadData = await updateRoadDataTagsRequest(sessionId, roadDataId, { tags });

    // do not fully overwrite as we are only changing one property of the road data
    dispatch({
        overrideKeys: ['tags'],
        type: 'STREET_VIEW/UPDATED_ROAD_DATA',
        updatedRoadData,
    });

    dispatch(loaderStopped());
};

// ROAD DATA related actions
export const retrieveRoadDataIntervalOfCurrentImage = () => async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state = getState();
    const image = currentImageSelector(state);
    const taggedElementsOnly = taggedElementsOnlyQuery(state);
    const imageRoadataOnly = imageRoaddataOnlyQuery(state);
    const rubricToDisplayIds = rubricToDisplayIdsQuery(state);
    const referenceToDisplay = referenceToDisplayIds(state);
    const imageRoadDataInterval = await getRoadDataIntervalForImageRequest(
        image.id,
        rubricToDisplayIds,
        referenceToDisplay,
        taggedElementsOnly,
        imageRoadataOnly,
    );

    dispatch({
        type: 'STREET_VIEW/UPDATE_ROAD_DATAS',
        roadDatas: imageRoadDataInterval.roaddatas,
        totalSize: imageRoadDataInterval.totalSize,
        rubricIdToCount: imageRoadDataInterval.rubricIdToCount,
    });
};

export const updateRoadDataReference = (roadData: RoadData, reference?: Reference) => async (dispatch, getState) => {
    dispatch(loaderStarted());
    const sessionId = sessionIdQuery(getState());
    const updatedRoadData = await updateRoadDataReferenceRequest(sessionId, roadData, { reference });

    dispatch({
        overrideKeys: ['reference'],
        type: 'STREET_VIEW/UPDATED_ROAD_DATA',
        updatedRoadData,
    });
    dispatch({
        type: 'STREET_VIEW/UPDATED_ANNOTATION_ROAD_DATA',
        updatedRoadData,
    });

    dispatch(loaderStopped());
};
