/* eslint-disable @typescript-eslint/no-unused-vars */
import { createCommand } from '../../utils';
import { gradeReferenceSelectedOnMap, moduleName } from '../state';
import type { GradeReference } from '../../domain/Roaddata';
import { userOrganizationQuery } from '../../authentication/queries';

type Args = {
    selectedGradeReference: GradeReference;
};

export const UpdateSelectedGradeReferenceCommand = createCommand(
    `${moduleName}/UPDATE_SELECTED_GRADE_REFERENCE`,
    async ({ selectedGradeReference }: Args, { dispatch, extra, getState }) => {
        const userOrganization = userOrganizationQuery(getState());

        if (userOrganization) {
            await extra.mapViewSettings.updateSelectedGradeReferenceForOrganization(
                userOrganization.id,
                selectedGradeReference,
            );
        }

        dispatch(gradeReferenceSelectedOnMap(selectedGradeReference));
    },
);
