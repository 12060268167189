import { Stack } from '@mui/material';
import formatDateWithBrowserLocale from '../../../../../core/legacy/utils/date';
import type { FC, ReactNode } from 'react';
import { currentImageSelector } from '../../../../../core/legacy/selectors/streetView';
import { useAppSelector } from '../../../../../types';
import { theme } from '../../../style/theme';
import { BaseTypography, RegularText } from '../../../../design_system/texts';
import { useDirectionTranslation } from '../../../../common/use-direction-translation';
import { sessionQuery } from '../../../../../core/streetViewer/queries';

const SessionGradeContainer: FC<{ children: ReactNode; backgroundColor: string; display?: boolean }> = ({
    children,
    backgroundColor,
    display,
}) => {
    return (
        <Stack
            justifyContent="center"
            alignContent="center"
            sx={{
                backgroundColor,
                padding: '4px 8px',
                borderRadius: '8px',
                display: display ? 'block' : 'none', // TODO remove this when the calculation is implemented
            }}
        >
            {children}
        </Stack>
    );
};

export const SessionGrade: FC<{ display?: boolean }> = ({ display }) => {
    const grade = 3.5;
    const backgroundColor = theme.color.yellow.warning;

    return (
        <SessionGradeContainer backgroundColor={backgroundColor} display={display}>
            <BaseTypography fontWeight="bold" fontSize={theme.font.size.medium3} color={theme.color.grey.dark}>
                {grade} / 5
            </BaseTypography>
        </SessionGradeContainer>
    );
};

const SessionName: FC<{ name: string }> = ({ name }) => {
    return (
        <BaseTypography noWrap lineHeight={1.1} fontSize={theme.font.size.medium3} fontWeight={500} title={name}>
            {name}
        </BaseTypography>
    );
};

const SessionAcquisitionDate: FC<{ acquisitionDate?: string }> = ({ acquisitionDate }) => {
    const getDateString = (date) => {
        if (!date) {
            return '';
        }

        return formatDateWithBrowserLocale(new Date(date), 'Pp');
    };

    return <RegularText>{getDateString(acquisitionDate)}</RegularText>;
};

const SessionInformation: FC = () => {
    const session = useAppSelector(sessionQuery);
    const currentImage = useAppSelector(currentImageSelector);
    const { getPrefixedDirection } = useDirectionTranslation();

    if (!session) {
        return null;
    }

    return (
        !!session.metadata && (
            <Stack direction="column" padding="16px" gap={0.5} sx={{ backgroundColor: theme.color.blue.light }}>
                <SessionName name={session.name} />
                <Stack direction="row" justifyContent="space-between">
                    <Stack justifyContent="space-between">
                        <SessionAcquisitionDate acquisitionDate={currentImage?.captureDate} />
                        <RegularText>{getPrefixedDirection(session.metadata.roadDirection)}</RegularText>
                    </Stack>
                    <SessionGrade />
                </Stack>
            </Stack>
        )
    );
};

export default SessionInformation;
