import { createCommand } from '../../utils';
import { sessionUploadsQuery } from '../queries';
import { moduleName, sessionUploadsReset } from '../state';

export const ResetSessionUploadsCommand = createCommand(
    `${moduleName}/CLEAR_SESSION_UPLOADS`,
    async (_, { dispatch, extra, getState }) => {
        const state = getState();
        const sessionUploads = sessionUploadsQuery(state);
        extra.serializableFileProvider.clear(sessionUploads);
        await dispatch(sessionUploadsReset());
    },
);
