import { getShapeTypeFromAnnotation } from '../utils/annotationEngine';

const initialState = {
    images: new Map(),
    annotations: [],
    roadDatas: [],
    totalSize: 0,
    rubricIdToCount: new Map(),
    roadDataIdInEdition: undefined,
    annotationIdInEdition: undefined,
    selectedRubric: undefined,
    hoveredRoadDataId: undefined,
    selectedRoadDataId: undefined,
    temporaryDeformedAnnotationToEdit: undefined,
    roadDataIdToScrollTo: undefined,
};

const streetViewReducer = (state = initialState, { type, ...payload }) => {
    const sortRoadDatasByCumulStart = (list) => {
        return [...list].sort((a, b) => (+a.cumulStartSession > +b.cumulStartSession ? -1 : 1));
    };
    const updateAnnotation = (annotationToUpdate) => {
        const annoIndex = state.annotations.findIndex(
            (annotation) => annotation.roadData.id === annotationToUpdate.roadData.id,
        );
        const annotationToEdit = state.annotations[annoIndex];
        const roadDataIndex = state.roadDatas.findIndex((roadData) => roadData.id === annotationToUpdate.roadData.id);
        const roadDataToEdit = state.roadDatas[roadDataIndex];

        return {
            ...state,
            annotations: [
                ...state.annotations.slice(0, annoIndex),
                {
                    ...annotationToEdit,
                    ...annotationToUpdate,
                    isShapeClosed: getShapeTypeFromAnnotation(annotationToUpdate) !== 'POLYLINE',
                },
                ...state.annotations.slice(annoIndex + 1),
            ],
            roadDatas: sortRoadDatasByCumulStart([
                ...state.roadDatas.slice(0, roadDataIndex),
                { ...roadDataToEdit, ...annotationToUpdate.roadData },
                ...state.roadDatas.slice(roadDataIndex + 1),
            ]),
        };
    };
    switch (type) {
        case 'STREET_VIEW/INIT': {
            const newImages = new Map();
            payload.images.forEach((image) => newImages.set(image.index, image));

            return {
                ...state,
                images: newImages,
            };
        }
        // Image cache management
        case 'STREET_VIEW/UPDATE_IMAGE_CACHE': {
            const newImages = new Map(state.images.entries());
            payload.images.forEach((image) => newImages.set(image.index, image));

            return {
                ...state,
                images: newImages,
            };
        }
        // Rubric
        case 'STREET_VIEW/SELECT_RUBRIC':
            return {
                ...state,
                selectedRubric: payload.rubric,
            };
        case 'STREET_VIEW/UNSELECT_RUBRIC':
            return {
                ...state,
                selectedRubric: undefined,
            };
        // Road data
        case 'STREET_VIEW/SELECT_ROAD_DATA_IN_EDITION':
            return {
                ...state,
                roadDataIdInEdition: payload.roadDataId,
            };
        case 'STREET_VIEW/NO_MORE_ROAD_DATA_IN_EDITION':
            return {
                ...state,
                roadDataIdInEdition: undefined,
            };
        case 'STREET_VIEW/SELECT_SELECTED_ROAD_DATA':
            return {
                ...state,
                selectedRoadDataId: payload.roadDataId,
            };
        case 'STREET_VIEW/NO_MORE_SELECTED_ROAD_DATA':
            return {
                ...state,
                selectedRoadDataId: undefined,
                temporaryDeformedAnnotationToEdit: undefined,
                annotationIdInEdition: undefined,
            };
        case 'STREET_VIEW/UPDATE_ROAD_DATAS':
            return {
                ...state,
                roadDatas: payload.roadDatas,
                totalSize: payload.totalSize,
                rubricIdToCount: payload.rubricIdToCount,
            };
        case 'STREET_VIEW/REPLACE_ROAD_DATAS': {
            const roadDatas = state.roadDatas.map((roadData) => {
                const roadDataFromPayload = payload.roadDatas.find((rd) => rd.id === roadData.id);
                if (roadDataFromPayload !== undefined) {
                    return roadDataFromPayload;
                }

                return roadData;
            });

            return {
                ...state,
                roadDatas,
            };
        }
        case 'STREET_VIEW/UPDATED_ROAD_DATA': {
            const { updatedRoadData, overrideKeys } = payload;
            const { roadDatas } = state;

            const newRoadDatas = roadDatas.map((roadData) => {
                if (roadData.id === updatedRoadData.id) {
                    // if you do not precise what to override, everything will be
                    if (!overrideKeys?.length) return updatedRoadData;

                    // create a new object with overridden keys to avoid object mutation
                    const newData = overrideKeys.reduce((data, key) => ({ ...data, [key]: updatedRoadData[key] }), {});

                    return { ...roadData, ...newData };
                }

                return roadData;
            });

            return {
                ...state,
                roadDatas: newRoadDatas,
            };
        }
        case 'STREET_VIEW/UPDATED_ANNOTATION_ROAD_DATA': {
            const { updatedRoadData } = payload;
            const { annotations } = state;

            const newAnnotations = annotations.map((anno) =>
                updatedRoadData.id === anno.roadData.id ? { ...anno, roadData: updatedRoadData } : anno,
            );

            return { ...state, annotations: newAnnotations };
        }
        case 'STREET_VIEW/DELETE_ROADDATA': {
            const annoIndex = state.annotations.findIndex((annotation) => annotation.roadData.id === payload.id);
            const roadDataIndex = state.roadDatas.findIndex((roadData) => roadData.id === payload.id);
            const annotations =
                annoIndex >= 0
                    ? [...state.annotations.slice(0, annoIndex), ...state.annotations.slice(annoIndex + 1)]
                    : state.annotations;

            return {
                ...state,
                annotations,
                roadDatas: [...state.roadDatas.slice(0, roadDataIndex), ...state.roadDatas.slice(roadDataIndex + 1)],
                selectedRoadDataId: undefined,
                hoveredRoadDataId: undefined,
            };
        }
        // Annotation
        case 'STREET_VIEW/SELECT_ANNOTATION_IN_EDITION':
            return {
                ...state,
                annotationIdInEdition: payload.roadDataId,
            };
        case 'STREET_VIEW/NO_MORE_ANNOTATION_IN_EDITION':
            return {
                ...state,
                annotationIdInEdition: undefined,
                temporaryDeformedAnnotationToEdit: undefined,
            };
        case 'STREET_VIEW/UPDATE_TEMPORARY_DEFORMED_ANNOTATION_TO_EDIT':
            return {
                ...state,
                temporaryDeformedAnnotationToEdit: payload.temporaryDeformedAnnotationToEdit,
            };
        case 'STREET_VIEW/RESET_TEMPORARY_DEFORMED_ANNOTATION_TO_EDIT':
            return {
                ...state,
                temporaryDeformedAnnotationToEdit: undefined,
            };
        case 'STREET_VIEW/UPDATE_ANNOTATIONS':
            return {
                ...state,
                annotations: payload.annotations.map((annotation) => ({
                    ...annotation,
                    isShapeClosed: getShapeTypeFromAnnotation(annotation) !== 'POLYLINE',
                })),
            };
        case 'STREET_VIEW/ADD_ANNOTATION':
            return {
                ...state,
                annotations: [
                    ...state.annotations,
                    {
                        ...payload.annotation,
                        isShapeClosed: getShapeTypeFromAnnotation(payload.annotation) !== 'POLYLINE',
                    },
                ],
                roadDatas: sortRoadDatasByCumulStart([...state.roadDatas, payload.annotation.roadData]),
            };
        case 'STREET_VIEW/UPDATE_ANNOTATION':
            return updateAnnotation(payload.annotation);
        case 'STREET_VIEW/UPDATE_ANNOTATION_PRESET_ATTRIBUTE_SHAPE': {
            const pureAnnotation = { ...payload.annotation };
            pureAnnotation.attributes = payload.annotation.attributes.map((attribute) => {
                const pureAttribute = { ...attribute };
                if (attribute.attributeValue.id === payload.annotation.roadData.presetAttributeValueIdForShape) {
                    pureAttribute.projectedShape = payload.projectedShape;
                }

                return pureAttribute;
            });

            return updateAnnotation(pureAnnotation);
        }
        // Player
        case 'STREET_VIEW/SELECT_HOVERED_ROAD_DATA':
            return {
                ...state,
                hoveredRoadDataId: payload.roadDataId,
            };
        case 'STREET_VIEW/NO_MORE_HOVERED_ROAD_DATA':
            return {
                ...state,
                hoveredRoadDataId: undefined,
            };
        case 'STREET_VIEW/UPDATE_ROAD_DATA_ID_TO_SCROLL_TO':
            return {
                ...state,
                roadDataIdToScrollTo: payload.roadDataIdToScrollTo,
            };
        case 'STREET_VIEW/RESET_ROAD_DATA_ID_TO_SCROLL_TO':
            return {
                ...state,
                roadDataIdToScrollTo: undefined,
            };
        case 'STREET_VIEW/RESET':
            return initialState;
        default:
            return state;
    }
};

export default streetViewReducer;
