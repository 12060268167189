import { selectedReferenceByRubricIdQuery } from '../../../../../core/catalogs/queries';
import {
    addAnnotation,
    saveEditedAnnotationPoints,
    resetTemporaryDeformedAnnotationToEdit,
} from '../../../../../core/legacy/actions';
import { convertAnnotationPointsToRealSize } from '../../../../../core/legacy/utils/roadViewerUtils';
import { useAppDispatch, useAppSelector } from '../../../../../types';

const useSaveAnnotation = (deformedAnnotationToEdit, selectedRubric) => {
    const dispatch = useAppDispatch();

    const selectedRubricReference = useAppSelector(selectedReferenceByRubricIdQuery(selectedRubric?.id));
    const reference = selectedRubricReference ?? null;

    return (geometry) => {
        const recalculatedAnnotationPoints = convertAnnotationPointsToRealSize(geometry);
        if (deformedAnnotationToEdit) {
            dispatch(resetTemporaryDeformedAnnotationToEdit());
            dispatch(saveEditedAnnotationPoints(recalculatedAnnotationPoints));
            // plus tard ce pourrait être la forme d'un attribut sélectionné dans le volet de droite >> autre route, autre use-case
        } else {
            dispatch(addAnnotation(recalculatedAnnotationPoints, selectedRubric.id, reference));
        }
    };
};

export default useSaveAnnotation;
