import { loadConfigFromRootDataset } from '../../../config';

const getMapboxToken = (): string => {
    if ('development' === process.env.NODE_ENV) {
        return process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;
    }

    const { mapboxToken } = loadConfigFromRootDataset();

    if (!mapboxToken) {
        throw new Error('Missing mapbox token');
    }

    return mapboxToken;
};

const mapboxToken = getMapboxToken();

export default mapboxToken;
