import { ListItemText, MenuItem, Stack } from '@mui/material';
import { FC, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../types';
import { BaseMenuItemText } from '../../../../../../design_system/inputs/menuI-items/BaseMenuItemText';
import { NavigateToNetworkMapCommand } from '../../../../../../../core/networkMap/commands/NavigateToNetworkMapCommand';
import { sessionQuery } from '../../../../../../../core/streetViewer/queries';
import { MapIcon } from '../../../../../../design_system/icons/MapIcon';

type Props = {
    onActionExecuted: () => void;
};

export const OpenNetworkMapMenuItem: FC<Props> = ({ onActionExecuted }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const session = useAppSelector(sessionQuery);

    const handleClick = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            onActionExecuted();
            dispatch(NavigateToNetworkMapCommand({ sessionId: session?.id }));
        },
        [dispatch, onActionExecuted],
    );

    return (
        <MenuItem onClick={handleClick}>
            <Stack direction="row" gap="10px" alignItems="center">
                <MapIcon />
                <ListItemText>
                    <BaseMenuItemText>{t('streetView.networkMapButton')}</BaseMenuItemText>
                </ListItemText>
            </Stack>
        </MenuItem>
    );
};
