/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Session } from '../../domain/Session';
import { createCommand } from '../../utils';
import { moduleName, sessionZoomedOnMap, SessionShownOnMap } from '../state';

export type ToggleSessionProps = SessionShownOnMap;

export const ZoomOnSessionCommand = createCommand(
    `${moduleName}/ZOOM_ON_SESSION`,
    async (session: Session, { dispatch }) => {
        dispatch(sessionZoomedOnMap(session));
    },
);
