import styled from 'styled-components';
import dashboardBackground from '../../../../assets/img/dashboard-background.svg';

export const DashboardAcquisitionContainer = styled.div`
    background-color: #ebf1ff;
    background-image: url(${dashboardBackground});
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
`;
