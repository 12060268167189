import { useTranslation } from 'react-i18next';
import type { RoadDirection } from '../../core/domain/Session';
import { translateDirection } from '../../core/legacy/utils/translations';

export const useDirectionTranslation = () => {
    const { t } = useTranslation();

    const getRawDirection = (direction: RoadDirection) => translateDirection(direction);
    const getPrefixedDirection = (direction: RoadDirection) => {
        const prefix = t('session.measureDirection');

        return `${prefix} ${getRawDirection(direction).toLowerCase()}`;
    };

    return { getRawDirection, getPrefixedDirection };
};
