import type { SessionId } from '../../domain/Session';
import { createCommand } from '../../utils';
import { moduleName, lastOpenedSessionIdIsSet } from '../state';

export const SetLastOpenedSessionIdCommand = createCommand(
    `${moduleName}/SET_LAST_OPENED_SESSION_ID`,
    async (sessionId: SessionId, { dispatch }) => {
        await dispatch(lastOpenedSessionIdIsSet(sessionId));
    },
);
