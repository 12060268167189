import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
    allRoadDatasIdOfCurrentImageSelector,
    roadDataIdToScrollToSelector,
} from '../../../../../../core/legacy/selectors/streetView';

const useScroll = (roadDataId, summaryOffsetHeight) => {
    const roadDataIdToScrollTo = useSelector(roadDataIdToScrollToSelector);
    const roadDataIdsOfCurrentImage = useSelector(allRoadDatasIdOfCurrentImageSelector);
    const isRoadDataOnImage = (id) => roadDataIdsOfCurrentImage.includes(id);
    const rubricElementRef = useRef(null);

    const scrollTo = () => {
        rubricElementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const shouldScroll = () => {
        if (roadDataIdToScrollTo !== roadDataId) {
            return false;
        }

        if (!isRoadDataOnImage(roadDataId)) {
            return true;
        }

        const rubricElementOverflows = () => {
            const parentNode = rubricElementRef.current?.parentNode;

            const rubricElementOverflowsTop = () => {
                const parentTopDistanceFromWindowTop = parentNode?.getBoundingClientRect()?.top;
                const rubricElementTopDistanceFromWindowTop = rubricElementRef.current?.getBoundingClientRect()?.top;
                const rubricElementTopDistanceFromParentTop =
                    rubricElementTopDistanceFromWindowTop - parentTopDistanceFromWindowTop;

                return rubricElementTopDistanceFromParentTop < 0;
            };
            if (rubricElementOverflowsTop()) {
                return true;
            }

            const rubricElementOverflowsBottom = () => {
                const rubricElementBottomDistanceFromWindowTop = rubricElementRef.current?.getBoundingClientRect()
                    ?.bottom;
                const parentBottomDistanceFromWindowTop = parentNode?.getBoundingClientRect()?.bottom;
                const rubricElementDistanceFromParentBottom =
                    rubricElementBottomDistanceFromWindowTop > parentBottomDistanceFromWindowTop;

                return rubricElementDistanceFromParentBottom > 0;
            };
            if (rubricElementOverflowsBottom()) {
                return true;
            }

            return false;
        };

        if (rubricElementOverflows()) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (shouldScroll()) {
            scrollTo();
        }
    }, [roadDataIdToScrollTo, summaryOffsetHeight]);

    return rubricElementRef;
};

export default useScroll;
