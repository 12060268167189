import type { Expression, LineLayer } from 'mapbox-gl';
import { COLORS } from '../../legacy/components/StreetView/NavigationBar/Itinerary/use-itinerary';
import type { GradeReference } from '../../../core/domain/Roaddata';
import { MAP_GRADE_COLORS, MAP_WIDTH_COLORS } from './map-configuration';
import { WIDTH_PARTIAL_CODE } from '../../../core';

export const SOURCE_NETWORK_SESSION_ID = 'network_session' as const;
export const LAYER_LINE_NETWORK_SESSION_ID = 'network_session' as const;

const OPACITY: Expression = ['case', ['boolean', ['feature-state', 'hover'], false], 0.5, 1];
const WIDTH: Expression = ['case', ['boolean', ['feature-state', 'hover'], false], 6, 3];

const getColorExpression = (selectedReference: GradeReference | null): Expression => {
    if (!selectedReference || selectedReference === 'AGGREGATE') {
        return [
            'case',
            ['to-boolean', ['get', 'aggregatedGrade']],
            ['step', ['get', 'aggregatedGrade'], ...MAP_GRADE_COLORS],
            COLORS.TARGET,
        ];
    }

    const key = typeof selectedReference === 'string' ? selectedReference : selectedReference.key;
    const mapColors = key.includes(WIDTH_PARTIAL_CODE) ? MAP_WIDTH_COLORS : MAP_GRADE_COLORS;

    return [
        'case',
        ['to-boolean', ['get', key, ['get', 'referenceGrades']]],
        ['step', ['get', key, ['get', 'referenceGrades']], ...mapColors],
        COLORS.TARGET,
    ];
};

export const getSessionLayerConfig = (selectedReference: GradeReference | null): LineLayer => ({
    id: LAYER_LINE_NETWORK_SESSION_ID,
    source: SOURCE_NETWORK_SESSION_ID,
    type: 'line',
    paint: {
        'line-color': getColorExpression(selectedReference),
        'line-opacity': OPACITY,
        'line-width': WIDTH,
    },
});
