import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Tag } from '../domain/Roaddata';

const name = 'TAGS';

interface State {
    tags: Tag[];
}

const initialState: State = { tags: [] };

const tagsSlice = createSlice({
    name,
    initialState,
    reducers: {
        tagsInitialized(state: State, { payload }: PayloadAction<State>) {
            state.tags = payload.tags;
        },
    },
});

const {
    reducer: tagsReducer,
    actions: { tagsInitialized },
} = tagsSlice;

export { name as moduleName, tagsInitialized, tagsReducer };
