import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import type { ExternalAiAnalysisPort } from './automatic_analysis/ExternalAiAnalysisPort';
import type { RootState } from '../store';
import type { ExternalAuthenticationPort } from './authentication/ExternalAuthenticationPort';
import type { ExternalSessionsPort } from './sessions/ExternalSessionsPort';
import type { ExternalSessionConfigurationPort } from './sessions/ExternalSessionConfigurationPort';
import type { ExternalIntervalPort } from './automatic_analysis/ExternalIntervalPort';
import type { TimeProviderPort } from '../providers/TimeProviderPort';
import type { DownloadProviderPort } from '../providers/DownloadProviderPort';
import type { SerializableFileProviderPort } from '../providers/SerializableFileProviderPort';
import type { ExternalUploadPort } from './uploads/ExternalUploadPort';
import type { StorageProviderPort } from '../providers/StorageProviderPort';
import type { ExternalCatalogsPort } from '../providers/ExternalCatalogsPort';
import type { ExternalRoadDatasPort } from '../providers/ExternalRoadDatasPort';
import type { ExternalNetworkMapPort } from './networkMap/ExternalNetworkMapPort';
import type MapViewSettingsPort from './networkMap/MapViewSettingsPort';
import type { ExternalTagsPort } from '../providers/ExternalTagsProviderPort';
import type { ExternalOrganizationPort } from '../providers/ExternalOrganizationProviderPort';

export interface Providers {
    externalAuthentication: ExternalAuthenticationPort;
    externalAiAnalysis: ExternalAiAnalysisPort;
    externalSessions: ExternalSessionsPort;
    externalSessionConfiguration: ExternalSessionConfigurationPort;
    externalInterval: ExternalIntervalPort;
    externalUpload: ExternalUploadPort;
    time: TimeProviderPort;
    download: DownloadProviderPort;
    serializableFileProvider: SerializableFileProviderPort;
    storageProvider: StorageProviderPort;
    externalCatalogs: ExternalCatalogsPort;
    externalRoadDatas: ExternalRoadDatasPort;
    externalNetworkMap: ExternalNetworkMapPort;
    mapViewSettings: MapViewSettingsPort;
    externalTags: ExternalTagsPort;
    externalOrganization: ExternalOrganizationPort;
}

export const createCommand = <Args = void>(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<void, Args, { extra: Providers; state: RootState }>,
) => createAsyncThunk<void, Args, { extra: Providers; state: RootState }>(typePrefix, payloadCreator);

const isObject = (item: any) => item && typeof item === 'object' && !Array.isArray(item);

export const mergeDeep = (target: any, source: any) => {
    const output = { ...target };
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target)) Object.assign(output, { [key]: source[key] });
                else {
                    output[key] = mergeDeep(target[key], source[key]);
                }
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }

    return output;
};
