import { Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';

export const AttributeLine: FC<{ label: ReactNode; value: string }> = ({ label, value }) => {
    return (
        <Typography
            sx={{
                wordBreak: 'break-all',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Typography>{label} :</Typography>
            <Typography fontWeight={600}>{value}</Typography>
        </Typography>
    );
};
