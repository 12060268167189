// TODO reimplement with Generic Modal system (cf SessionsList)
import styled from 'styled-components';
import { theme } from '../../../../style/theme';
import PropTypes from 'prop-types';
import BaseModal from '../../../ui/modals/BaseModal';
import React, { forwardRef, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    PrecisionLevelEnum,
    getCalibrationErrorRatesSelector,
    getCalibrationPrecisionLevelSelector,
} from '../../../../../../core/legacy/selectors/calibration';
import { Flex, Text } from '../../../../style';
import Tips from '../../../ui/Tips';

const Headline = styled(Text)`
    font-size: ${theme.font.size.medium2};
`;
const Body = styled(Text)`
    font-size: ${theme.font.size.regular};
`;
const PrecisionLevel = styled(Headline)`
    display: inline;
    font-weight: bold;
`;
const PrecisionLevelExcellent = styled(PrecisionLevel)`
    color: ${theme.color.green.success};
`;
const PrecisionLevelPoor = styled(PrecisionLevel)`
    color: ${theme.color.red.primary};
`;
const ContentContainer = styled(Flex).attrs({ justify: 'left', column: true, align: 'start' })``;

/*
    Modal content part
*/

const DefaultContent = () => {
    const { t } = useTranslation();

    return (
        <>
            <Headline>{t('calibration.modals.generate.content.default.headline')}</Headline>
            <Body>
                <Trans components={{ br: <br /> }} i18nKey="calibration.modals.generate.content.default.body" />
            </Body>
        </>
    );
};

const graduatedContentFactory = (metersErrorRate, colorComponent, headlineTranslationKey, bodyTranslationKey) => {
    return (
        <>
            <Headline>
                <Trans
                    components={{ color: colorComponent }}
                    i18nKey={headlineTranslationKey}
                    values={{ metersErrorRate }}
                />
            </Headline>
            <Body>
                <Trans components={{ br: <br /> }} i18nKey={bodyTranslationKey} />
            </Body>
        </>
    );
};

const ExcellentContent = ({ metersErrorRate }) =>
    graduatedContentFactory(
        metersErrorRate,
        <PrecisionLevelExcellent />,
        'calibration.modals.generate.content.excellent.headline',
        'calibration.modals.generate.content.excellent.body',
    );

const PoorContent = ({ metersErrorRate }) =>
    graduatedContentFactory(
        metersErrorRate,
        <PrecisionLevelPoor />,
        'calibration.modals.generate.content.poor.headline',
        'calibration.modals.generate.content.poor.body',
    );

const useModalContent = (errorRates, precisionLevel) => {
    const [ContentComponent, setContentComponent] = useState(null);
    useEffect(() => {
        switch (precisionLevel) {
            case PrecisionLevelEnum.EXCELLENT:
                setContentComponent(<ExcellentContent metersErrorRate={errorRates.meters} />);
                break;
            case PrecisionLevelEnum.POOR:
                setContentComponent(<PoorContent metersErrorRate={errorRates.meters} />);
                break;
            default:
                setContentComponent(<DefaultContent metersErrorRate={errorRates.meters} />);
                break;
        }
    }, [errorRates]);

    return ContentComponent;
};

/*
    Tips content part
*/

const graduatedTipsFactory = (headlineTranslationKey, bodyTranslationKey) => {
    return <Tips headline={headlineTranslationKey} body={bodyTranslationKey} />;
};

const useTipsContent = (errorRates, precisionLevel) => {
    const { t } = useTranslation();
    const [TipsComponent, setTipsComponent] = useState(null);
    useEffect(() => {
        switch (precisionLevel) {
            case PrecisionLevelEnum.EXCELLENT:
                setTipsComponent(
                    graduatedTipsFactory(
                        t('calibration.modals.generate.tips.excellent.headline'),
                        t('calibration.modals.generate.tips.excellent.body'),
                    ),
                );
                break;
            case PrecisionLevelEnum.POOR:
                setTipsComponent(
                    graduatedTipsFactory(
                        t('calibration.modals.generate.tips.poor.headline'),
                        t('calibration.modals.generate.tips.poor.body'),
                    ),
                );
                break;
            default:
                setTipsComponent(null);
                break;
        }
    }, [errorRates]);

    return TipsComponent;
};

const GenerateCalibrationModal = forwardRef(({ onValidate }, ref) => {
    const { t } = useTranslation();
    const errorRates = useSelector(getCalibrationErrorRatesSelector);
    const precisionLevel = useSelector(getCalibrationPrecisionLevelSelector);
    const ContentComponent = useModalContent(errorRates, precisionLevel);
    const TipsComponent = useTipsContent(errorRates, precisionLevel);
    const [validateDisable, setValidateDisable] = useState(false);

    useEffect(() => {
        setValidateDisable(precisionLevel === PrecisionLevelEnum.POOR);
    }, [precisionLevel]);

    return (
        <BaseModal
            ref={ref}
            onValidate={onValidate}
            cancelButtonText={t('calibration.modals.generate.cancel')}
            validateButtonText={t('calibration.modals.generate.validate')}
            isValidateDisable={validateDisable}
            hideCancelButton
        >
            <ContentContainer>
                {ContentComponent}
                {TipsComponent}
            </ContentContainer>
        </BaseModal>
    );
});

GenerateCalibrationModal.propTypes = {
    onValidate: PropTypes.func.isRequired,
};

export default GenerateCalibrationModal;
