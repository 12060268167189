import { useEffect, FC, MouseEvent } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../types';
import { Summary } from '../components';
import SessionSummary from './SessionSummary';
import { RegularTextBold } from '../../design_system/texts';
import { Title, HeadingContainer, Panel } from './components';
import { ToggleAllSessionsOnMapCommand } from '../../../core/networkMap/commands/ToggleAllSessionsOnMapCommand';
import { ToggleSessionsVisibility } from './ToggleSessionsVisibility';
import { Stack } from '@mui/material';
import GradeFiltersControl from '../Map/GradeFiltersControl';
import { theme } from '../../legacy/style/theme';
import type { Session } from '../../../core/domain/Session';
import useGetSortedSessionsByAiAnalyzed from './use-get-sorted-sessions-by-ai-analyzed';
import { allSessionsAreShownQuery } from '../../../core/networkMap/queries';
import { RefreshNetworkMapExecutionCommand } from '../../../core/networkMap/commands/RefreshNetworkMapExecutionCommand';

const SessionsPanel: FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const allSessionsHaveBeenShown: boolean = useAppSelector(allSessionsAreShownQuery);
    const sortedSessions: Session[] | null = useGetSortedSessionsByAiAnalyzed();

    const handleToggleSessions = (event: MouseEvent) => {
        event.stopPropagation();
        dispatch(ToggleAllSessionsOnMapCommand(!allSessionsHaveBeenShown));
    };

    useEffect(() => {
        setTimeout(() => dispatch(RefreshNetworkMapExecutionCommand()), 10000);

        const intervalId = setInterval(() => {
            dispatch(RefreshNetworkMapExecutionCommand());
        }, 30000);

        return () => clearInterval(intervalId);
    }, []);

    return !sortedSessions ? null : (
        <Panel>
            <HeadingContainer>
                <Title />
            </HeadingContainer>
            <GradeFiltersControl />
            <Accordion
                defaultExpanded
                disableGutters
                elevation={0}
                sx={{
                    boxShadow: 'unset',
                    '&:before': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <AccordionSummary
                    sx={{
                        backgroundColor: theme.color.blue.selected,
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Stack
                        sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: '10px',
                        }}
                    >
                        <RegularTextBold>
                            {t(`networkMap.sessionsPanel.inventory`, { count: sortedSessions?.length ?? 0 })}
                        </RegularTextBold>
                        <ToggleSessionsVisibility
                            isVisible={allSessionsHaveBeenShown}
                            handleClick={handleToggleSessions}
                        />
                    </Stack>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        height: '74vh',
                        overflow: 'auto',
                        backgroundColor: theme.color.blue.selected,
                        padding: 0,
                    }}
                >
                    <Summary>
                        {sortedSessions.map((session) => (
                            <SessionSummary key={session.id} session={session} />
                        ))}
                    </Summary>
                </AccordionDetails>
            </Accordion>
        </Panel>
    );
};

export default SessionsPanel;
