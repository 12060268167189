import type { GeoJsonProperties } from 'geojson';
import type { MapLayerMouseEvent, MapboxGeoJSONFeature } from 'mapbox-gl';
import { useCallback, useState } from 'react';
import type { FeatureManager } from '../feature-manager';

export type UseCreatePopupHoverOutput<T extends GeoJsonProperties> = {
    popupOnHover: (event: MapLayerMouseEvent) => void;
    hideHoverPopup: () => void;
    popupIsHovered: boolean;
    popupCoordinates: [number, number] | null;
    networkProperties: T | null;
};

export default <T extends GeoJsonProperties>(
    fm: FeatureManager,
    layer: string,
    source: string,
    sourceLayer?: string,
): UseCreatePopupHoverOutput<T> => {
    const [, setDisplayPopup] = useState<boolean>(false);
    const [popupIsShown, setPopupIsShown] = useState<boolean>(false);
    const [popupCoordinates, setPopupCoordinates] = useState<[number, number] | null>(null);
    const [networkProperties, setNetworkProperties] = useState<T | null>(null);

    const popupOnHover = useCallback((event: MapLayerMouseEvent) => {
        const showPopup = () => setDisplayPopup(true);
        const feature = fm.getClickedFeature(event);

        if (!(feature && feature.layer.id === layer)) {
            setPopupIsShown(false);
            fm.restoreFeatureStyle({ source, sourceLayer });
            const map = fm.getCurrentMap();
            if (map) {
                map.getCanvas().style.cursor = '';
            }
        }

        if (feature && feature.layer.id === layer && !popupIsShown) {
            setPopupCoordinates([event.lngLat.lat, event.lngLat.lng]);
            setNetworkProperties({
                ...((feature as MapboxGeoJSONFeature).properties as T),
            });
            fm.setFeatureStyleToHover(feature);
            setPopupIsShown(true);
            const map = fm.getCurrentMap();
            if (map) {
                map.getCanvas().style.cursor = 'pointer';
            }
            showPopup();
        }
    }, []);

    return {
        popupOnHover,
        hideHoverPopup: () => setDisplayPopup(false),
        popupIsHovered: popupIsShown,
        popupCoordinates,
        networkProperties,
    };
};
