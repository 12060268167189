import { FC, useCallback, useState } from 'react';
import {
    isAnalysisDoneOnSessionQuery,
    isAnalysisOngoingOnSessionQuery,
} from '../../../../../../core/automatic_analysis/queries';
import type { SessionId } from '../../../../../../core/domain/Session';
import { useAppSelector } from '../../../../../../types';
import { ExecutionDoneBaner } from './ExecutionDoneBaner';
import { ExecutionOngoingBaner } from './ExecutionOngoingBaner';

type Props = {
    sessionId: SessionId;
};

type States = 'notInit' | 'Ongoing' | 'DoneWithBaner' | 'DoneWithoutBaner';

export const ExecutionBaner: FC<Props> = ({ sessionId }) => {
    const [previousSession, setPreviousSession] = useState(sessionId);
    const [state, setState] = useState<States>('notInit');

    const isAnalysisOngoing = useAppSelector(isAnalysisOngoingOnSessionQuery(sessionId));
    const isAnalysisDone = useAppSelector(isAnalysisDoneOnSessionQuery(sessionId));

    const handleClose = useCallback(() => {
        setState('DoneWithoutBaner');
    }, [setState]);

    if (previousSession !== sessionId) {
        setState('notInit');
        setPreviousSession(sessionId);
    }

    if (isAnalysisOngoing && state === 'notInit') {
        setState('Ongoing');
    }

    if (isAnalysisDone && state === 'Ongoing') {
        setState('DoneWithBaner');
    }

    if (state === 'Ongoing') {
        return <ExecutionOngoingBaner sessionId={sessionId} />;
    }

    if (state === 'DoneWithBaner') {
        return <ExecutionDoneBaner onClose={handleClose} />;
    }

    return null;
};
