import styled from 'styled-components';

export const Container = styled.div`
    background-color: grey;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    border: ${(props) => props.borderWidth}px solid white;
    position: absolute;
    top: 70px;
    right: 0;
    overflow: hidden;
`;

export const Viewport = styled.div.attrs((props) => ({
    style: {
        backgroundPosition: `-${props.left}px -${props.top}px`,
    },
}))`
    background-image: url('${(props) => props.imageUrl}');
    background-size: ${(props) => props.imageWidth}px ${(props) => props.imageHeight}px;
    width: 100%;
    height: 100%;
`;

export const Cross = styled.div.attrs((props) => ({
    style: {
        bottom: `${props.containerHeight / 2 + props.containerBorderWidth - props.length / 2 + props.shiftY}px`,
        left: `${props.containerWidth / 2 + props.containerBorderWidth - props.thickness / 2 - props.shiftX}px`,
    },
}))`
    background: ${(props) => props.backgroundColor};
    height: ${(props) => props.length}px;
    width: ${(props) => props.thickness}px;
    position: absolute;

    &:after {
        background: ${(props) => props.backgroundColor};
        content: '';
        height: ${(props) => props.thickness}px;
        left: -${(props) => (props.length - props.thickness) / 2}px;
        position: absolute;
        top: ${(props) => (props.length - props.thickness) / 2}px;
        width: ${(props) => props.length}px;
    }
`;
