import GenericModal, { GenericModalProps } from '../GenericModal';
import Tips from '../../Tips';
import ResultIcon from '../ResultIcon';
import { ContentContainer, ModalCenterTitle } from '../styles';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

interface SuccessModalWithTipProps extends Pick<GenericModalProps, 'modalType' | 'onValidate' | 'options'> {
    body: string;
    headline: string;
    title: string;
}

const SuccessModalWithTip: FC<SuccessModalWithTipProps> = ({ body, headline, options = {}, title, ...props }) => {
    const { t } = useTranslation();
    const opts = { ...options, hideCancelButton: true, centerButtons: true, notDismissable: true };

    return (
        <GenericModal {...props} options={opts} validateButtonText={t('labels.wellNoted')}>
            <ContentContainer>
                <ResultIcon />
                <ModalCenterTitle>{title}</ModalCenterTitle>
                <Tips headline={headline} body={body} icon="bell" />
            </ContentContainer>
        </GenericModal>
    );
};

export default SuccessModalWithTip;
