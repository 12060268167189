import CalibrationPointTypeEnum from './CalibrationPointTypeEnum';

const getAttributeByType = (type) => (calibrationPoint) =>
    calibrationPoint.attributes.find((attribute) => attribute.type === type);

export const getPixelXAttribute = (calibrationPoint) =>
    getAttributeByType(CalibrationPointTypeEnum.PIXEL_X)(calibrationPoint);
export const getPixelYAttribute = (calibrationPoint) =>
    getAttributeByType(CalibrationPointTypeEnum.PIXEL_Y)(calibrationPoint);
export const getCoordinateXAttribute = (calibrationPoint) =>
    getAttributeByType(CalibrationPointTypeEnum.COORDINATE_X)(calibrationPoint);
export const getCoordinateYAttribute = (calibrationPoint) =>
    getAttributeByType(CalibrationPointTypeEnum.COORDINATE_Y)(calibrationPoint);
export const getCoordinateZAttribute = (calibrationPoint) =>
    getAttributeByType(CalibrationPointTypeEnum.COORDINATE_Z)(calibrationPoint);

export const getPixelXValue = (calibrationPoint) => getPixelXAttribute(calibrationPoint).value;
export const getPixelYValue = (calibrationPoint) => getPixelYAttribute(calibrationPoint).value;
export const getCoordinateXValue = (calibrationPoint) => getCoordinateXAttribute(calibrationPoint).value;
export const getCoordinateYValue = (calibrationPoint) => getCoordinateYAttribute(calibrationPoint).value;
export const getCoordinateZValue = (calibrationPoint) => getCoordinateZAttribute(calibrationPoint).value;

export const isPixelAttribute = (type) =>
    type === CalibrationPointTypeEnum.PIXEL_X || type === CalibrationPointTypeEnum.PIXEL_Y;

export const isCoordinateAttribute = (type) =>
    type === CalibrationPointTypeEnum.COORDINATE_X ||
    type === CalibrationPointTypeEnum.COORDINATE_Y ||
    type === CalibrationPointTypeEnum.COORDINATE_Z;

export const calibrationPointToAnnotationMapper = (calibrationPoint) => ({
    id: calibrationPoint.id,
    name: 'Calibration',
    isClosed: true,
    coordinates: [
        {
            x: getPixelXValue(calibrationPoint),
            y: getPixelYValue(calibrationPoint),
        },
    ],
});
