import { playerGoNext, playerGoPrevious } from '../../../../../core/legacy/actions';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { isMovingSessionQuery } from '../../../../../core/streetViewer/queries';

const useScrollNavigation = () => {
    const dispatch = useAppDispatch();
    const isMovingSession = useAppSelector(isMovingSessionQuery);

    let blocked = false;
    let timeout: ReturnType<typeof setTimeout> | undefined;
    const moveDelay = 250;

    const moveInSession = (deltaY: number) => {
        if (blocked || !isMovingSession) {
            return;
        }
        blocked = true;
        timeout = setTimeout(() => {
            if (deltaY < 0) {
                dispatch(playerGoNext());
            }
            if (deltaY > 0) {
                dispatch(playerGoPrevious());
            }
            blocked = false;
        }, moveDelay);
    };

    useEffect(() => {
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, []);

    return moveInSession;
};

export default useScrollNavigation;
