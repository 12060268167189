import styled from 'styled-components';
import { SimpleBlock } from '../../../../style';

export const Block = styled(SimpleBlock)`
    border: ${({ selected, theme }) => (selected ? `solid 1px ${theme.color.blue.primary}` : 'none')};
    box-shadow: ${({ selected, externalHover, theme }) =>
        // eslint-disable-next-line no-nested-ternary
        selected ? theme.boxShadow.main : externalHover ? theme.boxShadow.medium : 'none'};
    &:hover {
        cursor: pointer;
        box-shadow: ${({ selected, theme }) => (selected ? theme.boxShadow.main : theme.boxShadow.medium)};
    }
`;
