import type { StorageProviderPort } from './StorageProviderPort';

const SELECTED_RUBRICS_KEY = 'filteredRubricIds';
const SELECTED_REFERENCES_KEY = 'filteredReferenceIds';
const IS_FILTER_APPLIED_KEY = 'isFilterApplied';

const SEPARATOR = ',';

export class StorageProvider implements StorageProviderPort {
    setReferenceIds(referenceIds: string[]): void {
        if (referenceIds) {
            return localStorage.setItem(SELECTED_REFERENCES_KEY, referenceIds.join(SEPARATOR));
        }

        return localStorage.removeItem(SELECTED_REFERENCES_KEY);
    }

    getReferenceIds(): string[] | null {
        return (
            localStorage
                .getItem(SELECTED_REFERENCES_KEY)
                ?.split(SEPARATOR)
                ?.filter((e) => e !== '') ?? null
        );
    }

    getIsFilterApplied(): boolean {
        return localStorage.getItem(IS_FILTER_APPLIED_KEY) === 'true';
    }

    getRubricIds(): string[] | null {
        return (
            localStorage
                .getItem(SELECTED_RUBRICS_KEY)
                ?.split(SEPARATOR)
                ?.filter((e) => e !== '') ?? null
        );
    }

    setIsFilterApplied(isFilterApplied: boolean): void {
        localStorage.setItem(IS_FILTER_APPLIED_KEY, isFilterApplied.toString());
    }

    setRubricIds(rubricIds: string[]): void {
        if (rubricIds) {
            return localStorage.setItem(SELECTED_RUBRICS_KEY, rubricIds.join(SEPARATOR));
        }

        return localStorage.removeItem(SELECTED_RUBRICS_KEY);
    }
}
