import React from 'react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import { ThemeProvider as MUIThemeProvider } from '@mui/material';
import { AppRoutes } from './Routes';
import { GlobalStyles, theme } from './legacy/style/theme';
import type { AppHistory } from '../store';
import type { Store } from 'redux';
import { Provider } from 'react-redux';
import muiTheme from './style/theme';

export const App: React.FC<{ store: Store; history: AppHistory }> = ({ history, store }) => (
    <Provider store={store}>
        <MUIThemeProvider theme={muiTheme}>
            <ThemeProvider theme={theme}>
                <Router history={history}>
                    <GlobalStyles />
                    <React.StrictMode>
                        <ModalProvider>
                            <AppRoutes />
                        </ModalProvider>
                    </React.StrictMode>
                </Router>
            </ThemeProvider>
        </MUIThemeProvider>
    </Provider>
);
