import { render } from 'react-dom';
import type { Store } from 'redux';
import type { AppHistory } from '../store';
import { App } from './App';
import ErrorBoundary from './ErrorBoundary';
import { LicenseInfo } from '@mui/x-license';

export const buildUi = (isProd: boolean, store: Store, history: AppHistory): void => {
    const container = document.getElementById('root');
    LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_LICENSE_KEY as string);

    if (isProd) {
        render(
            <ErrorBoundary>
                <App store={store} history={history} />
            </ErrorBoundary>,
            container,
        );
    } else {
        render(<App store={store} history={history} />, container);
    }
};
