import { createCommand } from '../../utils';
import { filterUpdated, moduleName } from '../state';
import type { SessionFilter } from '../../domain/Session';

const FilterSessionCommand = createCommand(
    `${moduleName}/FILTER_SESSIONS`,
    async (sessionFilter: SessionFilter, { dispatch, extra }) => {
        extra.externalSessionConfiguration.saveSessionsFilter(sessionFilter);
        dispatch(filterUpdated(sessionFilter));
    },
);

export default FilterSessionCommand;
