import { ReactComponent } from '../../../assets/img/roadcare-logo.svg';
import type { FC } from 'react';
import { Box } from '@mui/material';

type Props = {
    height?: string;
    width?: string;
};

export const RoadcareLogoWhite: FC<Props> = ({ width, height }) => {
    return (
        <Box
            sx={{
                '&>svg': {
                    display: 'block',
                    height: height ?? 'unset',
                    width: width ?? 'unset',
                },
            }}
        >
            <ReactComponent />
        </Box>
    );
};
