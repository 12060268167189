/* eslint-disable prettier/prettier */
import { styled as styledMUI } from '@mui/material';
import Menu from '@mui/material/Menu';
import styled from 'styled-components';
import { ButtonIcon as ButtonIconBase, Flex } from '../../../style';

export const Container = styled(Flex).attrs((props) => ({
    ...props,
    justify: 'left',
    align: 'stretch',
}))`
    width: auto;
    max-width: 83%;
    margin: 0;
    max-height: 64px;
`;

export const ButtonContainer = styled(Flex).attrs({
    align: 'center',
})`
    cursor: pointer;
    min-width: 64px;

    background-color: ${({ hovered, selected, theme }) => {
        if (hovered) {
            return `${theme.color.black.main}B3`;
        }
        if (selected) {
            return `${theme.color.blue.primary3}FF`;
        }

        return `${theme.color.grey.dark}80`;
    }};

    &:first-child {
        border-radius: 8px 0 0 8px;
    }
    &:last-child {
        border-radius: 0 8px 8px 0;
    }

    &:hover {
        background-color: ${({ theme, selected }) =>
        selected ? `${theme.color.blue.primary3}FF` : `${theme.color.black.main}B3`};
    }
`;

export const Button = styled(ButtonIconBase).attrs((props) => ({
    ...props,
    color: props.theme.color.white.main,
}))`
    border-radius: 5px;
    min-width: 64px;
    &:hover {
        & svg {
            opacity: 1;
        }
    }
`;

export const StyledMenu = styledMUI((props) => (
    // eslint-disable-next-line react/jsx-filename-extension
    <Menu
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        style={{
            top: '20px',
        }}
        {...props}
    />
), {
    shouldForwardProp: (prop) => prop !== 'getContentAnchorEl'
})(() => ({
    '': {
        cursor: 'pointer',
    },
    '& .MuiPaper-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
}));
