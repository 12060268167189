import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    hoveredCalibrationPointIdSelector,
    selectedCalibrationPointIdSelector,
} from '../../../../../core/legacy/selectors/calibration';
import { clickStyle, hoverStyle } from '../../ui/annotation-engine/drawing-styles';

const useSortAndStyleAnnotations = (sortedAnnotations, setSortedAnnotations, styleOps) => {
    const hoveredAnnotationId = useSelector(hoveredCalibrationPointIdSelector);
    const selectedAnnotationId = useSelector(selectedCalibrationPointIdSelector);

    const getAnnoIndexInImageReferential = (annoIndex) =>
        sortedAnnotations.findIndex((annotation) => annotation?.id === annoIndex);

    const getNeitherSelectedNorHoveredAnnotations = () =>
        sortedAnnotations.filter(
            (annotation) => annotation?.id !== hoveredAnnotationId && annotation?.id !== selectedAnnotationId,
        );

    const isAnnotationAlreadyInForeground = (annoId) => {
        const indexOfAnnotationInImageReferential = getAnnoIndexInImageReferential(annoId);

        return indexOfAnnotationInImageReferential === sortedAnnotations.length - 1;
    };

    const getReorganizedAnnotations = (annotationToMoveToForegroundId) => {
        const annotationsToDisplay = [...getNeitherSelectedNorHoveredAnnotations()];

        if (selectedAnnotationId && hoveredAnnotationId && selectedAnnotationId !== hoveredAnnotationId) {
            annotationsToDisplay.push(
                sortedAnnotations.find((annotation) => annotation.id === selectedAnnotationId),
                sortedAnnotations.find((annotation) => annotation.id === hoveredAnnotationId),
            );
        } else {
            annotationsToDisplay.push(
                sortedAnnotations.find((annotation) => annotation.id === annotationToMoveToForegroundId),
            );
        }

        return annotationsToDisplay;
    };

    const sortAnnotationsWithAnnoInForeground = (annotationToMoveToForegroundId) => {
        if (
            annotationToMoveToForegroundId === undefined ||
            sortedAnnotations?.length === 0 ||
            isAnnotationAlreadyInForeground(annotationToMoveToForegroundId)
        ) {
            return sortedAnnotations;
        }

        return getReorganizedAnnotations(annotationToMoveToForegroundId);
    };

    useEffect(() => {
        if (!hoveredAnnotationId && !selectedAnnotationId) {
            return styleOps.removeStylesFromAnnotationsByStyleNames(hoverStyle.name, clickStyle.name);
        }

        if (hoveredAnnotationId && hoveredAnnotationId !== selectedAnnotationId) {
            const newSortedPoints = sortAnnotationsWithAnnoInForeground(hoveredAnnotationId);
            setSortedAnnotations(newSortedPoints);

            return styleOps.setStyleExclusivelyToAnnotationId(hoverStyle, hoveredAnnotationId);
        }
        const newSortedPoints = sortAnnotationsWithAnnoInForeground(selectedAnnotationId);
        setSortedAnnotations(newSortedPoints);

        return styleOps.setStyleExclusivelyToAnnotationIdAndRemoveAllStyles(clickStyle, selectedAnnotationId);
    }, [hoveredAnnotationId, selectedAnnotationId]);
};

export default useSortAndStyleAnnotations;
