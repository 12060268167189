import { useState, useEffect } from 'react';
import { InitAiAnalysisCommand } from '../../../core/automatic_analysis/InitAiAnalysisCommand';
import { LoadNetworkMapDataCommand } from '../../../core/networkMap/commands/LoadNetworkMapDataCommand';
import { SelectGradeReferenceForOrganizationCommand } from '../../../core/networkMap/commands/SelectGradeReferenceForOrganizationCommand';
import { InitSessionsCommand } from '../../../core/sessions/commands/InitSessionsCommand';
import { sessionsQuery } from '../../../core/sessions/queries';
import { useAppDispatch, useAppSelector } from '../../../types';

const useLoadNetworkData = (): boolean => {
    const dispatch = useAppDispatch();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [sessionsAreLoading, setSessionsAreLoading] = useState<boolean>(false);
    const sessions = useAppSelector(sessionsQuery);

    useEffect(() => {
        const initSessionsCommand = async () => {
            await dispatch(InitSessionsCommand());
            await dispatch(InitAiAnalysisCommand());
        };
        if (!sessionsAreLoading) {
            initSessionsCommand();
            setSessionsAreLoading(true);
        }
    }, []);

    useEffect(() => {
        const loadNetworkMapDataCommand = async () => {
            await dispatch(LoadNetworkMapDataCommand());
            await dispatch(SelectGradeReferenceForOrganizationCommand());
        };
        if (sessionsAreLoading && !dataLoaded && sessions.length > 0) {
            setDataLoaded(true);
            loadNetworkMapDataCommand();
        }
    }, [sessions]);

    return dataLoaded;
};

export default useLoadNetworkData;
