const container = document.getElementById('root');
const { backendUrl } = container?.dataset ?? { backendUrl: '' };

const baseUrlFinder = () => {
    if ('development' === process.env.NODE_ENV) {
        return process.env.REACT_APP_API_URL;
    }

    return backendUrl;
};

const apiEndpoint = baseUrlFinder();

export default apiEndpoint;
