import * as Sentry from '@sentry/browser';
import { SubmissionError } from 'redux-form';
import apiEndpoint from '../../../core/legacy/utils/api';
import axios from 'axios';
import { DELETE, GET, PATCH, POST, PUT } from '../../../core/legacy/utils/http';
// TODO remove axios, use only http-based methods

export const downloadSessionBlobImageRequest = async (url) => {
    const res = await GET(url, null, { jsonify: false });
    const blob = await res.blob();

    return URL.createObjectURL(blob);
};

export const updateAttributeValueOfRoadDataRequest = async (sessionId, roadData, attributeValue, newValue) => {
    try {
        return await PUT(
            `${apiEndpoint}/sessions/${sessionId}/road-datas/${roadData.id}/attribute-values/${attributeValue.id}`,
            newValue,
        );
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileUpdatingAttributeValue' });
    }
};

export const updateRoadDataReferenceRequest = async (sessionId, roadData, reference) => {
    try {
        return PUT(`${apiEndpoint}/sessions/${sessionId}/road-datas/${roadData.id}/reference`, reference);
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileUpdatingAttributeValue' });
    }
};

export const deleteAnnotationRequest = async (id) => {
    try {
        await DELETE(`${apiEndpoint}/annotation/delete/${id}`);
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileDeletingAnnotation' });
    }
};

export const getAnnotationsForImageRequest = async (
    imageId,
    rubricToDisplayIds,
    referenceToDisplayIds,
    taggedElementsOnly,
    imageRoaddataOnly,
) => {
    try {
        if (rubricToDisplayIds.length === 0) {
            return [];
        }

        const { data } = await axios.request({
            method: 'POST',
            url: `${apiEndpoint}/image/${imageId}/annotations`,
            data: {
                rubricToDisplayIds,
                referenceToDisplayIds,
                taggedElementsOnly,
                imageRoaddataOnly,
            },
            withCredentials: true,
        });

        return data;
    } catch (e) {
        console.error(e);
        throw new SubmissionError({ _error: 'serverErrors.whileGettingAnnotations' });
    }
};

export const addAnnotationRequest = async ({ rubricId, imageId, annotationPoints, reference }) => {
    try {
        return POST(`${apiEndpoint}/annotation/add`, {
            rubricId,
            imageId,
            pointsInImage: annotationPoints,
            reference,
        });
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileAddingAnnotation' });
    }
};

export const saveAnnotationRequest = async (imageId, annotation, annotationPoints) => {
    try {
        return await PUT(`${apiEndpoint}/annotation/update/${annotation.roadData.id}`, {
            imageId,
            pixels: annotationPoints,
        });
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileUpdatingAnnotation' });
    }
};

// FIXME LATER add translations for all server errors
export const getStreetViewRequest = async (sessionId) => {
    try {
        return await GET(`${apiEndpoint}/sessions/${sessionId}/streetview`);
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileGettingMultipleImages' });
    }
};

export const getStreetviewImageFirstRequest = async (sessionId) => {
    try {
        return await GET(`${apiEndpoint}/sessions/${sessionId}/streetview/first`);
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileGettingMultipleImages' });
    }
};

export const getStreetviewImageLastRequest = async (sessionId) => {
    try {
        return await GET(`${apiEndpoint}/sessions/${sessionId}/streetview/last`);
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileGettingMultipleImages' });
    }
};

export const getStreetviewImagePreviousRequest = async (sessionId, imageIndex) => {
    try {
        return await GET(`${apiEndpoint}/sessions/streetview/${sessionId}/${imageIndex}/previous`);
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileGettingMultipleImages' });
    }
};

export const getStreetviewImageNextRequest = async (sessionId, imageIndex) => {
    try {
        return await GET(`${apiEndpoint}/sessions/streetview/${sessionId}/${imageIndex}/next`);
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileGettingMultipleImages' });
    }
};

export const getImageByRoadDataRequest = async (sessionId, roadDataId) => {
    try {
        const images = await GET(
            `${apiEndpoint}/sessions/${sessionId}/images?roadData=${roadDataId}&sort=desc&limit=1`,
        );

        return images[0];
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileGettingOneImage' });
    }
};

export const getImageLandmarkRequest = async (sessionId, imageId) => {
    try {
        return await GET(`${apiEndpoint}/sessions/${sessionId}/images/${imageId}/landmark`);
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileGettingImageData' });
    }
};

export const getImageByCumulStartRequest = async (sessionId, cumulStart) => {
    try {
        const image = await GET(`${apiEndpoint}/sessions/${sessionId}/images/before/${cumulStart}`);

        return image;
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileGettingImageData' });
    }
};

export const uploadCalibrationFileRequest = async (formData) => {
    try {
        const response = await POST(`${apiEndpoint}/calibration/upload`, formData, { jsonify: false });

        if (!response.ok) {
            throw response;
        }
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'Erreur de serveur' });
    }
};

export const exportSessionRequest = async (exportData) => {
    try {
        const response = await POST(`${apiEndpoint}/annotation/export`, exportData, {
            jsonify: false,
        });
        if (!response.ok) {
            throw response;
        }

        return response.blob();
    } catch (e) {
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'Erreur de serveur', response: e });
    }
};

export const getNewCalibrationProcessRequest = async (imageId) => {
    try {
        const calibrationProcess = await POST(`${apiEndpoint}/calibration-process`, {
            imageId,
        });

        return calibrationProcess;
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileCreatingCalibrationPoint' });
    }
};

export const updateCalibrationPointRequest = async (calibrationProcessId, calibrationPoint, pointData) => {
    try {
        return await PATCH(
            `${apiEndpoint}/calibration-process/${calibrationProcessId}/calibration-point/${calibrationPoint.id}`,
            {
                pointData,
            },
        );
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileUpdatingCalibrationPoint' });
    }
};

export const updateCalibrationPixelsRequest = async (calibrationProcessId, calibrationPoint, pointData) => {
    try {
        return await PATCH(
            `${apiEndpoint}/calibration-process/${calibrationProcessId}/calibration-point/${calibrationPoint.id}`,
            {
                pointData,
            },
        );
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileUpdatingCalibrationPoint' });
    }
};

export const deleteCalibrationPointRequest = async (calibrationProcessId, calibrationPointId) => {
    try {
        await DELETE(
            `${apiEndpoint}/calibration-process/${calibrationProcessId}/calibration-point/${calibrationPointId}`,
        );
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileDeletingCalibrationPoint' });
    }
};

export const addCalibrationPointRequest = async (calibrationProcessId, pixels) => {
    try {
        const calibrationProcess = await POST(
            `${apiEndpoint}/calibration-process/${calibrationProcessId}/calibration-point`,
            {
                pixels,
            },
        );

        return calibrationProcess;
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileCreatingCalibrationPoint' });
    }
};

export const updateRoadDataTagsRequest = async (sessionId, roadDataId, tags) => {
    try {
        return await PUT(`${apiEndpoint}/sessions/${sessionId}/road-datas/${roadDataId}/tags`, tags);
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileDeletingAnnotation' });
    }
};
