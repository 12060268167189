import { Tooltip, Typography } from '@mui/material';
import type { FC } from 'react';
import { tooltipCell } from './styles';

interface SessionStringDisplayProps {
    data: string;
    fullCell?: boolean;
    stopPropagation?: boolean;
}

const SessionStringDisplay: FC<SessionStringDisplayProps> = ({ data, fullCell, stopPropagation = true }) => (
    <Tooltip title={data}>
        <Typography
            onClick={(e) => {
                if (stopPropagation) e.stopPropagation();
            }}
            sx={{ ...tooltipCell, ...(fullCell && { height: 1, width: 1 }) }}
        >
            {data}
        </Typography>
    </Tooltip>
);

export default SessionStringDisplay;
