import { Typography } from '@mui/material';
import { FC, MouseEvent, ReactNode, useMemo } from 'react';
import { theme } from '../../../legacy/style/theme';

type Props = {
    onClick?: () => void;
    children: ReactNode;
    highlight?: boolean;
};

export const PopupButton: FC<Props> = ({ onClick, children, highlight = false }) => {
    const handleClick = useMemo(() => {
        if (onClick) {
            return (event: MouseEvent<HTMLSpanElement>) => {
                event.stopPropagation();
                onClick();
            };
        }

        return () => {};
    }, [onClick]);

    return (
        <Typography
            fontSize={theme.font.size.medium1}
            onClick={handleClick}
            color={highlight ? theme.color.white.main : theme.color.blue.primary3}
            bgcolor={highlight ? theme.color.blue.primary3 : theme.color.white.main}
            style={{
                cursor: 'pointer',
                padding: highlight ? '10px' : '5px',
                textAlign: 'center',
                borderRadius: '5px',
                fontWeight: highlight ? 600 : 500,
                textDecoration: highlight ? 'none' : 'underline',
            }}
        >
            {children}
        </Typography>
    );
};
