import styled from 'styled-components';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { PrimaryButton as PrimaryButtonBase } from '../../../../style';
import { theme } from '../../../../style/theme';
import { StyledModal, ButtonsContainer, CloseContainer, ContentContainer } from './styles';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';
import { ReactComponent as SuccessIcon } from '../../../../../../assets/icons/check-circle.svg';

const ValidateButton = styled(PrimaryButtonBase)`
    margin: 0 0px;
    width: auto !important;
    padding: 0 16px;
    &:hover {
        background-color: ${theme.color.blue.primary2} !important;
    }
`;

const Close = styled(CloseIcon)`
    cursor: pointer;
    height: 13px;
`;

const Success = styled(SuccessIcon)`
    color: ${theme.color.blue.primary};
    margin-bottom: 10px;
`;

// FIXME: add factory to have error, warning, info icons
// for now, only success

const ResultModal = forwardRef(({ children, onCancel, onValidate, validateButtonText }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const cancelModal = () => {
        hideModal();
        if (onCancel) {
            onCancel();
        }
    };

    const saveModal = () => {
        hideModal();
        if (onValidate) {
            onValidate();
        }
    };

    useImperativeHandle(ref, () => {
        return { openModal };
    });

    return (
        <StyledModal isOpen={isOpen} onEscapeKeydown={() => hideModal()} onBackgroundClick={() => hideModal()}>
            <CloseContainer>
                <Close onClick={() => cancelModal()} />
            </CloseContainer>
            <ContentContainer>
                <Success />
                {children}
            </ContentContainer>
            <ButtonsContainer>
                <ValidateButton onClick={() => saveModal()} type="button">
                    {validateButtonText}
                </ValidateButton>
            </ButtonsContainer>
        </StyledModal>
    );
});

ResultModal.propTypes = {
    onCancel: PropTypes.func,
    onValidate: PropTypes.func,
    children: PropTypes.node,
    validateButtonText: PropTypes.string.isRequired,
};

export default ResultModal;
