import { setModalToDisplay, unsetModalToDisplay } from '../legacy/actions/modals';
import { SessionsModalTypes } from '../legacy/reducers/modals';
import { createCommand } from '../utils';
import { sessionIdsForAnalysesQuery, modelCodesPerSessionId } from './queries';
import { analysisStarted, moduleName } from './state';

export const TriggerAnalysisOnSessionsCommand = createCommand(
    `${moduleName}/TRIGGER_ANALYSIS_ON_SESSIONS_COMMAND`,
    async (modelCodes: string[], { dispatch, getState, extra }) => {
        dispatch(unsetModalToDisplay);

        const state = getState();
        const sessionIds = sessionIdsForAnalysesQuery(state);
        const modelsPerSession = modelCodesPerSessionId(state);

        try {
            await Promise.all(
                sessionIds.map(async (sessionId) => {
                    const filteredModelsCodes = modelCodes.filter(
                        (code) => !modelsPerSession[sessionId]?.includes(code),
                    );

                    for (const modelCode of filteredModelsCodes) {
                        // eslint-disable-next-line no-await-in-loop
                        const execution = await extra.externalAiAnalysis.triggerAnalysis(modelCode, sessionId);
                        dispatch(analysisStarted(execution));
                    }
                }),
            );

            dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_ML_SUCCESS));
        } catch (err) {
            dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_ML_FAILURE));
        }
    },
);
