import { useEffect } from 'react';
import { useMap } from 'react-map-gl';

const loadImage = (src: string, height?: number, width?: number): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new window.Image(height, width);
        image.addEventListener('error', reject);
        image.addEventListener('load', () => {
            resolve(image);
        });
        image.src = src;
    });

interface ImageProps {
    name: string;
    src: string;
    height?: number;
    width?: number;
    sdf?: boolean;
}

const Image = ({ name, src, height, width, sdf = false }: ImageProps) => {
    const map = useMap().current;

    useEffect(() => {
        if (!map) {
            return;
        }

        map.on('style.load', async () => {
            map.addImage(name, await loadImage(src, height, width), { sdf });
        });
    }, [map]);

    return null;
};

export default Image;
