import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

const slice = (state: RootState) => state.uploads;

export const sessionUploadsQuery = createSelector(slice, (state) => state.sessionUploads);
export const sessionUploadByUrlQuery = (url: string) =>
    createSelector(slice, (state) => state.sessionUploads.find((sessionUpload) => sessionUpload.url === url));

export const sessionUploadUploadedPercentageQuery = (url: string) =>
    createSelector(slice, (state) => {
        const sessionUpload = state.sessionUploads.find((upload) => upload.url === url);
        if (!sessionUpload) {
            throw new Error(`No session upload found for url ${url}`);
        }

        const { totalSize, uploadedSize } = sessionUpload;

        return (uploadedSize / totalSize) * 100;
    });
