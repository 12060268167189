import { uploadCalibrationFileRequest } from '../../../providers/legacy/datasources/sessionDataSource';
import { loaderStarted, loaderStopped } from '../../loader/state';
import { InitSessionsCommand } from '../../sessions/commands/InitSessionsCommand';
import { SetLastOpenedSessionIdCommand } from '../../sessions/commands/SetLastOpenedSessionIdCommand';
import { targettedSessionIdsForInteractionQuery } from '../../sessions/queries';
import { SessionsModalTypes } from '../reducers/modals';
import { setModalToDisplay, unsetModalToDisplay } from './modals';

export const uploadCalibrationFile = (calibration, shouldRecalculCalibration) => async (dispatch, getState) => {
    const state = getState();
    const targetedSessionIds = targettedSessionIdsForInteractionQuery(state);
    await dispatch(unsetModalToDisplay());
    await dispatch(loaderStarted());
    const file = calibration[0];
    const formData = new FormData();
    formData.append('calibration', file, file.name.slice(0, file.name.lastIndexOf('.')));
    formData.append('selectedSessionIds', targetedSessionIds.join(','));
    formData.append('shouldRecalculCalibration', shouldRecalculCalibration);
    try {
        await uploadCalibrationFileRequest(formData);

        await dispatch(loaderStopped());
        if (targetedSessionIds[0]) {
            await dispatch(SetLastOpenedSessionIdCommand(targetedSessionIds[0]));
        }
        await dispatch(InitSessionsCommand());
        if (shouldRecalculCalibration) {
            await dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_CALIBRATE_ONGOING));
        } else {
            await dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_CALIBRATE_SUCCESS));
        }
    } catch (error) {
        await dispatch(loaderStopped());
        await dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_CALIBRATE_FAILURE));
    }
};
