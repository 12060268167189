import FailureModal from '../../ui/modals/FailureModal';
import { useTranslation } from 'react-i18next';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';

const GenerateCalibrationFailureModal = () => {
    const { t } = useTranslation();

    return (
        <FailureModal
            title={t('calibration.modals.errors.server.title')}
            subtitle={t('calibration.modals.errors.server.subtitle')}
            modalType={SessionsModalTypes.MANUAL_CALIBRATION_FAILURE}
            options={{ hideValidateButton: true }}
        />
    );
};

export default GenerateCalibrationFailureModal;
