export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const INFO_MESSAGE = 'INFO_MESSAGE';
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const WARNING_MESSAGE = 'WARNING_MESSAGE';
export const RESET_MESSAGES = 'RESET_MESSAGES';

const initialState = {
    errors: [],
    infos: [],
    success: [],
    warnings: [],
};

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ERROR_MESSAGE:
            return {
                ...state,
                errors: [...action.payload.messages],
            };
        case INFO_MESSAGE:
            return {
                ...state,
                infos: [...action.payload.messages],
            };
        case SUCCESS_MESSAGE:
            return {
                ...state,
                success: [...action.payload.messages],
            };
        case WARNING_MESSAGE:
            return {
                ...state,
                warnings: [...action.payload.messages],
            };
        case RESET_MESSAGES:
            return initialState;
        default:
            return state;
    }
};

export default modalReducer;
