/* eslint-disable @typescript-eslint/no-shadow */
import styled from 'styled-components';
import { theme } from '../../../style/theme';
import { Flex, Text } from '../../../style';

export const Layout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    background-color: ${({ theme }) => theme.color.blue.light};
    gap: 0.5rem;
    padding: 6px 10px;
    border-radius: 5px;
    cursor: ${({ editable }) => (editable ? 'pointer' : 'default')};
`;

export const Value = styled.span`
    font-size: ${({ theme }) => theme.font.size.small};
    color: ${({ theme }) => theme.color.black.main};
    font-weight: bold;
    text-align: end;
`;

export const Summary = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-grow: 1;
    padding: 0 16px;
`;

export const HeadingContainer = styled(Flex).attrs({ justify: 'space-between', width: 'auto' })`
    padding: 16px;
`;
export const StyledNumberOfHiddenElements = styled(Text).attrs({
    size: theme.font.size.regular,
    margin: '0 0 0 10px',
})``;
export const HeadingTitleContainer = styled(Flex).attrs({ justify: 'start' })``;

export const EditableValue = styled.span.attrs(() => ({
    role: 'button',
    'aria-label': 'edit',
}))`
    font-size: ${({ theme }) => theme.font.size.small};
    color: ${({ theme }) => theme.color.blue.medium};
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    text-align: end;
    ${({ empty, theme }) =>
        empty &&
        `background-color: ${theme.color.white.main};
        width: 100%;
        height: 100%;
        border-radius: 5px;`}
`;

export const DetailsLayout = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
        'title      .           actions'
        'attributes attributes  attributes';
    background-color: ${({ theme }) => theme.color.blue.tint};
    padding: 20px;
    padding-top: 0;
    margin-top: 10px;
    margin-bottom: 30px;
    box-shadow: ${({ theme }) => theme.boxShadow.light};
`;

export const Actions = styled.div`
    grid-area: actions;
    display: flex;
`;

export const Button = styled.button`
    border: none;
    background: none;
    cursor: pointer;
    color: ${({ selected, theme }) => (!selected ? 'inherit' : theme.color.blue.primary)};
`;

export const Attributes = styled.div`
    grid-area: attributes;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
