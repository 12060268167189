import { useState } from 'react';
import { currentImageUrlSelector } from '../../../../../../core/legacy/selectors/streetView';
import { getImageBackgroundFromAnnotationEngine } from '../../../../../../core/legacy/utils/roadViewerUtils';
import { useAppSelector } from '../../../../../../types';
import ZoomEnabler from '../../../ui/zoom/ZoomEnabler';
import ZoomViewport from '../../../ui/zoom/ZoomViewport';

export const Zoom = () => {
    const [zoomEnabled, setZoomEnabled] = useState<boolean>(false);
    const toggleSelected = () => setZoomEnabled(!zoomEnabled);
    const currentImageUrl = useAppSelector(currentImageUrlSelector);
    const imageNode = getImageBackgroundFromAnnotationEngine();
    const zoomFactor = 4;

    return (
        <>
            <ZoomEnabler selected={zoomEnabled} toggleSelected={toggleSelected} />
            {zoomEnabled && (
                <ZoomViewport
                    imageUrl={currentImageUrl}
                    imgNode={imageNode}
                    nodeToListen={imageNode?.parentNode}
                    zoomFactor={zoomFactor}
                />
            )}
        </>
    );
};
