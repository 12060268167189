import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import type { SessionId } from '../domain/Session';

const slice = (state: RootState) => state.networkMap;

export const networkMapQuery = createSelector(slice, (state) => state.network);
export const networkMapShouldRefreshQuery = createSelector(slice, (state) => state.networkExecutionsNeedRefresh);
export const networkExecutionStatusesQuery = createSelector(slice, (state) => state.networkExecutionStatuses);
export const networkMapRoadDataQuery = createSelector(slice, (state) => state.networkMapRoadData);
export const allSessionsAreShownQuery = createSelector(slice, (state) => state.sessionsShownOnMap.length > 0);
export const zoomedSessionQuery = createSelector(slice, (state) => state.sessionZoomedOnMap);
export const zoomedOrganizationQuery = createSelector(slice, (state) => state.organizationZoomedOnMap);
export const selectedGradeReferenceQuery = createSelector(slice, (state) => state.selectedGradeReference);
export const displayPrQuery = createSelector(slice, (state) => state.displayPr);
export const selectedmapStyleQuery = createSelector(slice, (state) => state.selectedMapStyle);
export const currentMapBoundsQuery = createSelector(slice, (state) => state.currentMapBounds);
export const sessionsShownQuery = createSelector(slice, (state) => state.sessionsShownOnMap);
export const isSessionShownQuery = (sessionId: SessionId) =>
    createSelector(slice, (state) => !!state.sessionsShownOnMap.find((id) => id === sessionId));
export const renderedSessionsIdsQuery = createSelector(slice, (state) => state.renderedSessionsIds);
