import styled from 'styled-components';
import { Warning as WarningBase } from '../../style';

export const OverridingEngineImage = styled.div`
    align-self: center;
    height: 579px;
    width: 772px;
    position: absolute;
`;

export const Warning = styled(WarningBase)`
    text-align: center;
    width: 20%;
    padding-top: 10px;
`;

export const GradeBlock = styled.span`
    display: block;
    padding: 5px 0;
`;

export const TransparentButton = styled.button`
    display: flex;
    background-color: transparent;
    border: none;
    text-align: left;
    padding: 0;
    align-items: center;
    cursor: pointer;
    height: 10px;
    font-family: ${({ theme }) => theme.font.family};
`;
