import styled from 'styled-components';
import { Error as ErrorBase, Flex as FlexBase } from '../../../style';

export const FieldFlex = styled(FlexBase).attrs({
    align: 'stretch',
    justify: 'flex-start',
    column: true,
})``;

export const FirstFieldFlex = styled(FieldFlex).attrs({
    margin: '0 20px 0 0',
    width: '45%',
    align: 'stretch',
    justify: 'flex-start',
    column: true,
})``;

export const Error = styled(ErrorBase).attrs({
    margin: '5px 0 0 0',
})``;
