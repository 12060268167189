import { setModalToDisplay } from '../../legacy/actions/modals';
import { SessionsModalTypes } from '../../legacy/reducers/modals';
import { createCommand } from '../../utils';
import { sessionUploadsQuery } from '../queries';
import { moduleName, sessionUploadInitiated, sessionUploadsReset, UploadableSession } from '../state';
import { createAccessor } from './utils';

export const UploadSessionsCommand = createCommand(
    `${moduleName}/INITIATE_SESSION_UPLOADS`,
    async (sessions: UploadableSession[], { dispatch, extra, getState }) => {
        try {
            const previousSessionUploads = sessionUploadsQuery(getState());
            extra.serializableFileProvider.clear(previousSessionUploads);
            await dispatch(sessionUploadsReset());

            for (const session of sessions) {
                // eslint-disable-next-line no-await-in-loop
                const sessionUploadData = await extra.externalUpload.init(session, extra.serializableFileProvider);

                dispatch(sessionUploadInitiated(sessionUploadData));
            }

            await dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_UPLOAD_IN_PROGRESS));
        } catch (error) {
            await dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_UPLOAD_INIT_FAILURE));

            const previousSessionUploads = sessionUploadsQuery(getState());
            extra.serializableFileProvider.clear(previousSessionUploads);
            await dispatch(sessionUploadsReset());
        }

        const state = getState();
        const sessionUploads = sessionUploadsQuery(state);

        const uploadPosts = sessionUploads.map((sessionUpload) => {
            const accessor = createAccessor(sessionUpload, dispatch, extra, getState);

            return extra.externalUpload.execute(accessor);
        });

        await Promise.all(uploadPosts);
    },
);
