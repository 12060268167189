import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import type { Folder, Rubric, Reference } from '../domain/Roaddata';
import type { RubricId } from '../domain/Session';
import { WIDTH_PARTIAL_CODE } from '..';

const slice = (state: RootState) => state.catalogs;

export const catalogsQuery = createSelector(slice, (state) => state.catalogs);
export const allReferencesQuery = (state): Reference[] => {
    const rubrics = allRubricsQuery(state);
    const seenKeys = new Set<string>();

    const distinctReferences: Set<Reference> = rubrics.reduce((references, rubric) => {
        rubric.references.forEach((ref) => {
            if (!seenKeys.has(ref.key)) {
                references.add(ref);
                seenKeys.add(ref.key);
            }
        });

        return references;
    }, new Set<Reference>());

    return Array.from(distinctReferences);
};

export const gradedAndWidthQuery = (state): Reference[] => {
    const rubrics = allRubricsQuery(state);
    const seenKeys = new Set<string>();

    const distinctReferences: Set<Reference> = rubrics.reduce((references, rubric) => {
        rubric.references.forEach((ref) => {
            if ((ref.isGraded || ref.key.includes(WIDTH_PARTIAL_CODE)) && !seenKeys.has(ref.key)) {
                references.add(ref);
                seenKeys.add(ref.key);
            }
        });

        return references;
    }, new Set<Reference>());

    return Array.from(distinctReferences);
};

export const allRubricsQuery = createSelector(slice, (state): Rubric[] => {
    const foundRubrics: Array<Rubric> = [];

    const exploreFolders = (foldersToExplore: Array<Folder>) => {
        foldersToExplore.forEach(({ rubrics, folders }) => {
            if (rubrics.length > 0) {
                rubrics.forEach((rubric) => {
                    foundRubrics.push(rubric);
                });
            }
            if (folders.length > 0) {
                exploreFolders(folders);
            }
        });
    };

    exploreFolders(state.catalogs);

    return foundRubrics;
});

export const rubricByIdQuery = (rubricId: RubricId) => (state): Rubric | undefined => {
    const allRubrics = allRubricsQuery(state);

    return allRubrics.find((rubric) => rubric.id === rubricId);
};

export const referencesByRubricIdQuery = (rubricId: RubricId) => (state): Reference[] => {
    const rubric = rubricByIdQuery(rubricId)(state);

    return rubric?.references ?? [];
};

export const sortedReferencesByRubricIdQuery = (rubricId: RubricId | undefined) => (state): Reference[] => {
    if (!rubricId) {
        return [];
    }

    const references = referencesByRubricIdQuery(rubricId)(state);

    return [...references].sort((a, b) => a.key.localeCompare(b.key));
};

export const allReferenceIdsQuery = (state): Array<string> => {
    const rubrics = allRubricsQuery(state);

    return rubrics
        .map((rubric) => rubric.references)
        .flat()
        .map((r) => r.id);
};

export const allRubricsIdsQuery = (state): Array<string> => {
    const rubrics = allRubricsQuery(state);

    return rubrics.map((rubric) => rubric.id);
};

export const selectedReferenceByRubricIdQuery = (rubricId: RubricId) =>
    createSelector(
        slice,
        (state): Reference | undefined =>
            state.selectedReferenceByRubricIds.find((refByRubricId) => refByRubricId.rubricId === rubricId)?.reference,
    );
