import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../../style/theme';

const HeadingContainer = styled.div`
    padding: 8px 16px;
`;

const BoldHeading = styled.span`
    font-weight: bold;
    color: ${theme.color.black.main};
    font-size: ${theme.font.size.regular};
`;

const PlainHeading = styled(BoldHeading)`
    margin-left: 4px;
    font-weight: normal;
`;

const CalibrationHeading = ({ boldText, plainText }) => (
    <HeadingContainer>
        <BoldHeading>
            {boldText}
            <PlainHeading>{plainText}</PlainHeading>
        </BoldHeading>
    </HeadingContainer>
);

CalibrationHeading.propTypes = {
    boldText: PropTypes.string.isRequired,
    plainText: PropTypes.string.isRequired,
};

export default CalibrationHeading;
