import {
    convertAnnotationPointsToRealSize,
    convertAnnotationPointsToEngineSize,
} from '../../../../../core/legacy/utils/roadViewerUtils';
import TranslationService from '../../../../../core/legacy/utils/translationService';
import { sessionQuery } from '../../../../../core/streetViewer/queries';
import { useAppSelector } from '../../../../../types';

const useGuideLinesDrawing = () => {
    const session = useAppSelector(sessionQuery);

    if (!session) {
        return {
            drawGuideLines: () => {},
        };
    }

    const translationService = new TranslationService(session.calibration);

    const getGuideLines = (draggedAnnotationPoints) => {
        const annotationPoints = convertAnnotationPointsToRealSize(draggedAnnotationPoints);

        const [
            [guideLinePoint1a, guideLinePoint1b],
            [guideLinePoint2a, guideLinePoint2b],
        ] = translationService.getGuideLinesPixelsCoordinates(...annotationPoints);

        return convertAnnotationPointsToEngineSize([
            guideLinePoint1a,
            guideLinePoint1b,
            guideLinePoint2a,
            guideLinePoint2b,
        ]);
    };
    const drawGuideLine = (context, startCoordinates, endCoordinates) => {
        context.beginPath();
        context.strokeStyle = '#a3a90a';
        context.moveTo(startCoordinates.x, startCoordinates.y);
        context.lineWidth = 2;
        context.lineTo(endCoordinates.x, endCoordinates.y);
        context.stroke();
        context.closePath();
    };
    const drawGuideLines = (annotationPoints, operations) => {
        const [guideLinePoint1a, guideLinePoint1b, guideLinePoint2a, guideLinePoint2b] = getGuideLines(
            annotationPoints,
        );

        operations.drawOnCanvas((context2d) => {
            drawGuideLine(context2d, guideLinePoint1a, guideLinePoint1b);
            drawGuideLine(context2d, guideLinePoint2a, guideLinePoint2b);
        });
    };

    return { drawGuideLines };
};

export default useGuideLinesDrawing;
