import fetchIntercept from 'fetch-intercept';
import { mergeDeepRight } from 'ramda';

export class HttpError extends Error {
    constructor(response) {
        super(response.statusText);

        this.status = response.status;
        this.response = response;
    }
}

const defaultOptions = {
    credentials: 'include',
    headers: {},
    jsonify: true, // automatically parse JSON on successful response
};

const factory = (method, customFactoryOptions = {}) => {
    const factoryOptions = mergeDeepRight(defaultOptions, customFactoryOptions);

    const customFetch = async (url, body = null, customOptions = {}) => {
        const options = mergeDeepRight(factoryOptions, customOptions);

        const fetchOptions = {
            method,
            headers: options.headers,
            credentials: options.credentials,
        };

        if (body) {
            if (body instanceof FormData) {
                fetchOptions.body = body;
            } else {
                fetchOptions.body = JSON.stringify(body);
                fetchOptions.headers = {
                    ...fetchOptions.headers,
                    'Content-Type': 'application/json',
                };
            }
        }

        const response = await fetch(url, fetchOptions);

        if (options.jsonify) {
            return response.json();
        }

        return response;
    };

    customFetch.overrideOptions = (newOptions) => factory(method, mergeDeepRight(factoryOptions, newOptions));

    return customFetch;
};

export const fetchInterceptors = (() => {
    let interceptors;

    const getInterceptors = () => {
        return interceptors;
    };

    const register = () => {
        if (!interceptors) {
            interceptors = fetchIntercept.register({
                // catch 401 Unauthorized error, then redirect to login
                response(res) {
                    if (res.status === 401) {
                        window.location.replace('/?expired=true');
                    }

                    return res;
                },
            });
        }
    };

    return { unregister: getInterceptors, register };
})();

export const GET = factory('GET');
export const POST = factory('POST');
export const PUT = factory('PUT');
export const PATCH = factory('PATCH');
export const DELETE = factory('DELETE');
