import { Stack } from '@mui/material';
import type { FC } from 'react';
import { OptionsMenu } from './OptionsMenu';
import { Zoom } from './Zoom';

const ImageActions: FC = () => {
    return (
        <Stack direction="row" gap="16px" gridRow={1} marginLeft="auto" marginRight="16px">
            <Zoom />
            <OptionsMenu />
        </Stack>
    );
};

export default ImageActions;
