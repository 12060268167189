import { useCallback, ChangeEvent, FC } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../types';
import toggleCheckedFilterCommand from '../../../../../core/filters/commands/ToggleCheckedFilterCommand';

interface TagFilterProps {
    checked: boolean;
}

const TagFilter: FC<TagFilterProps> = ({ checked }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleChange = useCallback(
        ({ target }: ChangeEvent<HTMLInputElement>) => dispatch(toggleCheckedFilterCommand(target.checked)),
        [dispatch],
    );

    return (
        <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} size="small" disableRipple />}
            label={<Typography sx={{ fontSize: 14 }}>{t('tags.filter')}</Typography>}
        />
    );
};

export default TagFilter;
