const hoverStyleOptions = {
    label: {
        shadow: {
            blur: 8,
            color: 'white',
        },
    },
};

const clickStyleOptions = {
    label: {
        fillColor: '#fff',
        textColor: '#132155',
    },
    line: {
        strokeColor: '#fff',
    },
    polygon: {
        strokeColor: '#fff',
    },
    point: {
        strokeColor: '#fff',
    },
};

const editStyleOptions = {
    point: {
        strokeColor: '#FFFFFF',
        fillColor: '#0053CC66',
        outerRadius: 8,
        innerRadius: 8,
        width: 1,
    },
    line: {
        strokeColor: '#0053CC',
    },
};

export const hiddenStyleOptions = {
    point: {
        strokeColor: 'transparent',
        fillColor: 'transparent',
    },
};

export const smallPointsStyleOptions = {
    point: {
        strokeColor: '#FFFFFF95',
        fillColor: '#0053CC66',
        width: 2,
        outerRadius: 3,
        innerRadius: 3,
    },
};

export const highlightStyleOptions = {
    point: {
        strokeColor: '#FFFFFF95',
        fillColor: '#0053CC66',
        width: 2,
        outerRadius: 14,
        innerRadius: 12,
    },
};

export const hoverStyle = {
    style: hoverStyleOptions,
    name: 'hover',
};

export const clickStyle = {
    style: clickStyleOptions,
    name: 'click',
};

export const editStyle = {
    style: editStyleOptions,
    name: 'edit',
};

export const highlightStyle = {
    style: highlightStyleOptions,
    name: 'highlight',
};

export const smallPointsStyle = {
    style: smallPointsStyleOptions,
    name: 'smallPoints',
};

export const hiddenStyle = {
    style: hiddenStyleOptions,
    name: 'hidden',
};
