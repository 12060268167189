import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Flex, Text } from '../../../style';
import { ReactComponent as Bell } from '../../../../../assets/icons/bell.svg';
import { ReactComponent as Bulb } from '../../../../../assets/icons/bulb.svg';

const TipsContainer = styled(Flex).attrs({ justify: 'left' })`
    border-radius: 8px;
    margin: 24px 0;
    padding: 10px 0;
    background-color: ${({ theme }) => theme.color.yellow.light};
`;
const TipsIconContainer = styled(Flex).attrs({ justify: 'left', column: true, align: 'start' })`
    font-size: ${({ theme }) => theme.font.size.big};
    padding: 0 15px;
    width: auto;
`;
const TipsTextContainer = styled(Flex).attrs({ justify: 'left', column: true, align: 'start' })`
    padding-right: 10px;
`;

const TipsBody = styled(Text)`
    font-size: ${({ theme }) => theme.font.size.regular};
    line-height: 1.5;
    white-space: pre-line;
`;
const TipsHeadline = styled(TipsBody)`
    font-weight: bold;
`;

const TipsIcon = ({ icon }) => {
    let tipsIcon;

    switch (icon) {
        case 'bell':
            tipsIcon = <Bell />;
            break;
        case 'bulb':
        default:
            tipsIcon = <Bulb />;
            break;
    }

    return <TipsIconContainer>{tipsIcon}</TipsIconContainer>;
};

TipsIcon.defaultProps = {
    icon: 'bulb',
};
TipsIcon.propTypes = {
    icon: PropTypes.oneOf(['bulb', 'bell']),
};

const Tips = ({ headline, body, icon }) => {
    return (
        <TipsContainer>
            <TipsIcon icon={icon} />
            <TipsTextContainer>
                <TipsHeadline>{headline}</TipsHeadline>
                <TipsBody>{body}</TipsBody>
            </TipsTextContainer>
        </TipsContainer>
    );
};

Tips.propTypes = {
    body: PropTypes.string,
    headline: PropTypes.string,
    icon: PropTypes.string,
};

export default Tips;
