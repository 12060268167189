import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import FlashBag from '../../components/ui/flashbag';
import { MainContainer } from './styles';
import Loader from '../../components/ui/loader';
import { DashboardAcquisitionContainer } from '../../components/Acquisition/styles';
import { useAppDispatch, useAppSelector } from '../../../../types';
import { isLoadingQuery } from '../../../../core/loader/queries';
import { InitCatalogsCommand } from '../../../../core/catalogs/commands/InitCatalogsCommand';

const Dashboard = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(isLoadingQuery);
    useEffect(() => {
        dispatch(InitCatalogsCommand());
    }, []);

    return (
        <MainContainer>
            <FlashBag />
            {isLoading && <Loader />}
            <DashboardAcquisitionContainer data-test-id="dashboard__acquisition">
                <Outlet />
            </DashboardAcquisitionContainer>
        </MainContainer>
    );
};

export default Dashboard;
