/* eslint-disable @typescript-eslint/no-unused-vars */
import { push } from 'redux-first-history';
import type { SessionId } from '../../domain/Session';
import { createCommand } from '../../utils';
import { moduleName } from '../state';

type Args = {
    sessionId?: SessionId;
};
export const NavigateToNetworkMapCommand = createCommand(
    `${moduleName}/NAVIGATE_TO_NETWORK_MAP`,
    async ({ sessionId }: Args, { dispatch }) => {
        let path = `/dashboard/acquisition/map`;
        if (sessionId) {
            path += `?sessionId=${sessionId}`;
        }

        dispatch(push(path));
    },
);
