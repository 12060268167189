import { setModalToDisplay } from '../../legacy/actions/modals';
import { SessionsModalTypes } from '../../legacy/reducers/modals';
import { createCommand } from '../../utils';
import type { Bbox } from '../ExternalNetworkMapPort';
import { moduleName, networkMapRoadDataLoaded } from '../state';

export type Props = {
    bbox: Bbox;
    zoom: number;
};

export const MIN_ZOOM_FOR_OBJECT_DISPLAY = 18;

export const LoadNetworkMapRoadDataCommand = createCommand(
    `${moduleName}/LOAD_NETWORK_MAP_ROAD_DATA`,
    async ({ bbox, zoom }: Props, { dispatch, extra }) => {
        if (zoom < MIN_ZOOM_FOR_OBJECT_DISPLAY) {
            return;
        }

        dispatch(setModalToDisplay(SessionsModalTypes.NETWORK_MAP_LOADER));

        const data = await extra.externalNetworkMap.loadNetworkMapRoadData(bbox);
        dispatch(networkMapRoadDataLoaded(data));
        dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_NONE));
    },
);
