import { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../styles';
import GenericModal from '../../../../legacy/components/ui/modals/GenericModal';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';
import { ContentContainer, ModalTitle } from '../../../../legacy/components/ui/modals/styles';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { targettedSessionsForInteractionQuery } from '../../../../../core/sessions/queries';
import editSession from '../../../../../core/sessions/commands/EditSession';

const EditSessionNameModal: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const sessionToEdit = useAppSelector(targettedSessionsForInteractionQuery)[0];

    const [newSessionName, setNewSessionName] = useState('');
    const isNewNameValid = newSessionName.length !== 0;

    useEffect(() => {
        setNewSessionName(sessionToEdit?.name ?? '');
    }, [sessionToEdit]);

    const onValidate = () => {
        if (sessionToEdit) {
            dispatch(
                editSession({
                    sessionId: sessionToEdit.id,
                    sessionData: {
                        name: newSessionName,
                    },
                }),
            );
        }
    };

    const handleKeyDown = ({ key }: KeyboardEvent) => {
        if (key === 'Enter' && isNewNameValid) {
            onValidate();
        }
    };

    if (!sessionToEdit) return null;

    return (
        <GenericModal
            isValidateDisable={!isNewNameValid}
            onValidate={onValidate}
            onCancel={() => setNewSessionName(sessionToEdit?.name ?? '')}
            modalType={SessionsModalTypes.SESSIONS_EDIT_NAME}
            options={{ notDismissableByClickingOnBackground: true }}
        >
            <ContentContainer>
                <ModalTitle>{t('sessions.modals.editName')}</ModalTitle>
                <Input
                    onKeyDown={handleKeyDown}
                    type="text"
                    value={newSessionName}
                    onChange={(e) => setNewSessionName(e.target.value)}
                    autoFocus
                />
            </ContentContainer>
        </GenericModal>
    );
};

export default EditSessionNameModal;
