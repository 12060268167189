import { Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckMarkCircleIcon } from '../../../../../design_system/icons/CheckMarkCircleIcon';
import { ButtonLink } from '../../../../../design_system/inputs/buttonLinks/ButtonLink';
import { theme } from '../../../../style/theme';

type Props = {
    onClose: () => void;
};

export const ExecutionDoneBaner: FC<Props> = ({ onClose }) => {
    const { t } = useTranslation();

    return (
        <Stack
            direction="row"
            alignItems="center"
            gap={2}
            sx={{
                paddingY: 4,
                paddingX: 3,
                backgroundColor: theme.color.yellow.light,
            }}
        >
            <CheckMarkCircleIcon />
            <Stack gap={0.5}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography fontSize={theme.font.size.regular} fontWeight="700">
                        {t('ai_analysis.analysis_done')}
                    </Typography>
                    <ButtonLink variant="secondary" onClick={onClose}>
                        {t('ai_analysis.close')}
                    </ButtonLink>
                </Stack>
                <Typography fontSize={theme.font.size.small} fontWeight="700" color={theme.color.grey.main}>
                    {t('ai_analysis.in_order_to_display_the_annotations_activate_relevant_filters')}
                </Typography>
            </Stack>
        </Stack>
    );
};
