import { allRubricsIdsQuery } from '../../catalogs/queries';
import NoDataToExportError from '../../domain/errors/NoDataToExportError';
import type { ExportSessionsCommandArgs, SessionExportData } from '../../domain/Session';
import { setModalToDisplay, unsetModalToDisplay } from '../../legacy/actions/modals';
import { SessionsModalTypes } from '../../legacy/reducers/modals';
import { createCommand } from '../../utils';
import { moduleName } from '../state';

export const ExportSessionsRoadDatasCommand = createCommand(
    `${moduleName}/EXPORT_SESSIONS_ROAD_DATAS`,
    async ({ sessionIds, decimalSeparator = 'comma' }: ExportSessionsCommandArgs, { dispatch, extra, getState }) => {
        const state = getState();
        const rubricsIdsToExport = allRubricsIdsQuery(state);
        const exportData: SessionExportData = {
            selectedSessions: sessionIds,
            decimalSeparator,
            rubricsIdsToExport,
        };

        try {
            await dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_EXPORT_IN_PROGRESS));

            const file = await extra.externalSessions.exportSessionsRoadDatas(exportData);
            const time = extra.time.now();
            const filename = `export_${time.toISOString()}.zip`;
            extra.download.execute(file, filename);

            await dispatch(unsetModalToDisplay());
        } catch (e: any) {
            switch (e.constructor) {
                case NoDataToExportError:
                    dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_EXPORT_NO_ANNOTATION));
                    break;
                default:
                    dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_EXPORT_FAILURE));
                    break;
            }
        }
    },
);
