import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedCatalog } from '../../../../../core/legacy/actions';

const useCatalogHandler = () => {
    const dispatch = useDispatch();
    const [currentCatalogId, setCurrentCatalogId] = useState(null);

    return async (catalog) => {
        if (currentCatalogId !== catalog.id) {
            setCurrentCatalogId(catalog.id);
            await dispatch(setSelectedCatalog(catalog));
        }
    };
};

export default useCatalogHandler;
