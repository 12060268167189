import styled from 'styled-components';
import { Flex, Input as BaseInput, H2, Text } from '../../legacy/style';
import { SxProps } from '@mui/material';

export const Title = styled(H2)`
    font-size: ${({ theme }) => theme.font.size.veryLarge};
    line-height: 1;
    margin: 12px 0 24px;
`;

export const Input = styled(BaseInput)`
    outline: 1px solid ${({ theme }) => theme.color.grey.light};
    width: 100%;
    box-sizing: border-box;

    &:focus {
        outline: 2px solid ${({ theme }) => theme.color.blue.primary};
    }
`;

export const EmptySession = styled(Flex).attrs({ column: true })`
    height: 100%;
`;

export const TextInformation = styled(Text).attrs({ size: '18px' })`
    line-height: 1;
`;

export const tooltipCell: SxProps = {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};
