import GenericModal from '../../legacy/components/ui/modals/GenericModal';
import { ContentContainer } from '../../legacy/components/ui/modals/styles';
import { SessionsModalTypes } from '../../../core/legacy/reducers/modals';
import { FilesContainer } from '../../legacy/components/ui/forms/FileUpload/styles';
import { useUploadStatus } from './use-upload-status';
import { FileUploadItem } from './FileUploadItem';
import { useAppDispatch, useAppSelector } from '../../../types';
import type { FC } from 'react';
import { sessionUploadsQuery } from '../../../core/uploads/queries';
import { ResetSessionUploadsCommand } from '../../../core/uploads/commands/ResetSessionUploadsCommand';

export const UploadInProgressModal: FC<{}> = () => {
    const dispatch = useAppDispatch();

    const sessionUploads = useAppSelector(sessionUploadsQuery);
    const {
        getModalInformations,
        hasInitiatedOrInProgressUploads,
        hasOnlySuccessUploads,
        getValidateButtonText,
    } = useUploadStatus(sessionUploads);

    const options = {
        hideDefaultButtons: hasInitiatedOrInProgressUploads,
        notDismissable: !hasOnlySuccessUploads,
        centerButtons: true,
        hideCancelButton: true,
    };

    const onCancel = () => {
        if (hasOnlySuccessUploads) {
            onValidate();
        }
    };

    const onValidate = () => {
        dispatch(ResetSessionUploadsCommand());
    };

    return (
        <GenericModal
            options={options}
            modalType={SessionsModalTypes.SESSIONS_UPLOAD_IN_PROGRESS}
            validateButtonText={getValidateButtonText()}
            onCancel={onCancel}
            onValidate={onValidate}
        >
            <ContentContainer>
                {getModalInformations()}

                <FilesContainer>
                    {[...sessionUploads]
                        .sort((sessionUpload1, sessionUpload2) =>
                            sessionUpload1.fileName.localeCompare(sessionUpload2.fileName),
                        )
                        .map((sessionUpload) => (
                            <FileUploadItem key={sessionUpload.url} sessionUpload={sessionUpload} />
                        ))}
                </FilesContainer>
            </ContentContainer>
        </GenericModal>
    );
};
