import { Button, Stack, styled } from '@mui/material';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import { SmallText } from '../../texts';

export const LightBlueButtonBase = styled(Button)(({ theme, selected }) => ({
    backgroundColor: selected ? theme.color.blue.primary : theme.color.blue.tint2,
    textTransform: 'unset',
    borderRadius: '8px',
    color: selected ? theme.color.white.main : theme.color.blue.primary,
    border: 'none',
    '&:hover': {
        backgroundColor: selected ? theme.color.blue.primary : theme.color.blue.selected,
        border: 'none',
    },
}));

type Props = {
    children?: ReactNode;
    textContent: string;
    handleClick: MouseEventHandler<HTMLButtonElement>;
    selected?: boolean;
};

export const LightBlueButton: FC<Props> = ({ children, textContent, handleClick, selected }) => {
    return (
        <LightBlueButtonBase onClick={handleClick} selected={selected}>
            <Stack direction="row" gap="10px" alignItems="center">
                <SmallText color="inherit" fontWeight="bold">
                    {textContent}
                </SmallText>
                {children}
            </Stack>
        </LightBlueButtonBase>
    );
};
