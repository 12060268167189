import { createCommand } from '../../utils';
import { sessionUploadByUrlQuery } from '../queries';
import { moduleName } from '../state';
import { createAccessor } from './utils';

export const RetrySessionUploadByUrlCommand = createCommand(
    `${moduleName}/RETRY_SESSION_UPLOAD`,
    async (url: string, { dispatch, extra, getState }) => {
        const sessionUpload = sessionUploadByUrlQuery(url)(getState());
        if (sessionUpload) {
            const accessor = createAccessor(sessionUpload, dispatch, extra, getState);
            await extra.externalUpload.execute(accessor);
        }
    },
);
