import {
    addAnnotationRequest,
    deleteAnnotationRequest,
    getAnnotationsForImageRequest,
    saveAnnotationRequest,
    getStreetviewImageFirstRequest,
    getStreetviewImageLastRequest,
    getStreetviewImagePreviousRequest,
    getStreetviewImageNextRequest,
    getStreetViewRequest,
    updateAttributeValueOfRoadDataRequest,
    getImageByRoadDataRequest,
    getImageByCumulStartRequest,
    downloadSessionBlobImageRequest,
} from '../../../providers/legacy/datasources/sessionDataSource';
import {
    annotationInEditionSelector,
    currentImageIdSelector,
    currentImageSelector,
    isNextImageIndexLoadedSelector,
    isPreviousImageIndexLoadedSelector,
    getLoadedImageIndexesSelector,
    roadDataByIdSelector,
    currentImageUrlSelector,
    temporaryDeformedAnnotationToEditSelector,
    currentImageCumulStartSelector,
    selectedRoadDataIdSelector,
    allRoadDatasIdOfCurrentImageSelector,
    imagesSelector,
    roadDatasToDisplaySelector,
} from '../selectors/streetView';
import { convertAnnotationPointsToRealSize } from '../utils/roadViewerUtils';
import { findRoadDataIdToScrollTo, getAdjustedCumulStart } from '../utils/roadDataUtils';
import { loaderStarted, loaderStopped } from '../../loader/state';
import {
    applyFilterToImageQuery,
    imageRoaddataOnlyQuery,
    referenceToDisplayIds,
    rubricToDisplayIdsQuery,
    taggedElementsOnlyQuery,
} from '../../filters/queries';
import { allReferenceIdsQuery, allRubricsIdsQuery } from '../../catalogs/queries';
import {
    currentImageIndexQuery,
    sessionCalibrationQuery,
    sessionIdQuery,
    sessionQuery,
} from '../../streetViewer/queries';
import {
    currentImageCumulUpdated,
    currentImageIndexDecremented,
    currentImageIndexIncremented,
    currentImageIndexSetToFirst,
    currentImageIndexSetToLast,
    currentImageIndexUpdated,
    currentLandmarkUpdated,
    resetStarted as resetStartedForStreetViewer,
    sessionUpdated,
} from '../../streetViewer/state';
import { retrieveRoadDataIntervalOfCurrentImage } from '../../actions/streetView';
import { LoadItineraryForOneSessionCommand } from '../../sessions/commands/LoadItineraryForOneSessionCommand';

// Rubric

export const selectRubric = (rubric) => {
    return {
        type: 'STREET_VIEW/SELECT_RUBRIC',
        rubric,
    };
};

export const unselectRubric = () => {
    return {
        type: 'STREET_VIEW/UNSELECT_RUBRIC',
    };
};

// Road data

export const selectRoadDataInEdition = (roadDataId) => ({
    type: 'STREET_VIEW/SELECT_ROAD_DATA_IN_EDITION',
    roadDataId,
});

export const noMoreRoadDataInEdition = () => ({
    type: 'STREET_VIEW/NO_MORE_ROAD_DATA_IN_EDITION',
});

export const selectSelectedRoadData = (roadDataId) => (dispatch) => {
    dispatch(noMoreAnnotationInEdition());
    dispatch({
        type: 'STREET_VIEW/SELECT_SELECTED_ROAD_DATA',
        roadDataId,
    });
    dispatch(updateRoadDataIdToScrollTo(roadDataId));
};

export const noMoreSelectedRoadData = () => ({
    type: 'STREET_VIEW/NO_MORE_SELECTED_ROAD_DATA',
});

export const selectHoveredRoadData = (roadDataId) => ({
    type: 'STREET_VIEW/SELECT_HOVERED_ROAD_DATA',
    roadDataId,
});

export const noMoreHoveredRoadData = () => ({
    type: 'STREET_VIEW/NO_MORE_HOVERED_ROAD_DATA',
});

export const updateRoadDataIdToScrollTo = (roadDataIdToScrollTo) => ({
    type: 'STREET_VIEW/UPDATE_ROAD_DATA_ID_TO_SCROLL_TO',
    roadDataIdToScrollTo,
});

export const downloadSessionImage = () => async (_dispatch, getState) => {
    const sessionName = sessionQuery(getState())
        ?.name.replace(/[\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '_')
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '');
    const imageUrl = currentImageUrlSelector(getState());
    const imageIndex = currentImageIndexQuery(getState());
    const numberForImageName = imageIndex.toString().padStart(4, '0');
    const blob = await downloadSessionBlobImageRequest(imageUrl);
    const a = document.createElement('a');
    a.href = blob;
    a.download = `${sessionName}_image_${numberForImageName}.jpeg`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const roadDataIdToDelete = (id) => async (dispatch, getState) => {
    dispatch(loaderStarted());
    const state = getState();
    const roadDataToDelete = roadDataByIdSelector(id)(state);
    if (roadDataToDelete === undefined) {
        dispatch(loaderStopped());

        return;
    }
    await deleteAnnotationRequest(id);
    await dispatch({
        type: 'STREET_VIEW/DELETE_ROADDATA',
        id,
    });

    // refresh timeline
    const sessionId = sessionIdQuery(state);
    if (sessionId) {
        await dispatch(LoadItineraryForOneSessionCommand({ sessionId }));
    }

    // Update impacted roaddata
    if (roadDataToDelete.isReferentialLandmarks || roadDataToDelete.isSurfaceSections) {
        await dispatch(retrieveRoadDataIntervalOfCurrentImage());
    }

    await dispatch(loaderStopped());
};

export const updateAttributeValueOfRoadData = (roadData, attributeValue, newValue) => async (dispatch, getState) => {
    dispatch(loaderStarted());
    const sessionId = sessionIdQuery(getState());
    const updatedRoadData = await updateAttributeValueOfRoadDataRequest(sessionId, roadData, attributeValue, newValue);
    await dispatch({
        type: 'STREET_VIEW/UPDATED_ROAD_DATA',
        updatedRoadData,
    });

    await dispatch(retrieveAnnotationsForCurrentImage());

    dispatch(loaderStopped());
};

// Annotation

export const selectAnnotationInEdition = (roadDataId) => ({
    type: 'STREET_VIEW/SELECT_ANNOTATION_IN_EDITION',
    roadDataId,
});

export const noMoreAnnotationInEdition = () => ({
    type: 'STREET_VIEW/NO_MORE_ANNOTATION_IN_EDITION',
});

export const updateTemporaryDeformedAnnotationToEdit = (temporaryDeformedAnnotationToEdit) => ({
    type: 'STREET_VIEW/UPDATE_TEMPORARY_DEFORMED_ANNOTATION_TO_EDIT',
    temporaryDeformedAnnotationToEdit,
});
export const resetTemporaryDeformedAnnotationToEdit = () => ({
    type: 'STREET_VIEW/RESET_TEMPORARY_DEFORMED_ANNOTATION_TO_EDIT',
});

const getRoadDataIdToScrollTo = (state, currentImageCumulStart) => {
    let roadDataIdToScrollTo;
    const selectedRoadDataId = selectedRoadDataIdSelector(state);
    const allRoadDatasIdOfCurrentImage = allRoadDatasIdOfCurrentImageSelector(state);
    const isRoadDataOnImage = allRoadDatasIdOfCurrentImage.includes(selectedRoadDataId);
    if (isRoadDataOnImage) {
        roadDataIdToScrollTo = selectedRoadDataId;
    } else {
        const roadDatas = roadDatasToDisplaySelector(state);
        const adjustedCumulStart = getAdjustedCumulStart(currentImageCumulStart);
        roadDataIdToScrollTo = findRoadDataIdToScrollTo(adjustedCumulStart, roadDatas);
    }

    return roadDataIdToScrollTo;
};

export const updateScroll = () => (dispatch, getState) => {
    const state = getState();

    const currentImageCumulStart = currentImageCumulStartSelector(state);
    if (currentImageCumulStart === undefined) {
        return;
    }

    const roadDataIdToScrollTo = getRoadDataIdToScrollTo(state, currentImageCumulStart);
    dispatch(updateRoadDataIdToScrollTo(roadDataIdToScrollTo));
};

export const retrieveAnnotationsForCurrentImage = () => async (dispatch, getState) => {
    const state = getState();
    const image = currentImageSelector(state);
    const calibration = sessionCalibrationQuery(state);
    const applyFilterToImage = applyFilterToImageQuery(state);
    const taggedElementsOnly = taggedElementsOnlyQuery(state);
    const imageRoaddataOnly = imageRoaddataOnlyQuery(state);

    const rubricToDisplayIds = applyFilterToImage ? rubricToDisplayIdsQuery(state) : allRubricsIdsQuery(state);
    const referenceToDisplay = applyFilterToImage ? referenceToDisplayIds(state) : allReferenceIdsQuery(state);
    const taggeElementsToDisplay = applyFilterToImage ? taggedElementsOnly : false;
    const imageRoaddataToDisplay = applyFilterToImage ? imageRoaddataOnly : false;

    if (!image || !calibration) {
        return;
    }
    await Promise.all([
        (async () => {
            const imageAnnotations = await getAnnotationsForImageRequest(
                image.id,
                rubricToDisplayIds,
                referenceToDisplay,
                taggeElementsToDisplay,
                imageRoaddataToDisplay,
            );
            await dispatch({
                type: 'STREET_VIEW/UPDATE_ANNOTATIONS',
                annotations: imageAnnotations,
            });
        })(),
        dispatch(retrieveRoadDataIntervalOfCurrentImage()),
    ]);
};

export const addAnnotation = (annotationPoints, rubricId, reference) => async (dispatch, getState) => {
    dispatch(loaderStarted());
    const state = getState();
    const imageId = currentImageIdSelector(state);

    const annotation = await addAnnotationRequest({ rubricId, imageId, annotationPoints, reference });
    await dispatch({
        type: 'STREET_VIEW/ADD_ANNOTATION',
        annotation,
    });

    // Update impacted roaddata
    if (
        annotation.roadDataChanged &&
        (annotation.roadData.isReferentialLandmarks || annotation.roadData.isSurfaceSections)
    ) {
        await dispatch(retrieveRoadDataIntervalOfCurrentImage());
    }

    const rubricToDisplayIds = rubricToDisplayIdsQuery(state);
    if (!rubricToDisplayIds.includes(rubricId)) {
        dispatch({
            type: 'FILTERS/UPDATE_RUBRIC_TO_DISPLAY_IDS',
            rubricToDisplayIds: [...rubricToDisplayIds, rubricId],
        });
    }

    // refresh timeline
    const sessionId = sessionIdQuery(state);
    if (sessionId) {
        await dispatch(LoadItineraryForOneSessionCommand({ sessionId }));
    }

    await dispatch(selectSelectedRoadData(annotation.roadData.id));
    dispatch(loaderStopped());
};

export const saveEditedAnnotationPoints = (annotationPoints) => async (dispatch, getState) => {
    dispatch(loaderStarted());
    const state = getState();
    const editedAnnotation = annotationInEditionSelector(state);
    await dispatch({
        type: 'STREET_VIEW/UPDATE_ANNOTATION_PRESET_ATTRIBUTE_SHAPE',
        annotation: editedAnnotation,
        projectedShape: annotationPoints,
    });

    await dispatch(noMoreAnnotationInEdition());
    const imageId = currentImageIdSelector(state);
    const updatedAnnotation = await saveAnnotationRequest(imageId, editedAnnotation, annotationPoints);
    await dispatch({
        type: 'STREET_VIEW/UPDATE_ANNOTATION',
        annotation: updatedAnnotation,
    });

    // refresh timeline
    const sessionId = sessionIdQuery(state);
    if (sessionId) {
        await dispatch(LoadItineraryForOneSessionCommand({ sessionId }));
    }

    // Update impacted roaddata
    if (
        updatedAnnotation.roadDataChanged &&
        (updatedAnnotation.roadData.isReferentialLandmarks || updatedAnnotation.roadData.isSurfaceSections)
    ) {
        await dispatch(retrieveRoadDataIntervalOfCurrentImage());
    }

    dispatch(loaderStopped());
};

export const saveEditedAnnotation = () => async (dispatch, getState) => {
    const state = getState();
    const temporaryDeformedAnnotationToEdit = temporaryDeformedAnnotationToEditSelector(state);
    if (temporaryDeformedAnnotationToEdit) {
        await dispatch(
            saveEditedAnnotationPoints(
                convertAnnotationPointsToRealSize(temporaryDeformedAnnotationToEdit.coordinates),
            ),
        );
    }
    await dispatch(noMoreAnnotationInEdition());
};

const refreshImageLandmark = () => async (dispatch, getState) => {
    const currentImage = currentImageSelector(getState());
    if (currentImage) {
        const { landmark } = currentImage;
        dispatch(currentLandmarkUpdated(landmark));
    }
};

// Player

export const playerGoStart = () => async (dispatch, getState) => {
    await dispatch(saveEditedAnnotation());

    const sessionId = sessionIdQuery(getState());
    // actions below only return images data, since the current session data is stored
    const imagesFromSession = await getStreetviewImageFirstRequest(sessionId);
    await dispatch({
        type: 'STREET_VIEW/UPDATE_IMAGE_CACHE',
        images: imagesFromSession,
    });

    dispatch(currentImageIndexSetToFirst(imagesSelector(getState())));
    await dispatch(retrieveAnnotationsForCurrentImage());
    await dispatch(refreshImageLandmark());
    await dispatch(updateScroll());
};

export const playerGoEnd = () => async (dispatch, getState) => {
    await dispatch(saveEditedAnnotation());

    const sessionId = sessionIdQuery(getState());
    const imagesFromSession = await getStreetviewImageLastRequest(sessionId);
    await dispatch({
        type: 'STREET_VIEW/UPDATE_IMAGE_CACHE',
        images: imagesFromSession,
    });

    const images = imagesSelector(getState());
    dispatch(currentImageIndexSetToLast(images));
    await dispatch(retrieveAnnotationsForCurrentImage());
    await dispatch(refreshImageLandmark());
    await dispatch(updateScroll());
};

export const playerGoPrevious = () => async (dispatch, getState) => {
    await dispatch(saveEditedAnnotation());

    const sessionId = sessionIdQuery(getState());
    const currentImageIndex = currentImageIndexQuery(getState());
    // Preloading, if expected image not already loaded
    const needPreloading = !isPreviousImageIndexLoadedSelector(getState());
    if (needPreloading) {
        const imagesFromSession = await getStreetviewImagePreviousRequest(sessionId, currentImageIndex);
        await dispatch({
            type: 'STREET_VIEW/UPDATE_IMAGE_CACHE',
            images: imagesFromSession,
        });
    }

    // Change current index
    dispatch(currentImageIndexDecremented());
    await dispatch(retrieveAnnotationsForCurrentImage());

    // Postloading, to speed up next change
    const needPostloading = !isPreviousImageIndexLoadedSelector(getState());
    if (needPostloading) {
        const imagesFromSession = await getStreetviewImagePreviousRequest(sessionId, currentImageIndex);
        await dispatch({
            type: 'STREET_VIEW/UPDATE_IMAGE_CACHE',
            images: imagesFromSession,
        });
    }
    await dispatch(refreshImageLandmark());
    await dispatch(updateScroll());
};

export const playerGoNext = () => async (dispatch, getState) => {
    await dispatch(saveEditedAnnotation());
    const sessionId = sessionIdQuery(getState());
    const currentImageIndex = currentImageIndexQuery(getState());
    // Preloading, if expected image not already loaded
    const needPreloading = !isNextImageIndexLoadedSelector(getState());
    if (needPreloading) {
        const imagesFromSession = await getStreetviewImageNextRequest(sessionId, currentImageIndex);
        await dispatch({
            type: 'STREET_VIEW/UPDATE_IMAGE_CACHE',
            images: imagesFromSession,
        });
    }

    const images = imagesSelector(getState());
    dispatch(currentImageIndexIncremented(images));

    await dispatch(retrieveAnnotationsForCurrentImage());
    // Postloading, to speed up next change
    const needPostloading = !isNextImageIndexLoadedSelector(getState());
    if (needPostloading) {
        const imagesFromSession = await getStreetviewImageNextRequest(sessionId, currentImageIndex);
        await dispatch({
            type: 'STREET_VIEW/UPDATE_IMAGE_CACHE',
            images: imagesFromSession,
        });
    }

    await dispatch(refreshImageLandmark());
    await dispatch(updateScroll());
};

export const goToImageByRoadData = (roadDataId) => async (dispatch, getState) => {
    await dispatch(saveEditedAnnotation());
    await dispatch(noMoreHoveredRoadData());
    await dispatch(selectSelectedRoadData(roadDataId));
    dispatch(selectSelectedRoadData(roadDataId));
    dispatch(updateRoadDataIdToScrollTo(roadDataId));
    const sessionId = sessionIdQuery(getState());
    const image = await getImageByRoadDataRequest(sessionId, roadDataId);
    const loadedImageIndexes = getLoadedImageIndexesSelector(getState());
    const isAlreadyLoaded = loadedImageIndexes.includes(image.index);
    if (!isAlreadyLoaded) {
        await dispatch({
            type: 'STREET_VIEW/UPDATE_IMAGE_CACHE',
            images: [image],
        });
    }
    dispatch(currentImageIndexUpdated(image.index));
    await dispatch(retrieveAnnotationsForCurrentImage());
    await dispatch(refreshImageLandmark());
};

export const unselectRoadDataAndSave = (roadDataIdToHover) => async (dispatch) => {
    await dispatch(saveEditedAnnotation());
    await dispatch(noMoreSelectedRoadData());
    await dispatch(selectHoveredRoadData(roadDataIdToHover));
};

export const unselectRubricWithoutSaving = () => async (dispatch) => {
    await dispatch(noMoreAnnotationInEdition());
    await dispatch(unselectRubric());
};
export const cancelAnnotationCreationAndEdition = () => async (dispatch) => {
    await dispatch(noMoreSelectedRoadData());
    await dispatch(unselectRubricWithoutSaving());
};
export const selectAnnotationInEditionAndJump = (roadDataId) => async (dispatch) => {
    await dispatch(goToImageByRoadData(roadDataId));
    await dispatch(selectAnnotationInEdition(roadDataId));
};

export const goToImageByCumul = (cumulStart) => async (dispatch, getState) => {
    await dispatch(saveEditedAnnotation());
    const sessionId = sessionIdQuery(getState());
    let image;
    if (sessionId) {
        image = await getImageByCumulStartRequest(sessionId, cumulStart);
    }
    if (!image) {
        return;
    }
    const loadedImageIndexes = getLoadedImageIndexesSelector(getState());
    const isAlreadyLoaded = loadedImageIndexes.includes(image.index);
    if (!isAlreadyLoaded) {
        await dispatch({
            type: 'STREET_VIEW/UPDATE_IMAGE_CACHE',
            images: [image],
        });
    }
    await dispatch(currentImageIndexUpdated(image.index));
    if (sessionId) {
        dispatch(
            currentImageCumulUpdated({
                sessionId,
                cumul: cumulStart,
            }),
        );
    }
    await dispatch(refreshImageLandmark());
    await dispatch(retrieveAnnotationsForCurrentImage());
    await dispatch(updateScroll());
};

// Init street view

export const resetStreetView = () => (dispatch) => {
    dispatch({
        type: 'STREET_VIEW/RESET',
    });
    dispatch({
        type: 'CATALOGS/SELECTION_RESET',
    });
};

export const initStreetView = (sessionId) => async (dispatch) => {
    dispatch(resetStartedForStreetViewer());
    const streetViewFromSession = await getStreetViewRequest(sessionId);
    const { images, ...session } = streetViewFromSession;
    dispatch({
        type: 'STREET_VIEW/INIT',
        images: images ?? [],
    });
    dispatch(sessionUpdated(session));
    if (session.calibrationDone) {
        await dispatch(retrieveAnnotationsForCurrentImage());
        await dispatch(refreshImageLandmark());
    }
};
