import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ZoomOnOrganizationCommand } from '../../../../core/networkMap/commands/ZoomOnOrganizationCommand';
import { MapStyle, useAppDispatch, useAppSelector } from '../../../../types';
import { LabelGreyButton } from '../components';
import CropFreeIcon from '@mui/icons-material/CropFree';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as PinIcon } from '../../../../assets/icons/pin.svg';
import RemoveIcon from '@mui/icons-material/Remove';
import Satellite from '../../../../assets/img/satellite.jpg';
import Streets from '../../../../assets/img/street.jpg';
import { GreySquareButton } from '../../../design_system/inputs/buttons/GreySquareButton';
import { ButtonLabel, Control, MarginBottomBlock } from './components';
import { displayPrQuery, selectedmapStyleQuery } from '../../../../core/networkMap/queries';
import { UpdateMapStyleCommand } from '../../../../core/networkMap/commands/UpdateMapStyleCommand';
import { SetDisplayPrFromPersistenceCommand } from '../../../../core/networkMap/commands/SetDisplayPrFromPersistenceCommand';
import { SetMapStyleFromPersistenceCommand } from '../../../../core/networkMap/commands/SetMapStyleFromPersistenceCommand';
import { UpdateDisplayPrCommand } from '../../../../core/networkMap/commands/UpdateDisplayPrCommand';
import { loadConfigFromRootDataset } from '../../../../config';

export type Props = {
    handleZoomIn: () => void;
    handleZoomOut: () => void;
};

const { displayPrLayer } = loadConfigFromRootDataset();

export const MapControls: FC<Props> = ({ handleZoomIn, handleZoomOut }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(SetDisplayPrFromPersistenceCommand());
        dispatch(SetMapStyleFromPersistenceCommand());
    }, []);
    const displayPr = useAppSelector(displayPrQuery);
    const mapStyle = useAppSelector(selectedmapStyleQuery);

    const handleClickOnCenterMap = () => {
        dispatch(ZoomOnOrganizationCommand());
    };

    const handleChangeStyle = (value: MapStyle) => {
        dispatch(UpdateMapStyleCommand({ mapStyle: value }));
    };

    const handleDisplayPr = useCallback(() => {
        dispatch(UpdateDisplayPrCommand({ display: !displayPr }));
    }, [displayPr]);

    const [switchMapButtonLabel, switchMapButtonImageSource, toggleValue] =
        mapStyle === 'STREETS'
            ? [t('networkMap.navigation.satellite'), Satellite, 'SATELLITE']
            : [t('networkMap.navigation.streets'), Streets, 'STREETS'];

    return (
        <Control>
            <MarginBottomBlock>
                <LabelGreyButton label={t('networkMap.navigation.center')} handleClick={handleClickOnCenterMap}>
                    <CropFreeIcon />
                </LabelGreyButton>
            </MarginBottomBlock>
            <MarginBottomBlock>
                <GreySquareButton borderRadius="8px 8px 0 0" onClick={() => handleZoomIn()}>
                    <AddIcon />
                </GreySquareButton>
                <GreySquareButton borderRadius="0 0 8px 8px" onClick={() => handleZoomOut()}>
                    <RemoveIcon />
                </GreySquareButton>
            </MarginBottomBlock>
            <MarginBottomBlock>
                <GreySquareButton onClick={() => handleChangeStyle(toggleValue as MapStyle)}>
                    <img src={switchMapButtonImageSource} alt={switchMapButtonLabel} />
                    <ButtonLabel>{switchMapButtonLabel}</ButtonLabel>
                </GreySquareButton>
            </MarginBottomBlock>
            {displayPrLayer && (
                <MarginBottomBlock>
                    <GreySquareButton onClick={handleDisplayPr} selected={displayPr}>
                        <PinIcon />
                    </GreySquareButton>
                </MarginBottomBlock>
            )}
        </Control>
    );
};
