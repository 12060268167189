import { createCommand } from '../../utils';
import { moduleName, renderedSessionsIdsUpdated } from '../state';
import type { SessionId } from '../../domain/Session';

type Args = {
    renderedSessionsIds: SessionId[];
};

export const UpdateRenderedSessionsIdsCommand = createCommand(
    `${moduleName}/UPDATE_RENDERED_SESSIONS_IDS`,
    async ({ renderedSessionsIds }: Args, { dispatch }) => {
        dispatch(renderedSessionsIdsUpdated(renderedSessionsIds));
    },
);
