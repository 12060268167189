import { MenuItem, Select, Stack } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ReferenceKey, GradeReference, Reference } from '../../../../core/domain/Roaddata';
import { translateReference } from '../../../../core/legacy/utils/translations';
import { useAppSelector } from '../../../../types';
import { Control, Title } from './components';
import { theme } from '../../../legacy/style/theme';
import useGradesLoading from './use-grades-loading';
import { gradedAndWidthQuery } from '../../../../core/catalogs/queries';
import { selectedGradeReferenceQuery } from '../../../../core/networkMap/queries';
import referenceResolver from '../../../../core/legacy/utils/referenceResolver';

const GradeFiltersControl: FC = () => {
    const { t } = useTranslation();
    const rubricReferences = useAppSelector(gradedAndWidthQuery);
    const selectedGradeReference = useAppSelector(selectedGradeReferenceQuery);
    const resolvedReference = selectedGradeReference === null ? null : referenceResolver(selectedGradeReference);

    const { handleSelectorChange } = useGradesLoading();

    const placeholderIsSelected = useCallback(() => {
        return selectedGradeReference === undefined;
    }, [selectedGradeReference]);

    const aggregateItemIsSelected = useCallback(() => {
        return selectedGradeReference === 'AGGREGATE';
    }, [selectedGradeReference]);

    const referenceItemIsSelected = useCallback(
        (ref: GradeReference) => {
            return selectedGradeReference === ref || selectedGradeReference === (ref as Reference).key;
        },
        [selectedGradeReference],
    );

    const sortedRubricReferences = useMemo(() => rubricReferences.sort((a, b) => a.name.localeCompare(b.name)), [
        rubricReferences,
    ]);

    return (
        <Control>
            <Stack>
                <Title />
                <Stack flexDirection="row" justifyContent="space-around" alignItems="center">
                    <Select
                        sx={{
                            width: '100%',
                            backgroundColor: theme.color.white.main,
                        }}
                        value={(resolvedReference as ReferenceKey) ?? 'AGGREGATE'}
                        defaultValue={(resolvedReference as ReferenceKey) ?? 'AGGREGATE'}
                        onChange={handleSelectorChange}
                        displayEmpty
                    >
                        <MenuItem disabled value="" selected={placeholderIsSelected()}>
                            <em>{t('networkMap.gradesFilter.placeholder')}</em>
                        </MenuItem>
                        <MenuItem value="AGGREGATE" selected={aggregateItemIsSelected()}>
                            {translateReference('AGGREGATE')}
                        </MenuItem>
                        {sortedRubricReferences.map((ref) => (
                            <MenuItem key={ref.id} value={ref.key} selected={referenceItemIsSelected(ref)}>
                                {translateReference(ref)}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
            </Stack>
        </Control>
    );
};

export default GradeFiltersControl;
