import type { SessionId } from '../../domain/Session';
import { setModalToDisplay } from '../../legacy/actions/modals';
import { createCommand } from '../../utils';
import { moduleName, sessionIdsTargettedForInteraction } from '../state';

// this command exists for refactoring purposes
// in the long go, such UI related concerns should not be implemented as Commands
export const OpenModalWithContentCommand = createCommand(
    `${moduleName}/OPEN_MODAL_WITH_CONTENT`,
    async ({ modalToDisplay, sessionIds }: { modalToDisplay: string; sessionIds: Array<SessionId> }, { dispatch }) => {
        dispatch(sessionIdsTargettedForInteraction(sessionIds));
        dispatch(setModalToDisplay(modalToDisplay));
    },
);
