import { useTranslation } from 'react-i18next';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';
import { ReactComponent as CalibrationIcon } from '../../../../../assets/icons/calibrate.svg';
import {
    ContentContainer,
    IconContainer,
    ModalCenterSubTitle,
    ModalCenterTitle,
} from '../../../../legacy/components/ui/modals/styles';
import GenericModal from '../../../../legacy/components/ui/modals/GenericModal';
import type { FC } from 'react';

export const CalibrateOngoingModal: FC = () => {
    const { t } = useTranslation();
    const opts = { hideCancelButton: true, centerButtons: true, notDismissable: true };

    return (
        <GenericModal
            modalType={SessionsModalTypes.SESSIONS_CALIBRATE_ONGOING}
            options={opts}
            validateButtonText={t('labels.wellNoted')}
        >
            <ContentContainer>
                <IconContainer>
                    <CalibrationIcon />
                </IconContainer>
                <ModalCenterTitle>{t('calibrateSession.ongoing.title')}</ModalCenterTitle>
                <ModalCenterSubTitle>{t('calibrateSession.ongoing.subtitle')}</ModalCenterSubTitle>
                <ModalCenterSubTitle>{t('calibrateSession.ongoing.body')}</ModalCenterSubTitle>
            </ContentContainer>
        </GenericModal>
    );
};
