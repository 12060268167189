import type { SelectChangeEvent } from '@mui/material';
import type { GradeReference } from '../../../../core/domain/Roaddata';
import { UpdateSelectedGradeReferenceCommand } from '../../../../core/networkMap/commands/UpdateSelectedGradeReferenceCommand';
import { useAppDispatch } from '../../../../types';

type UseGradesLoading = {
    handleSelectorChange: (event: SelectChangeEvent) => void;
};

const useGradesLoading = (): UseGradesLoading => {
    const dispatch = useAppDispatch();

    const handleSelectorChange = (event: SelectChangeEvent) => {
        const referenceName = event.target.value as GradeReference;
        dispatch(UpdateSelectedGradeReferenceCommand({ selectedGradeReference: referenceName }));
    };

    return {
        handleSelectorChange,
    };
};

export default useGradesLoading;
