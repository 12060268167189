const AttributeTypeEnum = Object.freeze({
    NUMERIC: 'NUMERIC',
    ABD: 'ABD',
    TEXT: 'TEXT',
    LABEL: 'LABEL',
    LANDMARK_START: 'LANDMARK_START',
    LANDMARK_END: 'LANDMARK_END',
    LEXICON: 'LEXICON',
    CUMUL_START: 'CUMUL_START',
    CUMUL_END: 'CUMUL_END',
    POSITION: 'POSITION',
});

export default AttributeTypeEnum;
