import ScStyled from 'styled-components';
import { styled, Stack, StackProps } from '@mui/material';
import type { FC } from 'react';
import { BaseTypography } from '../../design_system/texts';
import { theme } from '../../legacy/style/theme';
import { useTranslation } from 'react-i18next';

export const Panel = ScStyled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: ${theme.color.blue.selected};
    width: 20vw;
    height: 100%;
    box-shadow: 0 0 3px 1px ${theme.color.blue.primary}1A; // alpha 10%
    box-sizing: border-box;
    overflow: hidden;
`;

export const HeadingContainer = styled(Stack)<StackProps>(() => ({
    justifyContent: 'flex-start',
    padding: '16px',
    flexDirection: 'row',
    alignItems: 'center',
    height: '30px',
    backgroundColor: theme.color.blue.light,
}));

export const Title: FC = () => {
    const { t } = useTranslation();

    return (
        <Stack direction="column" padding="16px" gap={0.5}>
            <BaseTypography noWrap lineHeight={1.1} fontSize={theme.font.size.medium3} fontWeight={500}>
                {t('networkMap.sessionsPanel.myNetwork')}
                {` (${t('networkMap.sessionsPanel.beta')})`}
            </BaseTypography>
        </Stack>
    );
};
