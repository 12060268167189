import type { ExternalIntervalPort } from '../core/automatic_analysis/ExternalIntervalPort';

export class IntervalProvider implements ExternalIntervalPort {
    private intervals: Map<string, any> = new Map(); // I can't type the NodeJS.Timer as value, don't know why…

    createNamedInterval(name: string, delayInSeconds: number, callback: () => Promise<void>) {
        if (this.intervals.has(name)) {
            clearInterval(this.intervals.get(name));
        }
        const interval = setInterval(callback, delayInSeconds * 1000);
        this.intervals.set(name, interval);
    }
}
