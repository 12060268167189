import { Ellipsis, Flex, Text } from '../../../style';
import styled from 'styled-components';

export const FileLabel = styled(Ellipsis)`
    font-size: ${({ theme }) => theme.font.size.regular};
    color: ${({ theme, warning }) => (warning ? theme.color.orange.main : theme.color.black.main)};
    font-weight: 700;
    width: 100%;
`;

export const FileItem = styled(Flex).attrs({
    justify: 'space-between',
})`
    padding: 0;
    margin: 0 0 10px 0;
    box-sizing: border-box;
`;

export const RightContainer = styled(Flex).attrs({ justify: 'end' })`
    margin-left: 10px;
    min-width: fit-content;
    width: fit-content;
`;

export const FileSize = styled(Text)`
    font-weight: 700;
    color: ${({ theme, warning }) => (warning ? theme.color.orange.main : theme.color.black.main)};
    margin-right: 6px;
`;
