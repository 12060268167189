import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { initStreetView, resetStreetView } from '../../../../../core/legacy/actions';
import { useParams } from 'react-router-dom';
import useConvertAnnotationsPixels from './use-convert-annotations-pixels';
import { AnnotationEngine } from '../../ui/annotation-engine/styles';
import {
    currentImageSelector,
    currentImageUrlSelector,
    selectedRubricSelector,
} from '../../../../../core/legacy/selectors/streetView';
import useEngineStateMachine from './use-engine-state-machine';
import useStyleOperations from './use-style-operations';
import useSortRealAnnoWithAnnoInForeground from './use-sort-real-annotations-with-anno-in-foreground';
import useAnnotationEngineSize from './use-annotation-engine-size';
import { editStyle } from '../../ui/annotation-engine/drawing-styles';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import useScreenIsZoomed from '../../utils/use-screen-is-zoomed';
import { RoadView } from '../../../../roadview';
import { sessionQuery } from '../../../../../core/streetViewer/queries';
import { Position, ToolContextMenu } from '../ToolContextMenu';
import { useIsRubricReferenceParameterizable } from '../../../../../utils/use-is-rubric-reference-parameterizable';

const RoadViewer: FC<{ children?: ReactNode }> = ({ children }) => {
    const { sessionId } = useParams();
    const dispatch = useAppDispatch();
    const selectedRubric = useAppSelector(selectedRubricSelector);
    const previousSelectedRubric = useRef(selectedRubric);
    const refAE = useRef<HTMLDivElement>(null);
    const session = useAppSelector(sessionQuery);
    const currentImage = useAppSelector(currentImageSelector);
    const currentImageUrl = useAppSelector(currentImageUrlSelector);
    const { isZoomed, pxRatio } = useScreenIsZoomed();

    const styleOps = useStyleOperations();
    const { deformedAnnotationToEdit, realAnnotations, setRealAnnotations } = useConvertAnnotationsPixels(
        styleOps,
        currentImage,
        pxRatio,
    );

    const shouldOpenContextMenu = useIsRubricReferenceParameterizable(selectedRubric);

    const [open, setOpen] = useState<boolean>(false);
    const [position, setPosition] = useState<Position>({ left: 0, top: 0 });
    const handleOpenContextMenu = (event: MouseEvent) => {
        setOpen(shouldOpenContextMenu);
        setPosition({ left: event.offsetX + 10, top: event.offsetY });
    };
    const handleCloseContextMenu = () => {
        setOpen(false);
    };

    const handleEvent = useEngineStateMachine(
        refAE,
        previousSelectedRubric,
        selectedRubric,
        deformedAnnotationToEdit,
        styleOps,
        handleOpenContextMenu,
    );
    useSortRealAnnoWithAnnoInForeground(realAnnotations, setRealAnnotations, styleOps);

    const { canvasSize } = useAnnotationEngineSize(refAE, currentImage, isZoomed, pxRatio, session);

    const sessionWasAlreadyOpened = (aSessionId) => aSessionId === session?.id;
    /**
     * On component mount, get current session and first 3 images
     */
    useEffect(() => {
        const paramSessionId = sessionId;
        if (!sessionWasAlreadyOpened(paramSessionId)) {
            dispatch(resetStreetView());
        }
        dispatch(initStreetView(paramSessionId));
    }, []);

    return (
        <RoadView>
            {currentImage && (
                <AnnotationEngine
                    ref={refAE}
                    canvasSize={canvasSize}
                    annotationToEdit={deformedAnnotationToEdit}
                    annotations={realAnnotations}
                    backgroundImagePath={currentImageUrl}
                    cursorStyle={styleOps.cursorStyle}
                    onEvent={handleEvent}
                    styleForAnnotationToEdit={editStyle.style}
                    styleForAnnotations={styleOps.styleForAnnotations}
                    styleForPointsToEdit={styleOps.styleForPointsToEdit}
                />
            )}
            {children}
            <ToolContextMenu open={open} position={position} handleCloseContextMenu={handleCloseContextMenu} />
        </RoadView>
    );
};

export default RoadViewer;
