import { ReactComponent } from '../../../assets/icons/upload.svg';
import type { FC } from 'react';
import { BaseIcon, IconProps } from './BaseIcon';

export const UploadIcon: FC<IconProps> = (props) => {
    return (
        <BaseIcon {...props}>
            <ReactComponent />
        </BaseIcon>
    );
};
