import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Organization } from '../domain/Organization';

const name = 'ORGANIZATION';

interface State {
    organization: Organization;
}

const initialState = { organization: {} as Organization };

const organizationSlice = createSlice({
    name,
    initialState,
    reducers: {
        organizationInitialized(state: State, { payload }: PayloadAction<Organization>) {
            state.organization = payload;
        },
    },
});

const {
    reducer: organizationReducer,
    actions: { organizationInitialized },
} = organizationSlice;

export { name as moduleName, organizationInitialized, organizationReducer };
