import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import RoadDataSummary from './RoadDataSummary';
import SelectedRoadDataAttributes from './SelectedRoadDataDetails';
import Filters from './Filters';
import { HeadingContainer, HeadingTitleContainer, StyledNumberOfHiddenElements, Summary } from './styles';
import { updateScroll } from '../../../../../core/legacy/actions';
import {
    numberOfHiddenRoadDatasSelector,
    roadDatasToDisplaySelector,
    roadDatasTotalSize,
} from '../../../../../core/legacy/selectors/streetView';
import { Panel } from '../../ui/panels/right-panel/styles';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { ExecutionBaner } from './AiAnalysisBanners/ExecutionBaner';
import SessionInformation from '../ImageInformation';
import { RegularText } from '../../../../design_system/texts';
import { sessionQuery } from '../../../../../core/streetViewer/queries';

const NumberOfHiddenElements = () => {
    const { t } = useTranslation();
    const numberOfHiddenRoadDatas = useAppSelector(numberOfHiddenRoadDatasSelector);

    return (
        <StyledNumberOfHiddenElements>
            {t('streetView.filteredElement', { count: numberOfHiddenRoadDatas })}
        </StyledNumberOfHiddenElements>
    );
};

const RoadDatasPanel: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const roadDatas = useAppSelector(roadDatasToDisplaySelector, shallowEqual);
    const totalSize = useAppSelector(roadDatasTotalSize);
    const summaryRef = useRef<HTMLDivElement>(null);
    const summaryOffsetHeight = summaryRef.current?.offsetHeight ?? 0;
    const session = useAppSelector(sessionQuery);

    useEffect(() => {
        dispatch(updateScroll());
    }, [roadDatas]);

    return (
        <Panel>
            {session && <ExecutionBaner sessionId={session.id} />}
            <SessionInformation />
            <HeadingContainer>
                <HeadingTitleContainer>
                    <RegularText fontWeight="bold">{t(`streetView.storedMeasure`, { count: totalSize })}</RegularText>
                    <NumberOfHiddenElements />
                </HeadingTitleContainer>
                <Filters />
            </HeadingContainer>
            <Summary ref={summaryRef}>
                {roadDatas.map((roadData) => {
                    return (
                        <RoadDataSummary
                            key={roadData.id}
                            roadData={roadData}
                            summaryOffsetHeight={summaryOffsetHeight}
                        />
                    );
                })}
            </Summary>
            <SelectedRoadDataAttributes />
        </Panel>
    );
};

export default RoadDatasPanel;
