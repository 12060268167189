import { useState } from 'react';

/**
 * Allows to manage canvas drawing styles from outside the canvas
 */
const useStyleOperations = () => {
    const [styleForAnnotations, setStyleForAnnotations] = useState(new Map());
    const [styleForPointsToEdit, setStyleForPointsToEdit] = useState(new Map());
    const [cursorStyle, setCursorStyle] = useState('default');
    const annotationHasStyle = (id, styleName) => !!(styleForAnnotations.get(id)?.name === styleName);
    const isStyleGivenToPoints = (styleName) =>
        Array.from(styleForPointsToEdit.values()).some((styleData) => styleData?.name === styleName);
    const isStyleGivenToAnnotations = (styleName) =>
        Array.from(styleForAnnotations.values()).some((styleData) => styleData?.name === styleName);

    const setStyleToAnnotationsByIndexes = (style, ...annotationsId) => {
        const newStyleForAnnotations = new Map(styleForAnnotations);
        annotationsId.forEach((id) => {
            newStyleForAnnotations.set(id, style);
        });
        setStyleForAnnotations(newStyleForAnnotations);
    };

    const removeStyleFromAnnotationsByIndexes = (...annotationsId) => {
        const newStyleForAnnotations = new Map(styleForAnnotations);
        annotationsId.forEach((id) => {
            newStyleForAnnotations.delete(id);
        });
        setStyleForAnnotations(newStyleForAnnotations);
    };

    const setStyleExclusivelyToId = (setState, state, exclusiveStyle, itemId) => {
        const newState = new Map(state);
        newState.forEach((style, id) => {
            if (style.name === exclusiveStyle.name) {
                newState.delete(id);
            }
        });
        newState.set(itemId, exclusiveStyle);
        setState(newState);
    };

    const setStyleExclusivelyToAnnotationIdAndRemoveAllStyles = (style, annotationId) => {
        setStyleForAnnotations(new Map([[annotationId, style]]));
    };

    const setStyleExclusivelyToAnnotationId = (exclusiveStyle, annotationId) => {
        setStyleExclusivelyToId(setStyleForAnnotations, styleForAnnotations, exclusiveStyle, annotationId);
    };

    const setStyleExclusivelyToPointId = (exclusiveStyle, pointId) => {
        setStyleExclusivelyToId(setStyleForPointsToEdit, styleForPointsToEdit, exclusiveStyle, pointId);
    };
    // FIXME later : refacto (or not, if StyleDataById are split between anno and points styles)

    const removeStylesFromAnnotationsByStyleNames = (...styleNames) => {
        if (styleNames.some((styleName) => isStyleGivenToAnnotations(styleName))) {
            const newStyleForAnnotations = new Map(styleForAnnotations);
            newStyleForAnnotations.forEach((styleData, annotationId) => {
                if (styleNames.includes(styleData.name)) {
                    newStyleForAnnotations.delete(annotationId);
                }
            });
            setStyleForAnnotations(newStyleForAnnotations);
        }
    };
    const removeStyleFromPointsByStyleNames = (...styleNames) => {
        if (styleNames.some((styleName) => isStyleGivenToPoints(styleName))) {
            const newStyleForPointsToEdit = new Map(styleForPointsToEdit);
            newStyleForPointsToEdit.forEach((styleData, annotationId) => {
                if (styleNames.includes(styleData.name)) {
                    newStyleForPointsToEdit.delete(annotationId);
                }
            });
            setStyleForPointsToEdit(newStyleForPointsToEdit);
        }
    };

    const removeStyleFromPointsByIndexes = (...pointIndexes) => {
        if (pointIndexes.some((pointId) => styleForPointsToEdit.has(pointId))) {
            const newStyleForPointsToEdit = new Map(styleForPointsToEdit);
            pointIndexes.forEach((pointId) => {
                newStyleForPointsToEdit.delete(pointId);
            });
            setStyleForPointsToEdit(newStyleForPointsToEdit);
        }
    };

    return {
        setStyleToAnnotationsByIndexes,
        removeStyleFromAnnotationsByIndexes,
        annotationHasStyle,
        styleForAnnotations,
        styleForPointsToEdit,
        setStyleExclusivelyToAnnotationId,
        removeStylesFromAnnotationsByStyleNames,
        removeStyleFromPointsByStyleNames,
        setStyleExclusivelyToPointId,
        removeStyleFromPointsByIndexes,
        setStyleExclusivelyToAnnotationIdAndRemoveAllStyles,
        cursorStyle,
        setCursorStyle,
    };
};

export default useStyleOperations;
