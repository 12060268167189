import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { SessionsModalTypes } from '../../core/legacy/reducers/modals';
import GenericModal from '../legacy/components/ui/modals/GenericModal';
import { ContentContainer, ModalCenterTitle, ModalCenterSubTitle } from '../legacy/components/ui/modals/styles';
import { useAppDispatch } from '../../types';
import { NavigateToSessionsCommand } from '../../core/sessions/commands/NavigateToSessionsCommand';

export const NoDataForNetworkMapModal: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const options = {
        hideCancelButton: true,
        notDismissable: true,
        centerButtons: true,
    };

    const onValidate = () => {
        dispatch(NavigateToSessionsCommand());
    };

    return (
        <GenericModal
            options={options}
            modalType={SessionsModalTypes.NO_DATA_FOR_NETWORK_MAP}
            validateButtonText={t('networkMap.noDataModal.validate')}
            onValidate={onValidate}
        >
            <ContentContainer>
                <ModalCenterTitle>{t('networkMap.noDataModal.title')}</ModalCenterTitle>
                <ModalCenterSubTitle>{t('networkMap.noDataModal.subtitle')}</ModalCenterSubTitle>
            </ContentContainer>
        </GenericModal>
    );
};
