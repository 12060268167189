import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import ComputeSessionsZones from '../../../../../core/sessions/commands/ComputeSessionsZones';
import { selectedSessionIdsQuery, targettedSessionIdsForInteractionQuery } from '../../../../../core/sessions/queries';
import GenericModal from '../../../../legacy/components/ui/modals/GenericModal';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';
import { unsetModalToDisplay } from '../../../../../core/legacy/actions/modals';
import ZonesForm from './ZonesForm';
import ZonesLoading from './ZonesLoading';

import type { HomogeneousZoneParams } from '../../../../../core/sessions/state';
import { TargetSessionsForInteractionCommand } from '../../../../../core/sessions/commands/TargetSessionsForInteractionCommand';

const ComputeZonesModal: FC = () => {
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const close = () => dispatch(unsetModalToDisplay());

    const checkedSessions = useAppSelector(selectedSessionIdsQuery);
    const targetSession = useAppSelector(targettedSessionIdsForInteractionQuery);

    // if one session is focused only modify this one, not checked ones
    const sessionIds = targetSession?.length ? targetSession : checkedSessions;

    const submitForm = async (formValue: HomogeneousZoneParams) => {
        setLoading(true);
        await dispatch(ComputeSessionsZones({ sessionIds, zoneParams: formValue }));
        setLoading(false);
        // reset focused session on close
        dispatch(TargetSessionsForInteractionCommand([]));
    };

    return (
        <GenericModal
            modalType={SessionsModalTypes.COMPUTE_ZONES}
            options={{ hideDefaultButtons: true, notDismissableByClickingOnBackground: true, notDismissable: loading }}
        >
            {loading ? <ZonesLoading /> : <ZonesForm onClose={close} onSubmit={submitForm} />}
        </GenericModal>
    );
};

export default ComputeZonesModal;
