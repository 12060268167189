import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import type { SessionId } from '../domain/Session';
import { isAnalysisDoneOnSession, isAnalysisNotStartedOnSession, isAnalysisOngoingOnSession } from './utils';

const slice = (state: RootState) => state.aiAnalysis;

export const allExecutionsQuery = createSelector(slice, (state) => state.executions);

export const isAnalysisNotStartedOnSessionQuery = (id: SessionId) =>
    createSelector(slice, (state) => isAnalysisNotStartedOnSession(state.executions, id));

export const isAnalysisOngoingOnSessionQuery = (id: SessionId) =>
    createSelector(slice, (state) => isAnalysisOngoingOnSession(state.executions, id));

export const isAnalysisDoneOnSessionQuery = (id: SessionId) =>
    createSelector(slice, (state) => isAnalysisDoneOnSession(state.executions, id));

export const analyzedSessionsIdQuery = createSelector(slice, (state) =>
    state.executions.filter((execution) => execution.status !== 'ONGOING').flatMap((execution) => execution.sessionId),
);

export const sessionIdsForAnalysesQuery = createSelector(slice, (state) => state.sessionIdsForAnalyses);

export const availableModelsQuery = createSelector(slice, (state) => state.models);

export const modelCodesPerSessionId = createSelector(slice, (state) =>
    state.executions.reduce((modelsPerSession, { modelCode, sessionId }) => {
        if (modelsPerSession[sessionId] === undefined) {
            modelsPerSession[sessionId] = [modelCode];
        } else {
            modelsPerSession[sessionId]!.push(modelCode);
        }

        return modelsPerSession;
    }, {} as Record<string, string[]>),
);

export const aiAnalysesOngoingOnSessionQuery = (id: SessionId) =>
    createSelector(slice, (state) =>
        state.executions.filter((execution) => execution.sessionId === id && execution.status === 'ONGOING'),
    );
