import PropTypes from 'prop-types';
import { ReactComponent as Cross } from '../../../../../../assets/icons/close.svg';
import { ReactComponent as CheckMark } from '../../../../../../assets/icons/checkmark.svg';
import { IconContainer } from '../styles';

const ResultIcon = ({ failure }) => {
    const Icon = failure ? <Cross /> : <CheckMark />;

    return <IconContainer failure={failure}>{Icon}</IconContainer>;
};

ResultIcon.defaultProps = {
    failure: false,
};

ResultIcon.propTypes = {
    failure: PropTypes.bool,
};

export default ResultIcon;
