import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './legacy/routes/Dashboard';
import PasswordInit from './legacy/routes/PasswordInit';
import { ProtectedRoute } from './ProtectedRoute';
import type React from 'react';
import StreetView from './legacy/components/StreetView';
import ManualCalibration from './legacy/components/ManualCalibration';
import { LoginPage } from './authentication/LoginPage';
import MapView from './network-map';
import { SessionsListPage } from './session/list-sessions/SessionsListPage';

export const AppRoutes: React.FC<{}> = () => {
    return (
        <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route
                path="/dashboard/acquisition"
                element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                }
            >
                <Route index element={<SessionsListPage />} />
                <Route path="street-view/:sessionId" element={<StreetView />} />
                <Route path="calibration/*" element={<ManualCalibration />} />
                <Route path="map" element={<MapView />} />
            </Route>
            <Route
                path="/init-password"
                element={
                    <ProtectedRoute>
                        <PasswordInit />
                    </ProtectedRoute>
                }
            />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};
