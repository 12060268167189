import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { NetworkImageProperties } from '../../../../../../core/networkMap/ExternalNetworkMapPort';
import ExpandableImage from '../../Shared/ExpandableImage';
import { theme } from '../../../../../legacy/style/theme';
import Measure from '../../Shared/Measure';
import { useAppSelector } from '../../../../../../types';
import { sessionByIdQuery } from '../../../../../../core/sessions/queries';

type Props = {
    properties: NetworkImageProperties;
};

const Content: FC<Props> = ({ properties }) => {
    const { t } = useTranslation();
    const session = useAppSelector(sessionByIdQuery(properties.sessionId));

    return (
        <Box marginBottom=".25rem">
            <Typography
                noWrap
                sx={{
                    wordBreak: 'break-all',
                    color: theme.color.black.main,
                    fontWeight: 600,
                }}
            >
                {session?.name}
            </Typography>
            <Measure
                label={t('map.popup.cumul-start-image')}
                value={`${t('map.popup.start')} + ${properties.cumulStartSession.toFixed(0)}m`}
            />
            <ExpandableImage sessionId={properties.sessionId} imageIndex={properties.imageIndex} />
        </Box>
    );
};

export default Content;
