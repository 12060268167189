import axios from 'axios';
import { captureException } from '@sentry/browser';
import type { Tags } from './ExternalTagsProviderPort';

export class ExternalTagsProvider {
    constructor(private readonly baseEndpoint: string) {}

    async loadTags(): Promise<Tags> {
        try {
            const response = await axios.request({
                method: 'GET',
                url: `${this.baseEndpoint}/tags`,
            });

            return { tags: response.data };
        } catch (e) {
            console.error(e);
            captureException(e);
            throw e;
        }
    }
}
