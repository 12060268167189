import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionUpload, SessionUploadBlock, UploadStatuses } from '../domain/SessionUpload';

export const moduleName = 'UPLOADS';

export type BlockId = string;
export type SessionUploadData = {
    url: string;
    blocks: SessionUploadBlock[];
    fileName: string;
    archiveId: string;
    key: string;
    totalSize: number;
};

export type UploadableSession = {
    size: number;
    path: string;
    slice: (...args: Array<any>) => any;
};

type State = {
    sessionUploads: SessionUpload[];
};

const initialState: State = {
    sessionUploads: [],
};

export const hasUrl = (url: string) => (upload: SessionUpload): boolean => upload.url === url;

const uploadsSlice = createSlice({
    name: moduleName,
    initialState,
    reducers: {
        sessionUploadInitiated(state: State, action: PayloadAction<SessionUploadData>) {
            state.sessionUploads.push({
                ...action.payload,
                status: UploadStatuses.INITIATED,
                blockList: [],
                failedBlocks: [],
                uploadedSize: 0,
            });
        },
        sessionUploadsReset(state: State) {
            state.sessionUploads = [];
        },
        sessionUploadStatusUpdated(state: State, action: PayloadAction<{ url: string; status: UploadStatuses }>) {
            const uploadToUpdate = state.sessionUploads.find(hasUrl(action.payload.url));

            if (uploadToUpdate) {
                uploadToUpdate.status = action.payload.status;
            }
        },
        sessionUploadBlockListUpdated(state: State, action: PayloadAction<{ url: string; blockList: BlockId[] }>) {
            const uploadToUpdate = state.sessionUploads.find(hasUrl(action.payload.url));

            if (uploadToUpdate) {
                uploadToUpdate.blockList = action.payload.blockList;
            }
        },
        sessionUploadFailedBlocksUpdated(
            state: State,
            action: PayloadAction<{ url: string; failedBlocks: SessionUploadBlock[] }>,
        ) {
            const uploadToUpdate = state.sessionUploads.find(hasUrl(action.payload.url));

            if (uploadToUpdate) {
                uploadToUpdate.failedBlocks = action.payload.failedBlocks;
            }
        },
        sessionUploadUploadedSizeIncremented(state: State, action: PayloadAction<{ url: string; blockSize: number }>) {
            const uploadToUpdate = state.sessionUploads.find(hasUrl(action.payload.url));
            if (uploadToUpdate) {
                const { uploadedSize } = uploadToUpdate;
                const newUploadedSize = uploadedSize + action.payload.blockSize;

                uploadToUpdate.uploadedSize = newUploadedSize;
            }
        },
        sessionUploadUploadedSizeUpdated(state: State, action: PayloadAction<{ url: string }>) {
            const uploadToUpdate = state.sessionUploads.find(hasUrl(action.payload.url));
            if (uploadToUpdate) {
                const { failedBlocks, totalSize } = uploadToUpdate;
                const failedBlocksSizeSum = failedBlocks.reduce((sum, { blockSize }) => {
                    return sum + blockSize;
                }, 0);
                const newUploadedSize = totalSize - failedBlocksSizeSum;

                uploadToUpdate.uploadedSize = newUploadedSize;
            }
        },
    },
});

export const {
    sessionUploadInitiated,
    sessionUploadsReset,
    sessionUploadBlockListUpdated,
    sessionUploadFailedBlocksUpdated,
    sessionUploadStatusUpdated,
    sessionUploadUploadedSizeIncremented,
    sessionUploadUploadedSizeUpdated,
} = uploadsSlice.actions;
export const uploadsReducer = uploadsSlice.reducer;
