import { minimumNumberOfPointsForCalibration } from '../../../../../core';
import { useState, useEffect } from 'react';
import {
    getCoordinateXValue,
    getCoordinateYValue,
    getCoordinateZValue,
} from '../../../../../core/legacy/utils/calibrationPointsUtils';

const useCalibrationPanelStateMachine = (calibrationPoints, t) => {
    const minimumNumberOfPointsToGenerateCalibration = minimumNumberOfPointsForCalibration;

    // TODO start - refacto this algo to improve perf
    const hasDuplicates = (calibrationPoint) => {
        const coordinatesToCheck = {
            x: +getCoordinateXValue(calibrationPoint),
            y: +getCoordinateYValue(calibrationPoint),
            z: +getCoordinateZValue(calibrationPoint),
        };
        const foundDuplicates = calibrationPoints.filter(
            (point) =>
                +getCoordinateXValue(point) === coordinatesToCheck.x &&
                +getCoordinateYValue(point) === coordinatesToCheck.y &&
                +getCoordinateZValue(point) === coordinatesToCheck.z,
        );

        return foundDuplicates.length > 1;
    };
    // TODO end

    const [calibrationIsReadyToGenerate, setCalibrationIsReadyToGenerate] = useState(false);

    const [notEnoughPointsWarning, setNotEnoughPointsWarning] = useState(
        t('calibration.warnings.numberOfPoints.notEnoughPoints'),
    );
    const [containsDuplicates, setContainsDuplicates] = useState(false);

    useEffect(() => {
        const enoughCalibrationPoints = calibrationPoints.length >= minimumNumberOfPointsToGenerateCalibration;
        const newContainsDuplicates = calibrationPoints.some((calibrationPoint) => hasDuplicates(calibrationPoint));
        setCalibrationIsReadyToGenerate(enoughCalibrationPoints && !newContainsDuplicates);

        const newNotEnoughPointsWarning = enoughCalibrationPoints
            ? `(${t('calibration.warnings.numberOfPoints.increasePrecisionWithAdditionalPoints')})`
            : `(${t('calibration.warnings.numberOfPoints.notEnoughPoints')}${
                  minimumNumberOfPointsToGenerateCalibration - calibrationPoints.length
              })`;
        setNotEnoughPointsWarning(newNotEnoughPointsWarning);

        setContainsDuplicates(newContainsDuplicates);
    }, [calibrationPoints]);

    return {
        calibrationIsReadyToGenerate,
        notEnoughPointsWarning,
        containsDuplicates,
    };
};

export default useCalibrationPanelStateMachine;
