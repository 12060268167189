import { useTranslation } from 'react-i18next';
import { FC, useCallback } from 'react';
import { useAppDispatch } from '../../../../types';
import { NavigateToNetworkMapCommand } from '../../../../core/networkMap/commands/NavigateToNetworkMapCommand';
import BaseButton from './BaseButton';
import { MapIcon } from '../../icons/MapIcon';

export const GoToMapViewButton: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleClick = useCallback(() => {
        dispatch(NavigateToNetworkMapCommand({}));
    }, []);

    return (
        <BaseButton onClick={handleClick} sx={{ ml: 1 }}>
            <MapIcon themeStyle="dark" />
            {t('labels.goToMapView')}
        </BaseButton>
    );
};
