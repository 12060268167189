import type { SessionUpload, SessionUploadBlock } from '../core/domain/SessionUpload';
import type { UploadableSession } from '../core/uploads/state';
import type { SerializableFileProviderPort } from './SerializableFileProviderPort';

export class SerializableFileProvider implements SerializableFileProviderPort {
    private readonly MAX_BLOCK_SIZE = 2 ** 20 * 4;
    clear(uploads: SessionUpload[]): void {
        for (const upload of uploads) {
            const { blocks } = upload;

            for (const block of blocks) {
                window.URL.revokeObjectURL(block.objectUrl ?? '');
            }
        }
    }

    splitFileIntoBlocks(session: UploadableSession, offset = this.MAX_BLOCK_SIZE): Array<any> {
        let startPointer = 0;
        const endPointer = session.size;
        const blocks: Array<SessionUploadBlock> = [];

        for (let index = 0; startPointer < endPointer; index++) {
            const newStartPointer = startPointer + offset;
            const blockContent = session.slice(startPointer, newStartPointer);
            const { size: blockSize } = blockContent;
            const objectUrl = window.URL.createObjectURL(blockContent);
            blocks.push({ id: this.formatBlockId(index), objectUrl, blockSize });
            startPointer = newStartPointer;
        }

        return blocks;
    }

    private formatBlockId(index: number): string {
        const baseId = index.toString();
        const id = baseId.padStart(8, 'A');

        return id;
    }
}
