import { createCommand } from '../../utils';
import { imageRoadataOnly, moduleName } from '../state';
import { UpdateFiltersCommand } from './UpdateFiltersCommand';

const toggleImageRoaddataOnlyCommand = createCommand(
    `${moduleName}/TOGGLE_CHECKED_FILTER`,
    async (checked: boolean, { dispatch }) => {
        dispatch(imageRoadataOnly(checked));
        dispatch(UpdateFiltersCommand());
    },
);

export default toggleImageRoaddataOnlyCommand;
