import { createCommand } from '../../utils';
import { moduleName, sortUpdated } from '../state';
import type { SessionSort } from '../../domain/Session';

export const SortSessionsCommand = createCommand(
    `${moduleName}/SORT_SESSIONS`,
    async (sessionSort: SessionSort, { dispatch, extra }) => {
        extra.externalSessionConfiguration.saveSessionsSort(sessionSort);
        dispatch(sortUpdated(sessionSort));
    },
);
