import { Button, styled } from '@mui/material';

export const TranslucentButton = styled(Button)(({ theme }) => ({
    border: 'none',
    width: '32px',
    minWidth: '32px',
    height: '32px',
    color: theme.color.grey.main,
    fontSize: '20px',
    '&:hover': {
        border: 'none',
        backgroundColor: `${theme.color.grey.light}B3`,
    },
}));
