import type { FC } from 'react';
import type { NetworkImageProperties } from '../../../../../../core/networkMap/ExternalNetworkMapPort';
import Content from '../Content';
import ButtonHideSession from '../../Shared/ButtonHideSession';
import ButtonGoToSession from '../../Shared/ButtonGoToSession';

type Props = {
    properties: NetworkImageProperties | null;
};

const ImagePopupOnClick: FC<Props> = ({ properties }) => {
    if (!properties) {
        return null;
    }

    return (
        <>
            <Content properties={properties} />
            <ButtonGoToSession sessionId={properties.sessionId} cumulStartSession={properties.cumulStartSession} />
            <ButtonHideSession sessionId={properties.sessionId} />
        </>
    );
};

export default ImagePopupOnClick;
