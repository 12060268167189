import { useCallback } from 'react';
import type { Rubric } from '../core/domain/Roaddata';

export const useIsRubricReferenceParameterizable = (rubric: Rubric | undefined): boolean => {
    const hasReferenceAttribute = useCallback((rub: Rubric): boolean => !!rub.references.length, [rubric]);
    const hasReferenceValues = useCallback((rub: Rubric): boolean => !!rub.references.length, []);

    if (!rubric) {
        return false;
    }

    if (!hasReferenceAttribute(rubric)) {
        return false;
    }

    if (!hasReferenceValues(rubric)) {
        return false;
    }

    return true;
};
