import { useEffect, MutableRefObject } from 'react';
import type { MapRef } from 'react-map-gl';
import type { OrganizationNetwork } from '../../../../core/networkMap/ExternalNetworkMapPort';
import { zoomedOrganizationQuery } from '../../../../core/networkMap/queries';
import { useAppSelector } from '../../../../types';
import { FIT_BOUNDS_OPTIONS } from '../map-configuration';

const useZoomOnOrganization = (mapRef: MutableRefObject<MapRef | null>, networkData: OrganizationNetwork | null) => {
    const zoomOnOrganization = useAppSelector(zoomedOrganizationQuery);

    useEffect(() => {
        if (zoomOnOrganization && networkData?.bbox) {
            mapRef.current?.fitBounds(networkData.bbox, FIT_BOUNDS_OPTIONS);
        }
    }, [zoomOnOrganization]);
};

export default useZoomOnOrganization;
