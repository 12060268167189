import type { SessionId } from '../../domain/Session';
import { createCommand } from '../../utils';
import { moduleName, sessionIdsTargettedForInteraction } from '../state';

export const TargetSessionsForInteractionCommand = createCommand(
    `${moduleName}/TARGET_SESSIONS_FOR_INTERACTION`,
    async (sessionIds: Array<SessionId>, { dispatch }) => {
        await dispatch(sessionIdsTargettedForInteraction(sessionIds));
    },
);
