import { format, Locale } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';

const locales = { en: enGB, fr, 'fr-FR': fr };

const locale = locales[navigator.language] as Locale;

const formatDateWithBrowserLocale = (date: Date, dateFormat: string = 'P'): string => {
    return format(new Date(date), dateFormat, { locale });
};

export default formatDateWithBrowserLocale;
