import type { FC } from 'react';
import { GreySquareButton } from '../../../../../design_system/inputs/buttons/GreySquareButton';
import { ReactComponent as ZoomIcon } from '../../../../../../assets/icons/zoom_in.svg';

type ZoomEnablerProps = {
    selected: boolean;
    toggleSelected: () => void;
};

const ZoomEnabler: FC<ZoomEnablerProps> = ({ selected, toggleSelected }) => {
    return (
        <GreySquareButton selected={selected} onClick={toggleSelected}>
            <ZoomIcon />
        </GreySquareButton>
    );
};

export default ZoomEnabler;
