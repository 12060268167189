// TODO use the new modal system (Generic Modal)
import BaseModal from '../../ui/modals/BaseModal';
import { FC, ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../style';

const LeaveCalibrationModal: FC<{ onValidate: () => void; ref: ForwardedRef<any> }> = forwardRef(
    ({ onValidate }, ref: ForwardedRef<any>) => {
        const { t } = useTranslation();

        return (
            <BaseModal
                onValidate={onValidate}
                cancelButtonText={t('calibration.modals.leave.cancel')}
                validateButtonText={t('calibration.modals.leave.validate')}
                ref={ref}
            >
                <Text>{t('calibration.modals.leave.text')}</Text>
            </BaseModal>
        );
    },
);

export default LeaveCalibrationModal;
