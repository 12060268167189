import { createCommand } from '../utils';
import { initialized, moduleName, updatedExecutions } from './state';

const commandName = `${moduleName}/INIT_AI_ANALYSIS_COMMAND`;
const refreshDelayInSecond = 60;

export const InitAiAnalysisCommand = createCommand(commandName, async (_, { dispatch, extra }) => {
    const [models, executions] = await Promise.all([
        extra.externalAiAnalysis.loadModels(),
        extra.externalAiAnalysis.loadExecutions(),
    ]);
    extra.externalInterval.createNamedInterval(commandName, refreshDelayInSecond, async () => {
        const newExecutions = await extra.externalAiAnalysis.loadExecutions();
        await dispatch(updatedExecutions(newExecutions));
    });
    await dispatch(
        initialized({
            models,
            executions,
        }),
    );
});
