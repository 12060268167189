import { FC, useCallback } from 'react';
import { ReactComponent as EndControl } from '../../../../../assets/icons/player_end.svg';
import { ReactComponent as FastForwardControl } from '../../../../../assets/icons/player_fast_forward.svg';
import { ReactComponent as FastRewindControl } from '../../../../../assets/icons/player_fast_rewind.svg';
import { ReactComponent as StartControl } from '../../../../../assets/icons/player_start.svg';
import {
    goToImageByCumul,
    playerGoEnd,
    playerGoNext,
    playerGoPrevious,
    playerGoStart,
} from '../../../../../core/legacy/actions';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { Box, ButtonGroup, styled, Typography } from '@mui/material';
import { GreySquareButton } from '../../../../design_system/inputs/buttons/GreySquareButton';
import { currentImageCumulStartSelector } from '../../../../../core/legacy/selectors/streetView';
import PlayerOptions from './PlayerOptions';
import { usePlayerOptions } from './use-player-options';
import { sessionQuery } from '../../../../../core/streetViewer/queries';

const PlayerButton = styled(GreySquareButton)(({ theme }) => ({
    transition: 'color 0ms linear',
    padding: '16px 0',
    width: '32px',
    '&:hover': {
        color: theme.color.blue.primary3,
        backgroundColor: `${theme.color.grey.dark}80`,
    },
    '&:first-child': {
        width: '40px',
        paddingLeft: '8px',
    },
    '&:last-child': {
        width: '40px',
        paddingRight: '8px',
    },
}));

const Player: FC = () => {
    const dispatch = useAppDispatch();
    const { stepOptions } = usePlayerOptions();
    const { step } = stepOptions;

    const currentImageCumulStart: number = useAppSelector(currentImageCumulStartSelector) ?? 0;

    const session = useAppSelector(sessionQuery);

    const handleGoStart = useCallback(async () => dispatch(playerGoStart()), []);
    const handleGoEnd = useCallback(async () => dispatch(playerGoEnd()), []);
    const handleGoPrevious = useCallback(async () => dispatch(playerGoPrevious()), []);
    const handleGoNext = useCallback(async () => dispatch(playerGoNext()), []);
    const handleFastRewind = useCallback(async () => {
        const calculateFastRewindCumul = () => {
            const cumul = currentImageCumulStart - step;

            return Math.max(cumul, 0);
        };
        const cumul = calculateFastRewindCumul();
        dispatch(goToImageByCumul(cumul));
    }, [currentImageCumulStart, step]);

    const handleFastForward = useCallback(async () => {
        if (!session) {
            return;
        }
        const calculateFastForwardCumul = () => {
            const cumul = currentImageCumulStart + step;

            return Math.min(cumul, session.metadata.cumulativeEnd);
        };
        const cumul = calculateFastForwardCumul();
        dispatch(goToImageByCumul(cumul));
    }, [currentImageCumulStart, step, session]);

    return (
        <Box sx={{ position: 'relative', gridRow: 1, marginX: 'auto' }}>
            <ButtonGroup
                disableElevation
                sx={{
                    '& .MuiButtonGroup-grouped': {
                        minWidth: '32px',
                    },
                    '& .MuiButtonGroup-grouped:not(:first-of-type)': {
                        marginLeft: 0,
                    },
                    position: 'relative',
                }}
            >
                <PlayerButton onClick={handleGoStart}>
                    <StartControl />
                </PlayerButton>
                <PlayerButton onClick={handleFastRewind}>
                    <FastRewindControl />
                </PlayerButton>
                <PlayerButton onClick={handleGoPrevious}>
                    <Typography fontSize="16px">&#9664;</Typography>
                </PlayerButton>
                <PlayerButton onClick={handleGoNext}>
                    <Typography fontSize="16px">&#9654;</Typography>
                </PlayerButton>
                <PlayerButton onClick={handleFastForward}>
                    <FastForwardControl />
                </PlayerButton>
                <PlayerButton onClick={handleGoEnd}>
                    <EndControl />
                </PlayerButton>
            </ButtonGroup>
            <PlayerOptions stepOptions={stepOptions} />
        </Box>
    );
};

export default Player;
