/* eslint-disable @typescript-eslint/no-unused-vars */
import { createCommand } from '../../utils';
import { moduleName, organizationZoomedOnMap, SessionShownOnMap } from '../state';

export type ToggleSessionProps = SessionShownOnMap;

export const ZoomOnOrganizationCommand = createCommand(
    `${moduleName}/ZOOM_ON_ORGANIZATION`,
    async (_, { dispatch }) => {
        dispatch(organizationZoomedOnMap());
    },
);
