import { ChangeEventHandler, useEffect, useState } from 'react';

export type StepOptions = {
    step: number;
    incrementStep: () => void;
    decrementStep: () => void;
    updateStep: ChangeEventHandler<HTMLInputElement>;
    resetStepIfNeeded: () => void;
};

export const MIN_STEP_VALUE = 10;

const PLAYER_STEP_KEY = 'PLAYER_STEP_KEY';
const DEFAULT_STEP_VALUE = 100;
const CHANGE_STEP_VALUE = 10;

export const usePlayerOptions = (): { stepOptions: StepOptions } => {
    const [step, setStep] = useState<number>(DEFAULT_STEP_VALUE);

    useEffect(() => {
        const storedStep = localStorage.getItem(PLAYER_STEP_KEY);
        const newStep = storedStep ? +storedStep : DEFAULT_STEP_VALUE;
        setStep(newStep);
    }, []);

    const persistStep = (value: number) => {
        setStep(value);
        localStorage.setItem(PLAYER_STEP_KEY, value.toString());
    };

    const decrementStep = () => {
        const newStep = step - CHANGE_STEP_VALUE;
        if (newStep >= MIN_STEP_VALUE) {
            persistStep(newStep);
        }
    };
    const incrementStep = () => {
        persistStep(step + CHANGE_STEP_VALUE);
    };
    const updateStep: ChangeEventHandler<HTMLInputElement> = (event) => {
        persistStep(+event.target.value);
    };

    const resetStepIfNeeded = () => {
        if (step < MIN_STEP_VALUE) {
            persistStep(DEFAULT_STEP_VALUE);
        }
    };

    return {
        stepOptions: {
            step,
            decrementStep,
            incrementStep,
            updateStep,
            resetStepIfNeeded,
        },
    };
};
