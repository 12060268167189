import type { CircleLayer, Expression } from 'mapbox-gl';
import { COLORS } from '../../legacy/components/StreetView/NavigationBar/Itinerary/use-itinerary';
import type { GradeReference } from '../../../core/domain/Roaddata';
import { MAP_GRADE_COLORS, MAP_WIDTH_COLORS } from './map-configuration';
import { WIDTH_PARTIAL_CODE } from '../../../core';

export const LAYER_NETWORK_DATA_ID = 'network' as const;
export const SOURCE_NETWORK_DATA_ID = 'network' as const;

type RadiusArgs = { radiusHover: number; radiusOut: number };

// Manage circles radius according to mouse hover or out events.
// We look at feature state named 'hover' (set to false by default)
// See app/src/ui/network-map/Map/feature-manager.ts line 43 => Mapbox GL JS core functionnality
// If state "hover" is true, use given {radiusHover}, else use given {radiusOut}
const featureStateMouseHoverAndOutCase = ({ radiusHover, radiusOut }: RadiusArgs): Expression => {
    return ['case', ['boolean', ['feature-state', 'hover'], false], radiusHover, radiusOut];
};
const elseFeatureStateMouseHoverAndOutCase = featureStateMouseHoverAndOutCase;

const untilZoomLevelReaches = (number: number): number => number;

const CIRCLE_RADIUS: Expression = [
    'step',
    ['zoom'],
    featureStateMouseHoverAndOutCase({ radiusHover: 6, radiusOut: 2 }),
    untilZoomLevelReaches(10),
    featureStateMouseHoverAndOutCase({ radiusHover: 12, radiusOut: 4 }),
    untilZoomLevelReaches(16),
    featureStateMouseHoverAndOutCase({ radiusHover: 18, radiusOut: 6 }),
    untilZoomLevelReaches(18),
    featureStateMouseHoverAndOutCase({ radiusHover: 20, radiusOut: 7 }),
    untilZoomLevelReaches(19),
    elseFeatureStateMouseHoverAndOutCase({ radiusHover: 20, radiusOut: 7 }),
];
const CIRCLE_OPACITY: Expression = ['case', ['boolean', ['feature-state', 'hover'], false], 0.5, 1];

const getColorExpression = (selectedReference: GradeReference | null): Expression => {
    if (!selectedReference || selectedReference === 'AGGREGATE') {
        return [
            'case',
            ['to-boolean', ['get', 'aggregatedGrade']],
            ['step', ['get', 'aggregatedGrade'], ...MAP_GRADE_COLORS],
            COLORS.TARGET,
        ];
    }

    const key = typeof selectedReference === 'string' ? selectedReference : selectedReference.key;
    const mapColors = key.includes(WIDTH_PARTIAL_CODE) ? MAP_WIDTH_COLORS : MAP_GRADE_COLORS;

    return [
        'case',
        ['to-boolean', ['get', key, ['get', 'referenceGrades']]],
        ['step', ['get', key, ['get', 'referenceGrades']], ...mapColors],
        COLORS.TARGET,
    ];
};

export const getLayerConfig = (selectedReference: GradeReference | null): CircleLayer => ({
    id: LAYER_NETWORK_DATA_ID,
    type: 'circle',
    minzoom: 15,
    paint: {
        'circle-radius': CIRCLE_RADIUS,
        'circle-color': getColorExpression(selectedReference),
        'circle-opacity': CIRCLE_OPACITY,
    },
});
