import { captureException } from '@sentry/browser';
import axios from 'axios';
import NoDataToExportError from '../core/domain/errors/NoDataToExportError';
import type { ItinerariesBySessionIds, Itinerary } from '../core/domain/Itinerary';
import type { Session, SessionExportData } from '../core/domain/Session';
import type { ExternalSessionsPort, HomogeneousZoneResponse } from '../core/sessions/ExternalSessionsPort';
import type { HomogeneousZoneParams } from '../core/sessions/state';

export class ExternalSessionsProvider implements ExternalSessionsPort {
    constructor(private readonly baseEndpoint: string) {}

    async loadSessions(): Promise<Session[]> {
        try {
            const response = await axios.request({
                method: 'GET',
                url: `${this.baseEndpoint}/sessions/me`,
                withCredentials: true,
            });

            return response.data;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async editSessions(sessionsIds: string[], data: Partial<Session>): Promise<Session[]> {
        try {
            const response = await axios.request({
                method: 'PATCH',
                url: `${this.baseEndpoint}/sessions`,
                data: {
                    sessionsIds,
                    data,
                },
                withCredentials: true,
            });

            return response.data;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async exportSessionsRoadDatas(exportData: SessionExportData): Promise<Blob> {
        try {
            const response = await axios.request({
                method: 'POST',
                url: `${this.baseEndpoint}/annotation/export`,
                data: exportData,
                withCredentials: true,
                responseType: 'arraybuffer',
                validateStatus: (status) => status === 200 || status === 403,
            });

            if (response.status !== 200) {
                throw new NoDataToExportError();
            }

            const blob = new Blob([response.data]);

            return blob;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async exportSessionsReport(exportData: SessionExportData): Promise<Blob> {
        try {
            const response = await axios.request({
                method: 'POST',
                url: `${this.baseEndpoint}/annotation/export-report`,
                data: exportData,
                withCredentials: true,
                responseType: 'arraybuffer',
                validateStatus: (status) => status === 200 || status === 403,
            });

            if (response.status !== 200) {
                throw new NoDataToExportError();
            }

            const blob = new Blob([response.data]);

            return blob;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async exportSectionsReport(sessionIds: string[]): Promise<Blob> {
        try {
            const response = await axios.request({
                method: 'POST',
                url: `${this.baseEndpoint}/sessions/section-report`,
                data: { sessionIds },
                withCredentials: true,
                responseType: 'arraybuffer',
                validateStatus: (status) => status === 200 || status === 403,
            });

            if (response.status !== 200) {
                throw new NoDataToExportError();
            }

            const blob = new Blob([response.data]);

            return blob;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async exportGeojsonSectionsReport(sessionIds: string[]): Promise<{ data: Blob; filename: string }> {
        try {
            const {
                data,
                headers: { 'content-type': contentType },
                status: responseStatus,
            } = await axios.request({
                method: 'POST',
                url: `${this.baseEndpoint}/sessions/section-report-geojson`,
                data: { sessionIds },
                withCredentials: true,
                responseType: 'arraybuffer',
                validateStatus: (status) => status === 200 || status === 403,
            });

            if (responseStatus !== 200) {
                throw new NoDataToExportError();
            }

            const time = new Date().toISOString();
            const cleanType = contentType?.substring(0, contentType.indexOf(';'));
            let extension: string;
            let suffix = '';

            switch (cleanType) {
                case 'application/json':
                    extension = 'json';
                    break;
                case 'text/plain':
                    suffix = 'errors_';
                    extension = 'txt';
                    break;
                default:
                    extension = 'zip';
            }

            return { data: new Blob([data]), filename: `export_geojson_${suffix}${time}.${extension}` };
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async loadAggregateItineraryForOneSession(
        sessionId: string,
        rubricReferences: string[],
    ): Promise<ItinerariesBySessionIds> {
        try {
            const response: { data: Itinerary } = await axios.request({
                method: 'POST',
                data: {
                    rubricReferences,
                },
                url: `${this.baseEndpoint}/sessions/${sessionId}/aggregate-itinerary`,
                withCredentials: true,
            });

            const { sectionsFractions, elementaryFractions, landmarks, length } = response.data;

            return {
                [response.data.sessionId]: {
                    landmarks,
                    length,
                    fractions: {
                        AGGREGATE: {
                            elementary: elementaryFractions,
                            section: sectionsFractions,
                        },
                    },
                },
            };
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async loadReferenceItineraryForOneSession(
        sessionId: string,
        rubricReference: string,
    ): Promise<ItinerariesBySessionIds> {
        try {
            const response: { data: Itinerary } = await axios.request({
                method: 'POST',
                data: {
                    rubricReference,
                },
                url: `${this.baseEndpoint}/sessions/${sessionId}/reference-itinerary`,
                withCredentials: true,
            });

            const { sectionsFractions, elementaryFractions, landmarks, length } = response.data;

            return {
                [response.data.sessionId]: {
                    landmarks,
                    length,
                    fractions: {
                        [rubricReference]: {
                            elementary: elementaryFractions,
                            section: sectionsFractions,
                        },
                    },
                },
            };
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async computeHomogeneousZones(
        sessionIds: string[],
        params: HomogeneousZoneParams,
    ): Promise<HomogeneousZoneResponse> {
        try {
            return axios
                .request({
                    method: 'POST',
                    data: {
                        params,
                        sessionIds,
                    },
                    url: `${this.baseEndpoint}/sessions/homogeneous-zones`,
                    withCredentials: true,
                })
                .then(({ data }) => data)
                .catch(({ response: data }) => data.data);
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }
}
