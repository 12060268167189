/* eslint-disable @typescript-eslint/no-unused-vars */
import { createCommand } from '../../utils';
import { selectedMapStyle, moduleName } from '../state';
import { userOrganizationQuery } from '../../authentication/queries';
import type { MapStyle } from '../../../types';

type Args = {
    mapStyle: MapStyle;
};

export const UpdateMapStyleCommand = createCommand(
    `${moduleName}/UPDATE_MAP_STYLE`,
    async ({ mapStyle }: Args, { dispatch, extra, getState }) => {
        const userOrganization = userOrganizationQuery(getState());

        if (userOrganization) {
            await extra.mapViewSettings.updateMapStyleForOrganization(userOrganization.id, mapStyle);
        }

        dispatch(selectedMapStyle(mapStyle));
    },
);
