import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        font-family: 'Hind Siliguri', sans-serif;
    }
`;

export const theme = {
    boxShadow: {
        main: '-5px 0 15px -3px rgba(0, 83, 204, 0.6)',
        medium: '0px 0 10px -3px rgba(0, 83, 204, 0.6)',
        light: '0 0 7px -2px rgba(0, 83, 204, 0.15)',
        grey: {
            thin: '0 0 20px 0 #DFE4F0',
        },
    },
    color: {
        black: {
            main: '#132155',
            lighter: '#1D1E1F',
            dark: '#000000',
            medium: '#231F20',
        },
        blue: {
            grey: '#F6F6FF',
            light: 'rgb(241, 245, 255)',
            light2: '#C8D4E0',
            light3: '#B9D4FC',
            tint: '#F8FAFE',
            tint2: '#F2F6FF',
            tint3: '#E5EEFF',
            primary: '#295EEF',
            primary2: '#0048B0',
            primary3: '#0053CC',
            medium: '#3B84F0',
            selected: '#E0E9FF',
        },
        grey: {
            dark: '#373435',
            main: '#828894',
            medium: '#E4E9F4',
            medium2: '#F5F5F5',
            light: '#898F9B',
            lighter: '#F8FAFE',
        },
        red: {
            primary: '#F05D5F',
            darker: '#F03437',
        },
        orange: {
            main: '#f16828',
            light: '#FFF4ED',
            dark: '#F97316',
        },
        yellow: {
            light: '#FFFDEF',
            medium: '#FDF1D9',
            dark: '#F0A202',
            warning: '#FFC107',
        },
        green: {
            light: '#E9F5E5',
            main: '#0d6e03',
            dark: '#6DBA51',
            success: '#02CEA4',
        },
        white: {
            main: '#FFFFFF',
        },
        background: {
            loader: 'rgba(0, 0, 0, 0.8)',
        },
        grades: {
            saturated: '#D2140A',
            bad: '#EA3323',
            prettyBad: '#F5C242',
            prettyGood: '#FFFF54',
            good: '#9FCE63',
            perfect: '#4FAD5B',
        },
        widths: {
            belowFour: '#F9E6FE',
            fourToEight: '#E595FC',
            eightToTwelve: '#CF37F9',
            twelveToSixteen: '#B200F0',
            aboveSixteen: '#8100E6',
        },
    },
    radius: {
        xs: '4px',
        s: '5px',
        m: '8px',
    },
    font: {
        size: {
            big: '36px',
            veryLarge: '32px',
            large: '24px',
            medium3: '20px',
            medium2: '18px',
            medium: '16px',
            regular: '14px',
            small: '12px',
            smaller: '11px',
            verySmall: '10px',
        },
        family: 'Hind Siliguri',
    },
};
