import { useRef, useState, useEffect, FC, ReactNode } from 'react';
import { AnnotationEngine } from '../../ui/annotation-engine/styles';
import { editStyle } from '../../ui/annotation-engine/drawing-styles';
import useEngineStateMachine from './use-engine-state-machine';
import useStyleOperations from './use-style-operations';
import {
    calibrationImageUrlSelector,
    calibrationPointInEditionSelector,
    calibrationPointsSelector,
    displayCalibrationWelcomePanelSelector,
    isDrawingToolActivatedSelector,
} from '../../../../../core/legacy/selectors/calibration';
import { calibrationPointToAnnotationMapper } from '../../../../../core/legacy/utils/calibrationPointsUtils';
import useSortAndStyleAnnotations from './use-sort-and-style-annotations';
import { closeWelcomeCalibrationPanel } from '../../../../../core/legacy/actions';
import { currentImageSelector } from '../../../../../core/legacy/selectors/streetView';
import useAnnotationEngineSize from '../../StreetView/RoadViewer/use-annotation-engine-size';
import { convertManualCalibrationPointToEngineSize } from '../../../../../core/legacy/utils/roadViewerUtils';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import useScreenIsZoomed from '../../utils/use-screen-is-zoomed';
import { RoadView } from '../../../../roadview';

const RoadViewer: FC<{ children?: ReactNode }> = ({ children }) => {
    const calibrationPoints = useAppSelector(calibrationPointsSelector);
    const dispatch = useAppDispatch();

    const currentImage = useAppSelector(currentImageSelector);
    const pointToEdit = useAppSelector(calibrationPointInEditionSelector);
    const currentImageUrl = useAppSelector(calibrationImageUrlSelector);
    const calibrationPointToEdit = useAppSelector(calibrationPointInEditionSelector);

    const refAE = useRef<HTMLDivElement>(null);
    const styleOps = useStyleOperations();

    const [pointToEditAsAnnotation, setPointToEditAsAnnotation] = useState(undefined);
    const handleEvent = useEngineStateMachine(calibrationPointToEdit);
    const [sortedAnnotations, setSortedAnnotations] = useState([]);

    const isWelcomePanelOpen = useAppSelector(displayCalibrationWelcomePanelSelector);

    const { isZoomed, pxRatio } = useScreenIsZoomed();

    useEffect(() => {
        if (isWelcomePanelOpen && calibrationPoints.length) {
            dispatch(closeWelcomeCalibrationPanel());
        }
        setPointToEditAsAnnotation(
            pointToEdit
                ? convertManualCalibrationPointToEngineSize(calibrationPointToAnnotationMapper(pointToEdit))
                : undefined,
        );
        setSortedAnnotations(
            calibrationPoints.map((point) =>
                convertManualCalibrationPointToEngineSize(calibrationPointToAnnotationMapper(point)),
            ),
        );
    }, [pointToEdit, calibrationPoints, pxRatio]);

    useSortAndStyleAnnotations(sortedAnnotations, setSortedAnnotations, styleOps);

    // TODO resize implemented below
    // but not yet really functionnal, to investigate
    const { canvasSize } = useAnnotationEngineSize(refAE, currentImage, isZoomed, pxRatio, null);

    const styleForAnnotationToEdit = editStyle.style;

    const drawingToolActivated = useAppSelector(isDrawingToolActivatedSelector);

    return (
        <RoadView fullHeight>
            <AnnotationEngine
                ref={refAE}
                canvasSize={canvasSize}
                annotationToEdit={pointToEditAsAnnotation}
                annotations={sortedAnnotations}
                backgroundImagePath={currentImageUrl}
                cursorStyle={drawingToolActivated ? 'crosshair' : 'default'}
                onEvent={handleEvent}
                styleForAnnotationToEdit={styleForAnnotationToEdit}
                styleForAnnotations={styleOps.styleForAnnotations}
            />
            {children}
        </RoadView>
    );
};

export default RoadViewer;
