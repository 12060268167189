import { Box, Stack, SxProps } from '@mui/material';
import { theme } from '../../../../style/theme';
import type { RoadData } from '../../../../../../core/domain/Roaddata';
import { ReactComponent as PRIcon } from '../../../../../../assets/icons/PR.svg';

const iconSx = (isLandmark: boolean, position: number): SxProps => {
    const iconStyle: SxProps = {
        height: isLandmark ? 8 : 12,
        left: `calc(${position}% - ${isLandmark ? '4px' : '1px'})`,
        position: 'absolute',
        top: isLandmark ? -9 : -1,
    };

    if (isLandmark) return iconStyle;

    return { ...iconStyle, bgcolor: '#8B5CF6', borderRadius: 3, width: 3 };
};

export const ItineraryLandmarks = ({ landmarks, sessionLength }: { landmarks: RoadData[]; sessionLength: number }) => {
    return (
        <Stack
            direction="row"
            sx={{
                width: '100%',
                height: '10px',
                background: theme.color.grey.medium,
                position: 'relative',
            }}
        >
            {landmarks.map(({ cumulStartSession, id, isReferentialLandmarks }) => {
                const position = Math.max(0, Math.min((cumulStartSession * 100) / sessionLength, 100));

                return (
                    <Box key={id} sx={iconSx(isReferentialLandmarks, position)}>
                        {isReferentialLandmarks && <PRIcon height="100%" />}
                    </Box>
                );
            })}
        </Stack>
    );
};
