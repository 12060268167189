import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { FieldContainer, MainContainer } from './styles';
import { ReactComponent as EyeOff } from '../../../../../../assets/icons/eye-off.svg';
import { ReactComponent as EyeOn } from '../../../../../../assets/icons/eye-on.svg';
import { ButtonIcon, Error, Input } from '../../../../style';

const FormInputPassword = ({ input, type, placeholder, meta: { touched, error }, width, ...props }) => {
    const { t } = useTranslation();
    const [fieldType, setFieldType] = useState(type);
    const switchType = (e) => {
        // prevents untimely form validation
        e.preventDefault();
        setFieldType((state) => (state === 'password' ? 'text' : 'password'));
    };

    return (
        <MainContainer>
            <FieldContainer width={width}>
                <Input width={width} {...input} {...props} placeholder={placeholder} type={fieldType} />
                <ButtonIcon onClick={switchType} size="10px" type="button">
                    {fieldType === 'text' ? <EyeOff /> : <EyeOn />}
                </ButtonIcon>
            </FieldContainer>
            {touched && error && (
                <Error data-test-id="form__inputError" margin="-18px 0 0 0">
                    {t(error)}
                </Error>
            )}
        </MainContainer>
    );
};

FormInputPassword.propTypes = {
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password']),
    width: PropTypes.string,
};

export default FormInputPassword;
