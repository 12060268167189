import type { MapRef } from 'react-map-gl';
import { LngLatBounds } from 'mapbox-gl';
import { useAppDispatch } from '../../../../types';
import { StoreCurrentMapBoundsCommand } from '../../../../core/networkMap/commands/StoreCurrentMapBoundsCommand';
import type { MutableRefObject } from 'react';

const useStoreMapBounds = () => {
    const dispatch = useAppDispatch();

    const storeMapBounds = (mapRef: MutableRefObject<MapRef | null>): void => {
        const bounds = mapRef?.current?.getMap().getBounds();
        if (bounds) {
            const mapBounds = new LngLatBounds([bounds.getSouthWest(), bounds.getNorthEast()]);
            dispatch(StoreCurrentMapBoundsCommand(mapBounds));
        }
    };

    return storeMapBounds;
};

export default useStoreMapBounds;
