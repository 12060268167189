import { useTranslation } from 'react-i18next';
import { SessionsModalTypes } from '../../../../core/legacy/reducers/modals';
import { setModalToDisplay } from '../../../../core/legacy/actions/modals';
import { FC, useCallback } from 'react';
import { useAppDispatch } from '../../../../types';
import { UploadIcon } from '../../icons/UploadIcon';
import BaseButton from './BaseButton';

export const UploadSessionsButton: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleClick = useCallback(() => {
        dispatch(setModalToDisplay(SessionsModalTypes.SESSIONS_UPLOAD_SESSIONS));
    }, []);

    return (
        <BaseButton onClick={handleClick}>
            <UploadIcon themeStyle="dark" />
            {t('labels.import')}
        </BaseButton>
    );
};
