import { allRubricsIdsQuery, allRubricsQuery } from '../../catalogs/queries';
import { currentImageSelector } from '../../legacy/selectors/streetView';
import { sessionCalibrationQuery } from '../../streetViewer/queries';
import { createCommand } from '../../utils';
import {
    applyFilterToImageQuery,
    imageRoaddataOnlyQuery,
    referenceToDisplayIds,
    rubricToDisplayIdsQuery,
    taggedElementsOnlyQuery,
} from '../queries';
import { moduleName } from '../state';

let debounceTimeout: number | null = null;

export const UpdateFiltersCommand = createCommand(
    `${moduleName}/UDATE_FILTERS`,
    async (_, { dispatch, extra, getState }) => {
        if (debounceTimeout) {
            window.clearTimeout(debounceTimeout);
        }

        debounceTimeout = window.setTimeout(async () => {
            const state = getState();
            const image = currentImageSelector(state);
            const calibration = sessionCalibrationQuery(state);
            if (!image || !calibration) {
                return;
            }

            const applyFilterToImage = applyFilterToImageQuery(state);

            const imageRoaddataOnly = imageRoaddataOnlyQuery(state);
            const imageRoaddataOnlyForAnnotations = applyFilterToImage ? imageRoaddataOnly : false;

            const taggedElementsOnly = taggedElementsOnlyQuery(state);
            const taggedElementsOnlyForAnnotations = applyFilterToImage ? taggedElementsOnly : false;

            const rubricsToDisplay = rubricToDisplayIdsQuery(state);
            const rubricToDisplayIdsForAnnotations = applyFilterToImage ? rubricsToDisplay : allRubricsIdsQuery(state);

            const referencesToDisplay = referenceToDisplayIds(state);
            const referenceToDisplayIdsForAnnotations = applyFilterToImage
                ? referencesToDisplay
                : allRubricsQuery(state).flatMap((r) => r.references.map(({ id }) => id));

            const [annotations, interval] = await Promise.all([
                extra.externalRoadDatas.loadAnnotationsForImageByRubricIds(
                    image.id,
                    rubricToDisplayIdsForAnnotations,
                    referenceToDisplayIdsForAnnotations,
                    taggedElementsOnlyForAnnotations,
                    imageRoaddataOnlyForAnnotations,
                ),
                extra.externalRoadDatas.loadRoadDataIntervalOfCurrentImage(
                    image.id,
                    rubricsToDisplay,
                    referencesToDisplay,
                    taggedElementsOnly,
                    imageRoaddataOnly,
                ),
            ]);

            dispatch({
                type: 'STREET_VIEW/UPDATE_ANNOTATIONS',
                annotations,
            });
            dispatch({
                type: 'STREET_VIEW/UPDATE_ROAD_DATAS',
                roadDatas: interval.roaddatas,
                totalSize: interval.totalSize,
                rubricIdToCount: interval.rubricIdToCount,
            });
        }, 500);
    },
);
