import { Button, styled } from '@mui/material';

export const GreySquareButton = styled(Button, { shouldForwardProp: (prop: string) => prop !== 'borderRadius' })(
    ({ selected, theme, hoverColor, padding, borderRadius, width, height }) => ({
        backgroundColor: selected ? `${theme.color.blue.primary3}FF` : `${theme.color.grey.dark}80`,
        minWidth: width ?? '64px',
        width: width ?? '64px',
        height: height ?? '64px',
        padding: padding ?? '16px',
        borderRadius: borderRadius ?? '8px',
        color: theme.color.white.main,
        border: 'none',
        overflow: 'hidden',
        '&:hover': {
            backgroundColor: selected ? `${theme.color.blue.primary3}B3` : `${theme.color.grey.dark}B3`,
            border: 'none',
            color: hoverColor ?? theme.color.white.main,
        },
        '& svg': {
            width: '24px',
            height: '24px',
        },
        '& img': {
            width: '60px',
            height: '60px',
            borderRadius: '6px',
        },
    }),
);
