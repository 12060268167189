import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Landmark } from '../domain/Roaddata';
import type { Session, SessionId } from '../domain/Session';

export const moduleName = 'STREET_VIEWER';

export type CurrentImageCumul = {
    sessionId: SessionId;
    cumul: number;
};

type State = {
    currentImageCumul: CurrentImageCumul | null;
    currentImageIdx: number;
    session: Session | null;
    currentLandmark: Landmark;
};

const initialState: State = {
    currentImageIdx: 0,
    currentImageCumul: null,
    session: null,
    currentLandmark: { label: 'start', sign: '+', abd: '0' },
};

const streetViewerSlice = createSlice({
    name: moduleName,
    initialState,
    reducers: {
        resetStarted(state: State) {
            state.currentImageIdx = 0;
            state.session = null;
            state.currentLandmark = { label: 'start', sign: '+', abd: '0' };
        },
        currentImageIndexUpdated(state: State, action: PayloadAction<number>) {
            state.currentImageIdx = action.payload;
        },
        currentImageCumulUpdated(state: State, action: PayloadAction<CurrentImageCumul | null>) {
            state.currentImageCumul = action.payload;
        },
        currentImageIndexIncremented(state: State, action: PayloadAction<Map<any, any>>) {
            const maxIndex = Array.from(action.payload.keys()).reduce((max, cur) => (max > cur ? max : cur));
            state.currentImageIdx =
                state.currentImageIdx + 1 <= maxIndex ? state.currentImageIdx + 1 : state.currentImageIdx;
        },
        currentImageIndexDecremented(state: State) {
            state.currentImageIdx = state.currentImageIdx - 1 >= 0 ? state.currentImageIdx - 1 : 0;
        },
        currentImageIndexSetToFirst(state: State, action: PayloadAction<Map<any, any>>) {
            state.currentImageIdx = Array.from(action.payload.keys()).reduce((min, cur) => (min < cur ? min : cur));
        },
        currentImageIndexSetToLast(state: State, action: PayloadAction<Map<any, any>>) {
            state.currentImageIdx = Array.from(action.payload.keys()).reduce((max, cur) => (max > cur ? max : cur));
        },
        sessionUpdated(state: State, action: PayloadAction<Session>) {
            state.session = action.payload;
        },
        currentLandmarkUpdated(state: State, action: PayloadAction<Landmark>) {
            state.currentLandmark = action.payload;
        },
    },
});

export const {
    resetStarted,
    currentImageIndexUpdated,
    currentImageIndexIncremented,
    currentImageIndexDecremented,
    currentImageIndexSetToFirst,
    currentImageIndexSetToLast,
    sessionUpdated,
    currentLandmarkUpdated,
    currentImageCumulUpdated,
} = streetViewerSlice.actions;
export const streetViewerReducer = streetViewerSlice.reducer;
