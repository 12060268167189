import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    calibrationPointsSelector,
    getCalibrationErrorRatesSelector,
} from '../../../../../core/legacy/selectors/calibration';
import CalibrationPointSummary from './CalibrationPointSummary';
import SelectedCalibrationPointDetails from './SelectedCalibrationPointDetails';
import { Panel, Summary } from '../../ui/panels/right-panel/styles';
import { getCalibrationFile, generateCalibrationProcessResult } from '../../../../../core/legacy/actions';
import useCalibrationPanelStateMachine from './use-calibration-panel-state-machine';
import GenerateCalibrationModal from './GenerateCalibrationModal';
import GenerateCalibrationSuccessModal from './GenerateCalibrationSuccessModal';
import { WidePrimaryButton as GenerateCalibrationButton } from '../styles';
import DuplicateWarning from './DuplicateWarning';
import CalibrationHeading from './CalibrationHeading';
import { NavigateToSessionsCommand } from '../../../../../core/sessions/commands/NavigateToSessionsCommand';
import { useAppDispatch } from '../../../../../types';

const CalibrationPointsPanel = ({ children }) => {
    const { t } = useTranslation();
    const calibrationPoints = useSelector(calibrationPointsSelector);
    const results = useSelector(getCalibrationErrorRatesSelector);
    const dispatch = useAppDispatch();
    const generateCalibrationModalRef = useRef(null);
    const successModalRef = useRef(null);
    const messagesEndRef = useRef(null);

    const generateCalibration = async () => {
        await dispatch(generateCalibrationProcessResult());
        generateCalibrationModalRef.current?.openModal();
    };
    const getCalibrationFileHandler = () => {
        dispatch(getCalibrationFile());
        generateCalibrationModalRef.current.hideModal();
        successModalRef.current.openModal();
    };
    const goToSessionsList = () => {
        dispatch(NavigateToSessionsCommand());
    };

    const {
        calibrationIsReadyToGenerate,
        notEnoughPointsWarning,
        containsDuplicates,
    } = useCalibrationPanelStateMachine(calibrationPoints, t);

    const boldText = t('calibration.storedCalibrationPoint', { count: calibrationPoints.length });

    return (
        <Panel>
            {children}
            <CalibrationHeading boldText={boldText} plainText={notEnoughPointsWarning} />
            <Summary>
                {calibrationPoints.map((point) => {
                    return <CalibrationPointSummary key={point.id} calibrationPoint={point} />;
                })}
                {containsDuplicates && <DuplicateWarning />}
                <div ref={messagesEndRef} />
            </Summary>
            <SelectedCalibrationPointDetails />
            {results && (
                <>
                    <GenerateCalibrationModal
                        ref={generateCalibrationModalRef}
                        onValidate={getCalibrationFileHandler}
                    />
                    <GenerateCalibrationSuccessModal ref={successModalRef} onValidate={goToSessionsList} />
                </>
            )}

            <GenerateCalibrationButton
                type="button"
                disabled={!calibrationIsReadyToGenerate}
                onClick={generateCalibration}
            >
                {t('calibration.finishCalibration')}
            </GenerateCalibrationButton>
        </Panel>
    );
};

export default CalibrationPointsPanel;
