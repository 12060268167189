import i18n, { StringMap } from 'i18next';
import type { RoadDirection } from '../../domain/Session';
import type { ReferenceKey, Reference } from '../../domain/Roaddata';
import referenceResolver from './referenceResolver';

const internalTranslation = (root: string, name: string, params?: StringMap) => {
    if (!name) return '';
    const key = `${root}.${name}`;

    return i18n.exists(key, params) ? i18n.t(key, params) : name;
};

export const translateFolder = (name: string) => {
    return internalTranslation('catalog.folder', name);
};

export const translateRubric = (name: string = '') => {
    return internalTranslation('catalog.rubric', name);
};

export const translateAttribute = (name: string) => {
    return internalTranslation('catalog.rubric.labelValue', name);
};

export const translateTags = (name: string, params?: StringMap) => internalTranslation('tags', name, params);

export const translateLexiconValue = (attributeName: string, lexiconValue = '') => {
    const attName = attributeName.toLowerCase();
    const lexicon = lexiconValue?.toLowerCase();
    const lexiconValueTranslationKey = `catalog.rubric.labelValue.${attName}_values.${lexicon}`;

    return i18n.exists(lexiconValueTranslationKey) ? i18n.t(lexiconValueTranslationKey) : lexiconValue;
};

export const translateReference = (reference: Reference | ReferenceKey | undefined) => {
    if (!reference) {
        return null;
    }

    if (typeof reference === 'object') {
        return (reference as Reference).name;
    }

    const resolvedReference = referenceResolver(reference);

    return translateLexiconValue('REFERENCE', resolvedReference);
};

export const translateDirection = (direction: RoadDirection) => {
    const name = direction === 'D - Sens aller' ? 'going' : 'coming';

    return internalTranslation('session', name);
};
