import apiEndpoint from '../utils/api';
import { getShapeTypeFromAnnotation, getShapeTypeFromRubric } from '../utils/annotationEngine';
import { currentImageIndexQuery, sessionIdQuery } from '../../streetViewer/queries';

// Image
export const imagesSelector = (state) => state.streetView.images;
export const currentImageSelector = (state) => state.streetView.images.get(currentImageIndexQuery(state));
export const currentImageIdSelector = (state) => currentImageSelector(state)?.id;
export const currentImageCumulStartSelector = (state) => {
    const currentImage = currentImageSelector(state);
    if (!currentImage) {
        return undefined;
    }

    return +currentImage.cumulStartSession;
};
export const isPreviousImageIndexLoadedSelector = (state) => {
    const currentIndex = currentImageIndexQuery(state);
    const previousIndex = currentIndex - 1 < 0 ? 0 : currentIndex - 1;

    return state.streetView.images.has(previousIndex);
};
export const isNextImageIndexLoadedSelector = (state) => state.streetView.images.has(currentImageIndexQuery(state) + 1);
export const getLoadedImageIndexesSelector = (state) => Array.from(state.streetView.images.keys());
export const currentImageUrlSelector = (state) => {
    const sessionId = sessionIdQuery(state);
    const index = currentImageIndexQuery(state);
    if (!sessionId || index === undefined) {
        return null;
    }

    return `${apiEndpoint}/sessions/${sessionId}/images/${index}.jpeg`;
};
// Annotation
export const allAnnotationsOfCurrentImageSelector = (state) => state.streetView.annotations;
export const allRoadDatasIdOfCurrentImageSelector = (state) => {
    const allAnnotationsOfCurrentImage = allAnnotationsOfCurrentImageSelector(state);

    return allAnnotationsOfCurrentImage.map((annotation) => annotation.roadData.id);
};
export const annotationIdInEdition = (state) => state.streetView.annotationIdInEdition;
export const annotationInEditionSelector = (state) => {
    const annotationId = annotationIdInEdition(state);

    const annotationToEdit = state.streetView.annotations.find((annotation) => annotation.roadData.id === annotationId);

    if (annotationToEdit !== undefined) {
        return {
            ...annotationToEdit,
            isClosed: getShapeTypeFromAnnotation(annotationToEdit) !== 'POLYLINE',
        };
    }

    return undefined;
};
export const annotationInEditionShapeTypeSelector = (state) => {
    const annotationToEdit = annotationInEditionSelector(state);

    return getShapeTypeFromAnnotation(annotationToEdit);
};
export const temporaryDeformedAnnotationToEditSelector = (state) => state.streetView.temporaryDeformedAnnotationToEdit;

// RoadData

export const roadDataByIdSelector = (roadDataId) => (state) =>
    state.streetView.roadDatas.find((roadData) => roadData.id === roadDataId);
export const numberOfRoadDataByRubricIdSelector = (rubricId) => (state) => {
    return state.streetView.rubricIdToCount[rubricId] ?? 0;
};
export const roadDatasOfSessionSelector = (state) => state.streetView.roadDatas;
export const roadDataIdInEditionSelector = (state) => state.streetView.roadDataIdInEdition;
export const roadDataInEditionSelector = (state) => {
    const roadDataIdInEdition = roadDataIdInEditionSelector(state);
    const roadDatas = roadDatasOfSessionSelector(state);

    return roadDatas.find((roadData) => roadData.id === roadDataIdInEdition);
};
export const hoveredRoadDataIdSelector = (state) => state.streetView.hoveredRoadDataId;
export const selectedRoadDataIdSelector = (state) => state.streetView.selectedRoadDataId;
export const selectedRoadDataSelector = (state) => {
    const selectedRoadDataId = selectedRoadDataIdSelector(state);
    const roadDatas = roadDatasOfSessionSelector(state);

    return roadDatas.find((roadData) => roadData.id === selectedRoadDataId);
};
export const roadDataIdToScrollToSelector = (state) => state.streetView.roadDataIdToScrollTo;
export const roadDatasTotalSize = (state) => state.streetView.totalSize;

// Catalog
export const selectedRubricSelector = (state) => state.streetView.selectedRubric;

// Rubric
const findCatalogByRubric = (rubric, catalogs) => {
    function findInRubricsList(rubrics) {
        return rubrics.find((r) => r.id === rubric.id);
    }

    return catalogs.find((catalog) => {
        if (catalog?.folders.length > 0) {
            return findInRubricsList(catalog.folders);
        }

        return findInRubricsList(catalog.rubrics);
    });
};
export const getCatalogBySelectedRubricSelector = (state) => {
    const selectedRubric = selectedRubricSelector(state);
    if (selectedRubric === undefined) {
        return null;
    }
    const { catalogs } = state.catalogs;

    return findCatalogByRubric(selectedRubric, catalogs);
};
export const selectedRubricShapeTypeSelector = (state) => {
    const selectedRubric = selectedRubricSelector(state);

    return getShapeTypeFromRubric(selectedRubric);
};
export const rubricIdSelectedSelector = (state) => selectedRubricSelector(state)?.id;

// road-datas & annotations
export const roadDatasToDisplaySelector = (state) => state.streetView.roadDatas;

const numberOfRoadDatasToDisplaySelector = (state) => roadDatasToDisplaySelector(state).length;

export const numberOfHiddenRoadDatasSelector = (state) => {
    const countOfAllRoadDatas = state.streetView.totalSize;
    const countOfRoadDatasToDisplay = numberOfRoadDatasToDisplaySelector(state);

    return countOfAllRoadDatas - countOfRoadDatasToDisplay;
};

export const annotationToDisplayOnImageSelector = (state) => allAnnotationsOfCurrentImageSelector(state);
