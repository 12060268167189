/* eslint-disable @typescript-eslint/no-shadow */
import styled from 'styled-components';
import { Flex, Error as BaseError, Text } from '../../../../style';
import { theme } from '../../../../style/theme';

export const DescriptionContainer = styled(Flex)`
    margin-bottom: 10px;
`;

export const Description = styled.span`
    font-size: ${({ theme }) => theme.font.size.medium3};
    margin: 0 0 0 20px;
`;

export const DragAndDropContainer = styled(Flex).attrs({ column: true })`
    color: ${({ theme }) => theme.color.grey.main};
    background-color: ${({ theme }) => theme.color.white.main};
    border-radius: 10px;
    padding: 80px;
    margin: 30px 0 20px;
    outline: dashed 2px ${({ theme }) => theme.color.grey.medium};
    box-sizing: border-box;
    cursor: pointer;
    width: 600px;
    height: 300px;

    & span {
        cursor: pointer;
        white-space: pre-line;
    }
`;

export const FilesContainer = styled.div`
    margin-top: 10px;
    overflow: auto;
    width: 100%;
    max-width: 600px;
    max-height: 250px;
    padding: 0 8px;
`;

export const Error = styled(BaseError).attrs({ margin: '5px 0 0 0' })``;

export const MediumText = styled(Text).attrs({ size: theme.font.size.medium })``;

export const ColoredText = styled(MediumText).attrs({ color: theme.color.blue.primary })`
    text-decoration: underline;
    display: inline;
`;
export const CumulCheckboxLabel = styled.label`
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 5px;
    max-width: 655px;
    align-items: baseline;
`;
