import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { LngLatBounds } from 'mapbox-gl';
import type { GradeReference } from '../domain/Roaddata';
import type { Session, SessionId } from '../domain/Session';
import type { ExecutionStatus, NetworkMapRoadData, OrganizationNetwork } from './ExternalNetworkMapPort';
import type { SelectedGradeReference } from './MapViewSettingsPort';
import type { MapStyle } from '../../types';

export const moduleName = 'SESSIONS_MAP';

export type SessionShownOnMap = { sessionId: SessionId; shown: boolean };
export type OrganizationZoomedOnMap = { toggle: boolean };

type State = {
    networkMapRoadData: NetworkMapRoadData | null;
    network: OrganizationNetwork | null;
    networkExecutionStatuses: Array<ExecutionStatus> | null;
    networkExecutionsNeedRefresh: Array<{ sessionId: string; refresh: boolean }>;
    sessionsIds: SessionId[];
    sessionZoomedOnMap: Session | null;
    organizationZoomedOnMap: OrganizationZoomedOnMap;
    sessionsShownOnMap: SessionId[];
    selectedGradeReference: SelectedGradeReference;
    currentMapBounds: LngLatBounds | null;
    renderedSessionsIds: SessionId[];
    selectedMapStyle: MapStyle;
    displayPr: boolean;
};

const initialState: State = {
    network: null,
    networkExecutionStatuses: null,
    networkExecutionsNeedRefresh: [],
    networkMapRoadData: null,
    sessionsIds: [],
    organizationZoomedOnMap: { toggle: false },
    sessionZoomedOnMap: null,
    sessionsShownOnMap: [],
    selectedGradeReference: null,
    currentMapBounds: null,
    renderedSessionsIds: [],
    selectedMapStyle: 'STREETS',
    displayPr: false,
};

const networkMapSlice = createSlice({
    name: moduleName,
    initialState,
    reducers: {
        resetStarted(state: State) {
            state.network = null;
            state.organizationZoomedOnMap = { toggle: false };
            state.sessionZoomedOnMap = null;
        },
        networkMapLoaded(state: State, action: PayloadAction<OrganizationNetwork>) {
            state.network = action.payload;
            state.sessionZoomedOnMap = null;
        },
        executionStatusesRefreshed(state: State, action: PayloadAction<Array<ExecutionStatus>>) {
            state.networkExecutionsNeedRefresh = (state.networkExecutionStatuses ?? []).map((oldStatus) => {
                const newStatus = action.payload.find((ns) => ns.sessionId === oldStatus.sessionId);

                return {
                    sessionId: oldStatus.sessionId,
                    refresh: oldStatus.status !== newStatus?.status && newStatus?.status === 'SUCCEED',
                };
            });
            state.networkExecutionStatuses = action.payload;
        },
        networkMapRoadDataLoaded(state: State, action: PayloadAction<NetworkMapRoadData>) {
            state.networkMapRoadData = action.payload;
            state.sessionZoomedOnMap = null;
        },
        initSessionsToggledOnMap(state: State, action: PayloadAction<Session[]>) {
            if (state.sessionsIds.length === 0) {
                state.sessionsIds = action.payload.flatMap((session) => session.id);
                state.sessionsShownOnMap = action.payload.flatMap((session) => session.id);
            }
        },
        sessionToggledOnMap(state: State, action: PayloadAction<SessionShownOnMap>) {
            const index = state.sessionsShownOnMap.findIndex((sessionId) => sessionId === action.payload.sessionId);
            if (index === -1 && action.payload.shown) {
                state.sessionsShownOnMap = [...state.sessionsShownOnMap, action.payload.sessionId];
            }
            if (index > -1 && !action.payload.shown) {
                state.sessionsShownOnMap.splice(index, 1);
            }
        },
        allSessionsToggledOnMap(state: State, action: PayloadAction<boolean>) {
            if (action.payload) {
                state.sessionsShownOnMap = [...state.sessionsIds];
            }
            if (!action.payload) {
                state.sessionsShownOnMap = [];
            }
        },
        sessionZoomedOnMap(state: State, action: PayloadAction<Session>) {
            state.sessionZoomedOnMap = { ...action.payload };
            state.organizationZoomedOnMap = { toggle: false };
        },
        organizationZoomedOnMap(state: State) {
            state.organizationZoomedOnMap = { toggle: true };
            state.sessionZoomedOnMap = null;
        },
        gradeReferenceSelectedOnMap(state: State, action: PayloadAction<GradeReference>) {
            state.selectedGradeReference = action.payload;
        },
        selectedMapStyle(state: State, action: PayloadAction<MapStyle>) {
            state.selectedMapStyle = action.payload;
        },
        displayPr(state: State, action: PayloadAction<boolean>) {
            state.displayPr = action.payload;
        },
        currentMapBoundsStored(state: State, action: PayloadAction<LngLatBounds>) {
            state.currentMapBounds = action.payload;
            state.sessionZoomedOnMap = null;
        },
        renderedSessionsIdsUpdated(state: State, action: PayloadAction<SessionId[]>) {
            state.renderedSessionsIds = action.payload;
        },
    },
});

export const {
    resetStarted,
    networkMapLoaded,
    networkMapRoadDataLoaded,
    initSessionsToggledOnMap,
    sessionToggledOnMap,
    allSessionsToggledOnMap,
    sessionZoomedOnMap,
    organizationZoomedOnMap,
    gradeReferenceSelectedOnMap,
    currentMapBoundsStored,
    renderedSessionsIdsUpdated,
    selectedMapStyle,
    displayPr,
    executionStatusesRefreshed,
} = networkMapSlice.actions;
export const networkMapReducer = networkMapSlice.reducer;
