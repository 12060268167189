import { CalibrateFailureModal } from './Calibrate/CalibrateFailureModal';
import { CalibrateSessionModal } from './Calibrate/CalibrateSessionModal';
import { CalibrateSuccessModal } from './Calibrate/CalibrateSuccessModal';
import { EditFailureModal } from './Edit/EditFailureModal';
import { ExportInProgressModal } from './Export/ExportInProgressModal';
import { ExportFailureModal } from './Export/ExportFailureModal';
import { ExportNoAnnotationModal } from './Export/ExportNoAnnotationModal';
import { MlFailureModal } from './Ml/MlFailureModal';
import { MlSuccessModal } from './Ml/MlSuccessModal';
import { CalibrateOngoingModal } from './Calibrate/CalibrateOngoingModal';
import { UploadSessionModal } from '../../upload-sessions/UploadSessionModal';
import { UploadInProgressModal } from '../../upload-sessions/UploadInProgressModal';
import { UploadInitFailureModal } from '../../upload-sessions/UploadInitFailureModal';
import type { FC } from 'react';
import { TriggerAiAnalysisModal } from '../../../ai_analysis/TriggerAiAnalysisModal';
import ComputeZonesModal from './ComputeZones';
import ComputeZonesModalFailure from './ComputeZones/ZonesErrors';
import EditSessionCampaignModal from './Edit/EditSessionCampaignModal';
import EditSessionStateModal from './Edit/EditSessionStateModal';
import EditSessionNameModal from './Edit/EditSessionNameModal';

export const SessionsListModals: FC = () => {
    return (
        <>
            <EditSessionNameModal />
            <EditSessionCampaignModal />
            <EditSessionStateModal />
            <EditFailureModal />
            <CalibrateSessionModal />
            <CalibrateSuccessModal />
            <CalibrateFailureModal />
            <CalibrateOngoingModal />
            <ExportInProgressModal />
            <ExportFailureModal />
            <ExportNoAnnotationModal />
            <UploadSessionModal />
            <UploadInProgressModal />
            <UploadInitFailureModal />
            <MlSuccessModal />
            <MlFailureModal />
            <TriggerAiAnalysisModal />
            <ComputeZonesModal />
            <ComputeZonesModalFailure />
        </>
    );
};
