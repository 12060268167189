import type { GeoJsonProperties } from 'geojson';
import type { MapLayerMouseEvent, MapboxGeoJSONFeature } from 'mapbox-gl';
import { useCallback, useState } from 'react';
import type { FeatureManager } from '../feature-manager';

export type UseCreatePopupClickedOutput<T extends GeoJsonProperties> = {
    popupOnClick: (event: MapLayerMouseEvent) => void;
    hideClickedPopup: () => void;
    popupIsClicked: boolean;
    popupCoordinates: [number, number] | null;
    networkProperties: T | null;
};

export default <T extends GeoJsonProperties>(
    fm: FeatureManager,
    layer: string,
    source: string,
    sourceLayer?: string,
): UseCreatePopupClickedOutput<T> => {
    const [, setDisplayPopup] = useState<boolean>(false);
    const [popupIsShown, setPopupIsShown] = useState<boolean>(false);
    const [popupCoordinates, setPopupCoordinates] = useState<[number, number] | null>(null);
    const [networkProperties, setNetworkProperties] = useState<T | null>(null);

    const popupOnClick = useCallback((event: MapLayerMouseEvent) => {
        const showPopup = () => setDisplayPopup(true);
        const feature = fm.getClickedFeature(event);
        setPopupIsShown(false);
        fm.restoreFeatureStyle({ source, sourceLayer });

        if (feature && feature.layer.id === layer) {
            fm.setFeatureStyleToHover(feature);
            setPopupIsShown(true);
            setPopupCoordinates([event.lngLat.lat, event.lngLat.lng]);
            setNetworkProperties({
                ...((feature as MapboxGeoJSONFeature).properties as T),
            });
            showPopup();
        }
    }, []);

    return {
        popupOnClick,
        hideClickedPopup: () => setDisplayPopup(false),
        popupIsClicked: popupIsShown,
        popupCoordinates,
        networkProperties,
    };
};
