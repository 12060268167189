import type { FC } from 'react';
import type {
    NetworkRoadDataGeojsonProperties,
    NetworkRoadDataProperties,
} from '../../../../../../core/networkMap/ExternalNetworkMapPort';
import Content from '../Content';
import useNetworkProperties from '../useNetworkProperties';

type Props = {
    networkProperties: NetworkRoadDataGeojsonProperties | null;
};

const RoadDataPopupOnHover: FC<Props> = ({ networkProperties }) => {
    if (!networkProperties) {
        return null;
    }

    const properties: NetworkRoadDataProperties = useNetworkProperties(networkProperties);

    return <Content properties={properties} />;
};

export default RoadDataPopupOnHover;
