import { captureException } from '@sentry/browser';
import axios from 'axios';
import type { Annotation, RoadDataInterval } from '../core/domain/Roaddata';
import type { ExternalRoadDatasPort } from './ExternalRoadDatasPort';

export class ExternalRoadDatasProvider implements ExternalRoadDatasPort {
    constructor(private readonly baseEndpoint: string) {}

    async loadAnnotationsForImageByRubricIds(
        imageId: string,
        rubricToDisplayIds: string[],
        referenceToDisplayIds: Array<string>,
        taggedElementsOnly: boolean,
        imageRoaddataOnly: boolean,
    ): Promise<Annotation[]> {
        try {
            if (rubricToDisplayIds.length === 0) {
                return [];
            }

            const { data } = await axios.request({
                method: 'POST',
                url: `${this.baseEndpoint}/image/${imageId}/annotations`,
                data: {
                    rubricToDisplayIds,
                    referenceToDisplayIds,
                    taggedElementsOnly,
                    imageRoaddataOnly,
                },
                withCredentials: true,
            });

            return data;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }

    async loadRoadDataIntervalOfCurrentImage(
        imageId: string,
        rubricToDisplayIds: string[],
        referenceToDisplayIds: Array<string>,
        taggedElementsOnly: boolean,
        imageRoaddataOnly: boolean,
    ): Promise<RoadDataInterval> {
        try {
            const { data } = await axios.request({
                method: 'POST',
                url: `${this.baseEndpoint}/image/${imageId}/roaddata-interval`,
                data: {
                    rubricToDisplayIds,
                    referenceToDisplayIds,
                    taggedElementsOnly,
                    imageRoaddataOnly,
                },
                withCredentials: true,
            });

            if (rubricToDisplayIds.length === 0) {
                return {
                    roaddatas: [],
                    totalSize: data.totalSize,
                    rubricIdToCount: data.rubricIdToCount,
                };
            }

            return data;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }
}
