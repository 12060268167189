import type { GradeReference } from '../core/domain/Roaddata';
import type {
    OrganizationMapViewSettings,
    SelectedGradeReference,
    StoredOrganizationMapViewSettings,
} from '../core/networkMap/MapViewSettingsPort';
import type MapViewSettingsPort from '../core/networkMap/MapViewSettingsPort';
import type { MapStyle } from '../types';

const MAP_VIEW_SETTINGS_LOCAL_STORAGE_KEY = 'ROADCARE_MAPVIEW_SETTINGS';

export default class MapViewSettingsProvider implements MapViewSettingsPort {
    async getSettingsByOrganizationId(organizationId: string): Promise<OrganizationMapViewSettings | null> {
        const organizationSettings = this.getOrganizationSettings(organizationId);

        if (!organizationSettings) {
            return null;
        }

        return organizationSettings;
    }

    async updateMapStyleForOrganization(organizationId: string, mapStyle: MapStyle): Promise<void> {
        const settingsFromLocalStorage = localStorage.getItem(MAP_VIEW_SETTINGS_LOCAL_STORAGE_KEY);

        if (!settingsFromLocalStorage) {
            this.initializeSettings(organizationId, { mapStyle });

            return;
        }

        const settings: StoredOrganizationMapViewSettings = JSON.parse(settingsFromLocalStorage);

        const organizationSettings = settings.find((setting) => setting.organizationId === organizationId);

        if (!organizationSettings) {
            this.addOrganizationSettings(organizationId, { mapStyle }, settings);

            return;
        }

        this.updateOrganizationSettings(organizationSettings, { mapStyle }, settings);
    }

    async updateDisplayPrForOrganization(organizationId: string, displayPr: boolean): Promise<void> {
        const settingsFromLocalStorage = localStorage.getItem(MAP_VIEW_SETTINGS_LOCAL_STORAGE_KEY);

        if (!settingsFromLocalStorage) {
            this.initializeSettings(organizationId, { displayPr });

            return;
        }

        const settings: StoredOrganizationMapViewSettings = JSON.parse(settingsFromLocalStorage);

        const organizationSettings = settings.find((setting) => setting.organizationId === organizationId);

        if (!organizationSettings) {
            this.addOrganizationSettings(organizationId, { displayPr }, settings);

            return;
        }

        this.updateOrganizationSettings(organizationSettings, { displayPr }, settings);
    }

    async updateSelectedGradeReferenceForOrganization(
        organizationId: string,
        selectedGradeReference: SelectedGradeReference,
    ): Promise<void> {
        const settingsFromLocalStorage = localStorage.getItem(MAP_VIEW_SETTINGS_LOCAL_STORAGE_KEY);

        if (!settingsFromLocalStorage) {
            this.initializeSettings(organizationId, { selectedGradeReference });

            return;
        }

        const settings: StoredOrganizationMapViewSettings = JSON.parse(settingsFromLocalStorage);

        const organizationSettings = settings.find((setting) => setting.organizationId === organizationId);

        if (!organizationSettings) {
            this.addOrganizationSettings(organizationId, { selectedGradeReference }, settings);

            return;
        }

        this.updateOrganizationSettings(organizationSettings, { selectedGradeReference }, settings);
    }

    private updateOrganizationSettings(
        organizationSettings: OrganizationMapViewSettings,
        newState: {
            selectedGradeReference?: GradeReference | null;
            mapStyle?: MapStyle;
            displayPr?: boolean;
        },
        settings: StoredOrganizationMapViewSettings,
    ) {
        const { selectedGradeReference, mapStyle, displayPr } = organizationSettings.settings;
        organizationSettings.settings = {
            selectedGradeReference: newState.selectedGradeReference ?? selectedGradeReference,
            mapStyle: newState.mapStyle ?? mapStyle,
            displayPr: newState.displayPr ?? displayPr,
        };

        localStorage.setItem(MAP_VIEW_SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify(settings));
    }

    private addOrganizationSettings(
        organizationId: string,
        initialState: {
            selectedGradeReference?: GradeReference | null;
            mapStyle?: MapStyle;
            displayPr?: boolean;
        },
        settings: StoredOrganizationMapViewSettings,
    ) {
        const newSettings: OrganizationMapViewSettings = {
            organizationId,
            settings: {
                selectedGradeReference: initialState.selectedGradeReference ?? null,
                mapStyle: initialState.mapStyle ?? 'STREETS',
                displayPr: initialState.displayPr ?? false,
            },
        };

        localStorage.setItem(MAP_VIEW_SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify([...settings, newSettings]));
    }

    private initializeSettings(
        organizationId: string,
        initialState: {
            selectedGradeReference?: GradeReference | null;
            mapStyle?: MapStyle;
            displayPr?: boolean;
        },
    ) {
        const newSettings: OrganizationMapViewSettings = {
            organizationId,
            settings: {
                selectedGradeReference: initialState.selectedGradeReference ?? null,
                mapStyle: initialState.mapStyle ?? 'STREETS',
                displayPr: initialState.displayPr ?? false,
            },
        };
        localStorage.setItem(MAP_VIEW_SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify([newSettings]));
    }

    private getOrganizationSettings(organizationId: string): OrganizationMapViewSettings | null {
        const settingsFromLocalStorage = localStorage.getItem(MAP_VIEW_SETTINGS_LOCAL_STORAGE_KEY);
        if (!settingsFromLocalStorage) {
            return null;
        }

        const settings: StoredOrganizationMapViewSettings = JSON.parse(settingsFromLocalStorage);

        const organizationSettings = settings.find((setting) => setting.organizationId === organizationId);
        if (!organizationSettings) {
            return null;
        }

        return organizationSettings;
    }
}
