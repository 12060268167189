import { useTranslation } from 'react-i18next';
import { FieldArray, reduxForm } from 'redux-form';
import FileUpload from '../../legacy/components/ui/forms/FileUpload';
import { filesRequired, required } from '../../../core/legacy/utils/validation';
import { ButtonsContainer, ValidateButton } from '../../legacy/components/ui/modals/styles';
import { StyledForm } from '../../legacy/components/ui/forms/styles';
import type { FC } from 'react';
import { UploadSessionsCommand } from '../../../core/uploads/commands/UploadSessionsCommand';

export const MAX_UPLOAD_SESSION_SIZE = 13000000000; // slightly more than strictly 12 GiB

type Props = {
    handleSubmit: () => void;
    invalid: boolean;
};

const UploadSessionForm: FC<Props> = ({ handleSubmit, invalid }) => {
    const { t } = useTranslation();

    const fileSize = (files) => {
        const isAFileTooBig = files.some((file) => file.size > MAX_UPLOAD_SESSION_SIZE);

        return isAFileTooBig ? 'error.fileSize' : null;
    };

    return (
        <StyledForm onSubmit={handleSubmit}>
            <FieldArray
                component={FileUpload}
                name="sessions"
                props={{
                    accept: {
                        'application/x-zip': [],
                        'application/x-zip-compressed': [],
                        'application/zip': ['.zip'],
                        'application/octet-stream': [],
                    },
                    multiple: true,
                    maxSize: MAX_UPLOAD_SESSION_SIZE,
                    extraInfo: t('uploadSession.extraInfo'),
                    description: 'uploadFile.descriptionMultipleZIP',
                }}
                validate={[required, filesRequired, fileSize]}
            />
            <ButtonsContainer>
                <ValidateButton disabled={invalid} type="submit">
                    {t('uploadFile.submit')}
                </ValidateButton>
            </ButtonsContainer>
        </StyledForm>
    );
};

export default reduxForm({
    form: 'upload-sessions',
    onSubmit: async (values, dispatch) => {
        await dispatch(UploadSessionsCommand(values.sessions));
    },
})(UploadSessionForm);
