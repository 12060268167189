import { createCommand } from '../../utils';
import { sessionFilterQuery, sessionSortQuery } from '../queries';
import { moduleName, resetStarted, resetWith } from '../state';

export const InitSessionsCommand = createCommand(
    `${moduleName}/INIT_SESSIONS`,
    async (_, { dispatch, extra, getState }) => {
        dispatch(resetStarted());
        const sessions = await extra.externalSessions.loadSessions();
        const state = getState();

        let sort = sessionSortQuery(state);
        if (sort.length === 0) {
            sort = extra.externalSessionConfiguration.loadSessionsSort();
        }

        let filter = sessionFilterQuery(state);
        if (filter.items.length === 0) {
            filter = extra.externalSessionConfiguration.loadSessionsFilter();
        }

        dispatch(resetWith({ sessions, sort, filter }));
    },
);
