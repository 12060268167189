import { captureException } from '@sentry/browser';
import axios from 'axios';
import type { Catalogs, ExternalCatalogsPort } from './ExternalCatalogsPort';

export class ExternalCatalogsProvider implements ExternalCatalogsPort {
    constructor(private readonly baseEndpoint: string) {}

    async loadCatalogs(): Promise<Catalogs> {
        try {
            const response = await axios.request({
                method: 'GET',
                url: `${this.baseEndpoint}/annotation/catalog`,
                withCredentials: true,
            });

            return response.data;
        } catch (err) {
            console.error(err);
            captureException(err);
            throw err;
        }
    }
}
