import { ReactComponent as CalibrateIcon } from '../../../../assets/icons/calibrate.svg';
import { useTranslation } from 'react-i18next';
import { SessionsModalTypes } from '../../../../core/legacy/reducers/modals';
import { FC, useCallback } from 'react';
import { useAppDispatch } from '../../../../types';
import { OpenModalWithContentCommand } from '../../../../core/sessions/commands/OpenModalWithContentCommand';
import BaseButton from './BaseButton';

interface CalibrateSelectedSessionsButtonProps {
    calibrated: boolean;
    sessionIds: string[];
}

export const CalibrateSelectedSessionsButton: FC<CalibrateSelectedSessionsButtonProps> = ({
    calibrated,
    sessionIds,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleClick = useCallback(() => {
        dispatch(
            OpenModalWithContentCommand({
                modalToDisplay: SessionsModalTypes.SESSIONS_CALIBRATE_SESSION,
                sessionIds,
            }),
        );
    }, [dispatch, sessionIds]);

    return (
        <BaseButton onClick={handleClick} variant="secondary">
            <CalibrateIcon />
            {t(calibrated ? 'labels.recalibrate' : 'labels.calibrate')}
        </BaseButton>
    );
};
