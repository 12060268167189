import { createCommand } from '../../utils';
import { displayPr, moduleName } from '../state';
import { userOrganizationQuery } from '../../authentication/queries';

export const SetDisplayPrFromPersistenceCommand = createCommand(
    `${moduleName}/SET_DISPLAY_PR_FROM_PERSISTANCE`,
    async (_, { dispatch, extra, getState }) => {
        const userOrganization = userOrganizationQuery(getState());

        if (userOrganization) {
            const organizationSettings = await extra.mapViewSettings.getSettingsByOrganizationId(userOrganization.id);
            if (organizationSettings?.settings?.displayPr) {
                dispatch(displayPr(organizationSettings.settings.displayPr));
            }
        }
    },
);
