import { ListItemText, MenuItem, Stack } from '@mui/material';
import { FC, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadSessionImage } from '../../../../../../../core/legacy/actions';
import { useAppDispatch } from '../../../../../../../types';
import { BaseMenuItemText } from '../../../../../../design_system/inputs/menuI-items/BaseMenuItemText';
import { ReactComponent as UploadIcon } from '../../../../../../../assets/icons/export.svg';

type Props = {
    onActionExecuted: () => void;
};

export const DownloadImageMenuItem: FC<Props> = ({ onActionExecuted }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleClick = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            onActionExecuted();
            dispatch(downloadSessionImage());
        },
        [dispatch, onActionExecuted],
    );

    return (
        <MenuItem onClick={handleClick}>
            <Stack direction="row" gap="10px" alignItems="center">
                <UploadIcon />
                <ListItemText>
                    <BaseMenuItemText>{t('buttons.download-image')}</BaseMenuItemText>
                </ListItemText>
            </Stack>
        </MenuItem>
    );
};
