import { CircularProgress } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionsModalTypes } from '../../../../core/legacy/reducers/modals';
import GenericModal from '../../../legacy/components/ui/modals/GenericModal';
import { ContentContainer, ModalCenterTitle, ModalCenterSubTitle } from '../../../legacy/components/ui/modals/styles';

export const NetworkMapDataLoading: FC = () => {
    const { t } = useTranslation();
    const options = {
        hideDefaultButtons: true,
        notDismissable: true,
    };

    return (
        <GenericModal options={options} modalType={SessionsModalTypes.NETWORK_MAP_LOADER}>
            <ContentContainer>
                <CircularProgress />
                <ModalCenterTitle>{t('networkMap.dataLoading.title')}</ModalCenterTitle>
                <ModalCenterSubTitle>{t('networkMap.dataLoading.subtitle')}</ModalCenterSubTitle>
            </ContentContainer>
        </GenericModal>
    );
};
