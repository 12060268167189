/* eslint-disable @typescript-eslint/no-shadow */
import styled from 'styled-components';
import { BlueContainer as BlueContainerBase } from '../../../style';

export const BlueContainer = styled(BlueContainerBase)`
    background-color: ${({ theme }) => theme.color.blue.light};
    height: 100vh;
    width: 100vw;
    z-index: 10;
    position: relative;
    top: 0;
    left: 0;
    padding: 0;
    display: flex;
`;
