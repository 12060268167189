import { getShapeTypeFromAnnotation } from './annotationEngine';
import type {
    Annotation,
    Coordinates,
} from '../../../ui/legacy/components/ui/annotation-engine-lib/annotation-engine/models';
import { resolveRoadDataName } from '../../../utils/resolve-road-data-name';
import { resolveRoadDataColor } from '../../../utils/resolve-road-data-color';

type ImageRatio = {
    widthRatio: number;
    heightRatio: number;
};

const getImageBackgroundFromAnnotationEngine = (): HTMLImageElement => {
    const IMAGE_BACKGROUND_ELEMENT_POSITION = 0;

    const imageBackground = document.getElementById('annotation-engine')?.parentNode?.children[
        IMAGE_BACKGROUND_ELEMENT_POSITION
    ] as HTMLImageElement;

    return imageBackground;
};

const getCanvasFromAnnotationEngine = (): HTMLCanvasElement => {
    const CANVAS_ELEMENT_POSITION = 1;

    const canvas = document.getElementById('annotation-engine')?.parentNode?.children[
        CANVAS_ELEMENT_POSITION
    ] as HTMLCanvasElement;

    return canvas;
};

/**
 * Get the ratio for image height and width calculated with the natural size and the current size.
 */
const getCanvasRatio = (): ImageRatio => {
    const img = getImageBackgroundFromAnnotationEngine();
    const canvas = getCanvasFromAnnotationEngine();

    return {
        widthRatio: img.naturalWidth / canvas.clientWidth,
        heightRatio: img.naturalHeight / canvas.clientHeight,
    };
};

/**
 * Converts the array of pixels from the image natural size according to the size of the image in the streetview
 * @param annotationPoints
 * @returns {*}
 */
const convertAnnotationPointsToEngineSize = (annotationPoints: Coordinates[] | Coordinates[][]): Coordinates[] => {
    const { widthRatio, heightRatio } = getCanvasRatio();
    // flat in case of polygon
    const flattenAnnotationPoints = annotationPoints.flat();

    return flattenAnnotationPoints.map((point) => {
        return {
            x: point.x / widthRatio,
            y: point.y / heightRatio,
        };
    });
};

const convertAnnotationToEngineSize = (annotation): Annotation => {
    const pureAnnotation = { ...annotation };
    // FIXME: sera utile pour l'internationalisation
    // pureAnnotation.roadData.name = translateOutsideComponent(
    //     `catalog.rubric.${pureAnnotation.roadData.name.toLowerCase()}`,
    // );
    const attrId = pureAnnotation.roadData.presetAttributeValueIdForShape;
    const { projectedShape } = pureAnnotation.attributes.find((attribute) => attribute.attributeValue.id === attrId);
    const shapeType = getShapeTypeFromAnnotation(pureAnnotation);

    return {
        id: pureAnnotation.roadData.id,
        isClosed: pureAnnotation.roadData.isShapeClosed,
        color: resolveRoadDataColor(pureAnnotation.roadData),
        name: resolveRoadDataName(pureAnnotation.roadData),
        coordinates: convertAnnotationPointsToEngineSize(projectedShape),
        hasMiddlePoints: shapeType === 'POLYGON' || shapeType === 'POLYLINE',
    };
};

const convertManualCalibrationPointToEngineSize = (annotation) => {
    const pureAnnotation = { ...annotation };

    return {
        ...pureAnnotation,
        coordinates: convertAnnotationPointsToEngineSize(annotation.coordinates),
    };
};

/**
 * Converts ONE pixel from the annotation engine to real pixels data according to
 * the image natural size.
 */
const convertAnnotationPointToRealSize = (annotationPoint: Coordinates): Coordinates => {
    const { widthRatio, heightRatio } = getCanvasRatio();

    return {
        x: annotationPoint.x * widthRatio,
        y: annotationPoint.y * heightRatio,
    };
};

/**
 * Converts the pixels from the annotation engine to real pixels data according to
 * the image natural size.
 */
const convertAnnotationPointsToRealSize = (annotationPoints: Coordinates[]): Coordinates[] => {
    const { widthRatio, heightRatio } = getCanvasRatio();

    return annotationPoints.map((point) => ({
        x: point.x * widthRatio,
        y: point.y * heightRatio,
    }));
};

export {
    getImageBackgroundFromAnnotationEngine,
    convertAnnotationPointsToEngineSize,
    convertAnnotationToEngineSize,
    convertAnnotationPointsToRealSize,
    convertManualCalibrationPointToEngineSize,
    convertAnnotationPointToRealSize,
};
