import type { BaseSyntheticEvent, FC } from 'react';
import { StyledButton } from '../../../legacy/components/ui/Menu/style';
import { useAppDispatch } from '../../../../types';
import type { SessionId } from '../../../../core/domain/Session';
import { SessionMenu } from './SessionMenu';
import { NavigateToNetworkMapCommand } from '../../../../core/networkMap/commands/NavigateToNetworkMapCommand';
import { SetLastOpenedSessionIdCommand } from '../../../../core/sessions/commands/SetLastOpenedSessionIdCommand';
import { MapIcon } from '../../../design_system/icons/MapIcon';
import { useTranslation } from 'react-i18next';
import { OpenModalWithContentCommand } from '../../../../core/sessions/commands/OpenModalWithContentCommand';
import { SessionsModalTypes } from '../../../../core/legacy/reducers/modals';
import { EditIcon } from '../../../design_system/icons/EditIcon';

export const ContextMenu: FC<{ sessionId: SessionId }> = ({ sessionId }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleGoToNetworkMap = (event: BaseSyntheticEvent) => {
        event.stopPropagation();
        dispatch(SetLastOpenedSessionIdCommand(sessionId));
        dispatch(NavigateToNetworkMapCommand({ sessionId }));
    };

    const handleRename = (event: BaseSyntheticEvent) => {
        event.stopPropagation();
        dispatch(
            OpenModalWithContentCommand({
                modalToDisplay: SessionsModalTypes.SESSIONS_EDIT_NAME,
                sessionIds: [sessionId],
            }),
        );
    };

    return (
        <>
            <StyledButton onClick={handleRename} type="button">
                <EditIcon title={t('labels.editName')} themeStyle="light" size="tableIconSize" />
            </StyledButton>
            <StyledButton onClick={handleGoToNetworkMap} type="button">
                <MapIcon title={t('labels.networkMap')} themeStyle="light" size="tableIconSize" />
            </StyledButton>
            <SessionMenu sessionId={sessionId} />
        </>
    );
};
