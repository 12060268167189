import { GreySquareButton } from '../../../../design_system/inputs/buttons/GreySquareButton';
import { ReactComponent as BackIcon } from '../../../../../assets/icons/arrow-back.svg';
import type { FC } from 'react';

export const BackButton: FC<{ handleClick: () => void }> = ({ handleClick }) => {
    return (
        <GreySquareButton onClick={handleClick}>
            <BackIcon />
        </GreySquareButton>
    );
};
