import type { Execution } from '../domain/AIAnalysis';

const isAnalysisNotStartedOnSession = (executions: Execution[], sessionId: string): boolean =>
    executions.every((execution) => execution.sessionId !== sessionId);

const isAnalysisOngoingOnSession = (executions: Execution[], sessionId: string): boolean =>
    executions.some((execution) => execution.sessionId === sessionId && execution.status === 'ONGOING');

const isAnalysisDoneOnSession = (executions: Execution[], sessionId: string): boolean =>
    executions.some((execution) => execution.sessionId === sessionId && execution.status !== 'ONGOING');

export { isAnalysisDoneOnSession, isAnalysisNotStartedOnSession, isAnalysisOngoingOnSession };
