import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

const slice = (state: RootState) => state.authentication;
export const loggedOutQuery = createSelector(slice, (state) => state.status === 'LOGGED_OUT');
export const loggedInQuery = createSelector(slice, (state) => state.status === 'LOGGED_IN');
export const logOngoingQuery = createSelector(slice, (state) => state.status === 'LOG_ONGOING');
export const logFailedQuery = createSelector(slice, (state) => state.status === 'LOG_FAILED');
export const userQuery = createSelector(slice, (state) => state.user);
export const userOrganizationQuery = createSelector(slice, (state) => state.user?.organization);
export const logFailedErrorQuery = createSelector(slice, (state) => state.errorMessage);

export const userIdQuery = createSelector(slice, (state) => state.user?.id);
export const aiAnalysisAllowedQuery = createSelector(
    slice,
    (state) => (state.user?.parameters.aiAnalysisAllowedModelCodes.length ?? -1) > 0,
);
