import { useTranslation } from 'react-i18next';
import { TransparentButton } from '../../legacy/components/StreetView/styles';
import { Text } from '../../legacy/style';
import { useAppDispatch, useAppSelector } from '../../../types';
import type { FC } from 'react';
import {
    FileItem,
    FileLabel,
    FileSize as FilePercentage,
    RightContainer,
} from '../../legacy/components/ui/files/styles';
import { sessionUploadUploadedPercentageQuery } from '../../../core/uploads/queries';
import { RetrySessionUploadByUrlCommand } from '../../../core/uploads/commands/RetrySessionUploadByUrlCommand';
import type { SessionUpload, UploadStatuses } from '../../../core/domain/SessionUpload';

const RetryIcon = () => (
    <Text size="20px" margin="0 0 0 5px" pointer>
        &#8634;
    </Text>
);

type Props = {
    sessionUpload: SessionUpload;
};

export const FileUploadItem: FC<Props> = ({ sessionUpload }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const percentage = useAppSelector(sessionUploadUploadedPercentageQuery(sessionUpload.url));

    const isSuccess = (status: UploadStatuses) => status === 'SUCCESS';
    const isProgress = (status: UploadStatuses) => status === 'IN_PROGRESS' || status === 'INITIATED';
    const isFailure = (status: UploadStatuses) => status === 'FAILURE';

    const retryUpload = (url: string) => async () => {
        dispatch(RetrySessionUploadByUrlCommand(url));
    };

    const getFileStatusInformation = ({ status, url }) => {
        if (isProgress(status)) {
            return <FileLabel>{t('labels.uploadInProgress')}</FileLabel>;
        }
        if (isFailure(status)) {
            return (
                <TransparentButton type="button" onClick={retryUpload(url)}>
                    {t('labels.retry')}
                    <RetryIcon />
                </TransparentButton>
            );
        }
        if (isSuccess(status)) {
            return <FileLabel>{t('labels.uploadFinished')}</FileLabel>;
        }

        return null;
    };

    const getUploadProgress = () => {
        return `${percentage.toFixed(0)}%`;
    };

    return (
        <FileItem>
            <FileLabel warning={isFailure(sessionUpload.status)}>{sessionUpload.fileName}</FileLabel>
            <RightContainer>
                <FilePercentage warning={isFailure(sessionUpload.status)}>{getUploadProgress()}</FilePercentage>
                {getFileStatusInformation(sessionUpload)}
            </RightContainer>
        </FileItem>
    );
};
