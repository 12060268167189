import { MouseEventHandler, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { currentImageSelector } from '../../../../../core/legacy/selectors/streetView';
import { goToImageByCumul } from '../../../../../core/legacy/actions';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { ItinerarySchema } from './Itinerary';
import { ItineraryLandmarks } from './Landmarks';
import { Label } from './Label';
import { ProgressCursor } from './ProgressCursor';
import LandmarkValue from '../../../../components/streetView/roadDatasPanel/values/LandmarkValue';
import type { Landmark } from '../../../../../core/domain/Roaddata';
import { currentLandmarkQuery, sessionQuery } from '../../../../../core/streetViewer/queries';
import type { Image, Session } from '../../../../../core/domain/Session';
import useItineraryData from './useItineraryData';

const computeLabelsLeftSpans = (
    progressBarWidth: number,
    fillValuePercentage: number,
    progressCursorWidth: number,
    landmarkLabelWidth: number,
) => {
    const labelTheoreticalPosition = (fillValuePercentage * progressBarWidth) / 100;
    const MARGIN_FOR_BETTER_READING = 1;
    const labelMinLeftPosition = MARGIN_FOR_BETTER_READING;

    const labelWouldOverflowOnTheLeft = () => {
        return labelTheoreticalPosition < labelMinLeftPosition;
    };
    const labelWouldOverflowOnTheRight = (labelWidth) => {
        const maxLabelLeftPosition = progressBarWidth - labelWidth - MARGIN_FOR_BETTER_READING;

        return labelTheoreticalPosition > maxLabelLeftPosition;
    };

    const computeLabelLeftSpan = (labelWidth: number): number => {
        const labelMaxLeftPosition = progressBarWidth - labelWidth - MARGIN_FOR_BETTER_READING;
        let leftSpan = labelTheoreticalPosition - labelWidth / 2;

        if (labelWouldOverflowOnTheLeft()) {
            leftSpan = labelMinLeftPosition;
        }
        if (labelWouldOverflowOnTheRight(labelWidth)) {
            leftSpan = labelMaxLeftPosition;
        }

        return Math.min(Math.max(labelMinLeftPosition, leftSpan), labelMaxLeftPosition);
    };

    const landmarkLeftSpan = computeLabelLeftSpan(landmarkLabelWidth);

    const maxProgressCursorLeftSpan = progressBarWidth - progressCursorWidth;
    const progressCursorLeftSpan = Math.min(labelTheoreticalPosition, maxProgressCursorLeftSpan);

    return { arrowLeftSpan: progressCursorLeftSpan, landmarkLeftSpan };
};

const useGetCumulLabelSpan = (currentImage: Image, fillValue: number, progressBarRef: RefObject<HTMLDivElement>) => {
    const progressCursorRef = useRef<HTMLDivElement>(null);
    const landmarkLabelRef = useRef<HTMLSpanElement>(null);
    const [progressCursorLeftSpan, setProgressCursorLeftSpan] = useState(0);
    const [landmarkLabelLeftSpan, setlandmarkLabelLeftSpan] = useState(0);

    const displayDataAreMissing =
        !currentImage || !progressCursorRef?.current || !landmarkLabelRef?.current || !progressBarRef?.current;

    useEffect(() => {
        if (displayDataAreMissing) return;

        const { width: progressCursorWidth } = progressCursorRef!.current!.getBoundingClientRect();
        const { width: landmarkLabelWidth } = landmarkLabelRef!.current!.getBoundingClientRect();
        const { width: progressBarWidth } = progressBarRef!.current!.getBoundingClientRect();

        const { arrowLeftSpan, landmarkLeftSpan } = computeLabelsLeftSpans(
            progressBarWidth,
            fillValue,
            progressCursorWidth,
            landmarkLabelWidth,
        );
        setProgressCursorLeftSpan(arrowLeftSpan);
        setlandmarkLabelLeftSpan(landmarkLeftSpan);
    }, [currentImage, fillValue, progressBarRef, displayDataAreMissing]);

    return {
        progressCursorRef,
        progressCursorLeftSpan,
        landmarkLabelRef,
        landmarkLabelLeftSpan,
    };
};

// return the percent of fill for the progress-bar according to the current image cumul start
const useGetFillValuePercentage = (
    currentSession: Session | null,
    currentImage: Image,
    { current: progressBar },
    sessionLength: number,
) => {
    const [fillValue, setFillValue] = useState(0);

    useEffect(() => {
        if (!(currentSession && currentImage && progressBar && sessionLength)) return;

        const imageCumulStart = Number(currentImage.cumulStartSession);
        const newFillValue = (imageCumulStart / sessionLength) * 100;

        if (newFillValue !== fillValue) {
            setFillValue(newFillValue);
        }
    }, [currentSession, currentImage, progressBar, fillValue, sessionLength]);

    return fillValue;
};

const NavigationBar = () => {
    const dispatch = useAppDispatch();
    const currentImage = useAppSelector(currentImageSelector);
    const currentSession = useAppSelector(sessionQuery);
    const currentImageLandmark: Landmark = useAppSelector(currentLandmarkQuery);
    const progressBarRef = useRef<HTMLDivElement>(null);

    const [sessionLength, landmarks] = useItineraryData(currentSession?.id);

    const fillValue = useGetFillValuePercentage(currentSession, currentImage, progressBarRef, sessionLength);
    const { progressCursorRef, progressCursorLeftSpan, landmarkLabelRef, landmarkLabelLeftSpan } = useGetCumulLabelSpan(
        currentImage,
        fillValue,
        progressBarRef,
    );

    const sessionClickHandler: MouseEventHandler<HTMLDivElement> = useCallback(
        ({ pageX }) => {
            if (!progressBarRef.current) return;

            const { left: progressBarLeft, width: progressBarWidth } = progressBarRef.current.getBoundingClientRect();
            const position = pageX - progressBarLeft;
            const ratio = sessionLength / progressBarWidth;
            const cumul = Math.round(position * ratio);

            dispatch(goToImageByCumul(cumul));
        },
        [sessionLength, progressBarRef],
    );

    if (!currentSession || !currentImage || !sessionLength) {
        // set empty div with same height than below component
        // otherwise canvas takes too much place and hides navigation bottom
        return <Box height={65} />;
    }

    return (
        <Box
            sx={{
                cursor: 'pointer',
                display: 'grid',
                gap: '5px',
                position: 'relative',
                pt: '5px',
                width: '100%',
                pb: 2.5,
            }}
            onClick={sessionClickHandler}
            ref={progressBarRef}
        >
            <ItineraryLandmarks landmarks={landmarks} sessionLength={sessionLength} />
            <ItinerarySchema type="SECTION" sessionLength={sessionLength} />
            <ItinerarySchema type="ELEMENTARY" sessionLength={sessionLength} />
            <ProgressCursor ref={progressCursorRef} left={progressCursorLeftSpan} top={8} height={42} />
            <Label ref={landmarkLabelRef} left={landmarkLabelLeftSpan} top={50}>
                <LandmarkValue landmark={currentImageLandmark} />
            </Label>
        </Box>
    );
};

export default NavigationBar;
