import { ButtonBase, ButtonProps } from '@mui/material';
import type { FC } from 'react';
import { theme } from '../../../../style/theme';

interface RightPanelButtonProps extends ButtonProps {
    selected?: boolean;
}

const RightPanelButton: FC<RightPanelButtonProps> = ({ selected, sx, ...props }) => (
    <ButtonBase
        {...props}
        sx={{
            background: 'none',
            border: 'none',
            color: selected ? theme.color.blue.primary : 'inherit',
            cursor: 'pointer',
            paddingBlock: '1px',
            paddingInline: '6px',
            ...sx,
        }}
    />
);

export { RightPanelButton };
