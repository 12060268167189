import { useMemo, FC, ReactNode, MouseEvent } from 'react';
import { StartAiAnalysisWorkflowOnSessionsCommand } from '../../../../core/automatic_analysis/StartAiAnalysisWorkflowOnSessionsCommand';
import { ExportSessionsRoadDatasCommand } from '../../../../core/sessions/commands/ExportSessionsRoadDatasCommand';
import { ExportSessionsReportCommand } from '../../../../core/sessions/commands/ExportSessionsReportCommand';
import { ExportSectionsReportCommand } from '../../../../core/sessions/commands/ExportSectionsReportCommand';
import { setModalToDisplay } from '../../../../core/legacy/actions/modals';
import { SessionsModalTypes } from '../../../../core/legacy/reducers/modals';
import { useTranslation } from 'react-i18next';
import type { SessionId } from '../../../../core/domain/Session';
import { MenuItem, MenuList } from '@mui/material';
import { CalibrationIcon } from '../../icons/CalibrationIcon';
import { AnalysisIcon } from '../../icons/AnalysisIcon';
import { ExportIcon } from '../../icons/ExportIcon';
import { BaseMenuItemText } from './BaseMenuItemText';
import { EditIcon } from '../../icons/EditIcon';
import { ExportGeojsonSectionsReportCommand } from '../../../../core/sessions/commands/ExportGeojsonSectionReportCommand';

interface SessionMenuItem {
    action: unknown;
    hidden?: boolean;
    icon: ReactNode;
    title: string;
}

interface SessionMenuItemsProps {
    aiAnalysisAllowed: boolean;
    calibrated: boolean;
    onItemClick: (action: unknown, event?: MouseEvent) => void;
    sessionId: SessionId;
}

const SessionMenuItems: FC<SessionMenuItemsProps> = ({ aiAnalysisAllowed, calibrated, onItemClick, sessionId }) => {
    const { t } = useTranslation();

    const menuItems: SessionMenuItem[] = useMemo(
        () =>
            [
                {
                    action: setModalToDisplay(SessionsModalTypes.SESSIONS_CALIBRATE_SESSION),
                    icon: <CalibrationIcon size="small" />,
                    title: t(calibrated ? 'labels.recalibrate' : 'labels.calibrate'),
                },
                {
                    action: StartAiAnalysisWorkflowOnSessionsCommand([sessionId]),
                    icon: <AnalysisIcon size="small" />,
                    title: t('labels.ai_analyze'),
                    hidden: !(calibrated && aiAnalysisAllowed),
                },
                {
                    action: setModalToDisplay(SessionsModalTypes.SESSIONS_EDIT_CAMPAIGN),
                    icon: <EditIcon size="small" />,
                    title: t('sessions.modals.editCampaign'),
                },
                {
                    action: setModalToDisplay(SessionsModalTypes.COMPUTE_ZONES),
                    icon: <CalibrationIcon size="small" />,
                    title: t('session.compute_zones'),
                    hidden: !calibrated,
                },
                {
                    action: ExportSessionsRoadDatasCommand({ sessionIds: [sessionId] }),
                    icon: <ExportIcon size="small" />,
                    title: t('session.export.road_datas'),
                },
                {
                    action: ExportSessionsReportCommand({ sessionIds: [sessionId] }),
                    icon: <ExportIcon size="small" />,
                    title: t('session.export.report'),
                },
                {
                    action: ExportSectionsReportCommand({ sessionIds: [sessionId] }),
                    icon: <ExportIcon size="small" />,
                    title: t('session.export.sectionsCsv'),
                },
                {
                    action: ExportGeojsonSectionsReportCommand([sessionId]),
                    icon: <ExportIcon size="small" />,
                    title: t('session.export.sectionsGeojson'),
                },
            ].filter(({ hidden = false }) => !hidden),
        [calibrated, sessionId],
    );

    return (
        <MenuList>
            {menuItems.map(({ action, icon, title }) => (
                <MenuItem
                    key={title}
                    onClick={(ev) => onItemClick(action, ev)}
                    sx={{ alignItems: 'center', display: 'flex', gap: 1.25 }}
                >
                    {icon}
                    <BaseMenuItemText>{title}</BaseMenuItemText>
                </MenuItem>
            ))}
        </MenuList>
    );
};

export default SessionMenuItems;
