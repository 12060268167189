export interface Config {
    googleAnalyticsTag: string | undefined;
    sentryDsn: string | undefined;
    sentryEnvironment: string | undefined;
    mapboxToken: string | undefined;
    mapboxPrTilesetId: string | undefined;
    mapboxPrLayer: string | undefined;
    displayPrLayer: boolean | undefined;
}

export const loadConfigFromRootDataset = (): Config => {
    const root = document.getElementById('root');

    if (root === null) {
        throw new Error(`"root" element not found in the DOM.`);
    }

    return {
        googleAnalyticsTag: root.dataset.googleAnalyticsTag,
        sentryDsn: root.dataset.sentryDsn,
        sentryEnvironment: root.dataset.sentryEnvironment,
        mapboxToken: root.dataset.mapboxToken,
        mapboxPrTilesetId: root.dataset.mapboxPrTilesetId,
        mapboxPrLayer: root.dataset.mapboxPrLayer,
        displayPrLayer: root.dataset.displayPrLayer === 'true',
    };
};
