import PropTypes from 'prop-types';
import { FC, useEffect, useState } from 'react';
import { Container, Viewport, Cross } from './styles';

function processCrossShift(lensPositionWithAllConstraints, unboundedLensPosition, lensSize) {
    const crossShiftUnbounded = {
        x: lensPositionWithAllConstraints.left - unboundedLensPosition.left,
        y: lensPositionWithAllConstraints.top - unboundedLensPosition.top,
    };
    const crossShiftWithMinConstraint = {
        x: crossShiftUnbounded.x < -lensSize.x / 2 ? -lensSize.x / 2 : crossShiftUnbounded.x,
        y: crossShiftUnbounded.y < -lensSize.y / 2 ? -lensSize.y / 2 : crossShiftUnbounded.y,
    };
    const crossShiftWithAllConstraints = {
        x: crossShiftWithMinConstraint.x > lensSize.x / 2 ? lensSize.x / 2 : crossShiftWithMinConstraint.x,
        y: crossShiftWithMinConstraint.y > lensSize.y / 2 ? lensSize.y / 2 : crossShiftWithMinConstraint.y,
    };

    return crossShiftWithAllConstraints;
}

function processLensPosition(event, imgNode: HTMLImageElement, lensSize: Size, zoomFactor: number, imageSize: Size) {
    const imagePosition = imgNode.getBoundingClientRect();
    const cursorPosition = {
        x: event.pageX,
        y: event.pageY,
    };
    const cursorPositionRelativeToImage = {
        x: (cursorPosition.x - imagePosition.left - window.pageXOffset) * zoomFactor,
        y: (cursorPosition.y - imagePosition.top - window.pageYOffset) * zoomFactor,
    };
    const minLensPosition = {
        left: 0,
        top: 0,
    };
    const maxLensPosition = {
        left: imageSize.width - lensSize.width,
        top: imageSize.height - lensSize.height,
    };
    const unboundedLensPosition = {
        left: cursorPositionRelativeToImage.x - lensSize.width / 2,
        top: cursorPositionRelativeToImage.y - lensSize.height / 2,
    };
    const lensPositionWithMinConstraint = {
        left: unboundedLensPosition.left < minLensPosition.left ? minLensPosition.left : unboundedLensPosition.left,
        top: unboundedLensPosition.top < minLensPosition.top ? minLensPosition.top : unboundedLensPosition.top,
    };
    const lensPositionWithAllConstraints = {
        left:
            lensPositionWithMinConstraint.left > maxLensPosition.left
                ? maxLensPosition.left
                : lensPositionWithMinConstraint.left,
        top:
            lensPositionWithMinConstraint.top > maxLensPosition.top
                ? maxLensPosition.top
                : lensPositionWithMinConstraint.top,
    };

    return {
        lensPosition: lensPositionWithAllConstraints,
        crossShift: processCrossShift(lensPositionWithAllConstraints, unboundedLensPosition, lensSize),
    };
}

const CrossWithBorder = ({
    shiftX,
    shiftY,
    length,
    thickness,
    borderThickness,
    containerHeight,
    containerWidth,
    containerBorderWidth,
}) => {
    return (
        <>
            <Cross
                backgroundColor="white"
                shiftX={shiftX}
                shiftY={shiftY}
                length={length + borderThickness}
                thickness={thickness + borderThickness}
                containerHeight={containerHeight}
                containerWidth={containerWidth}
                containerBorderWidth={containerBorderWidth}
            />
            <Cross
                backgroundColor="black"
                shiftX={shiftX}
                shiftY={shiftY}
                length={length}
                thickness={thickness}
                containerHeight={containerHeight}
                containerWidth={containerWidth}
                containerBorderWidth={containerBorderWidth}
            />
        </>
    );
};

CrossWithBorder.propTypes = {
    shiftX: PropTypes.number.isRequired,
    shiftY: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    thickness: PropTypes.number.isRequired,
    borderThickness: PropTypes.number.isRequired,
    containerHeight: PropTypes.number.isRequired,
    containerWidth: PropTypes.number.isRequired,
    containerBorderWidth: PropTypes.number.isRequired,
};

type Size = {
    width: number;
    height: number;
};

type ZoomViewportProps = {
    imageUrl?: string | null;
    imgNode: HTMLImageElement;
    nodeToListen?: ParentNode | null;
    zoomFactor: number;
    lensSize?: Size;
    borderWidthSize?: number;
};

const ZoomViewport: FC<ZoomViewportProps> = ({
    imageUrl,
    imgNode,
    nodeToListen,
    zoomFactor,
    lensSize,
    borderWidthSize,
}) => {
    const imageSize: Size = {
        width: imgNode.offsetWidth * zoomFactor,
        height: imgNode.offsetHeight * zoomFactor,
    };
    const lens = lensSize || {
        width: 220,
        height: 220,
    };
    const borderWidth = borderWidthSize || 3;
    const [state, setState] = useState({
        lensPosition: {
            left: imageSize.width / 2,
            top: imageSize.height / 2,
        },
        crossShift: {
            x: 0,
            y: 0,
        },
    });
    useEffect(() => {
        const mouseMoveListener = (event) => {
            setState(processLensPosition(event, imgNode, lens, zoomFactor, imageSize));
        };
        nodeToListen?.addEventListener('mousemove', mouseMoveListener);

        return () => nodeToListen?.removeEventListener('mousemove', mouseMoveListener);
    }, []);

    return (
        <Container height={lens.height} width={lens.width} borderWidth={borderWidth}>
            <Viewport
                left={state.lensPosition.left}
                top={state.lensPosition.top}
                imageHeight={imageSize.height}
                imageWidth={imageSize.width}
                imageUrl={imageUrl}
            />
            <CrossWithBorder
                shiftX={state.crossShift.x}
                shiftY={state.crossShift.y}
                length={20}
                thickness={2}
                borderThickness={2}
                containerHeight={lens.height}
                containerWidth={lens.width}
                containerBorderWidth={borderWidth}
            />
        </Container>
    );
};

export default ZoomViewport;
