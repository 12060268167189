import { useEffect } from 'react';
import CalibrationPointsPanel from './CalibrationPointsPanel';
import { BlueContainer } from '../ui/road-view/styles';
import { useSelector } from 'react-redux';
import {
    calibrationSessionSelector,
    displayCalibrationWelcomePanelSelector,
} from '../../../../core/legacy/selectors/calibration';
import { initCalibrationProcess } from '../../../../core/legacy/actions';
import { currentImageSelector } from '../../../../core/legacy/selectors/streetView';
import GenerateCalibrationFailureModal from './CalibrationPointsPanel/GenerateCalibrationFailureModal';
import WelcomePanel from './WelcomePanel';
import RoadViewer from './RoadViewer';
import { NavigateToSessionsCommand } from '../../../../core/sessions/commands/NavigateToSessionsCommand';
import { useAppDispatch } from '../../../../types';
import ManualCalibrationNavigation from './ManualCalibrationNavigation';
import { LeftPanel as RoadViewPanel } from '../../../roadview';
import CurrentPositionInSession from '../../../components/streetView/CurrentPositionInSession';
import { Box } from '@mui/material';
import { MediumTextBold } from '../../../design_system/texts';
import { theme } from '../../style/theme';

const ManualCalibration = () => {
    const dispatch = useAppDispatch();
    const currentImage = useSelector(currentImageSelector);
    const calibrationSession = useSelector(calibrationSessionSelector);

    useEffect(() => {
        if (currentImage) {
            dispatch(initCalibrationProcess(currentImage.id));
        } else {
            dispatch(NavigateToSessionsCommand());
        }
    }, [currentImage]);

    const displayCalibrationWelComePanel = useSelector(displayCalibrationWelcomePanelSelector);

    if (calibrationSession?.id === undefined) {
        return null;
    }

    const RightPanel = displayCalibrationWelComePanel ? WelcomePanel : CalibrationPointsPanel;

    return (
        <BlueContainer data-test-id="calibration-view">
            <RoadViewPanel>
                <RoadViewer>
                    <ManualCalibrationNavigation
                        sessionId={calibrationSession.id}
                        cumul={currentImage?.cumulStartSession ?? 0}
                    />
                </RoadViewer>
            </RoadViewPanel>
            <RightPanel>
                <Box sx={{ px: 2, pt: 0.5, textAlign: 'center', bgcolor: theme.color.blue.light }}>
                    <MediumTextBold>{calibrationSession.name}</MediumTextBold>
                    <CurrentPositionInSession session={calibrationSession} />
                </Box>
            </RightPanel>
            <GenerateCalibrationFailureModal />
        </BlueContainer>
    );
};

export default ManualCalibration;
