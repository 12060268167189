import { Stack, StackProps, styled } from '@mui/material';

export const Container = styled(Stack)<StackProps>(({ theme }) => ({
    backgroundColor: theme.color.blue.light,
    height: '100vh',
    width: '100vw',
    padding: 0,
    zIndex: 10,
    position: 'relative',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
}));

export const MapPanel = styled(Stack)<StackProps>(({ theme }) => ({
    justifyContent: 'space-between',
    flex: 1,
    padding: 0,
    overflow: 'hidden',
    alignSelf: 'stretch',
    minHeight: '100%',
    position: 'relative',
    backgroundColor: theme.color.white.main,
}));

export const Summary = styled(Stack)<StackProps>(() => ({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    flexGrow: 1,
    padding: '0 16px',
}));
