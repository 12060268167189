import { networkMapReducer } from './core/networkMap/state';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import modalReducer from './core/legacy/reducers/modals';
import streetViewReducer from './core/legacy/reducers/streetView';
import annotationReducer from './core/legacy/reducers/annotation';
import calibrationReducer from './core/legacy/reducers/calibration';
import flashbagReducer from './core/legacy/reducers/flashbag';
import { aiAnalysisReducer } from './core/automatic_analysis/state';
import type { Providers } from './core/utils';
import { authenticationReducer, loggedOut } from './core/authentication/state';
import { createBrowserHistory, History } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { sessionsReducer } from './core/sessions/state';
import { loaderReducer } from './core/loader/state';
import { uploadsReducer } from './core/uploads/state';
import { filterReducer } from './core/filters/state';
import { catalogsReducer } from './core/catalogs/state';
import { streetViewerReducer } from './core/streetViewer/state';
import { tagsReducer } from './core/tags/state';
import { organizationReducer } from './core/organization/state';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const combinedReducer = combineReducers({
    router: routerReducer,
    form: formReducer,
    loader: loaderReducer,
    flashbag: flashbagReducer,
    modals: modalReducer,
    annotation: annotationReducer,
    filters: filterReducer,
    calibration: calibrationReducer,
    uploads: uploadsReducer,
    authentication: authenticationReducer,
    aiAnalysis: aiAnalysisReducer,
    sessions: sessionsReducer,
    catalogs: catalogsReducer,
    streetView: streetViewReducer, // legacy
    streetViewer: streetViewerReducer, // new
    networkMap: networkMapReducer,
    tags: tagsReducer,
    organization: organizationReducer,
});

const reducer: typeof combinedReducer = (state, action) => {
    if (action.type === loggedOut.type) {
        return combinedReducer(undefined, action);
    }

    return combinedReducer(state, action);
};

export const createStore = (providers: Providers) => {
    const store = configureStore({
        reducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: providers,
                },
                immutableCheck: false, // FIXME manu: if 'true', break redux form (CalibrateSessionForm)
                serializableCheck: false, // FIXME manu: if 'true', spawn a lot of warning about a 'Map' in state
            }).concat(routerMiddleware),
    });
    const history = createReduxHistory(store);

    return {
        store,
        history,
    };
};

export type AppHistory = History & {
    listenObject: boolean;
};
export type RootState = ReturnType<typeof reducer>;
