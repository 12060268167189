import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateToSessionStreetviewCommand } from '../../../../../core/sessions/commands/NavigateToSessionStreetviewCommand';
import { useAppDispatch } from '../../../../../types';
import { PopupButton } from '../styles';
import type { SessionId } from '../../../../../core/domain/Session';

type Props = {
    sessionId: SessionId;
    cumulStartSession: number;
};

const ButtonGoToSession: FC<Props> = ({ sessionId, cumulStartSession }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const handleClickGoToSession = async () => {
        await dispatch(
            NavigateToSessionStreetviewCommand({
                sessionId,
                cumul: cumulStartSession,
            }),
        );
    };

    return (
        <PopupButton highlight onClick={handleClickGoToSession}>
            {t('networkMap.imagePopup.goToStreetview')}
        </PopupButton>
    );
};

export default ButtonGoToSession;
