import { Box, Divider, Stack } from '@mui/material';
import { forwardRef } from 'react';
import { theme } from '../../../style/theme';

type ProgressCursorProps = {
    left: number;
    top: number;
    height: number;
};

export const ProgressCursor = forwardRef<HTMLDivElement, ProgressCursorProps>(({ left, top, height }, ref) => {
    return (
        <Stack
            alignItems="center"
            ref={ref}
            fontSize={theme.font.size.small}
            sx={{
                left,
                top,
                position: 'absolute',
                height,
            }}
        >
            <Box position="absolute" top={-20}>
                &#x25BC;
            </Box>
            <Divider
                orientation="vertical"
                sx={{
                    borderColor: 'black',
                    borderStyle: 'dashed',
                    marginTop: '10px',
                    flex: '1 1 0',
                    borderRightWidth: '2px',
                }}
            />
        </Stack>
    );
});
