import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    calibrationPointInEditionSelector,
    selectedCalibrationPointSelector,
} from '../../../../../core/legacy/selectors/calibration';
import CalibrationAttribute from './CalibrationAttribute';
import {
    deleteCalibrationPoint,
    noMoreCalibrationPointInEdition,
    selectCalibrationPointInEdition,
} from '../../../../../core/legacy/actions';
import { useTranslation } from 'react-i18next';
import { Attributes, ElementLayout as LayoutClassName, Value } from '../../ui/panels/right-panel/styles';
import { Text } from '../../../style';
import SelectedElementDetails from '../../ui/panels/right-panel/SelectedElementDetails';
import type { AnyAction } from 'redux';

const SelectedCalibrationPointDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedCalibrationPoint = useSelector(selectedCalibrationPointSelector);
    const calibrationPointInEdition = useSelector(calibrationPointInEditionSelector);
    const handleEdit = useCallback((calibrationPointId) => {
        return !calibrationPointInEdition
            ? dispatch(selectCalibrationPointInEdition(calibrationPointId))
            : dispatch(noMoreCalibrationPointInEdition());
    }, []);
    const handleDelete = (calibrationPointId) =>
        dispatch((deleteCalibrationPoint(calibrationPointId) as unknown) as AnyAction);

    if (!selectedCalibrationPoint) {
        return null;
    }

    const { attributes } = selectedCalibrationPoint;

    return (
        <SelectedElementDetails
            title={t('calibration.informations')}
            selected={!!calibrationPointInEdition}
            onEdit={() => handleEdit(selectedCalibrationPoint.id)}
            onDelete={() => handleDelete(selectedCalibrationPoint.id)}
        >
            <Attributes>
                <LayoutClassName>
                    <Text>{t('calibration.class')}</Text>
                    <Value>{t('calibration.calibrationPoint')}</Value>
                </LayoutClassName>
                {attributes.map((attribute) => {
                    return (
                        <CalibrationAttribute
                            key={attribute.name}
                            calibrationPoint={selectedCalibrationPoint}
                            attribute={attribute}
                        />
                    );
                })}
            </Attributes>
        </SelectedElementDetails>
    );
};

export default SelectedCalibrationPointDetails;
