import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { StyledModal, ButtonsContainer, CloseContainer, Close, CancelButton, ValidateButton } from '../styles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { modalToDisplaySelector } from '../../../../../../core/legacy/selectors/dashboard';
import { unsetModalToDisplay } from '../../../../../../core/legacy/actions/modals';
import type { SessionsModalTypes } from '../../../../../../core/legacy/reducers/modals';
import type { GenericModalOptions } from '../../../../../components/propTypes';

const useDisableButtons = (isCancelDisable, isValidateDisable) => {
    const [disableCancel, setDisableCancel] = useState(isCancelDisable ?? false);
    const [disableValidate, setDisableValidate] = useState(isValidateDisable ?? false);

    useEffect(() => {
        if (isCancelDisable !== disableCancel) {
            setDisableCancel(isCancelDisable);
        }
    }, [isCancelDisable]);

    useEffect(() => {
        if (isValidateDisable !== disableValidate) {
            setDisableValidate(isValidateDisable);
        }
    }, [isValidateDisable]);

    return { disableCancel, disableValidate };
};

const DEFAULT_OPTIONS: GenericModalOptions = {
    hideDefaultButtons: false,
    hideCancelButton: false,
    hideValidateButton: false,
    isDangerous: false,
    notDismissable: false,
    centerButtons: false,
    backgroundColor: '',
};

export interface GenericModalProps extends PropsWithChildren {
    cancelButtonText?: string;
    isCancelDisable?: boolean;
    isValidateDisable?: boolean;
    onCancel?: Function;
    onValidate?: Function;
    modalType: keyof typeof SessionsModalTypes;
    options?: GenericModalOptions;
    validateButtonText?: string;
}

const GenericModal: FC<GenericModalProps> = ({
    cancelButtonText,
    children,
    isCancelDisable,
    isValidateDisable,
    onCancel,
    onValidate,
    modalType,
    options = DEFAULT_OPTIONS,
    validateButtonText,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { disableCancel, disableValidate } = useDisableButtons(isCancelDisable, isValidateDisable);
    const {
        hideDefaultButtons,
        hideCancelButton,
        hideValidateButton,
        isDangerous,
        notDismissable,
        centerButtons,
        backgroundColor,
        notDismissableByClickingOnBackground,
    } = options;
    const modalToDisplay = useSelector(modalToDisplaySelector);

    const openModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
        dispatch(unsetModalToDisplay());
    };

    const hideIfDismissable = () => {
        if (!notDismissable) {
            hideModal();
        }
    };

    const cancelModal = () => {
        hideIfDismissable();
        if (onCancel) {
            onCancel();
        }
    };

    const cancelModalByBackgroundClick = () => {
        if (notDismissableByClickingOnBackground) {
            return;
        }
        hideIfDismissable();
        if (onCancel) {
            onCancel();
        }
    };

    const saveModal = () => {
        hideModal();
        if (onValidate) {
            onValidate();
        }
    };

    useEffect(() => {
        if (modalType === modalToDisplay) {
            openModal();
        } else {
            setIsOpen(false);
        }
    }, [modalToDisplay]);

    return (
        <StyledModal
            isOpen={isOpen}
            onEscapeKeydown={cancelModal}
            onBackgroundClick={cancelModalByBackgroundClick}
            backgroundColor={backgroundColor}
        >
            {!notDismissable && (
                <CloseContainer>
                    <Close onClick={cancelModal} />
                </CloseContainer>
            )}
            {children}
            {!hideDefaultButtons && (
                <ButtonsContainer centerButtons={centerButtons}>
                    {!hideCancelButton && (
                        <CancelButton disabled={disableCancel} onClick={cancelModal} type="button">
                            {cancelButtonText ?? t('forms.cancel')}
                        </CancelButton>
                    )}
                    {!hideValidateButton && (
                        <ValidateButton
                            isDangerous={isDangerous}
                            disabled={disableValidate}
                            onClick={saveModal}
                            type="button"
                        >
                            {validateButtonText ?? t('forms.save')}
                        </ValidateButton>
                    )}
                </ButtonsContainer>
            )}
        </StyledModal>
    );
};

export default GenericModal;
