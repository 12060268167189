import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import type { Landmark } from '../domain/Roaddata';
import type { Session } from '../domain/Session';
import type { CurrentImageCumul } from './state';

const slice = (state: RootState) => state.streetViewer;

export const currentImageIndexQuery = createSelector(slice, (state): number => state.currentImageIdx);
export const currentImageCumulQuery = createSelector(
    slice,
    (state): CurrentImageCumul | null => state.currentImageCumul,
);

// SESSION
export const sessionQuery = createSelector(slice, (state): Session | null => state.session);
export const sessionIdQuery = createSelector(slice, (state): string | undefined => state.session?.id);
export const sessionCalibrationQuery = createSelector(slice, (state): any | undefined => state.session?.calibration);

export const isMovingSessionQuery = (state) => {
    const session = sessionQuery(state);
    if (!session) {
        return false;
    }

    return session?.metadata?.cumulativeEnd > 0;
};

// IMAGE
export const currentLandmarkQuery = createSelector(slice, (state): Landmark => state.currentLandmark);
