import { organizationInitialized } from '../organization/state';
import { createCommand } from '../utils';
import { logFailed, loggedIn, logOngoing, moduleName } from './state';

export const LoadCurrentUserCommand = createCommand(
    `${moduleName}/LOAD_CURRENT_USER`,
    async (_, { dispatch, extra }) => {
        dispatch(logOngoing());
        const user = await extra.externalAuthentication.loadCurrentUser();

        if (user !== undefined) {
            const organization = await extra.externalOrganization.loadOrganization(user.organization.id);
            dispatch(loggedIn(user));
            dispatch(organizationInitialized(organization));
        } else {
            dispatch(logFailed(''));
        }
    },
);
