import { ReactComponent } from '../../../assets/icons/options.svg';
import type { FC } from 'react';
import { BaseIcon, IconProps } from './BaseIcon';

export const OptionsIcon: FC<IconProps> = (props) => {
    return (
        <BaseIcon {...props}>
            <ReactComponent />
        </BaseIcon>
    );
};
