import { createCommand } from '../../utils';
import { selectedMapStyle, moduleName } from '../state';
import { userOrganizationQuery } from '../../authentication/queries';

export const SetMapStyleFromPersistenceCommand = createCommand(
    `${moduleName}/SET_MAP_STYLE_FROM_PERSISTANCE`,
    async (_, { dispatch, extra, getState }) => {
        const userOrganization = userOrganizationQuery(getState());

        if (userOrganization) {
            const organizationSettings = await extra.mapViewSettings.getSettingsByOrganizationId(userOrganization.id);
            if (organizationSettings?.settings?.mapStyle) {
                dispatch(selectedMapStyle(organizationSettings.settings.mapStyle));
            }
        }
    },
);
