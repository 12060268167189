import { useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../styles';
import GenericModal from '../../../../legacy/components/ui/modals/GenericModal';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';
import { ContentContainer, ModalTitle } from '../../../../legacy/components/ui/modals/styles';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { selectedSessionsQuery, targettedSessionIdsForInteractionQuery } from '../../../../../core/sessions/queries';
import editSessions from '../../../../../core/sessions/commands/EditSessions';
import { TargetSessionsForInteractionCommand } from '../../../../../core/sessions/commands/TargetSessionsForInteractionCommand';

const EditSessionCampaignModal: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const checkedSessions = useAppSelector(selectedSessionsQuery);
    const checkedSessionIds = checkedSessions.map((s) => s.id);
    const targetSession = useAppSelector(targettedSessionIdsForInteractionQuery);

    // if one session is focused only modify this one, not checked ones
    const sessionIds = targetSession?.length ? targetSession : checkedSessionIds;

    const [campaignName, setCampaignName] = useState('');
    const isNewNameValid = campaignName.length > 0;

    useEffect(() => {
        const campagneNames = checkedSessions
            .map((s) => s.metadata.campaignName)
            .filter((n) => n !== undefined)
            .reduce((acc, val) => acc.add(val!), new Set<string>());
        if (campagneNames.size === 1) {
            setCampaignName(campagneNames.values().next().value as string);
        } else {
            setCampaignName('');
        }
    }, [checkedSessions]);

    const onValidate = () => {
        dispatch(
            editSessions({
                sessionIds,
                edit: {
                    metadata: { campaignName },
                },
            }),
        );
        setCampaignName('');
        // reset focused session on close
        dispatch(TargetSessionsForInteractionCommand([]));
    };

    const handleKeyDown = ({ key }: KeyboardEvent) => {
        if (isNewNameValid && key === 'Enter') {
            onValidate();
        }
    };

    return (
        <GenericModal
            isValidateDisable={!isNewNameValid}
            onValidate={onValidate}
            modalType={SessionsModalTypes.SESSIONS_EDIT_CAMPAIGN}
            options={{ notDismissableByClickingOnBackground: true }}
        >
            <ContentContainer>
                <ModalTitle>{t('sessions.modals.editCampaign')}</ModalTitle>
                <Input
                    onKeyDown={handleKeyDown}
                    type="text"
                    value={campaignName}
                    onChange={({ target }) => setCampaignName(target.value)}
                    autoFocus
                />
            </ContentContainer>
        </GenericModal>
    );
};

export default EditSessionCampaignModal;
