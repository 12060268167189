import {
    goToImageByRoadData,
    selectHoveredRoadData,
    noMoreHoveredRoadData,
    unselectRoadDataAndSave,
    selectAnnotationInEditionAndJump,
} from '../../../../../../core/legacy/actions';
import { FC, useCallback } from 'react';
import {
    selectedRoadDataIdSelector,
    hoveredRoadDataIdSelector,
} from '../../../../../../core/legacy/selectors/streetView';
import AttributeTypeEnum from '../../../../../../core/legacy/utils/AttributeTypeEnum';
import { findAttributeValuesByType } from '../../../../../../core/legacy/utils/roadDataUtils';
import { ElementData as AnnotationData, StyledBlock } from '../../../ui/panels/right-panel/ElementSummary/styles';
import LandmarkValue from '../../../../../components/streetView/roadDatasPanel/values/LandmarkValue';
import useScroll from './use-scroll';
import { useAppDispatch, useAppSelector } from '../../../../../../types';
import type { RoadData } from '../../../../../../core/domain/Roaddata';
import { Box, SxProps, Typography } from '@mui/material';
import { resolveRoadDataName } from '../../../../../../utils/resolve-road-data-name';
import { resolveRoadDataColor } from '../../../../../../utils/resolve-road-data-color';
import { SmallChip } from '../../../../../components/streetView/roadDatasPanel/common';
import { theme } from '../../../../style/theme';
import { translateTags } from '../../../../../../core/legacy/utils/translations';

const detailsSx: SxProps = {
    display: 'flex',
    gap: 2,
    justifyContent: 'end',
};

const mainSx = (roadDataColor?: string): SxProps => ({
    alignItems: 'flex-start',
    borderLeft: 3.5,
    borderColor: roadDataColor,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'bold',
    justifyContent: 'center',
    pl: 2,
});

const titleSx: SxProps = {
    color: theme.color.black.main,
    fontSize: theme.font.size.small,
    fontWeight: 'bold',
};

type Props = {
    roadData: RoadData;
    summaryOffsetHeight: number;
};

const RoadDataSummary: FC<Props> = ({ roadData, summaryOffsetHeight }) => {
    const dispatch = useAppDispatch();
    const roadDataName = resolveRoadDataName(roadData);
    const roadDataColor = resolveRoadDataColor(roadData);
    const selectedRoadDataId = useAppSelector(selectedRoadDataIdSelector);
    const hoveredRoadDataId = useAppSelector(hoveredRoadDataIdSelector);
    const { id, landmarkStart, tags } = roadData;

    const handleSelect = useCallback(async () => dispatch(goToImageByRoadData(id)), [id]);
    const handleUnselect = useCallback(async () => dispatch(unselectRoadDataAndSave(id)), [id]);

    const handleMouseOver = (): void => {
        dispatch(selectHoveredRoadData(id));
    };
    const handleMouseLeave = (): void => {
        dispatch(noMoreHoveredRoadData());
    };
    const handleDoubleClick = (): void => {
        dispatch(selectAnnotationInEditionAndJump(id));
    };

    const roadDataCumulStart = findAttributeValuesByType(roadData, AttributeTypeEnum.CUMUL_START).value.numeric;
    const { unit } = findAttributeValuesByType(roadData, AttributeTypeEnum.CUMUL_START).attribute;
    const selected = selectedRoadDataId === id;
    const hovered = hoveredRoadDataId === id && !selected;
    const rubricElementRef = useScroll(id, summaryOffsetHeight);

    return (
        <StyledBlock
            onHover={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            selected={selected}
            hovered={hovered}
            onSelect={handleSelect}
            onUnselect={handleUnselect}
            onDoubleClick={handleDoubleClick}
            ref={rubricElementRef}
        >
            <Box sx={mainSx(roadDataColor)}>
                <Typography sx={titleSx}>{roadDataName}</Typography>
                {tags?.length ? <SmallChip label={translateTags('taggedRoadData')} /> : null}
            </Box>
            <Box sx={detailsSx}>
                <AnnotationData>{`${+roadDataCumulStart}${unit}`}</AnnotationData>
                <AnnotationData>{roadData.landmarkStart && <LandmarkValue landmark={landmarkStart} />}</AnnotationData>
            </Box>
        </StyledBlock>
    );
};

export default RoadDataSummary;
