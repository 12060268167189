import { MenuItem, Popover, Button, styled } from '@mui/material';
import { theme } from '../../../style/theme';

export const StyledMenu = styled((props) => (
    // eslint-disable-next-line react/jsx-filename-extension
    <Popover
        style={{
            top: '20px',
        }}
        {...props}
    />
))(() => ({
    '& .MuiPopover-paper': {
        padding: '10px',
    },
    '& .MuiPaper-rounded': {
        borderRadius: '8px',
    },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
    '& svg': {
        position: 'static',
        width: '16px',
        height: '16px',
        right: '104px',
        flex: 'none',
        order: '0',
        flexrow: '0',
        margin: ' 0px 8px',
    },
    '&.MuiMenuItem-root': {
        fontFamily: 'Hind Siliguri',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
    },
    '&.MuiListItem-button:hover': {
        backgroundColor: theme.color.blue.light,
    },
}));

export const StyledButton = styled(Button)(() => ({
    '&.MuiButton-text': {
        padding: '8px 5px',
    },
    '&.MuiButton-root': {
        minWidth: 'unset',
    },
}));
