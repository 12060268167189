import styled from 'styled-components';
import { useEffect, forwardRef, useImperativeHandle, useState, FC, ReactNode, ForwardedRef } from 'react';
import { PrimaryButton as PrimaryButtonBase } from '../../../../style';
import { theme } from '../../../../style/theme';
import { StyledModal, SubmitButton, ButtonsContainer, CloseContainer, ContentContainer } from './styles';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';

const CancelButton = styled(SubmitButton)`
    width: auto !important;
    padding: 0 16px;
    background-color: ${theme.color.blue.tint2} !important;
    color: ${theme.color.black.main};
    &:hover {
        background-color: ${theme.color.blue.tint3} !important;
    }
`;

const ValidateButton = styled(PrimaryButtonBase)`
    margin: 0 0 0 8px;
    padding: 0 16px;
    width: auto !important;
    &:hover {
        background-color: ${theme.color.blue.primary2} !important;
    }
`;

const Close = styled(CloseIcon)`
    cursor: pointer;
    height: 13px;
`;

const useDisableButtons = (isCancelDisable, isValidateDisable) => {
    const [disableCancel, setDisableCancel] = useState(isCancelDisable ?? false);
    const [disableValidate, setDisableValidate] = useState(isValidateDisable ?? false);

    useEffect(() => {
        if (isCancelDisable !== disableCancel) {
            setDisableCancel(isCancelDisable);
        }
    }, [isCancelDisable]);

    useEffect(() => {
        if (isValidateDisable !== disableValidate) {
            setDisableValidate(isValidateDisable);
        }
    }, [isValidateDisable]);

    return { disableCancel, disableValidate };
};

const BaseModal: FC<{
    children?: ReactNode;
    onCancel?: () => void;
    onValidate?: () => void;
    isCancelDisable?: boolean;
    isValidateDisable?: boolean;
    validateButtonText: string;
    cancelButtonText: string;
    hideCancelButton?: boolean;
    ref: ForwardedRef<any>;
}> = forwardRef(
    (
        {
            children,
            onCancel,
            onValidate,
            isCancelDisable,
            isValidateDisable,
            validateButtonText,
            cancelButtonText,
            hideCancelButton,
        },
        ref: ForwardedRef<any>,
    ) => {
        const [isOpen, setIsOpen] = useState(false);
        const { disableCancel, disableValidate } = useDisableButtons(isCancelDisable, isValidateDisable);

        const openModal = () => {
            setIsOpen(true);
        };

        const hideModal = () => {
            setIsOpen(false);
        };

        const cancelModal = () => {
            hideModal();
            if (onCancel) {
                onCancel();
            }
        };

        const saveModal = () => {
            hideModal();
            if (onValidate) {
                onValidate();
            }
        };

        useImperativeHandle(ref, () => {
            return { openModal, hideModal };
        });

        return (
            <StyledModal isOpen={isOpen} onEscapeKeydown={() => hideModal()} onBackgroundClick={() => hideModal()}>
                <CloseContainer>
                    <Close onClick={() => cancelModal()} />
                </CloseContainer>
                <ContentContainer>{children}</ContentContainer>
                <ButtonsContainer>
                    {!hideCancelButton && (
                        <CancelButton disabled={disableCancel} onClick={() => cancelModal()} type="button">
                            {cancelButtonText}
                        </CancelButton>
                    )}
                    <ValidateButton disabled={disableValidate} onClick={() => saveModal()} type="button">
                        {validateButtonText}
                    </ValidateButton>
                </ButtonsContainer>
            </StyledModal>
        );
    },
);

export default BaseModal;
