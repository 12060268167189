import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type React from 'react';
import type { ReactElement } from 'react';
import { LoadCurrentUserCommand } from '../core/authentication/LoadCurrentUserCommand';
import { useAppDispatch } from '../types';
import { logFailedQuery, loggedInQuery, loggedOutQuery, logOngoingQuery } from '../core/authentication/queries';

type Props = {
    children: ReactElement<any, any>;
};

export const ProtectedRoute: React.FC<Props> = ({ children }) => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(loggedInQuery);
    const isNotAuthenticated = useSelector(loggedOutQuery);
    const isAuthenticationOngoing = useSelector(logOngoingQuery);
    const isAuthenticationFailed = useSelector(logFailedQuery);

    if (isAuthenticationOngoing) {
        return null;
    }
    if (isAuthenticated) {
        return children ?? null;
    }
    if (isNotAuthenticated) {
        dispatch(LoadCurrentUserCommand());

        return null;
    }
    if (isAuthenticationFailed) {
        return <Navigate to="/" />;
    }

    return <Navigate to="/" />;
};
