import type { SessionId } from '../domain/Session';
import { setModalToDisplay } from '../legacy/actions/modals';
import { SessionsModalTypes } from '../legacy/reducers/modals';
import { createCommand } from '../utils';
import { /* analysisStarted,  */ moduleName, sessionIdsForAnalysesChosen } from './state';

export const StartAiAnalysisWorkflowOnSessionsCommand = createCommand(
    `${moduleName}/START_AI_ANALYSIS_WORKFLOW_ON_SESSIONS_COMMAND`,
    async (sessionIds: Array<SessionId>, { dispatch /* , extra */ }) => {
        await dispatch(sessionIdsForAnalysesChosen(sessionIds));
        await dispatch((setModalToDisplay(SessionsModalTypes.AI_ANALYSIS) as unknown) as any); // FIXME manu: remove unknown and any
    },
);
