export const ModalActions = Object.freeze({
    SET_MODAL_TO_DISPLAY: 'SET_MODAL_TO_DISPLAY',
});

export const SessionsModalTypes = Object.freeze({
    MANUAL_CALIBRATION_FAILURE: 'MANUAL_CALIBRATION_FAILURE',
    SESSIONS_EDIT_NAME: 'SESSIONS_EDIT_NAME',
    SESSIONS_EDIT_CAMPAIGN: 'SESSIONS_EDIT_CAMPAIGN',
    SESSIONS_EDIT_STATE: 'SESSIONS_EDIT_STATE',
    SESSIONS_EDIT_FAILURE: 'SESSIONS_EDIT_FAILURE',
    SESSIONS_UPLOAD_SESSIONS: 'SESSIONS_UPLOAD_SESSIONS',
    SESSIONS_UPLOAD_IN_PROGRESS: 'SESSIONS_UPLOAD_IN_PROGRESS',
    SESSIONS_UPLOAD_INIT_FAILURE: 'SESSIONS_UPLOAD_INIT_FAILURE',
    SESSIONS_CALIBRATE_SESSION: 'SESSIONS_CALIBRATE_SESSION',
    SESSIONS_CALIBRATE_SUCCESS: 'SESSIONS_CALIBRATE_SUCCESS',
    SESSIONS_CALIBRATE_FAILURE: 'SESSIONS_CALIBRATE_FAILURE',
    SESSIONS_CALIBRATE_ONGOING: 'SESSIONS_CALIBRATE_ONGOING',
    SESSIONS_EXPORT_IN_PROGRESS: 'SESSIONS_EXPORT_IN_PROGRESS',
    SESSIONS_EXPORT_NO_ANNOTATION: 'SESSIONS_EXPORT_NO_ANNOTATION',
    SESSIONS_EXPORT_FAILURE: 'SESSIONS_EXPORT_FAILURE',
    SESSIONS_ML_SUCCESS: 'SESSIONS_ML_SUCCESS',
    SESSIONS_ML_FAILURE: 'SESSIONS_ML_FAILURE',
    AI_ANALYSIS: 'AI_ANALYSIS',
    ENLARGED_IMAGE: 'ENLARGED_IMAGE',
    NO_DATA_FOR_NETWORK_MAP: 'NO_DATA_FOR_NETWORK_MAP',
    NETWORK_MAP_LOADER: 'NETWORK_MAP_LOADER',
    REFRESH_ALL_SESSIONS_GRADE_LOADING: 'REFRESH_ALL_SESSIONS_GRADE_LOADING',
    SESSIONS_NONE: 'SESSIONS_NONE',
    COMPUTE_ZONES: 'COMPUTE_ZONES',
    COMPUTE_ZONES_FAILURE: 'COMPUTE_ZONES_FAILURE',
});

const initialState = { modalToDisplay: '' };

const modalReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ModalActions.SET_MODAL_TO_DISPLAY:
            return { ...state, modalToDisplay: payload.modalToDisplay };
        default:
            return state;
    }
};

export default modalReducer;
