import styled from 'styled-components';
import { Form } from 'redux-form';
import { Error as ErrorBase, PrimaryButton as PrimaryButtonBase } from '../../../style';

export const Error = styled(ErrorBase).attrs({
    margin: '5px 0 0 0',
})``;

export const PrimaryButton = styled(PrimaryButtonBase).attrs({
    margin: '30px 0 0 0',
})``;

export const StyledForm = styled(Form)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 600px;
`;
