import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionsModalTypes } from '../../../../../core/legacy/reducers/modals';
import SuccessModalWithTip from '../../../../legacy/components/ui/modals/SuccessModalWithTip';

export const MlSuccessModal: FC = () => {
    const { t } = useTranslation();

    return (
        <SuccessModalWithTip
            title={t('run-ml.success.title')}
            headline={t('run-ml.success.tipTitle')}
            body={t('run-ml.success.tipDescription')}
            modalType={SessionsModalTypes.SESSIONS_ML_SUCCESS}
        />
    );
};
