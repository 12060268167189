import { createCommand } from '../../utils';
import { moduleName, rubricToDisplayIdsUpdated } from '../state';
import { UpdateFiltersCommand } from './UpdateFiltersCommand';
import { uniq } from 'ramda';

export const AddRubricsToDisplayIdCommand = createCommand(
    `${moduleName}/ADD_RUBRIC_TO_DISPLAY_ID`,
    async (rubricId: string, { dispatch, extra }) => {
        const previouslyStoredRubricIds: Array<string> = [];
        const rubricIdsFromProvider = extra.storageProvider.getRubricIds();
        if (rubricIdsFromProvider) {
            previouslyStoredRubricIds.push(...rubricIdsFromProvider);
        }

        const newStoredRubricIds: Array<string> = uniq([...previouslyStoredRubricIds, rubricId]);

        extra.storageProvider.setRubricIds(newStoredRubricIds);
        dispatch(rubricToDisplayIdsUpdated({ rubricIds: newStoredRubricIds }));

        dispatch(UpdateFiltersCommand());
    },
);
