import { forwardRef, ReactNode } from 'react';
import { RegularText } from '../../../../design_system/texts';

type LabelProps = {
    children: ReactNode;
    left: number;
    top: number;
};

export const Label = forwardRef<HTMLSpanElement, LabelProps>(({ children, left, top }, ref) => {
    return (
        <RegularText
            ref={ref}
            sx={{
                left,
                top,
                position: 'absolute',
                whiteSpace: 'nowrap',
            }}
        >
            {children}
        </RegularText>
    );
});
