import { DeepPartial } from '../../../types';
import type { Session, SessionId } from '../../domain/Session';
import { createCommand } from '../../utils';
import { moduleName, updatedSession } from '../state';

interface EditSessionParams {
    sessionId: SessionId;
    sessionData: DeepPartial<Session>;
}

const editSession = createCommand(
    `${moduleName}/EDIT_CAMPAIGN`,
    async ({ sessionData, sessionId }: EditSessionParams, { dispatch, extra }) => {
        const [session] = await extra.externalSessions.editSessions([sessionId], sessionData);

        dispatch(updatedSession(session as Session));
    },
);

export default editSession;
