import { MenuList } from '@mui/material';
import type { FC } from 'react';
import {
    selectedReferenceByRubricIdQuery,
    sortedReferencesByRubricIdQuery,
} from '../../../../../core/catalogs/queries';
import type { Reference } from '../../../../../core/domain/Roaddata';
import { translateReference } from '../../../../../core/legacy/utils/translations';
import { SelectReferenceByRubricIdCommand } from '../../../../../core/catalogs/commands/SelectReferenceByRubricIdCommand';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { StyledMenu, StyledMenuItem } from '../../ui/Menu/style';
import { selectedRubricSelector } from '../../../../../core/legacy/selectors/streetView';

export type Position = {
    left: number;
    top: number;
};

type Props = {
    open: boolean;
    position: Position;
    handleCloseContextMenu: () => void;
};

export const ToolContextMenu: FC<Props> = ({ open, position, handleCloseContextMenu }) => {
    const dispatch = useAppDispatch();
    const selectedRubric = useAppSelector(selectedRubricSelector);
    const references = useAppSelector(sortedReferencesByRubricIdQuery(selectedRubric?.id));
    const handleClickItem = (reference: Reference) => () => {
        if (selectedRubric) {
            dispatch(SelectReferenceByRubricIdCommand({ rubricId: selectedRubric.id, reference }));
        }
        handleCloseContextMenu();
    };

    const selectedRubriceference = useAppSelector(selectedReferenceByRubricIdQuery(selectedRubric?.id));

    return (
        <StyledMenu
            open={open}
            anchorPosition={position}
            anchorReference="anchorPosition"
            onClose={handleCloseContextMenu}
        >
            <MenuList>
                {references.map((reference) => (
                    <StyledMenuItem
                        key={reference.id}
                        selected={reference.id === selectedRubriceference?.id}
                        onClick={handleClickItem(reference)}
                    >
                        {translateReference(reference)}
                    </StyledMenuItem>
                ))}
            </MenuList>
        </StyledMenu>
    );
};
