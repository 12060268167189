import type { FC } from 'react';
import { Button, ButtonProps, SxProps, Theme } from '@mui/material';
import { theme } from '../../../legacy/style/theme';

type ButtonVariant = 'primary' | 'secondary' | 'secondaryGrey' | 'error';

interface ButtonColors {
    text: string;
    background: string;
}

interface BaseButtonProps extends Omit<ButtonProps, 'color' | 'variant'> {
    variant?: ButtonVariant;
}

const getColorSx = (color?: ButtonVariant, overrides?: SxProps<Theme>): SxProps => {
    let chosenColors: { main: ButtonColors; hover?: ButtonColors };
    const { color: themeColor } = theme;

    switch (color) {
        case 'secondary':
            chosenColors = {
                main: { background: themeColor.white.main, text: themeColor.black.main },
                hover: { background: themeColor.grey.medium, text: themeColor.black.main },
            };
            break;
        case 'secondaryGrey':
            chosenColors = {
                main: { background: themeColor.blue.grey, text: themeColor.black.main },
                hover: { background: themeColor.grey.medium, text: themeColor.black.main },
            };
            break;
        case 'error':
            chosenColors = {
                main: { background: themeColor.red.primary, text: themeColor.white.main },
                hover: { background: themeColor.red.darker, text: themeColor.white.main },
            };
            break;
        case 'primary':
        default:
            chosenColors = {
                main: { background: themeColor.blue.primary, text: themeColor.white.main },
                hover: { background: themeColor.black.main, text: themeColor.white.main },
            };
    }

    return {
        color: chosenColors.main.text,
        bgcolor: chosenColors.main.background,
        ':hover': {
            boxShadow: theme.boxShadow.light,
            ...(chosenColors.hover ? { color: chosenColors.hover.text, bgcolor: chosenColors.hover.background } : {}),
        },
        ':disabled': {
            bgcolor: chosenColors.main.background,
            color: chosenColors.main.text,
            cursor: 'not-allowed',
            pointerEvents: 'auto',
            opacity: 0.5,
        },
        ...overrides,
    };
};

export const getButtonSx = (color?: ButtonVariant, overrides?: SxProps<Theme>): SxProps<Theme> => {
    const baseSx: SxProps<Theme> = {
        height: 40,
        textTransform: 'unset',
        gap: 1,
        fontWeight: '700',
        boxShadow: 'none',
        minWidth: 'fit-content',
    };

    const buttonColors: SxProps<Theme> = getColorSx(color, overrides);

    return { ...baseSx, ...buttonColors };
};

const BaseButton: FC<BaseButtonProps> = ({ onClick, sx, variant, ...buttonProps }) => (
    <Button variant="contained" onClick={onClick} sx={getButtonSx(variant, sx)} {...buttonProps} />
);

export default BaseButton;
