import { Menu, MenuItem } from '@mui/material';
import { useState, FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportIcon } from '../../../design_system/icons/ExportIcon';
import { theme } from '../../../legacy/style/theme';
import { useAppDispatch } from '../../../../types';
import { ExportSessionsRoadDatasCommand } from '../../../../core/sessions/commands/ExportSessionsRoadDatasCommand';
import { ExportSessionsReportCommand } from '../../../../core/sessions/commands/ExportSessionsReportCommand';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import BaseButton from '../../../design_system/inputs/buttons/BaseButton';
import { ExportSectionsReportCommand } from '../../../../core/sessions/commands/ExportSectionsReportCommand';
import { ExportGeojsonSectionsReportCommand } from '../../../../core/sessions/commands/ExportGeojsonSectionReportCommand';

interface ExportButton {
    action: () => void;
    title: string;
}

interface SessionExportsMenuProps {
    sessionIds: string[];
}

const SessionExportsMenu: FC<SessionExportsMenuProps> = ({ sessionIds }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [anchor, setAnchor] = useState<Element | null>(null);

    const handleOpen: MouseEventHandler = (event) => {
        event.stopPropagation();
        setAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    };

    const buttons: ExportButton[] = [
        {
            action: () => dispatch(ExportSessionsRoadDatasCommand({ sessionIds })),
            title: t('session.export.road_datas'),
        },
        { action: () => dispatch(ExportSessionsReportCommand({ sessionIds })), title: t('session.export.report') },
        {
            action: () => dispatch(ExportSectionsReportCommand({ sessionIds })),
            title: t('session.export.sectionsCsv'),
        },
        {
            action: () => dispatch(ExportGeojsonSectionsReportCommand(sessionIds)),
            title: t('session.export.sectionsGeojson'),
        },
    ];

    const isOpen = anchor !== null;

    return (
        <>
            <BaseButton onClick={handleOpen} variant="secondary">
                {t('session.export.title')}
                {isOpen ? <ExpandLess /> : <ExpandMore />}
            </BaseButton>
            <Menu
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                onClose={handleClose}
                open={isOpen}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {buttons.map(({ action, title }) => (
                    <MenuItem
                        key={title}
                        onClick={() => {
                            action();
                            handleClose();
                        }}
                        sx={{ color: theme.color.black.main, fontSize: 14, fontWeight: 'bold', gap: 1 }}
                    >
                        <ExportIcon />
                        {title}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default SessionExportsMenu;
