import type { SessionUpload, SessionUploadBlock, UploadStatuses } from '../../domain/SessionUpload';
import { sessionUploadByUrlQuery } from '../queries';
import {
    sessionUploadBlockListUpdated,
    sessionUploadFailedBlocksUpdated,
    sessionUploadStatusUpdated,
    sessionUploadUploadedSizeIncremented,
    sessionUploadUploadedSizeUpdated,
} from '../state';

export interface Accessor {
    incrementUploadedSize(blockSize: number): Promise<void>;
    updateUploadStatus(status: UploadStatuses): Promise<void>;
    updateBlockList(blockList: Array<string>): Promise<void>;
    updateFailedBlocks(failedBlocks: Array<SessionUploadBlock>): Promise<void>;
    updateUploadedSize(): Promise<void>;
    reloadSessionUpload(): SessionUpload;
    clearSessionUploads(sessionUploads: Array<SessionUpload>): void;
}

export const createAccessor = ({ url }: SessionUpload, dispatch, extra, getState): Accessor => {
    const updateUploadStatus = async (status: UploadStatuses) => {
        dispatch(sessionUploadStatusUpdated({ url, status }));
    };

    const incrementUploadedSize = async (blockSize: number) => {
        dispatch(sessionUploadUploadedSizeIncremented({ url, blockSize }));
    };

    const updateBlockList = async (blockList: Array<string>) => {
        dispatch(sessionUploadBlockListUpdated({ url, blockList }));
    };

    const updateFailedBlocks = async (failedBlocks: Array<SessionUploadBlock>) => {
        dispatch(sessionUploadFailedBlocksUpdated({ url, failedBlocks }));
    };

    const updateUploadedSize = async () => {
        dispatch(sessionUploadUploadedSizeUpdated({ url }));
    };

    const reloadSessionUpload = () => {
        const sessionUpload = sessionUploadByUrlQuery(url)(getState());

        if (!sessionUpload) {
            throw new Error('Error during the upload');
        }

        return sessionUpload;
    };

    const clearSessionUploads = (sessionUploadsToClear: Array<SessionUpload>) => {
        extra.serializableFileProvider.clear(sessionUploadsToClear);
    };

    return {
        updateUploadStatus,
        incrementUploadedSize,
        updateBlockList,
        updateFailedBlocks,
        updateUploadedSize,
        reloadSessionUpload,
        clearSessionUploads,
    };
};
