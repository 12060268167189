import { Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MediumTextBold } from '../../../design_system/texts';

export const Title: FC = () => {
    const { t } = useTranslation();

    return (
        <MediumTextBold
            sx={{
                margin: 0,
            }}
        >
            {t('networkMap.gradesFilter.title')}
        </MediumTextBold>
    );
};

export type ControlProps = {
    children: ReactNode;
};
export const Control: FC<ControlProps> = ({ children }) => (
    <Box
        sx={{
            fontSize: '14px',
            padding: '12px 16px',
            outline: 'none',
            cursor: 'auto',
            display: 'block',
        }}
    >
        {children}
    </Box>
);

export type CloseButtonProps = {
    hideGradeFilters: (boolean) => void;
};
export const CloseButton: FC<CloseButtonProps> = ({ hideGradeFilters }) => (
    <Button
        type="button"
        onClick={hideGradeFilters}
        sx={{
            position: 'absolute',
            right: 0,
            top: 5,
        }}
    >
        <CloseIcon />
    </Button>
);
