import { selectRubric, unselectRubric } from '../../../../../core/legacy/actions';
import { FC, ReactNode, useCallback } from 'react';
import { rubricIdSelectedSelector } from '../../../../../core/legacy/selectors/streetView';
import { translateRubric } from '../../../../../core/legacy/utils/translations';
import { Stack } from '@mui/material';
import type { StackProps } from '@mui/material/Stack';
import { theme } from '../../../style/theme';
import { useAppDispatch, useAppSelector } from '../../../../../types';
import { SmallText } from '../../../../design_system/texts';

type Props = {
    title: string;
    selected: boolean;
    handleClick: () => void;
    children: ReactNode;
} & StackProps;

const RubricContainer: FC<Props> = ({ title, selected, handleClick, children }) => (
    <Stack
        onClick={handleClick}
        justifyContent="start"
        title={title}
        sx={{
            fontSize: theme.font.size.small,
            marginBottom: '1px',
            padding: '10px',
            boxSizing: 'border-box',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            ...(selected && {
                backgroundColor: theme.color.blue.primary,
            }),
            '&:hover': {
                backgroundColor: theme.color.black.lighter,
                cursor: 'pointer',
            },
        }}
    >
        {children}
    </Stack>
);

const RubricComp = ({ rubric }) => {
    const dispatch = useAppDispatch();
    const rubricName = translateRubric(rubric.name);
    const selectedRubricId = useAppSelector(rubricIdSelectedSelector);
    const selected = selectedRubricId === rubric.id;
    const handleSelect = useCallback(() => {
        if (selected) {
            dispatch(unselectRubric());
        } else {
            dispatch(selectRubric(rubric));
        }
    }, [rubric]);

    return (
        <RubricContainer handleClick={handleSelect} selected={selected} title={rubricName}>
            <SmallText color={theme.color.white.main}>{rubricName}</SmallText>
        </RubricContainer>
    );
};

export default RubricComp;
