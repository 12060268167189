import { InputBase } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { ChangeEvent, FC } from 'react';
import { EMPTY_VALUE } from '../../../utils/buildFiltersFromSessions';

interface CustomTextEditProps extends GridRenderEditCellParams {}

const CustomTextEdit: FC<CustomTextEditProps> = (props) => {
    const { api, field, id, value, hasFocus } = props;

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        api.setEditCellValue({ id, field, value: target.value });
    };

    return (
        <InputBase
            inputProps={{ style: { padding: '0 16px' } }}
            onChange={handleChange}
            type="text"
            value={value === EMPTY_VALUE ? '' : value}
            autoFocus={hasFocus}
            fullWidth
        />
    );
};

export default CustomTextEdit;
