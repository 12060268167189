import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Catalog } from '../domain/Roaddata';
import type { SelectedReferenceByRubricId } from './commands/SelectReferenceByRubricIdCommand';

export const moduleName = 'CATALOGS';

type State = {
    catalogs: Array<Catalog>;
    selectedReferenceByRubricIds: SelectedReferenceByRubricId[];
};

const initialState: State = {
    catalogs: [],
    selectedReferenceByRubricIds: [],
};

const catalogsSlice = createSlice({
    name: moduleName,
    initialState,
    reducers: {
        catalogsInitialized(state: State, action: PayloadAction<{ catalogs: Array<Catalog> }>) {
            state.catalogs = [...action.payload.catalogs];
        },
        selectedReferenceByRubricIdsUpdated(state: State, action: PayloadAction<SelectedReferenceByRubricId>) {
            const newSelectedReferenceByRubricIds = state.selectedReferenceByRubricIds.filter(
                (refByRubricId) => refByRubricId.rubricId !== action.payload.rubricId,
            );
            newSelectedReferenceByRubricIds.push(action.payload);

            state.selectedReferenceByRubricIds = newSelectedReferenceByRubricIds;
        },
    },
});

export const { catalogsInitialized, selectedReferenceByRubricIdsUpdated } = catalogsSlice.actions;
export const catalogsReducer = catalogsSlice.reducer;
