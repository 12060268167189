import type { FC } from 'react';
import { theme } from '../../../../../legacy/style/theme';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ElementData } from '../../../../../legacy/components/ui/panels/right-panel/ElementSummary/styles';
import type { Session } from '../../../../../../core/domain/Session';
import useZoomOnSession from '../../use-zoom-on-session';

export type Props = {
    session: Session;
};
const ZoomOption: FC<Props> = ({ session }) => {
    const { t } = useTranslation();
    const zoomOnSession = useZoomOnSession(session);

    const handleZoomOnSession = (e: MouseEvent) => {
        e.stopPropagation();
        zoomOnSession();
    };

    return (
        <ElementData color={theme.color.blue.primary} onClick={handleZoomOnSession}>
            <Tooltip title={t('networkMap.sessionsPanel.zoomOnSession')} placement="top" arrow>
                <CropFreeIcon />
            </Tooltip>
        </ElementData>
    );
};

export default ZoomOption;
