import { ReactComponent as BaseCheckMarkIcon } from '../../../../../../assets/icons/checkmark.svg';
import { StyledFilterReference } from './styles';
import { theme } from '../../../../style/theme';
import type { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../types';
import { referenceToDisplayIds } from '../../../../../../core/filters/queries';
import type { Reference, Rubric } from '../../../../../../core/domain/Roaddata';
import { ToggleReferenceToDisplayIdCommand } from '../../../../../../core/filters/commands/ToggleReferenceToDisplayIdCommand';

const CheckMarkIcon = () => <BaseCheckMarkIcon color={theme.color.white.main} />;

type FilterReferenceProps = {
    reference: Reference;
    rubric: Rubric;
};

const FilterReference: FC<FilterReferenceProps> = ({ reference, rubric }) => {
    const dispatch = useAppDispatch();
    const referenceToDisplay = useAppSelector(referenceToDisplayIds);
    const selected = referenceToDisplay.includes(reference.id);

    const handleClick = () => {
        dispatch(ToggleReferenceToDisplayIdCommand({ reference, rubric }));
    };

    return (
        <StyledFilterReference selected={selected} onClick={() => handleClick()}>
            {reference.name}
            {selected && <CheckMarkIcon />}
        </StyledFilterReference>
    );
};

export default FilterReference;
