import { useAppSelector } from '../../../../types';
import { useEffect, useMemo } from 'react';
import { sessionsShownQuery } from '../../../../core/networkMap/queries';
import type { SessionId } from '../../../../core/domain/Session';
import type { Expression } from 'mapbox-gl';
import { LINESTRING_FILTER } from '../network-map-road-data-layer-configuration';

type FilterOnSessions = {
    sessions: Expression;
    sessionImages: Expression;
    roadDatas: Expression;
};

const useFilterOnSessions = (): FilterOnSessions => {
    const sessionsShown: SessionId[] = useAppSelector(sessionsShownQuery);

    useEffect(() => {
        const popups = document.getElementsByClassName('mapboxgl-popup');
        Array.from(popups).forEach((popup) => {
            popup.remove();
        });
    }, [sessionsShown]);

    const buildFilter = useMemo((): FilterOnSessions => {
        const sessionsIdsList = sessionsShown.length > 0 ? sessionsShown : [''];
        const filterBySessionId: Expression = ['in', 'sessionId', ...sessionsIdsList];
        const filterById: Expression = ['in', 'id', ...sessionsIdsList];

        return {
            sessions: filterById,
            sessionImages: filterBySessionId,
            roadDatas: [...LINESTRING_FILTER, filterBySessionId],
        };
    }, [sessionsShown]);

    return buildFilter;
};

export default useFilterOnSessions;
