import Modal from 'styled-react-modal';
import styled from 'styled-components';
import { Text, SubmitButton as SubmitButtonBase, Flex as FlexBase } from '../../../../style';

export const StyledModal = Modal.styled`
  width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.white.main};
  border-radius: 15px;
  padding: 24px 24px 0px 24px;
  box-sizing: border-box;
`;

export const Title = styled(Text)`
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 30px;
`;

export const SubmitButton = styled(SubmitButtonBase)`
    margin: 0;
`;

export const CloseContainer = styled(FlexBase).attrs({ justify: 'right' })``;

export const ButtonsContainer = styled(FlexBase).attrs({ justify: 'right' })`
    margin: 20px 0 20px 0;
    width: 100%;
`;

export const ContentContainer = styled(FlexBase).attrs({ justify: 'left' })``;
