import { Box } from '@mui/material';
import type { FC } from 'react';

type Props = {
    seconds?: number;
};

export const ETA: FC<Props> = ({ seconds }) => {
    const min = Math.round((seconds ?? 0) / 60);

    return <Box component="span">{min} min</Box>;
};
