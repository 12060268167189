import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { SubmissionError } from 'redux-form';
import apiEndpoint from '../../core/legacy/utils/api';

export const getRoadDataIntervalForImageRequest = async (
    imageId: string,
    rubricToDisplayIds: string[],
    referenceToDisplayIds: string[],
    taggedElementsOnly: boolean,
    imageRoaddataOnly,
) => {
    try {
        const { data } = await axios.request({
            method: 'POST',
            url: `${apiEndpoint}/image/${imageId}/roaddata-interval`,
            data: {
                rubricToDisplayIds,
                referenceToDisplayIds,
                taggedElementsOnly,
                imageRoaddataOnly,
            },
            withCredentials: true,
        });

        if (rubricToDisplayIds.length === 0) {
            return {
                roaddatas: [],
                totalSize: data.totalSize,
                rubricIdToCount: data.rubricIdToCount,
            };
        }

        return data;
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw new SubmissionError({ _error: 'serverErrors.whileGettingRoadDataInterval' });
    }
};
